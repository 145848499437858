import { Pipe, PipeTransform } from '@angular/core';
import { US_STATES } from '@libs/constants/us-states.constant';
import { airports } from '@nwpr/airport-codes';

@Pipe({
  name: 'airportCity',
  standalone: true,
})
export class AirportCityPipe implements PipeTransform {
  transform(value: string): string {
    const airport = airports.find((ap: any) => ap.iata === value);
    const state = US_STATES.find((s: any) => s.cities.includes(airport?.city));

    let cityName = `(${value}) ${airport?.city}`;
    if (state) cityName = `${cityName}, ${state.abbreviation}`;

    return cityName;
  }
}

[
  {
    "code": "AAA",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "AAA",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "AAB",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AAB",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AAC",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "AAC",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "AAD",
    "countryCode": "SO",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AAD",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AAD",
    "countryCode": "SO",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AAE",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "AAE",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "AAF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AAF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AAG",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "AAG",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "AAH",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AAH",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AAI",
    "countryCode": "BR",
    "timezone": "America/Araguaina",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "AAI",
    "countryCode": "BR",
    "timezone": "America/Araguaina",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "AAJ",
    "countryCode": "SR",
    "timezone": "America/Paramaribo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "AAJ",
    "countryCode": "SR",
    "timezone": "America/Paramaribo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "AAJ",
    "countryCode": "SR",
    "timezone": "America/Paramaribo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "AAK",
    "countryCode": "KI",
    "timezone": "Pacific/Tarawa",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "AAK",
    "countryCode": "KI",
    "timezone": "Pacific/Tarawa",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "AAL",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AAL",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AAM",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "AAM",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "AAN",
    "countryCode": "AE",
    "timezone": "Asia/Dubai",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "AAN",
    "countryCode": "AE",
    "timezone": "Asia/Dubai",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "AAO",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "AAO",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "AAP",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "AAP",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "AAQ",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AAQ",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AAR",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AAR",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AAS",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "AAT",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "AAT",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "AAU",
    "countryCode": "WS",
    "timezone": "Pacific/Apia",
    "offset": {
      "gmt": 13,
      "dst": 13
    }
  },
  {
    "code": "AAU",
    "countryCode": "WS",
    "timezone": "Pacific/Apia",
    "offset": {
      "gmt": 13,
      "dst": 13
    }
  },
  {
    "code": "AAV",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "AAV",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "AAW",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "AAW",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AAX",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "AAX",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "AAY",
    "countryCode": "YE",
    "timezone": "Asia/Aden",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AAY",
    "countryCode": "YE",
    "timezone": "Asia/Aden",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AAZ",
    "countryCode": "GT",
    "timezone": "America/Guatemala",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "AAZ",
    "countryCode": "GT",
    "timezone": "America/Guatemala",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "ABA",
    "countryCode": "RU",
    "timezone": "Asia/Krasnoyarsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "ABA",
    "countryCode": "RU",
    "timezone": "Asia/Krasnoyarsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "ABB",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ABB",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "ABB",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ABC",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ABC",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ABD",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ABD",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ABE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ABE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ABF",
    "countryCode": "KI",
    "timezone": "Pacific/Tarawa",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "ABF",
    "countryCode": "KI",
    "timezone": "Pacific/Tarawa",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "ABG",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ABG",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ABH",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ABH",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ABI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ABI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ABJ",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "ABJ",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "ABK",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ABK",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ABL",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "ABL",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "ABM",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ABM",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ABN",
    "countryCode": "SR",
    "timezone": "America/Paramaribo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ABN",
    "countryCode": "SR",
    "timezone": "America/Paramaribo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ABO",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "ABO",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "ABP",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ABQ",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "ABQ",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "ABR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ABR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ABS",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "ABS",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "ABT",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ABT",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ABU",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ABU",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ABV",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ABV",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ABW",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ABX",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "ABX",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "ABY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ABY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ABZ",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "ABZ",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "ACA",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "ACA",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "ACB",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ACB",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ACC",
    "countryCode": "GH",
    "timezone": "Africa/Accra",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "ACC",
    "countryCode": "GH",
    "timezone": "Africa/Accra",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "ACD",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ACE",
    "countryCode": "ES",
    "timezone": "Atlantic/Canary",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "ACE",
    "countryCode": "ES",
    "timezone": "Atlantic/Canary",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "ACE",
    "countryCode": "ES",
    "timezone": "Atlantic/Canary",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "ACF",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "ACF",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "ACH",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ACH",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ACI",
    "countryCode": "GG",
    "timezone": "Europe/Guernsey",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "ACI",
    "countryCode": "GG",
    "timezone": "Europe/Guernsey",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "ACJ",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "ACJ",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "ACK",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ACK",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ACL",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ACL",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ACM",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ACM",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ACN",
    "countryCode": "MX",
    "timezone": "America/Matamoros",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ACN",
    "countryCode": "MX",
    "timezone": "America/Matamoros",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ACO",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "ACO",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ACO",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "ACP",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ACP",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ACQ",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ACR",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ACR",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ACS",
    "countryCode": "RU",
    "timezone": "Asia/Krasnoyarsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "ACS",
    "countryCode": "RU",
    "timezone": "Asia/Krasnoyarsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "ACT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ACT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ACU",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ACU",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ACV",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ACV",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ACX",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ACX",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ACY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ACY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ACZ",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ACZ",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ADA",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ADA",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ADB",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ADC",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ADC",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ADD",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ADD",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ADE",
    "countryCode": "YE",
    "timezone": "Asia/Aden",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ADE",
    "countryCode": "YE",
    "timezone": "Asia/Aden",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ADF",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ADF",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ADG",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ADG",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ADH",
    "countryCode": "RU",
    "timezone": "Asia/Yakutsk",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "ADH",
    "countryCode": "RU",
    "timezone": "Asia/Yakutsk",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "ADI",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "ADI",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "ADJ",
    "countryCode": "JO",
    "timezone": "Asia/Amman",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ADJ",
    "countryCode": "JO",
    "timezone": "Asia/Amman",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ADK",
    "countryCode": "US",
    "timezone": "America/Adak",
    "offset": {
      "gmt": -10,
      "dst": -9
    }
  },
  {
    "code": "ADK",
    "countryCode": "US",
    "timezone": "America/Adak",
    "offset": {
      "gmt": -10,
      "dst": -9
    }
  },
  {
    "code": "ADL",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "ADL",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "ADM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ADM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ADN",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ADN",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ADO",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "ADO",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "ADP",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "ADP",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "ADQ",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "ADQ",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "ADR",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ADR",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ADS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ADT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ADT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ADU",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ADU",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ADV",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "ADV",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "ADV",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "ADW",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ADW",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ADX",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "ADX",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "ADY",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "ADY",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "ADZ",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ADZ",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "AEA",
    "countryCode": "KI",
    "timezone": "Pacific/Tarawa",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "AEA",
    "countryCode": "KI",
    "timezone": "Pacific/Tarawa",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "AEB",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "AEB",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "AED",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "AEE",
    "countryCode": "SS",
    "timezone": "Africa/Juba",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "AEG",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "AEG",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "AEH",
    "countryCode": "TD",
    "timezone": "Africa/Ndjamena",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "AEI",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AEI",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AEK",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AEK",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AEL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "AEL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "AEM",
    "countryCode": "RU",
    "timezone": "Asia/Vladivostok",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AEM",
    "countryCode": "RU",
    "timezone": "Asia/Vladivostok",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AEN",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AEO",
    "countryCode": "MR",
    "timezone": "Africa/Nouakchott",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "AEP",
    "countryCode": "AR",
    "timezone": "America/Argentina/Buenos_Aires",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "AEQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "AER",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AER",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AES",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AES",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AET",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "AET",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "AEU",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AEU",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AEX",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "AEX",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "AEY",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "AEY",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "AFA",
    "countryCode": "AR",
    "timezone": "America/Argentina/Mendoza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "AFA",
    "countryCode": "AR",
    "timezone": "America/Argentina/Mendoza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "AFD",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "AFD",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "AFF",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "AFI",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "AFI",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "AFK",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "AFL",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "AFL",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "AFN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AFN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AFO",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "AFO",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "AFP",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AFR",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AFR",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AFS",
    "countryCode": "UZ",
    "timezone": "Asia/Samarkand",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "AFS",
    "countryCode": "UZ",
    "timezone": "Asia/Samarkand",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "AFT",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "AFT",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "AFW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "AFY",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AFY",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AFZ",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AFZ",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AGA",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "AGA",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "AGB",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AGC",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AGD",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "AGE",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AGE",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AGF",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AGF",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AGG",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AGH",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AGH",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AGH",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AGI",
    "countryCode": "SR",
    "timezone": "America/Paramaribo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "AGI",
    "countryCode": "SR",
    "timezone": "America/Paramaribo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "AGJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "AGJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "AGK",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AGK",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AGL",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AGL",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AGM",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "AGM",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "AGN",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "AGN",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "AGO",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "AGO",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "AGP",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AGP",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AGQ",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "AGR",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "AGR",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "AGS",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AGS",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AGT",
    "countryCode": "PY",
    "timezone": "America/Asuncion",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "AGT",
    "countryCode": "PY",
    "timezone": "America/Asuncion",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "AGU",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "AGU",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "AGV",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "AGV",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "AGW",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AGW",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AGX",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "AGX",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "AGY",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AGY",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AGZ",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "AGZ",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "AHA",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "AHB",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AHB",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AHC",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "AHC",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "AHD",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "AHE",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "AHE",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "AHF",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "AHF",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "AHH",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "AHH",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "AHI",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "AHI",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "AHJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "AHJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "AHL",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "AHL",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "AHM",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "AHM",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "AHN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AHN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AHO",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AHO",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AHS",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "AHT",
    "countryCode": "US",
    "timezone": "America/Adak",
    "offset": {
      "gmt": -10,
      "dst": -9
    }
  },
  {
    "code": "AHT",
    "countryCode": "US",
    "timezone": "America/Adak",
    "offset": {
      "gmt": -10,
      "dst": -9
    }
  },
  {
    "code": "AHU",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "AHU",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "AHW",
    "countryCode": "OM",
    "timezone": "Asia/Muscat",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "AHY",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AHY",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AHZ",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AHZ",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AIA",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "AIA",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "AIB",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "AIB",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "AIC",
    "countryCode": "MH",
    "timezone": "Pacific/Kwajalein",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "AIC",
    "countryCode": "MH",
    "timezone": "Pacific/Majuro",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "AID",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AID",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AIE",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AIE",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AIF",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "AIF",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "AIG",
    "countryCode": "CF",
    "timezone": "Africa/Bangui",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "AIG",
    "countryCode": "CF",
    "timezone": "Africa/Bangui",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "AIH",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AIH",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AII",
    "countryCode": "DJ",
    "timezone": "Africa/Djibouti",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AII",
    "countryCode": "DJ",
    "timezone": "Africa/Djibouti",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AIK",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AIK",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AIL",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "AIL",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "AIM",
    "countryCode": "MH",
    "timezone": "Pacific/Kwajalein",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "AIM",
    "countryCode": "MH",
    "timezone": "Pacific/Kwajalein",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "AIN",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "AIN",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "AIO",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "AIO",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "AIP",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "AIP",
    "countryCode": "MH",
    "timezone": "Pacific/Kwajalein",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "AIP",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "AIR",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "AIR",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "AIS",
    "countryCode": "KI",
    "timezone": "Pacific/Tarawa",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "AIS",
    "countryCode": "KI",
    "timezone": "Pacific/Tarawa",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "AIT",
    "countryCode": "CK",
    "timezone": "Pacific/Rarotonga",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "AIT",
    "countryCode": "CK",
    "timezone": "Pacific/Rarotonga",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "AIU",
    "countryCode": "CK",
    "timezone": "Pacific/Rarotonga",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "AIU",
    "countryCode": "CK",
    "timezone": "Pacific/Rarotonga",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "AIV",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "AIV",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "AIW",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "AIW",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "AIX",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AIY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AIY",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "AIY",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "AIY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AIZ",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "AIZ",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "AJA",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AJA",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AJF",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AJF",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AJI",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AJI",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AJJ",
    "countryCode": "MR",
    "timezone": "Africa/Nouakchott",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "AJJ",
    "countryCode": "MR",
    "timezone": "Africa/Nouakchott",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "AJK",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AJK",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AJL",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "AJL",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "AJL",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "AJN",
    "countryCode": "KM",
    "timezone": "Indian/Comoro",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AJN",
    "countryCode": "KM",
    "timezone": "Indian/Comoro",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AJN",
    "countryCode": "KM",
    "timezone": "Indian/Comoro",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AJO",
    "countryCode": "YE",
    "timezone": "Asia/Aden",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AJR",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AJR",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AJS",
    "countryCode": "MX",
    "timezone": "America/Hermosillo",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "AJS",
    "countryCode": "MX",
    "timezone": "America/Hermosillo",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "AJU",
    "countryCode": "BR",
    "timezone": "America/Maceio",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "AJU",
    "countryCode": "BR",
    "timezone": "America/Maceio",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "AJY",
    "countryCode": "NE",
    "timezone": "Africa/Niamey",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "AJY",
    "countryCode": "NE",
    "timezone": "Africa/Niamey",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "AKA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "AKA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "AKB",
    "countryCode": "US",
    "timezone": "America/Adak",
    "offset": {
      "gmt": -10,
      "dst": -9
    }
  },
  {
    "code": "AKB",
    "countryCode": "US",
    "timezone": "America/Adak",
    "offset": {
      "gmt": -10,
      "dst": -9
    }
  },
  {
    "code": "AKC",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AKD",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "AKE",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "AKE",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "AKF",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "AKF",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "AKF",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "AKG",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AKG",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AKH",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AKH",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AKI",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "AKI",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "AKJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "AKJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "AKK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "AKK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "AKL",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "AKL",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "AKM",
    "countryCode": "TD",
    "timezone": "Africa/Ndjamena",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "AKN",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "AKN",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "AKO",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "AKO",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "AKP",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "AKP",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "AKQ",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "AKR",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "AKR",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "AKS",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "AKS",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "AKT",
    "countryCode": "GB",
    "timezone": "Asia/Nicosia",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "AKU",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "AKU",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "AKV",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AKV",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AKW",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AKW",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AKX",
    "countryCode": "KZ",
    "timezone": "Asia/Aqtobe",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "AKX",
    "countryCode": "KZ",
    "timezone": "Asia/Aqtobe",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "AKY",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "AKY",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "ALA",
    "countryCode": "KZ",
    "timezone": "Asia/Almaty",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "ALA",
    "countryCode": "KZ",
    "timezone": "Asia/Almaty",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "ALB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ALB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ALC",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ALC",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ALD",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ALD",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ALE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ALE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ALF",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ALF",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ALG",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ALG",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ALH",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ALH",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ALI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ALI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ALJ",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "ALJ",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "ALK",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ALL",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ALL",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ALM",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "ALM",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "ALN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ALN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ALO",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ALO",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ALP",
    "countryCode": "SY",
    "timezone": "Asia/Damascus",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ALP",
    "countryCode": "SY",
    "timezone": "Asia/Damascus",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ALQ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ALQ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ALR",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "ALR",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "ALS",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "ALS",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "ALT",
    "countryCode": "BR",
    "timezone": "America/Santarem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ALT",
    "countryCode": "BR",
    "timezone": "America/Santarem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ALU",
    "countryCode": "SO",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ALU",
    "countryCode": "SO",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ALV",
    "countryCode": "AD",
    "timezone": "Europe/Andorra",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ALV",
    "countryCode": "AD",
    "timezone": "Europe/Andorra",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ALW",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ALW",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ALX",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ALX",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ALY",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "ALY",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "ALZ",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "ALZ",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "AMA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "AMA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "AMB",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AMB",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AMC",
    "countryCode": "TD",
    "timezone": "Africa/Ndjamena",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "AMC",
    "countryCode": "TD",
    "timezone": "Africa/Ndjamena",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "AMD",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "AMD",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "AME",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "AME",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "AMF",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AMF",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AMG",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AMG",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AMH",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AMH",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AMI",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "AMI",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "AMJ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "AMJ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "AMK",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "AML",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "AML",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "AMM",
    "countryCode": "JO",
    "timezone": "Asia/Amman",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AMM",
    "countryCode": "JO",
    "timezone": "Asia/Amman",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AMN",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AMN",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AMO",
    "countryCode": "TD",
    "timezone": "Africa/Ndjamena",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "AMO",
    "countryCode": "TD",
    "timezone": "Africa/Ndjamena",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "AMP",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AMP",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AMQ",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "AMQ",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "AMR",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AMS",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AMS",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AMS",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AMT",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "AMT",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "AMU",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AMU",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AMV",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AMV",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AMW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "AMW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "AMX",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "AMY",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AMY",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AMZ",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "ANA",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ANA",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ANA",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ANB",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ANB",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ANC",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "ANC",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "AND",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AND",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ANE",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ANE",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ANF",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "ANF",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "ANG",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ANG",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ANG",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ANG",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ANH",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "ANI",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "ANI",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "ANJ",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ANJ",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ANK",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ANK",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ANL",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ANL",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ANM",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ANM",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ANM",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ANN",
    "countryCode": "US",
    "timezone": "America/Metlakatla",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "ANN",
    "countryCode": "US",
    "timezone": "America/Metlakatla",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "ANO",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "ANP",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ANP",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ANQ",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ANQ",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ANR",
    "countryCode": "BE",
    "timezone": "Europe/Brussels",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ANR",
    "countryCode": "BE",
    "timezone": "Europe/Brussels",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ANS",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ANS",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ANT",
    "countryCode": "AT",
    "timezone": "Europe/Vienna",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ANT",
    "countryCode": "AT",
    "timezone": "Europe/Vienna",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ANU",
    "countryCode": "AG",
    "timezone": "America/Antigua",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "ANU",
    "countryCode": "AG",
    "timezone": "America/Antigua",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "ANV",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "ANV",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "ANW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ANW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ANX",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ANX",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ANY",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ANY",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ANZ",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "AOA",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AOB",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AOC",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AOC",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AOD",
    "countryCode": "TD",
    "timezone": "Africa/Ndjamena",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "AOE",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AOG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "AOG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "AOH",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AOH",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AOI",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AOI",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AOJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "AOJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "AOK",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "AOK",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "AOL",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "AOL",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "AOM",
    "countryCode": "OM",
    "timezone": "Asia/Muscat",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "AON",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AOO",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AOO",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AOP",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "AOP",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "AOQ",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "AOQ",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "AOR",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "AOR",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "AOS",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "AOS",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "AOT",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AOT",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AOU",
    "countryCode": "LA",
    "timezone": "Asia/Vientiane",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "AOU",
    "countryCode": "LA",
    "timezone": "Asia/Vientiane",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "APA",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "APB",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "APB",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "APC",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "APC",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "APD",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "APF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "APF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "APG",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "APG",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "APH",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "API",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "API",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "APJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "APK",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "APK",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "APL",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "APL",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "APN",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "APN",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "APO",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "APO",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "APP",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "APQ",
    "countryCode": "BR",
    "timezone": "America/Maceio",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "APQ",
    "countryCode": "BR",
    "timezone": "America/Maceio",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "APR",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "APR",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "APS",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "APS",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "APT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "APT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "APU",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "APU",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "APV",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "APV",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "APW",
    "countryCode": "WS",
    "timezone": "Pacific/Apia",
    "offset": {
      "gmt": 13,
      "dst": 13
    }
  },
  {
    "code": "APW",
    "countryCode": "WS",
    "timezone": "Pacific/Apia",
    "offset": {
      "gmt": 13,
      "dst": 13
    }
  },
  {
    "code": "APX",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "APX",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "APY",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "APZ",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "APZ",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "AQA",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "AQA",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "AQB",
    "countryCode": "GT",
    "timezone": "America/Guatemala",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "AQB",
    "countryCode": "GT",
    "timezone": "America/Guatemala",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "AQG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "AQG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "AQI",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AQI",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AQJ",
    "countryCode": "JO",
    "timezone": "Asia/Amman",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AQJ",
    "countryCode": "JO",
    "timezone": "Asia/Amman",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AQL",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AQM",
    "countryCode": "BR",
    "timezone": "America/Porto_Velho",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "AQP",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "AQP",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "AQS",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "AQY",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "AQY",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "AQY",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "ARA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ARA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ARB",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ARB",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ARC",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "ARC",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "ARD",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ARD",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ARE",
    "countryCode": "PR",
    "timezone": "America/Puerto_Rico",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "ARE",
    "countryCode": "PR",
    "timezone": "America/Puerto_Rico",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "ARF",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ARF",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ARG",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ARG",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ARH",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ARH",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ARI",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "ARI",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "ARJ",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "ARJ",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "ARK",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ARK",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ARL",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "ARL",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "ARM",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "ARM",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "ARN",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ARN",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ARO",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ARP",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ARQ",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ARQ",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ARR",
    "countryCode": "AR",
    "timezone": "America/Argentina/Catamarca",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ARR",
    "countryCode": "AR",
    "timezone": "America/Argentina/Catamarca",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ARS",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ART",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ART",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ARU",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ARU",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ARV",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ARV",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ARW",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "ARW",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "ARX",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ARX",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ARX",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ARY",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "ARY",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "ARZ",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ASA",
    "countryCode": "ER",
    "timezone": "Africa/Asmara",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ASA",
    "countryCode": "ER",
    "timezone": "Africa/Asmara",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ASB",
    "countryCode": "TM",
    "timezone": "Asia/Ashgabat",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "ASB",
    "countryCode": "TM",
    "timezone": "Asia/Ashgabat",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "ASC",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "ASC",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "ASD",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ASD",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ASE",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "ASE",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "ASF",
    "countryCode": "RU",
    "timezone": "Europe/Astrakhan",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "ASF",
    "countryCode": "RU",
    "timezone": "Europe/Astrakhan",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "ASG",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "ASG",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "ASH",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ASH",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ASI",
    "countryCode": "SH",
    "timezone": "Atlantic/St_Helena",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "ASI",
    "countryCode": "SH",
    "timezone": "Atlantic/St_Helena",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "ASJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "ASJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "ASK",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "ASK",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "ASL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ASL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ASM",
    "countryCode": "ER",
    "timezone": "Africa/Asmara",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ASM",
    "countryCode": "ER",
    "timezone": "Africa/Asmara",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ASN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ASN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ASO",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ASO",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ASP",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "ASP",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "ASQ",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ASQ",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ASR",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ASR",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ASS",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "ASS",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "AST",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "AST",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ASU",
    "countryCode": "PY",
    "timezone": "America/Asuncion",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "ASU",
    "countryCode": "PY",
    "timezone": "America/Asuncion",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "ASV",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ASV",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ASW",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "ASW",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "ASX",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ASX",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ASY",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ASY",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ASZ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ATA",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ATA",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ATB",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "ATB",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "ATC",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ATC",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ATD",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "ATD",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "ATE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ATE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ATF",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ATF",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ATG",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "ATG",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "ATH",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "ATH",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "ATI",
    "countryCode": "UY",
    "timezone": "America/Montevideo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ATI",
    "countryCode": "UY",
    "timezone": "America/Montevideo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ATJ",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ATJ",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ATK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "ATK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "ATL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ATL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ATM",
    "countryCode": "BR",
    "timezone": "America/Santarem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ATM",
    "countryCode": "BR",
    "timezone": "America/Santarem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ATN",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ATN",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ATO",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ATO",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ATP",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ATP",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ATQ",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "ATQ",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "ATR",
    "countryCode": "MR",
    "timezone": "Africa/Nouakchott",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "ATR",
    "countryCode": "MR",
    "timezone": "Africa/Nouakchott",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "ATS",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "ATS",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "ATT",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "ATT",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "ATU",
    "countryCode": "US",
    "timezone": "America/Adak",
    "offset": {
      "gmt": -10,
      "dst": -9
    }
  },
  {
    "code": "ATU",
    "countryCode": "US",
    "timezone": "America/Adak",
    "offset": {
      "gmt": -10,
      "dst": -9
    }
  },
  {
    "code": "ATV",
    "countryCode": "TD",
    "timezone": "Africa/Ndjamena",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ATV",
    "countryCode": "TD",
    "timezone": "Africa/Ndjamena",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ATW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ATW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ATX",
    "countryCode": "KZ",
    "timezone": "Asia/Almaty",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "ATX",
    "countryCode": "KZ",
    "timezone": "Asia/Almaty",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "ATY",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ATY",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ATZ",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "ATZ",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "AUA",
    "countryCode": "AW",
    "timezone": "America/Aruba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "AUA",
    "countryCode": "AW",
    "timezone": "America/Aruba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "AUB",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "AUB",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AUB",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "AUC",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "AUC",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "AUD",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AUE",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "AUE",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "AUF",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AUF",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AUG",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AUG",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AUH",
    "countryCode": "AE",
    "timezone": "Asia/Dubai",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "AUH",
    "countryCode": "AE",
    "timezone": "Asia/Dubai",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "AUI",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AUI",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AUJ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AUJ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AUK",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "AUK",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "AUL",
    "countryCode": "MH",
    "timezone": "Pacific/Majuro",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "AUL",
    "countryCode": "MH",
    "timezone": "Pacific/Majuro",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "AUM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "AUM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "AUN",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "AUN",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "AUO",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "AUO",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "AUP",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AUP",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AUQ",
    "countryCode": "PF",
    "timezone": "Pacific/Marquesas",
    "offset": {
      "gmt": -9,
      "dst": -9
    }
  },
  {
    "code": "AUQ",
    "countryCode": "PF",
    "timezone": "Pacific/Marquesas",
    "offset": {
      "gmt": -9,
      "dst": -9
    }
  },
  {
    "code": "AUR",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AUR",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AUS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "AUS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "AUT",
    "countryCode": "TL",
    "timezone": "Asia/Dili",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "AUT",
    "countryCode": "TL",
    "timezone": "Asia/Dili",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "AUU",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AUU",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AUV",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AUV",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AUW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "AUW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "AUX",
    "countryCode": "BR",
    "timezone": "America/Araguaina",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "AUX",
    "countryCode": "BR",
    "timezone": "America/Araguaina",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "AUY",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "AUY",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "AUY",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "AUZ",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "AUZ",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "AVA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "AVA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "AVB",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AVB",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AVF",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AVG",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "AVG",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "AVI",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AVI",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AVJ",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AVJ",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AVK",
    "countryCode": "MN",
    "timezone": "Asia/Ulaanbaatar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "AVK",
    "countryCode": "MN",
    "timezone": "Asia/Ulaanbaatar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "AVL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AVL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AVN",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AVN",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AVO",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AVO",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AVP",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AVP",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AVP",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AVR",
    "countryCode": "PT",
    "timezone": "Europe/Lisbon",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "AVR",
    "countryCode": "PT",
    "timezone": "Europe/Lisbon",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "AVS",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AVT",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AVU",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "AVV",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "AVW",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "AVX",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "AVX",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "AVX",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "AVX",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "AWA",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AWA",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AWB",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AWB",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AWD",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "AWD",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "AWE",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "AWH",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AWK",
    "countryCode": "UM",
    "timezone": "Pacific/Wake",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "AWK",
    "countryCode": "UM",
    "timezone": "Pacific/Wake",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "AWM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "AWM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "AWN",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "AWP",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "AWR",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AWR",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AWZ",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AWZ",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AXA",
    "countryCode": "AI",
    "timezone": "America/Anguilla",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "AXA",
    "countryCode": "AI",
    "timezone": "America/Anguilla",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "AXB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AXC",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AXC",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AXD",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "AXD",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "AXE",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "AXF",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "AXF",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "AXG",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "AXG",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "AXI",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "AXJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "AXJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "AXK",
    "countryCode": "YE",
    "timezone": "Asia/Aden",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AXK",
    "countryCode": "YE",
    "timezone": "Asia/Aden",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AXL",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "AXM",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "AXM",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "AXN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "AXN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "AXO",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "AXP",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AXP",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AXR",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "AXR",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "AXS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "AXT",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "AXT",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "AXU",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AXU",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AXV",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AXV",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AXX",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "AXX",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "AYA",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "AYC",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "AYD",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "AYE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AYG",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "AYH",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "AYI",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "AYJ",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "AYJ",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "AYK",
    "countryCode": "KZ",
    "timezone": "Asia/Qostanay",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "AYK",
    "countryCode": "KZ",
    "timezone": "Asia/Qostanay",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "AYL",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "AYM",
    "countryCode": "AE",
    "timezone": "Asia/Dubai",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "AYM",
    "countryCode": "AE",
    "timezone": "Asia/Dubai",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "AYN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "AYN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "AYO",
    "countryCode": "PY",
    "timezone": "America/Asuncion",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "AYO",
    "countryCode": "PY",
    "timezone": "America/Asuncion",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "AYP",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "AYP",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "AYQ",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "AYQ",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "AYR",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AYR",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AYS",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AYS",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AYT",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AYT",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AYU",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AYU",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AYW",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "AYX",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "AYX",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "AYY",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "AZA",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "AZB",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "AZD",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AZD",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "AZG",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "AZG",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "AZH",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "AZH",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "AZI",
    "countryCode": "AE",
    "timezone": "Asia/Dubai",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "AZJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "AZL",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "AZM",
    "countryCode": "MO",
    "timezone": "Asia/Macau",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "AZN",
    "countryCode": "UZ",
    "timezone": "Asia/Tashkent",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "AZN",
    "countryCode": "UZ",
    "timezone": "Asia/Tashkent",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "AZO",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AZO",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "AZP",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "AZQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "AZQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "AZR",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "AZR",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "AZS",
    "countryCode": "DO",
    "timezone": "America/Santo_Domingo",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "AZS",
    "countryCode": "DO",
    "timezone": "America/Santo_Domingo",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "AZT",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "AZZ",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "AZZ",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BAA",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BAA",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BAB",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "BAC",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "BAD",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BAE",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BAE",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BAE",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BAF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BAF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BAG",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BAG",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BAH",
    "countryCode": "BH",
    "timezone": "Asia/Bahrain",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BAH",
    "countryCode": "BH",
    "timezone": "Asia/Bahrain",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BAI",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "BAI",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "BAJ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BAK",
    "countryCode": "AZ",
    "timezone": "Asia/Baku",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "BAL",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BAL",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BAM",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "BAM",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "BAN",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BAN",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BAO",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "BAO",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "BAP",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BAQ",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "BAQ",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "BAR",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BAR",
    "countryCode": "US",
    "timezone": "America/Sitka",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "BAR",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BAS",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "BAS",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "BAT",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "BAT",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "BAU",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -2,
      "dst": -3
    }
  },
  {
    "code": "BAU",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BAV",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BAV",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BAW",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BAX",
    "countryCode": "RU",
    "timezone": "Asia/Barnaul",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "BAX",
    "countryCode": "RU",
    "timezone": "Asia/Barnaul",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "BAY",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "BAY",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "BAZ",
    "countryCode": "BR",
    "timezone": "America/Manaus",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "BAZ",
    "countryCode": "BR",
    "timezone": "America/Manaus",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "BBA",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "BBA",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "BBB",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BBB",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BBC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BBC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BBD",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BBD",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BBE",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BBE",
    "countryCode": "AR",
    "timezone": "America/Argentina/Buenos_Aires",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "BBF",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BBF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BBG",
    "countryCode": "KI",
    "timezone": "Pacific/Tarawa",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "BBG",
    "countryCode": "KI",
    "timezone": "Pacific/Tarawa",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "BBH",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BBH",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BBI",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BBI",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BBJ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BBJ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BBK",
    "countryCode": "BW",
    "timezone": "Africa/Gaborone",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BBK",
    "countryCode": "BW",
    "timezone": "Africa/Gaborone",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BBL",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BBL",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 4
    }
  },
  {
    "code": "BBL",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BBM",
    "countryCode": "KH",
    "timezone": "Asia/Phnom_Penh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "BBM",
    "countryCode": "KH",
    "timezone": "Asia/Phnom_Penh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "BBN",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BBN",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BBO",
    "countryCode": "SO",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BBO",
    "countryCode": "SO",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BBP",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BBP",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BBQ",
    "countryCode": "AG",
    "timezone": "America/Antigua",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "BBQ",
    "countryCode": "AG",
    "timezone": "America/Antigua",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "BBR",
    "countryCode": "GP",
    "timezone": "America/Guadeloupe",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "BBR",
    "countryCode": "GP",
    "timezone": "America/Guadeloupe",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "BBS",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BBS",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BBT",
    "countryCode": "CF",
    "timezone": "Africa/Bangui",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BBT",
    "countryCode": "CF",
    "timezone": "Africa/Bangui",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BBU",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "BBV",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "BBV",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "BBW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BBW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BBX",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BBX",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BBY",
    "countryCode": "CF",
    "timezone": "Africa/Bangui",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BBY",
    "countryCode": "CF",
    "timezone": "Africa/Bangui",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BBZ",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BBZ",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BCA",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BCA",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BCB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BCB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BCC",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "BCC",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "BCD",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BCD",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BCE",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "BCE",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "BCF",
    "countryCode": "CF",
    "timezone": "Africa/Bangui",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BCF",
    "countryCode": "CF",
    "timezone": "Africa/Bangui",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BCG",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "BCH",
    "countryCode": "TL",
    "timezone": "Asia/Dili",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "BCH",
    "countryCode": "TL",
    "timezone": "Asia/Dili",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "BCI",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BCI",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BCJ",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "BCJ",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "BCK",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BCL",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "BCL",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "BCL",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "BCM",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "BCM",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "BCN",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BCN",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BCO",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BCP",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BCQ",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BCQ",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BCR",
    "countryCode": "BR",
    "timezone": "America/Manaus",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "BCS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BCT",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BCT",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BCU",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BCU",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BCV",
    "countryCode": "BZ",
    "timezone": "America/Belize",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "BCV",
    "countryCode": "BZ",
    "timezone": "America/Belize",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "BCW",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BCW",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BCX",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BCX",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BCY",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BCZ",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "BCZ",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "BDA",
    "countryCode": "BM",
    "timezone": "Atlantic/Bermuda",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "BDA",
    "countryCode": "BM",
    "timezone": "Atlantic/Bermuda",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "BDA",
    "countryCode": "BM",
    "timezone": "Atlantic/Bermuda",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "BDB",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BDB",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BDC",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "BDD",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BDD",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BDE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BDE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BDF",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BDF",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BDG",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "BDG",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "BDH",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BDH",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BDI",
    "countryCode": "SC",
    "timezone": "Indian/Mahe",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "BDI",
    "countryCode": "SC",
    "timezone": "Indian/Mahe",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "BDJ",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BDJ",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BDK",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "BDK",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "BDL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BDL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BDM",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BDM",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BDN",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BDN",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BDO",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "BDO",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "BDP",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BDP",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BDQ",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BDQ",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BDR",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BDR",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BDS",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BDS",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BDT",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BDT",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BDU",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BDU",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BDV",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BDV",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BDW",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BDX",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "BDX",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "BDY",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "BDY",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "BDZ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BEA",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BEB",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BEB",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BEC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BED",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BED",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BEE",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BEE",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BEF",
    "countryCode": "NI",
    "timezone": "America/Managua",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "BEF",
    "countryCode": "NI",
    "timezone": "America/Managua",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "BEG",
    "countryCode": "RS",
    "timezone": "Europe/Belgrade",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BEG",
    "countryCode": "RS",
    "timezone": "Europe/Belgrade",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BEH",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BEH",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BEI",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BEI",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BEJ",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BEJ",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BEK",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BEK",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BEL",
    "countryCode": "BR",
    "timezone": "America/Belem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "BEL",
    "countryCode": "BR",
    "timezone": "America/Belem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "BEM",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BEM",
    "countryCode": "CF",
    "timezone": "Africa/Bangui",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BEM",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BEN",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BEN",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BEN",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BEO",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "BEP",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BEP",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BEQ",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BEQ",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BER",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BER",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BES",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BES",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BET",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "BET",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "BEU",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BEU",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BEV",
    "countryCode": "IL",
    "timezone": "Asia/Jerusalem",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "BEV",
    "countryCode": "IL",
    "timezone": "Asia/Jerusalem",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "BEW",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BEW",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BEX",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BEX",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BEY",
    "countryCode": "LB",
    "timezone": "Asia/Beirut",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "BEY",
    "countryCode": "LB",
    "timezone": "Asia/Beirut",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "BEZ",
    "countryCode": "KI",
    "timezone": "Pacific/Tarawa",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "BEZ",
    "countryCode": "KI",
    "timezone": "Pacific/Tarawa",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "BFA",
    "countryCode": "PY",
    "timezone": "America/Asuncion",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "BFB",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BFB",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "BFC",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BFC",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BFD",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BFD",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BFE",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BFE",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BFF",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "BFF",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "BFG",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "BFG",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "BFH",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "BFI",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "BFJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BFJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BFK",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "BFL",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "BFL",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "BFM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BFN",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BFN",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BFO",
    "countryCode": "ZW",
    "timezone": "Africa/Harare",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BFO",
    "countryCode": "ZW",
    "timezone": "Africa/Harare",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BFP",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BFP",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BFQ",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "BFQ",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "BFR",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BFR",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BFS",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BFS",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BFT",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BFT",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BFU",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BFU",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BFV",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "BFV",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "BFW",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BFW",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BFX",
    "countryCode": "CM",
    "timezone": "Africa/Douala",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BFX",
    "countryCode": "CM",
    "timezone": "Africa/Douala",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BGA",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "BGA",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "BGB",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BGC",
    "countryCode": "PT",
    "timezone": "Europe/Lisbon",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BGC",
    "countryCode": "PT",
    "timezone": "Europe/Lisbon",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BGD",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BGD",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BGE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BGE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BGF",
    "countryCode": "CF",
    "timezone": "Africa/Bangui",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BGF",
    "countryCode": "CF",
    "timezone": "Africa/Bangui",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BGG",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BGG",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "BGG",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BGH",
    "countryCode": "MR",
    "timezone": "Africa/Nouakchott",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "BGI",
    "countryCode": "BB",
    "timezone": "America/Barbados",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "BGI",
    "countryCode": "BB",
    "timezone": "America/Barbados",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "BGJ",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "BGJ",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "BGK",
    "countryCode": "BZ",
    "timezone": "America/Belize",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "BGK",
    "countryCode": "BZ",
    "timezone": "America/Belize",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "BGL",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BGM",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BGM",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BGN",
    "countryCode": "RU",
    "timezone": "Asia/Magadan",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "BGN",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BGN",
    "countryCode": "RU",
    "timezone": "Asia/Magadan",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "BGO",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BGO",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BGP",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BGQ",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "BGQ",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "BGR",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BGR",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BGS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BGS",
    "countryCode": "RU",
    "timezone": "Asia/Krasnoyarsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "BGS",
    "countryCode": "RU",
    "timezone": "Asia/Krasnoyarsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "BGT",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "BGT",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "BGU",
    "countryCode": "CF",
    "timezone": "Africa/Bangui",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BGU",
    "countryCode": "CF",
    "timezone": "Africa/Bangui",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BGV",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "BGW",
    "countryCode": "IQ",
    "timezone": "Asia/Baghdad",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BGW",
    "countryCode": "IQ",
    "timezone": "Asia/Baghdad",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BGX",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "BGX",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "BGY",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BGZ",
    "countryCode": "PT",
    "timezone": "Europe/Lisbon",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BGZ",
    "countryCode": "PT",
    "timezone": "Europe/Lisbon",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BHA",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "BHB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BHB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BHC",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BHD",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BHE",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "BHE",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "BHF",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "BHG",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "BHG",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "BHH",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BHH",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BHI",
    "countryCode": "AR",
    "timezone": "America/Argentina/Buenos_Aires",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "BHI",
    "countryCode": "AR",
    "timezone": "America/Argentina/Buenos_Aires",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "BHJ",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BHJ",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BHK",
    "countryCode": "UZ",
    "timezone": "Asia/Samarkand",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BHK",
    "countryCode": "UZ",
    "timezone": "Asia/Samarkand",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BHL",
    "countryCode": "MX",
    "timezone": "America/Tijuana",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "BHL",
    "countryCode": "MX",
    "timezone": "America/Tijuana",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "BHM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BHM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BHN",
    "countryCode": "YE",
    "timezone": "Asia/Aden",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BHN",
    "countryCode": "YE",
    "timezone": "Asia/Aden",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BHO",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BHO",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BHP",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BHP",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BHQ",
    "countryCode": "AU",
    "timezone": "Australia/Broken_Hill",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "BHQ",
    "countryCode": "AU",
    "timezone": "Australia/Broken_Hill",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "BHR",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BHR",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BHS",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "BHS",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "BHT",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BHT",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BHU",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BHU",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BHV",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BHV",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BHW",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BHX",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BHX",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BHY",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BHY",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BHZ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "BIA",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BIA",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BIB",
    "countryCode": "SO",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BIB",
    "countryCode": "SO",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BIC",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BIC",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "BIC",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BID",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BID",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BIE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BIE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BIF",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "BIG",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "BIG",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "BIH",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "BIH",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "BII",
    "countryCode": "MH",
    "timezone": "Pacific/Kwajalein",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "BIJ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BIK",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "BIK",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "BIL",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "BIL",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "BIM",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BIM",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BIN",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "BIN",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "BIO",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BIO",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BIP",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BIQ",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BIQ",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BIR",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BIR",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BIS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BIS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BIT",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BIU",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "BIU",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "BIV",
    "countryCode": "CF",
    "timezone": "Africa/Bangui",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BIV",
    "countryCode": "CF",
    "timezone": "Africa/Bangui",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BIW",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BIX",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BIX",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BIY",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BIY",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BIZ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BJA",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BJA",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BJB",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BJB",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BJC",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "BJC",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "BJD",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "BJE",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BJF",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BJF",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BJG",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BJG",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BJH",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BJH",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BJI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BJI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BJJ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BJJ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BJK",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "BJK",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "BJL",
    "countryCode": "GM",
    "timezone": "Africa/Banjul",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "BJL",
    "countryCode": "GM",
    "timezone": "Africa/Banjul",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "BJM",
    "countryCode": "BI",
    "timezone": "Africa/Bujumbura",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BJM",
    "countryCode": "BI",
    "timezone": "Africa/Bujumbura",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BJN",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BJO",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "BJO",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "BJP",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "BJP",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "BJQ",
    "countryCode": "OM",
    "timezone": "Asia/Muscat",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "BJR",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BJR",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BJS",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BJT",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BJT",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BJU",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BJU",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BJV",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BJV",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BJV",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BJW",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BJW",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BJX",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "BJX",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "BJY",
    "countryCode": "RS",
    "timezone": "Europe/Belgrade",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BJZ",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BJZ",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BKA",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BKA",
    "countryCode": "RU",
    "timezone": "Asia/Krasnoyarsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "BKA",
    "countryCode": "RU",
    "timezone": "Asia/Krasnoyarsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "BKB",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BKB",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BKC",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "BKC",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "BKD",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BKD",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BKE",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "BKE",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "BKF",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "BKF",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "BKF",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "BKG",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BKG",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BKH",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "BKH",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "BKI",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BKI",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BKJ",
    "countryCode": "GN",
    "timezone": "Africa/Conakry",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "BKJ",
    "countryCode": "GN",
    "timezone": "Africa/Conakry",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "BKK",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "BKK",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "BKL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BKM",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BKM",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BKN",
    "countryCode": "TM",
    "timezone": "Asia/Ashgabat",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BKN",
    "countryCode": "NE",
    "timezone": "Africa/Niamey",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BKN",
    "countryCode": "TM",
    "timezone": "Asia/Ashgabat",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BKO",
    "countryCode": "ML",
    "timezone": "Africa/Bamako",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "BKO",
    "countryCode": "ML",
    "timezone": "Africa/Bamako",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "BKP",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BKQ",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BKQ",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BKR",
    "countryCode": "TD",
    "timezone": "Africa/Ndjamena",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BKR",
    "countryCode": "TD",
    "timezone": "Africa/Ndjamena",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BKS",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "BKS",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "BKT",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BKT",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BKU",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BKU",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BKV",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BKV",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BKW",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BKW",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BKX",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BKX",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BKY",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BKY",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BKZ",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BKZ",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BLA",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "BLA",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "BLB",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "BLB",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "BLC",
    "countryCode": "CM",
    "timezone": "Africa/Douala",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BLC",
    "countryCode": "CM",
    "timezone": "Africa/Douala",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BLD",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "BLE",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BLE",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BLF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BLF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BLG",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BLG",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BLH",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "BLH",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "BLI",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "BLI",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "BLJ",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BLJ",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BLK",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BLK",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BLL",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BLL",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BLM",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BLM",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BLM",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BLN",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "BLN",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "BLO",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "BLP",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "BLP",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "BLQ",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BLQ",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BLR",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BLR",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BLS",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BLS",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BLT",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BLT",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BLU",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "BLU",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "BLV",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BLV",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BLW",
    "countryCode": "SO",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BLW",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "BLW",
    "countryCode": "SO",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BLW",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "BLX",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BLX",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BLY",
    "countryCode": "IE",
    "timezone": "Europe/Dublin",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BLY",
    "countryCode": "IE",
    "timezone": "Europe/Dublin",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BLZ",
    "countryCode": "MW",
    "timezone": "Africa/Blantyre",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BLZ",
    "countryCode": "MW",
    "timezone": "Africa/Blantyre",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BMA",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BMB",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BMB",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BMC",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "BMC",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "BMD",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BMD",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BME",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BME",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BMF",
    "countryCode": "CF",
    "timezone": "Africa/Bangui",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BMF",
    "countryCode": "CF",
    "timezone": "Africa/Bangui",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BMG",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BMG",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BMH",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BMI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BMI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BMJ",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "BMJ",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "BMK",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BMK",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BML",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BML",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BMM",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BMM",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BMN",
    "countryCode": "IQ",
    "timezone": "Asia/Baghdad",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BMO",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "BMO",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "BMP",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BMQ",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BMQ",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BMR",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BMR",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BMS",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "BMS",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "BMT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BMU",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BMU",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BMV",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "BMV",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "BMW",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BMW",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BMX",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "BMX",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "BMY",
    "countryCode": "NC",
    "timezone": "Pacific/Noumea",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "BMY",
    "countryCode": "NC",
    "timezone": "Pacific/Noumea",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "BMY",
    "countryCode": "NC",
    "timezone": "Pacific/Noumea",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "BMY",
    "countryCode": "NC",
    "timezone": "Pacific/Noumea",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "BMZ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BNA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BNA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BNB",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BNB",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BNC",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BNC",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BND",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BND",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BNE",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BNE",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BNF",
    "countryCode": "US",
    "timezone": "America/Sitka",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "BNG",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "BNG",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "BNH",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BNH",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BNI",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BNI",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BNJ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BNJ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BNK",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "BNK",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "BNL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BNL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BNM",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BNN",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BNN",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BNO",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "BNO",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "BNP",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BNP",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BNQ",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BNQ",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BNR",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "BNR",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "BNS",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "BNS",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "BNT",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BNU",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "BNU",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "BNV",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BNW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BNW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BNX",
    "countryCode": "BA",
    "timezone": "Europe/Sarajevo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BNX",
    "countryCode": "BA",
    "timezone": "Europe/Sarajevo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BNY",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "BNY",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "BNZ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BNZ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BOA",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BOA",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BOB",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "BOB",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "BOC",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "BOC",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "BOD",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BOD",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BOE",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BOE",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BOF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BOG",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "BOG",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "BOH",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BOH",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BOI",
    "countryCode": "US",
    "timezone": "America/Boise",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "BOI",
    "countryCode": "US",
    "timezone": "America/Boise",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "BOJ",
    "countryCode": "BG",
    "timezone": "Europe/Sofia",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "BOJ",
    "countryCode": "BG",
    "timezone": "Europe/Sofia",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "BOK",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "BOK",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "BOL",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BOL",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BOM",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BOM",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BON",
    "countryCode": "BQ",
    "timezone": "America/Kralendijk",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "BON",
    "countryCode": "BQ",
    "timezone": "America/Kralendijk",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "BOO",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BOO",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BOP",
    "countryCode": "CF",
    "timezone": "Africa/Bangui",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BOP",
    "countryCode": "CF",
    "timezone": "Africa/Bangui",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BOQ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BOR",
    "countryCode": "LA",
    "timezone": "Asia/Vientiane",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "BOR",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BOS",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BOS",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BOT",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BOT",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BOU",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BOU",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BOV",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BOW",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BOW",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BOX",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "BOX",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "BOY",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "BOY",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "BOZ",
    "countryCode": "CF",
    "timezone": "Africa/Bangui",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BOZ",
    "countryCode": "CF",
    "timezone": "Africa/Bangui",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BPA",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BPA",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BPA",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BPA",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BPB",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BPC",
    "countryCode": "CM",
    "timezone": "Africa/Douala",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BPC",
    "countryCode": "CM",
    "timezone": "Africa/Douala",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BPD",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BPE",
    "countryCode": "MM",
    "timezone": "Asia/Rangoon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "BPE",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BPE",
    "countryCode": "MM",
    "timezone": "Asia/Rangoon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "BPE",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BPE",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BPF",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "BPG",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "BPG",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "BPH",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BPH",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BPI",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "BPK",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BPK",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BPL",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BPL",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BPL",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BPM",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BPN",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BPN",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BPO",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BPO",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BPR",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BPS",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "BPS",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "BPT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BPT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BPT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BPU",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "BPU",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "BPX",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BPX",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BPY",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BPY",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BQA",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BQA",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BQB",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BQB",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BQC",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BQC",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BQD",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BQE",
    "countryCode": "GW",
    "timezone": "Africa/Bissau",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "BQE",
    "countryCode": "GW",
    "timezone": "Africa/Bissau",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "BQG",
    "countryCode": "RU",
    "timezone": "Asia/Vladivostok",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BQG",
    "countryCode": "RU",
    "timezone": "Asia/Vladivostok",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BQH",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BQI",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BQI",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BQJ",
    "countryCode": "RU",
    "timezone": "Asia/Vladivostok",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BQJ",
    "countryCode": "RU",
    "timezone": "Asia/Vladivostok",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BQK",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BQL",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BQL",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BQN",
    "countryCode": "PR",
    "timezone": "America/Puerto_Rico",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "BQN",
    "countryCode": "PR",
    "timezone": "America/Puerto_Rico",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "BQO",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "BQO",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "BQQ",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "BQQ",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "BQS",
    "countryCode": "RU",
    "timezone": "Asia/Yakutsk",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "BQS",
    "countryCode": "RU",
    "timezone": "Asia/Yakutsk",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "BQT",
    "countryCode": "BY",
    "timezone": "Europe/Minsk",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BQT",
    "countryCode": "BY",
    "timezone": "Europe/Minsk",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BQU",
    "countryCode": "VC",
    "timezone": "America/St_Vincent",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "BQU",
    "countryCode": "VC",
    "timezone": "America/St_Vincent",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "BQV",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "BQW",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BQW",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BQY",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BQZ",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BRA",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "BRA",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "BRB",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "BRB",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "BRC",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "BRC",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "BRD",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BRD",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BRE",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BRE",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BRF",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BRF",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BRG",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BRG",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BRG",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BRH",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BRI",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BRI",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BRJ",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "BRK",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "BRK",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "BRL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BRL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BRM",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "BRM",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "BRN",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BRN",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BRO",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BRO",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BRP",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BRQ",
    "countryCode": "CZ",
    "timezone": "Europe/Prague",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BRQ",
    "countryCode": "CZ",
    "timezone": "Europe/Prague",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BRR",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BRR",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BRS",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BRS",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BRT",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "BRT",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "BRU",
    "countryCode": "BE",
    "timezone": "Europe/Brussels",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BRU",
    "countryCode": "BE",
    "timezone": "Europe/Brussels",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BRV",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BRV",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BRW",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "BRW",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "BRX",
    "countryCode": "DO",
    "timezone": "America/Santo_Domingo",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "BRX",
    "countryCode": "DO",
    "timezone": "America/Santo_Domingo",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "BRY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BRY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BRZ",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "BRZ",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BRZ",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BSA",
    "countryCode": "SO",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BSA",
    "countryCode": "SO",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BSB",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "BSB",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "BSC",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "BSC",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "BSD",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BSD",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BSE",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BSE",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BSF",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "BSG",
    "countryCode": "GQ",
    "timezone": "Africa/Malabo",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BSG",
    "countryCode": "GQ",
    "timezone": "Africa/Malabo",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BSH",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BSH",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BSI",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BSI",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BSI",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BSI",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BSJ",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "BSJ",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "BSK",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BSK",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BSL",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BSL",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BSM",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BSM",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BSN",
    "countryCode": "CF",
    "timezone": "Africa/Bangui",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BSN",
    "countryCode": "CF",
    "timezone": "Africa/Bangui",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BSO",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BSO",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BSP",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BSQ",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "BSQ",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "BSR",
    "countryCode": "IQ",
    "timezone": "Asia/Baghdad",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BSR",
    "countryCode": "IQ",
    "timezone": "Asia/Baghdad",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BSS",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "BSS",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "BST",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "BST",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "BSU",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BSU",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BSV",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BSV",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BSW",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "BSW",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "BSX",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "BSX",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "BSY",
    "countryCode": "SO",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BSY",
    "countryCode": "SO",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BSZ",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "BTA",
    "countryCode": "CM",
    "timezone": "Africa/Douala",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BTA",
    "countryCode": "CM",
    "timezone": "Africa/Douala",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BTB",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BTB",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BTC",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BTC",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BTD",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "BTE",
    "countryCode": "SL",
    "timezone": "Africa/Freetown",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "BTE",
    "countryCode": "SL",
    "timezone": "Africa/Freetown",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "BTF",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "BTF",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "BTG",
    "countryCode": "CF",
    "timezone": "Africa/Bangui",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BTG",
    "countryCode": "CF",
    "timezone": "Africa/Bangui",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BTH",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "BTH",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "BTI",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "BTI",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "BTJ",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "BTJ",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "BTK",
    "countryCode": "RU",
    "timezone": "Asia/Irkutsk",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BTK",
    "countryCode": "RU",
    "timezone": "Asia/Irkutsk",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BTL",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BTL",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BTM",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "BTM",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "BTN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BTN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BTO",
    "countryCode": "SR",
    "timezone": "America/Paramaribo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "BTO",
    "countryCode": "SR",
    "timezone": "America/Paramaribo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "BTP",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BTP",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BTQ",
    "countryCode": "RW",
    "timezone": "Africa/Kigali",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BTQ",
    "countryCode": "RW",
    "timezone": "Africa/Kigali",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BTR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BTR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BTS",
    "countryCode": "SK",
    "timezone": "Europe/Bratislava",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BTS",
    "countryCode": "SK",
    "timezone": "Europe/Bratislava",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BTT",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "BTT",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "BTU",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BTU",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BTV",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BTV",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BTW",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BTW",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BTX",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BTX",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BTY",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "BTY",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "BTZ",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "BTZ",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "BTZ",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "BUA",
    "countryCode": "PG",
    "timezone": "Pacific/Bougainville",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "BUA",
    "countryCode": "PG",
    "timezone": "Pacific/Bougainville",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "BUB",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BUB",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BUC",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BUC",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BUD",
    "countryCode": "HU",
    "timezone": "Europe/Budapest",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BUD",
    "countryCode": "HU",
    "timezone": "Europe/Budapest",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BUE",
    "countryCode": "AR",
    "timezone": "America/Argentina/Buenos_Aires",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "BUF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BUF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BUG",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BUG",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BUH",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "BUI",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "BUI",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "BUJ",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BUJ",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BUK",
    "countryCode": "YE",
    "timezone": "Asia/Aden",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BUK",
    "countryCode": "YE",
    "timezone": "Asia/Aden",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BUL",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BUL",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BUM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BUM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BUN",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "BUN",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "BUO",
    "countryCode": "SO",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BUO",
    "countryCode": "SO",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BUP",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BUP",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BUQ",
    "countryCode": "ZW",
    "timezone": "Africa/Harare",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BUQ",
    "countryCode": "ZW",
    "timezone": "Africa/Harare",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BUR",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "BUR",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "BUS",
    "countryCode": "GE",
    "timezone": "Asia/Tbilisi",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "BUS",
    "countryCode": "GE",
    "timezone": "Asia/Tbilisi",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "BUT",
    "countryCode": "BT",
    "timezone": "Asia/Thimphu",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "BUT",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BUT",
    "countryCode": "BT",
    "timezone": "Asia/Thimphu",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "BUU",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "BUU",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "BUU",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "BUV",
    "countryCode": "UY",
    "timezone": "America/Montevideo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "BUW",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BUW",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BUX",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BUX",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BUY",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BUY",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BUZ",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BUZ",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BVA",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BVB",
    "countryCode": "BR",
    "timezone": "America/Boa_Vista",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "BVB",
    "countryCode": "BR",
    "timezone": "America/Boa_Vista",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "BVC",
    "countryCode": "CV",
    "timezone": "Atlantic/Cape_Verde",
    "offset": {
      "gmt": -1,
      "dst": -1
    }
  },
  {
    "code": "BVC",
    "countryCode": "CV",
    "timezone": "Atlantic/Cape_Verde",
    "offset": {
      "gmt": -1,
      "dst": -1
    }
  },
  {
    "code": "BVD",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "BVD",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "BVE",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BVE",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BVF",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "BVF",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "BVG",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BVG",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BVH",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "BVH",
    "countryCode": "BR",
    "timezone": "America/Porto_Velho",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "BVI",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BVI",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BVJ",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BVJ",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BVK",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "BVK",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "BVL",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "BVL",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "BVM",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "BVM",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "BVN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BVN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BVO",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BVO",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BVP",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BVQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BVR",
    "countryCode": "CV",
    "timezone": "Atlantic/Cape_Verde",
    "offset": {
      "gmt": -1,
      "dst": -1
    }
  },
  {
    "code": "BVS",
    "countryCode": "BR",
    "timezone": "America/Santarem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "BVS",
    "countryCode": "BR",
    "timezone": "America/Belem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "BVU",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "BVU",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "BVV",
    "countryCode": "RU",
    "timezone": "Asia/Sakhalin",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "BVV",
    "countryCode": "RU",
    "timezone": "Asia/Sakhalin",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "BVW",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BVW",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BVX",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BVX",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BVY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BVY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BVZ",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BWA",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BWA",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BWB",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BWB",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BWC",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "BWC",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "BWD",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BWD",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BWE",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BWE",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BWE",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BWF",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BWF",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BWF",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BWG",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BWG",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BWH",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BWH",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BWI",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BWI",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BWJ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BWK",
    "countryCode": "HR",
    "timezone": "Europe/Zagreb",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BWK",
    "countryCode": "HR",
    "timezone": "Europe/Zagreb",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BWL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BWL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BWM",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "BWM",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "BWN",
    "countryCode": "BN",
    "timezone": "Asia/Brunei",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BWN",
    "countryCode": "BN",
    "timezone": "Asia/Brunei",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BWO",
    "countryCode": "RU",
    "timezone": "Europe/Saratov",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "BWO",
    "countryCode": "RU",
    "timezone": "Europe/Saratov",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "BWP",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BWQ",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "BWQ",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "BWS",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "BWS",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "BWT",
    "countryCode": "AU",
    "timezone": "Australia/Hobart",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "BWT",
    "countryCode": "AU",
    "timezone": "Australia/Hobart",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "BWU",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "BWW",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BWW",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BWW",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BWX",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "BWX",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "BWY",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BXA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BXA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BXB",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "BXB",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "BXC",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BXD",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "BXE",
    "countryCode": "SN",
    "timezone": "Africa/Dakar",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "BXE",
    "countryCode": "SN",
    "timezone": "Africa/Dakar",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "BXF",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BXG",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "BXG",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "BXH",
    "countryCode": "KZ",
    "timezone": "Asia/Almaty",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "BXH",
    "countryCode": "KZ",
    "timezone": "Asia/Almaty",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "BXI",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "BXI",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "BXJ",
    "countryCode": "KZ",
    "timezone": "Asia/Almaty",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "BXK",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "BXK",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "BXL",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "BXL",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "BXM",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "BXN",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BXN",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BXO",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BXO",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BXP",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BXP",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BXR",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BXR",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BXS",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "BXS",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "BXT",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BXT",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BXU",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BXU",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BXV",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "BXV",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "BXW",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "BXW",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "BXX",
    "countryCode": "SO",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BXX",
    "countryCode": "SO",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BXY",
    "countryCode": "KZ",
    "timezone": "Asia/Qostanay",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "BXY",
    "countryCode": "KZ",
    "timezone": "Asia/Qostanay",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "BXZ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BYA",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "BYA",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "BYB",
    "countryCode": "OM",
    "timezone": "Asia/Muscat",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "BYB",
    "countryCode": "OM",
    "timezone": "Asia/Muscat",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "BYB",
    "countryCode": "AE",
    "timezone": "Asia/Dubai",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "BYB",
    "countryCode": "AE",
    "timezone": "Asia/Dubai",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "BYC",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "BYC",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "BYD",
    "countryCode": "YE",
    "timezone": "Asia/Aden",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BYD",
    "countryCode": "YE",
    "timezone": "Asia/Aden",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BYE",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BYE",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BYF",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BYF",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BYG",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "BYG",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "BYH",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BYH",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BYI",
    "countryCode": "US",
    "timezone": "America/Boise",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "BYI",
    "countryCode": "US",
    "timezone": "America/Boise",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "BYJ",
    "countryCode": "PT",
    "timezone": "Europe/Lisbon",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BYJ",
    "countryCode": "PT",
    "timezone": "Europe/Lisbon",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BYK",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "BYK",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "BYL",
    "countryCode": "LR",
    "timezone": "Africa/Monrovia",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "BYM",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BYM",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BYN",
    "countryCode": "MN",
    "timezone": "Asia/Ulaanbaatar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BYN",
    "countryCode": "MN",
    "timezone": "Asia/Ulaanbaatar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BYO",
    "countryCode": "BR",
    "timezone": "America/Campo_Grande",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "BYO",
    "countryCode": "BR",
    "timezone": "America/Campo_Grande",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "BYP",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BYP",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BYQ",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BYQ",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BYR",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BYR",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BYS",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "BYS",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "BYT",
    "countryCode": "IE",
    "timezone": "Europe/Dublin",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BYT",
    "countryCode": "IE",
    "timezone": "Europe/Dublin",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BYU",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BYU",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BYV",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "BYW",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "BYX",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "BZA",
    "countryCode": "NI",
    "timezone": "America/Managua",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "BZA",
    "countryCode": "NI",
    "timezone": "America/Managua",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "BZB",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BZC",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "BZC",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "BZC",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "BZD",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "BZD",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "BZE",
    "countryCode": "BZ",
    "timezone": "America/Belize",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "BZE",
    "countryCode": "BZ",
    "timezone": "America/Belize",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "BZF",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "BZG",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BZG",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BZH",
    "countryCode": "ZW",
    "timezone": "Africa/Harare",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BZH",
    "countryCode": "ZW",
    "timezone": "Africa/Harare",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BZI",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BZK",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BZK",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BZL",
    "countryCode": "BD",
    "timezone": "Asia/Dhaka",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "BZL",
    "countryCode": "BD",
    "timezone": "Asia/Dhaka",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "BZM",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "BZN",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "BZN",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "BZO",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BZO",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BZP",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "BZQ",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BZR",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BZR",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BZS",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "BZS",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "BZT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "BZU",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BZU",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "BZV",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BZV",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "BZX",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BZX",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BZX",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "BZY",
    "countryCode": "MD",
    "timezone": "Europe/Chisinau",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "BZZ",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "BZZ",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "CAA",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "CAA",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "CAB",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "CAB",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "CAC",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CAC",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CAD",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CAD",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CAE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CAE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CAF",
    "countryCode": "BR",
    "timezone": "America/Manaus",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CAF",
    "countryCode": "BR",
    "timezone": "America/Manaus",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CAG",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CAG",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CAH",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "CAH",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "CAI",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "CAI",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "CAJ",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CAJ",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CAK",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CAK",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CAL",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "CAL",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "CAM",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CAM",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CAN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CAN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CAO",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "CAO",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "CAP",
    "countryCode": "HT",
    "timezone": "America/Port-au-Prince",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CAP",
    "countryCode": "HT",
    "timezone": "America/Port-au-Prince",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CAQ",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CAQ",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CAR",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CAR",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CAS",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "CAT",
    "countryCode": "PT",
    "timezone": "Europe/Lisbon",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "CAT",
    "countryCode": "PT",
    "timezone": "Europe/Lisbon",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "CAT",
    "countryCode": "PT",
    "timezone": "Europe/Lisbon",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "CAT",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CAU",
    "countryCode": "BR",
    "timezone": "America/Recife",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CAU",
    "countryCode": "BR",
    "timezone": "America/Recife",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CAV",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "CAV",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "CAW",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CAW",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CAX",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "CAX",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "CAY",
    "countryCode": "GF",
    "timezone": "America/Cayenne",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CAY",
    "countryCode": "GF",
    "timezone": "America/Cayenne",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CAZ",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "CAZ",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "CBA",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CBA",
    "countryCode": "US",
    "timezone": "America/Sitka",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CBB",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CBB",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CBC",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CBC",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CBD",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "CBD",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "CBE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CBE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CBF",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CBF",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CBG",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "CBG",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "CBH",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "CBH",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "CBI",
    "countryCode": "AU",
    "timezone": "Australia/Hobart",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "CBI",
    "countryCode": "AU",
    "timezone": "Australia/Hobart",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "CBJ",
    "countryCode": "DO",
    "timezone": "America/Santo_Domingo",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CBJ",
    "countryCode": "DO",
    "timezone": "America/Santo_Domingo",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CBK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CBK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CBL",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CBL",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CBM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CBN",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "CBN",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "CBO",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CBO",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CBP",
    "countryCode": "PT",
    "timezone": "Europe/Lisbon",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "CBP",
    "countryCode": "PT",
    "timezone": "Europe/Lisbon",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "CBQ",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "CBQ",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "CBR",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "CBR",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "CBS",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CBS",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CBT",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "CBT",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "CBU",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CBU",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CBV",
    "countryCode": "GT",
    "timezone": "America/Guatemala",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "CBV",
    "countryCode": "GT",
    "timezone": "America/Guatemala",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "CBW",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CBX",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "CBX",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "CBY",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "CBZ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CBZ",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CBZ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CCA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CCA",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CCA",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CCA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CCB",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CCB",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CCC",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CCC",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CCC",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CCD",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "CCE",
    "countryCode": "MF",
    "timezone": "America/Marigot",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CCE",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "CCE",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "CCF",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CCF",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CCG",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CCG",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CCH",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "CCH",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "CCI",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CCI",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CCJ",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "CCJ",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "CCK",
    "countryCode": "CC",
    "timezone": "Indian/Cocos",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "CCK",
    "countryCode": "CC",
    "timezone": "Indian/Cocos",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "CCL",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "CCL",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "CCM",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CCM",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CCN",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "CCN",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "CCO",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CCO",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CCP",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "CCP",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "CCQ",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CCR",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CCR",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CCS",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CCS",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CCT",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CCT",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CCU",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "CCU",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "CCV",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "CCV",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "CCW",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "CCW",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "CCX",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CCY",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CCZ",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CCZ",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CDA",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "CDB",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CDB",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CDC",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "CDC",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "CDD",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "CDD",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "CDE",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CDE",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CDE",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CDF",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CDG",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CDH",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CDH",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CDI",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CDI",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CDJ",
    "countryCode": "BR",
    "timezone": "America/Araguaina",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CDJ",
    "countryCode": "BR",
    "timezone": "America/Belem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CDK",
    "countryCode": "US",
    "timezone": "America/Kentucky/Monticello",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CDK",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CDL",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CDL",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CDM",
    "countryCode": "UY",
    "timezone": "America/Montevideo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CDN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CDN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CDO",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "CDO",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "CDP",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "CDP",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "CDQ",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "CDQ",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "CDR",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "CDR",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "CDS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CDS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CDT",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CDT",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CDU",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "CDU",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "CDV",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CDV",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CDW",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CDW",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CDY",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CDY",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CDY",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CDZ",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CDZ",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CEA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CEB",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CEB",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CEC",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CEC",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CED",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "CED",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "CEE",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "CEE",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "CEF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CEF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CEG",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "CEG",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "CEH",
    "countryCode": "MW",
    "timezone": "Africa/Blantyre",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "CEH",
    "countryCode": "MW",
    "timezone": "Africa/Blantyre",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "CEI",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "CEI",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "CEJ",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "CEJ",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CEJ",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CEK",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "CEK",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "CEL",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CEL",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CEL",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CEL",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CEM",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CEM",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CEN",
    "countryCode": "MX",
    "timezone": "America/Hermosillo",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "CEN",
    "countryCode": "MX",
    "timezone": "America/Hermosillo",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "CEO",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "CEO",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "CEO",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "CEP",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CEP",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CEQ",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CEQ",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CER",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CER",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CER",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CES",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "CES",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "CET",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CET",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CEU",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CEU",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CEV",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CEV",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CEW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CEW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CEX",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CEY",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CEY",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CEZ",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "CEZ",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "CFA",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CFB",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CFB",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CFC",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CFC",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CFD",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CFD",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CFE",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CFE",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CFF",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "CFF",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "CFG",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CFG",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CFH",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "CFH",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "CFI",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "CFK",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "CFK",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "CFM",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "CFM",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "CFM",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "CFN",
    "countryCode": "IE",
    "timezone": "Europe/Dublin",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "CFN",
    "countryCode": "IE",
    "timezone": "Europe/Dublin",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "CFO",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CFP",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "CFP",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "CFQ",
    "countryCode": "CA",
    "timezone": "America/Creston",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "CFQ",
    "countryCode": "CA",
    "timezone": "America/Creston",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "CFR",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CFR",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CFS",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "CFS",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "CFT",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "CFT",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "CFU",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "CFU",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "CFV",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CFV",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CFW",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "CFZ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CFZ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CGA",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CGA",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CGB",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CGB",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CGC",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "CGD",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CGD",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CGE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CGE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CGF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CGG",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "CGH",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CGI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CGI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CGJ",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "CGJ",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "CGK",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "CGM",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CGM",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CGM",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CGN",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CGN",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CGO",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CGO",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CGP",
    "countryCode": "BD",
    "timezone": "Asia/Dhaka",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "CGP",
    "countryCode": "BD",
    "timezone": "Asia/Dhaka",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "CGQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CGQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CGR",
    "countryCode": "BR",
    "timezone": "America/Campo_Grande",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CGR",
    "countryCode": "BR",
    "timezone": "America/Campo_Grande",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CGS",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CGS",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CGT",
    "countryCode": "MR",
    "timezone": "Africa/Nouakchott",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "CGU",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CGV",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CGV",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CGX",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CGX",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "CGX",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "CGY",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CGY",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CGY",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CGZ",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "CGZ",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "CHA",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CHA",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CHB",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "CHB",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "CHC",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "CHC",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "CHD",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CHD",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CHE",
    "countryCode": "EE",
    "timezone": "Europe/Tallinn",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "CHF",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "CHF",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "CHG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CHG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CHH",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CHH",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CHI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CHJ",
    "countryCode": "ZW",
    "timezone": "Africa/Harare",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "CHJ",
    "countryCode": "ZW",
    "timezone": "Africa/Harare",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "CHK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CHK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CHL",
    "countryCode": "US",
    "timezone": "America/Boise",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "CHL",
    "countryCode": "US",
    "timezone": "America/Boise",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "CHM",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CHM",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CHN",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "CHN",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "CHO",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CHO",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CHP",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CHP",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CHQ",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "CHQ",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "CHR",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CHR",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CHS",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CHS",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CHT",
    "countryCode": "NZ",
    "timezone": "Pacific/Chatham",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "CHT",
    "countryCode": "NZ",
    "timezone": "Pacific/Chatham",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "CHU",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CHU",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CHV",
    "countryCode": "PT",
    "timezone": "Europe/Lisbon",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "CHV",
    "countryCode": "PT",
    "timezone": "Europe/Lisbon",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "CHW",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "CHW",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CHW",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "CHX",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CHX",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CHY",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "CHY",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "CHZ",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CHZ",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CIA",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CIB",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CIC",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CIC",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CID",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CID",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CIE",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CIF",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CIF",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CIG",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "CIG",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "CIH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CIH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CII",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "CII",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "CIJ",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CIJ",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CIK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CIK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CIL",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CIL",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CIM",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CIM",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CIN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CIN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CIO",
    "countryCode": "PY",
    "timezone": "America/Asuncion",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "CIP",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "CIP",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "CIQ",
    "countryCode": "GT",
    "timezone": "America/Guatemala",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "CIQ",
    "countryCode": "GT",
    "timezone": "America/Guatemala",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "CIR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CIS",
    "countryCode": "KI",
    "timezone": "Pacific/Kanton",
    "offset": {
      "gmt": 13,
      "dst": 13
    }
  },
  {
    "code": "CIS",
    "countryCode": "KI",
    "timezone": "Pacific/Kanton",
    "offset": {
      "gmt": 13,
      "dst": 13
    }
  },
  {
    "code": "CIT",
    "countryCode": "KZ",
    "timezone": "Asia/Almaty",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "CIT",
    "countryCode": "KZ",
    "timezone": "Asia/Almaty",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "CIU",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CIV",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CIV",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CIW",
    "countryCode": "VC",
    "timezone": "America/St_Vincent",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CIW",
    "countryCode": "VC",
    "timezone": "America/St_Vincent",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CIX",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CIX",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CIY",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CIY",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CIZ",
    "countryCode": "BR",
    "timezone": "America/Manaus",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CIZ",
    "countryCode": "BR",
    "timezone": "America/Manaus",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CJA",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CJA",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CJB",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "CJB",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "CJC",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "CJC",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "CJD",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CJF",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CJF",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CJH",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CJH",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CJI",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CJJ",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "CJJ",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "CJL",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "CJL",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "CJM",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "CJM",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "CJN",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "CJN",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "CJN",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CJS",
    "countryCode": "MX",
    "timezone": "America/Ciudad_Juarez",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "CJS",
    "countryCode": "MX",
    "timezone": "America/Ciudad_Juarez",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "CJT",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "CJT",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "CJU",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "CJU",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "CJZ",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CJZ",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CKA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CKA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CKB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CKB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CKC",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "CKC",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "CKD",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CKD",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CKE",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CKE",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CKF",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CKF",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CKG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CKG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CKG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CKH",
    "countryCode": "RU",
    "timezone": "Asia/Magadan",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "CKH",
    "countryCode": "RU",
    "timezone": "Asia/Magadan",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "CKI",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "CKI",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "CKJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CKK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CKK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CKL",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "CKL",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "CKM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CKM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CKN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CKN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CKO",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CKP",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CKR",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CKR",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CKS",
    "countryCode": "BR",
    "timezone": "America/Araguaina",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CKS",
    "countryCode": "BR",
    "timezone": "America/Belem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CKT",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "CKT",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "CKU",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CKV",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CKV",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CKW",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CKW",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CKX",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CKX",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CKY",
    "countryCode": "GN",
    "timezone": "Africa/Conakry",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "CKY",
    "countryCode": "GN",
    "timezone": "Africa/Conakry",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "CKZ",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "CKZ",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "CLA",
    "countryCode": "BD",
    "timezone": "Asia/Dhaka",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "CLA",
    "countryCode": "BD",
    "timezone": "Asia/Dhaka",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "CLB",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "CLB",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "CLC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CLC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CLD",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CLE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CLE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CLF",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "CLG",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CLG",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CLH",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "CLH",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "CLI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CLI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CLJ",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "CLJ",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "CLK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CLL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CLL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CLM",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CLM",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CLN",
    "countryCode": "BR",
    "timezone": "America/Araguaina",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CLN",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CLO",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CLO",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CLP",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CLP",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CLQ",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "CLQ",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "CLR",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CLR",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CLS",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CLT",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CLT",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CLU",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CLU",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CLV",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CLV",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CLW",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CLW",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CLX",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CLX",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CLY",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CLY",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CLZ",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CLZ",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CMA",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "CMA",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "CMB",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "CMB",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "CMC",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CMC",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CMD",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "CMD",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "CME",
    "countryCode": "MX",
    "timezone": "America/Merida",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "CME",
    "countryCode": "MX",
    "timezone": "America/Merida",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "CMF",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CMF",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CMF",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CMG",
    "countryCode": "BR",
    "timezone": "America/Campo_Grande",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CMG",
    "countryCode": "BR",
    "timezone": "America/Campo_Grande",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CMH",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CMH",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CMI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CMI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CMJ",
    "countryCode": "TW",
    "timezone": "Asia/Taipei",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CMK",
    "countryCode": "MW",
    "timezone": "Africa/Blantyre",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "CMK",
    "countryCode": "MW",
    "timezone": "Africa/Blantyre",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "CML",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "CML",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "CMM",
    "countryCode": "GT",
    "timezone": "America/Guatemala",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "CMM",
    "countryCode": "GT",
    "timezone": "America/Guatemala",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "CMN",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "CMN",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "CMO",
    "countryCode": "SO",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "CMP",
    "countryCode": "BR",
    "timezone": "America/Santarem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CMQ",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "CMQ",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "CMR",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CMR",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CMS",
    "countryCode": "SO",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "CMT",
    "countryCode": "BR",
    "timezone": "America/Belem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CMU",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "CMU",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "CMV",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "CMV",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "CMW",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CMW",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CMX",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CMX",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CMY",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CMY",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CMZ",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "CNA",
    "countryCode": "MX",
    "timezone": "America/Hermosillo",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "CNA",
    "countryCode": "MX",
    "timezone": "America/Hermosillo",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "CNB",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "CNB",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "CNC",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "CND",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "CND",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "CND",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "CNE",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "CNE",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "CNF",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CNG",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CNG",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CNH",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CNH",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CNI",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CNI",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CNJ",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "CNJ",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "CNK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CNK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CNL",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CNL",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CNM",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "CNM",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "CNN",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "CNN",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "CNN",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "CNO",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CNO",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CNP",
    "countryCode": "GL",
    "timezone": "America/Scoresbysund",
    "offset": {
      "gmt": -1,
      "dst": -1
    }
  },
  {
    "code": "CNP",
    "countryCode": "GL",
    "timezone": "America/Scoresbysund",
    "offset": {
      "gmt": -1,
      "dst": -1
    }
  },
  {
    "code": "CNP",
    "countryCode": "GL",
    "timezone": "America/Scoresbysund",
    "offset": {
      "gmt": -1,
      "dst": -1
    }
  },
  {
    "code": "CNQ",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CNQ",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CNR",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "CNS",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "CNS",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "CNT",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CNT",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CNU",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CNU",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CNV",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CNV",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CNW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CNX",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "CNX",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "CNY",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "CNY",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "CNZ",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "CNZ",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "COA",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "COA",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "COB",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "COC",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "COC",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "COD",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "COD",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "COE",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "COE",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "COF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "COF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "COG",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "COG",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "COH",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "COH",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "COI",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "COI",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "COJ",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "COJ",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "COK",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "COK",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "COL",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "COM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "COM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CON",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CON",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "COO",
    "countryCode": "BJ",
    "timezone": "Africa/Porto-Novo",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "COO",
    "countryCode": "BJ",
    "timezone": "Africa/Porto-Novo",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "COP",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "COP",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "COQ",
    "countryCode": "MN",
    "timezone": "Asia/Choibalsan",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "COQ",
    "countryCode": "MN",
    "timezone": "Asia/Choibalsan",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "COR",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "COR",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "COS",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "COS",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "COT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "COT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "COU",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "COU",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "COV",
    "countryCode": "PT",
    "timezone": "Europe/Lisbon",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "COV",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "COV",
    "countryCode": "PT",
    "timezone": "Europe/Lisbon",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "COW",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "COX",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "COX",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "COY",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "COY",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "COZ",
    "countryCode": "DO",
    "timezone": "America/Santo_Domingo",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CPA",
    "countryCode": "LR",
    "timezone": "Africa/Monrovia",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "CPA",
    "countryCode": "LR",
    "timezone": "Africa/Monrovia",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "CPB",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CPB",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CPC",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CPC",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CPD",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "CPD",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "CPE",
    "countryCode": "MX",
    "timezone": "America/Merida",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "CPE",
    "countryCode": "MX",
    "timezone": "America/Merida",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "CPF",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "CPF",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "CPG",
    "countryCode": "AR",
    "timezone": "America/Argentina/Buenos_Aires",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CPG",
    "countryCode": "AR",
    "timezone": "America/Argentina/Buenos_Aires",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CPH",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CPH",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CPI",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "CPJ",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CPL",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CPL",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CPM",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CPM",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CPN",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "CPO",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "CPO",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "CPP",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "CPP",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "CPQ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -2,
      "dst": -3
    }
  },
  {
    "code": "CPQ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -2,
      "dst": -3
    }
  },
  {
    "code": "CPR",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "CPR",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "CPS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CPT",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "CPT",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "CPU",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CPU",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CPV",
    "countryCode": "BR",
    "timezone": "America/Recife",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CPV",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CPX",
    "countryCode": "PR",
    "timezone": "America/Puerto_Rico",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CPX",
    "countryCode": "PR",
    "timezone": "America/Puerto_Rico",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CPZ",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CPZ",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CQA",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CQA",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CQD",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "CQD",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "CQF",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CQF",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CQF",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CQM",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CQM",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CQP",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "CQS",
    "countryCode": "BR",
    "timezone": "America/Porto_Velho",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CQS",
    "countryCode": "BR",
    "timezone": "America/Porto_Velho",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CQT",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CQW",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CQW",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CQW",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CQW",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CRA",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "CRA",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "CRB",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "CRB",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "CRC",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CRC",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CRD",
    "countryCode": "AR",
    "timezone": "America/Argentina/Catamarca",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CRD",
    "countryCode": "AR",
    "timezone": "America/Argentina/Catamarca",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CRE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CRF",
    "countryCode": "CF",
    "timezone": "Africa/Bangui",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "CRF",
    "countryCode": "CF",
    "timezone": "Africa/Bangui",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "CRG",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CRH",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "CRI",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CRI",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CRJ",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "CRJ",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "CRK",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CRK",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CRK",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CRL",
    "countryCode": "BE",
    "timezone": "Europe/Brussels",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CRM",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CRM",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CRN",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "CRN",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "CRO",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CRO",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CRP",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CRP",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CRQ",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CRQ",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CRR",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CRR",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CRS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CRS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CRT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CRT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CRU",
    "countryCode": "GD",
    "timezone": "America/Grenada",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CRU",
    "countryCode": "GD",
    "timezone": "America/Grenada",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CRV",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CRV",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CRW",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CRW",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CRX",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CRX",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CRY",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CRZ",
    "countryCode": "TM",
    "timezone": "Asia/Ashgabat",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "CRZ",
    "countryCode": "TM",
    "timezone": "Asia/Ashgabat",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "CSA",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "CSA",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "CSB",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "CSB",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "CSC",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "CSD",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "CSE",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "CSF",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CSF",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CSG",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CSG",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CSH",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "CSH",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "CSI",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "CSI",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "CSJ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CSJ",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "CSK",
    "countryCode": "SN",
    "timezone": "Africa/Dakar",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "CSK",
    "countryCode": "SN",
    "timezone": "Africa/Dakar",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "CSL",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CSL",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CSM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CSM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CSN",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CSN",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CSO",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CSO",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CSO",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CSP",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CSP",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CSQ",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CSQ",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CSR",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CSS",
    "countryCode": "BR",
    "timezone": "America/Campo_Grande",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CST",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "CSU",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CSV",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CSW",
    "countryCode": "BR",
    "timezone": "America/Porto_Velho",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CSW",
    "countryCode": "BR",
    "timezone": "America/Porto_Velho",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CSX",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CSX",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CSY",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "CSY",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "CSZ",
    "countryCode": "AR",
    "timezone": "America/Argentina/Buenos_Aires",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CSZ",
    "countryCode": "AR",
    "timezone": "America/Argentina/Buenos_Aires",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CTA",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CTA",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CTB",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "CTB",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "CTC",
    "countryCode": "AR",
    "timezone": "America/Argentina/Catamarca",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CTC",
    "countryCode": "AR",
    "timezone": "America/Argentina/Catamarca",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CTD",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CTD",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CTE",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CTE",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CTF",
    "countryCode": "GT",
    "timezone": "America/Guatemala",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "CTF",
    "countryCode": "GT",
    "timezone": "America/Guatemala",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "CTG",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CTG",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CTH",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CTH",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CTI",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "CTI",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "CTJ",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CTJ",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CTK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CTK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CTL",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "CTL",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "CTM",
    "countryCode": "MX",
    "timezone": "America/Cancun",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CTM",
    "countryCode": "MX",
    "timezone": "America/Cancun",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CTN",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "CTN",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "CTO",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CTO",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CTP",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CTP",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CTQ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CTR",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "CTS",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "CTT",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CTT",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CTU",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CTU",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CTV",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "CTW",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "CTW",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "CTX",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CTX",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CTY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CTY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CTZ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CTZ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CUA",
    "countryCode": "MX",
    "timezone": "America/Mazatlan",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "CUA",
    "countryCode": "MX",
    "timezone": "America/Mazatlan",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "CUB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CUC",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CUC",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CUD",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "CUD",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "CUE",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CUE",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CUF",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CUF",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CUG",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "CUG",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "CUH",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CUH",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CUI",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CUJ",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CUK",
    "countryCode": "BZ",
    "timezone": "America/Belize",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "CUK",
    "countryCode": "BZ",
    "timezone": "America/Belize",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "CUL",
    "countryCode": "MX",
    "timezone": "America/Mazatlan",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "CUL",
    "countryCode": "MX",
    "timezone": "America/Mazatlan",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "CUM",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CUM",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CUN",
    "countryCode": "MX",
    "timezone": "America/Cancun",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CUN",
    "countryCode": "MX",
    "timezone": "America/Cancun",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CUO",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CUO",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CUP",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CUP",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CUQ",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "CUQ",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "CUR",
    "countryCode": "CW",
    "timezone": "America/Curacao",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CUR",
    "countryCode": "CW",
    "timezone": "America/Curacao",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CUS",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "CUS",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "CUT",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CUT",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CUU",
    "countryCode": "MX",
    "timezone": "America/Chihuahua",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "CUU",
    "countryCode": "MX",
    "timezone": "America/Chihuahua",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "CUV",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CUV",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CUW",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CUW",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CUX",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "CUY",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CUY",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CUZ",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CUZ",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CVB",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "CVC",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "CVC",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "CVE",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CVE",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CVF",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CVF",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CVG",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CVG",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CVH",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CVH",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CVI",
    "countryCode": "AR",
    "timezone": "America/Argentina/Rio_Gallegos",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CVI",
    "countryCode": "AR",
    "timezone": "America/Argentina/Rio_Gallegos",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CVJ",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "CVJ",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "CVL",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "CVM",
    "countryCode": "MX",
    "timezone": "America/Monterrey",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "CVM",
    "countryCode": "MX",
    "timezone": "America/Monterrey",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "CVN",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "CVN",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "CVO",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CVO",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CVQ",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CVQ",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CVR",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CVS",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "CVT",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "CVT",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "CVU",
    "countryCode": "PT",
    "timezone": "Atlantic/Azores",
    "offset": {
      "gmt": -1,
      "dst": 0
    }
  },
  {
    "code": "CVU",
    "countryCode": "PT",
    "timezone": "Atlantic/Azores",
    "offset": {
      "gmt": -1,
      "dst": 0
    }
  },
  {
    "code": "CWA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CWB",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CWB",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CWC",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "CWC",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "CWF",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CWI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CWI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CWJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CWJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CWK",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "CWK",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "CWL",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "CWL",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "CWO",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CWP",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "CWR",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "CWS",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CWS",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CWT",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "CWT",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "CWW",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "CWW",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "CWX",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "CWX",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "CXA",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CXA",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CXB",
    "countryCode": "BD",
    "timezone": "Asia/Dhaka",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "CXB",
    "countryCode": "BD",
    "timezone": "Asia/Dhaka",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "CXC",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CXE",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "CXF",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CXF",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CXH",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CXI",
    "countryCode": "KI",
    "timezone": "Pacific/Kiritimati",
    "offset": {
      "gmt": 14,
      "dst": 14
    }
  },
  {
    "code": "CXI",
    "countryCode": "KI",
    "timezone": "Pacific/Kiritimati",
    "offset": {
      "gmt": 14,
      "dst": 14
    }
  },
  {
    "code": "CXJ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CXJ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CXL",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CXL",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CXN",
    "countryCode": "SO",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "CXN",
    "countryCode": "SO",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "CXO",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CXO",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CXP",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "CXP",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "CXQ",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CXR",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "CXT",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "CXT",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "CXX",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "CXY",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CXY",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CYA",
    "countryCode": "HT",
    "timezone": "America/Port-au-Prince",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CYA",
    "countryCode": "HT",
    "timezone": "America/Port-au-Prince",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CYB",
    "countryCode": "KY",
    "timezone": "America/Cayman",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CYB",
    "countryCode": "KY",
    "timezone": "America/Cayman",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CYC",
    "countryCode": "BZ",
    "timezone": "America/Belize",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "CYC",
    "countryCode": "BZ",
    "timezone": "America/Belize",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "CYD",
    "countryCode": "BZ",
    "timezone": "America/Belize",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "CYF",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CYF",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CYG",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "CYG",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "CYI",
    "countryCode": "TW",
    "timezone": "Asia/Taipei",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CYI",
    "countryCode": "TW",
    "timezone": "Asia/Taipei",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CYL",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "CYM",
    "countryCode": "US",
    "timezone": "America/Sitka",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CYM",
    "countryCode": "US",
    "timezone": "America/Sitka",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CYO",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CYO",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "CYP",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CYP",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CYR",
    "countryCode": "UY",
    "timezone": "America/Montevideo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CYR",
    "countryCode": "UY",
    "timezone": "America/Montevideo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CYS",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "CYS",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "CYT",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CYT",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CYU",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CYU",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CYW",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "CYW",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "CYX",
    "countryCode": "RU",
    "timezone": "Asia/Magadan",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "CYX",
    "countryCode": "RU",
    "timezone": "Asia/Magadan",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "CYY",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CYZ",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CYZ",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CZA",
    "countryCode": "MX",
    "timezone": "America/Merida",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "CZA",
    "countryCode": "MX",
    "timezone": "America/Merida",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "CZB",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CZB",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "CZC",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CZC",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CZE",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CZE",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "CZF",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CZF",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CZH",
    "countryCode": "BZ",
    "timezone": "America/Belize",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "CZH",
    "countryCode": "BZ",
    "timezone": "America/Belize",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "CZJ",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CZJ",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CZK",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CZK",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "CZL",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "CZL",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "CZM",
    "countryCode": "MX",
    "timezone": "America/Cancun",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CZM",
    "countryCode": "MX",
    "timezone": "America/Cancun",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CZN",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CZN",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CZO",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CZP",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "CZS",
    "countryCode": "BR",
    "timezone": "America/Eirunepe",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CZS",
    "countryCode": "BR",
    "timezone": "America/Rio_Branco",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CZT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CZT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "CZU",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CZU",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "CZW",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "CZX",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CZX",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "CZY",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "CZZ",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "DAA",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DAA",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DAB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DAB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DAC",
    "countryCode": "BD",
    "timezone": "Asia/Dhaka",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "DAC",
    "countryCode": "BD",
    "timezone": "Asia/Dhaka",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "DAD",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "DAD",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "DAE",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DAF",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DAG",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "DAG",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "DAH",
    "countryCode": "YE",
    "timezone": "Asia/Aden",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DAI",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DAJ",
    "countryCode": "AU",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DAJ",
    "countryCode": "AU",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DAK",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "DAK",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "DAL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DAM",
    "countryCode": "SY",
    "timezone": "Asia/Damascus",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DAM",
    "countryCode": "SY",
    "timezone": "Asia/Damascus",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DAN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DAN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DAO",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DAO",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DAP",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DAQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DAR",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DAR",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DAS",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "DAS",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "DAT",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DAT",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DAU",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DAU",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DAU",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DAV",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "DAV",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "DAX",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DAX",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DAY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DAY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DAZ",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "DBA",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DBA",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DBB",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "DBB",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "DBC",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DBC",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DBD",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DBD",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DBK",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DBK",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DBL",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DBM",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DBN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DBN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DBO",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "DBO",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "DBP",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DBQ",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DBQ",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DBR",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DBR",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DBR",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DBS",
    "countryCode": "US",
    "timezone": "America/Boise",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "DBT",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DBT",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DBU",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DBU",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DBV",
    "countryCode": "HR",
    "timezone": "Europe/Zagreb",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "DBV",
    "countryCode": "HR",
    "timezone": "Europe/Zagreb",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "DBY",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DBY",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DCA",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DCB",
    "countryCode": "DM",
    "timezone": "America/Dominica",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "DCB",
    "countryCode": "DM",
    "timezone": "America/Dominica",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "DCF",
    "countryCode": "DM",
    "timezone": "America/Dominica",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "DCG",
    "countryCode": "AE",
    "timezone": "Asia/Dubai",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "DCI",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "DCI",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "DCK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "DCK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "DCM",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "DCM",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "DCN",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DCP",
    "countryCode": "CA",
    "timezone": "America/Fort_Nelson",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "DCR",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DCT",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DCT",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DCU",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DCU",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DCY",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DCY",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DDA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DDB",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DDC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DDC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DDD",
    "countryCode": "MV",
    "timezone": "Indian/Maldives",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DDD",
    "countryCode": "MV",
    "timezone": "Indian/Maldives",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DDG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DDG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DDI",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DDI",
    "countryCode": "AU",
    "timezone": "Australia/Lindeman",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DDL",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "DDM",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DDN",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DDP",
    "countryCode": "PR",
    "timezone": "America/Puerto_Rico",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "DDR",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DDR",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DDU",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DDU",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DDV",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DEA",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DEA",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DEB",
    "countryCode": "HU",
    "timezone": "Europe/Budapest",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "DEB",
    "countryCode": "HU",
    "timezone": "Europe/Budapest",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "DEC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DEC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DED",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DED",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DEE",
    "countryCode": "RU",
    "timezone": "Asia/Sakhalin",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "DEE",
    "countryCode": "RU",
    "timezone": "Asia/Sakhalin",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "DEE",
    "countryCode": "RU",
    "timezone": "Asia/Sakhalin",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "DEF",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DEF",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DEH",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DEH",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DEI",
    "countryCode": "SC",
    "timezone": "Indian/Mahe",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "DEL",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DEL",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DEM",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DEM",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DEN",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "DEN",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "DEO",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "DEP",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DEP",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DEQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DEQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DEQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DEQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DER",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DES",
    "countryCode": "SC",
    "timezone": "Indian/Mahe",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "DES",
    "countryCode": "SC",
    "timezone": "Indian/Mahe",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "DET",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DEX",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "DEX",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "DEY",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DEY",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DEZ",
    "countryCode": "SY",
    "timezone": "Asia/Damascus",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DEZ",
    "countryCode": "SY",
    "timezone": "Asia/Damascus",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DFA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DFA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DFI",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DFI",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DFP",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DFW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DFW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DGA",
    "countryCode": "BZ",
    "timezone": "America/Belize",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "DGA",
    "countryCode": "BZ",
    "timezone": "America/Belize",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "DGB",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "DGB",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "DGC",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DGD",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DGE",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "DGE",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "DGF",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "DGF",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "DGG",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DGG",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DGH",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DGH",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DGK",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "DGK",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "DGL",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "DGM",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DGM",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DGN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DGN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DGO",
    "countryCode": "MX",
    "timezone": "America/Monterrey",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "DGO",
    "countryCode": "MX",
    "timezone": "America/Monterrey",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "DGP",
    "countryCode": "LV",
    "timezone": "Europe/Riga",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "DGP",
    "countryCode": "LV",
    "timezone": "Europe/Riga",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "DGR",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "DGR",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "DGT",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DGT",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DGU",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "DGU",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "DGW",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "DGW",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "DGX",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "DGX",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "DHA",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DHA",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DHB",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "DHB",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "DHC",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "DHD",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DHF",
    "countryCode": "AE",
    "timezone": "Asia/Dubai",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "DHG",
    "countryCode": "RU",
    "timezone": "Asia/Vladivostok",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DHG",
    "countryCode": "RU",
    "timezone": "Asia/Vladivostok",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DHI",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DHI",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DHL",
    "countryCode": "YE",
    "timezone": "Asia/Aden",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DHM",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DHM",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DHN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DHN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DHO",
    "countryCode": "CA",
    "timezone": "America/Fort_Nelson",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "DHR",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "DHR",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "DHS",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "DHT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DHT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DHU",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DHU",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DHX",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "DHX",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "DIA",
    "countryCode": "QA",
    "timezone": "Asia/Qatar",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DIB",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DIB",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DIC",
    "countryCode": "TL",
    "timezone": "Asia/Dili",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "DIE",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DIE",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DIG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DIG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DIJ",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "DIJ",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "DIK",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "DIK",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "DIL",
    "countryCode": "TL",
    "timezone": "Asia/Dili",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "DIL",
    "countryCode": "TL",
    "timezone": "Asia/Dili",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "DIM",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "DIM",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "DIN",
    "countryCode": "VN",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "DIN",
    "countryCode": "VN",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "DIO",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "DIO",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "DIP",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "DIP",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "DIQ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "DIQ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "DIR",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DIR",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DIS",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "DIS",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "DIU",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DIU",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DIV",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "DIV",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "DIW",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DIW",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DIY",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DIY",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DJA",
    "countryCode": "BJ",
    "timezone": "Africa/Porto-Novo",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "DJA",
    "countryCode": "BJ",
    "timezone": "Africa/Porto-Novo",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "DJB",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "DJB",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "DJE",
    "countryCode": "TN",
    "timezone": "Africa/Tunis",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "DJE",
    "countryCode": "TN",
    "timezone": "Africa/Tunis",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "DJE",
    "countryCode": "TN",
    "timezone": "Africa/Tunis",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "DJG",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "DJG",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "DJH",
    "countryCode": "AE",
    "timezone": "Asia/Dubai",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "DJH",
    "countryCode": "AE",
    "timezone": "Asia/Dubai",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "DJJ",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "DJJ",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "DJJ",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "DJK",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "DJM",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "DJM",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "DJN",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "DJN",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "DJO",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "DJO",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "DJR",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DJR",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DJU",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "DKA",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "DKA",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "DKI",
    "countryCode": "AU",
    "timezone": "Australia/Lindeman",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DKI",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DKK",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DKK",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DKL",
    "countryCode": "CA",
    "timezone": "America/Fort_Nelson",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "DKR",
    "countryCode": "SN",
    "timezone": "Africa/Dakar",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "DKR",
    "countryCode": "SN",
    "timezone": "Africa/Dakar",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "DKS",
    "countryCode": "RU",
    "timezone": "Asia/Krasnoyarsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "DKS",
    "countryCode": "RU",
    "timezone": "Asia/Krasnoyarsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "DKV",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "DKV",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "DLA",
    "countryCode": "CM",
    "timezone": "Africa/Douala",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "DLA",
    "countryCode": "CM",
    "timezone": "Africa/Douala",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "DLB",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "DLC",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DLC",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DLD",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "DLE",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "DLE",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "DLF",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DLG",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "DLG",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "DLH",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DLH",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DLI",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "DLI",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "DLK",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "DLL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DLL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DLM",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DLM",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DLN",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "DLN",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "DLO",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "DLR",
    "countryCode": "RU",
    "timezone": "Asia/Vladivostok",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DLR",
    "countryCode": "RU",
    "timezone": "Asia/Vladivostok",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DLS",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "DLS",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "DLU",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DLU",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DLV",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "DLY",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "DLY",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "DLZ",
    "countryCode": "MN",
    "timezone": "Asia/Ulaanbaatar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DLZ",
    "countryCode": "MN",
    "timezone": "Asia/Ulaanbaatar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DMA",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "DMB",
    "countryCode": "KZ",
    "timezone": "Asia/Almaty",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "DMB",
    "countryCode": "KZ",
    "timezone": "Asia/Almaty",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "DMD",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DMD",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DME",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DMK",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "DMM",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DMM",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DMN",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "DMN",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "DMO",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DMO",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DMR",
    "countryCode": "YE",
    "timezone": "Asia/Aden",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DMS",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DMT",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "DMT",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "DMU",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DMU",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DNA",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "DNB",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DNC",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "DND",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "DND",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "DNE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DNF",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "DNG",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DNH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DNH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DNI",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "DNI",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "DNK",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "DNK",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "DNL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DNM",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DNM",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DNN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DNN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DNO",
    "countryCode": "BR",
    "timezone": "America/Araguaina",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "DNO",
    "countryCode": "BR",
    "timezone": "America/Araguaina",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "DNP",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DNP",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DNQ",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "DNQ",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "DNR",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "DNR",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "DNS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DNS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DNT",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "DNU",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DNV",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DNV",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DNX",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "DNX",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "DNZ",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DNZ",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DNZ",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DOA",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DOA",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DOB",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "DOB",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "DOC",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "DOC",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "DOD",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DOD",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DOE",
    "countryCode": "SR",
    "timezone": "America/Paramaribo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "DOF",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "DOG",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "DOG",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "DOH",
    "countryCode": "QA",
    "timezone": "Asia/Qatar",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DOH",
    "countryCode": "QA",
    "timezone": "Asia/Qatar",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DOH",
    "countryCode": "QA",
    "timezone": "Asia/Qatar",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DOI",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DOK",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "DOK",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "DOL",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "DOL",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "DOM",
    "countryCode": "DM",
    "timezone": "America/Dominica",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "DOM",
    "countryCode": "DM",
    "timezone": "America/Dominica",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "DON",
    "countryCode": "GT",
    "timezone": "America/Guatemala",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "DON",
    "countryCode": "GT",
    "timezone": "America/Guatemala",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "DOO",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DOP",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DOP",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DOR",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "DOR",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "DOS",
    "countryCode": "PG",
    "timezone": "Pacific/Bougainville",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "DOU",
    "countryCode": "BR",
    "timezone": "America/Campo_Grande",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "DOU",
    "countryCode": "BR",
    "timezone": "America/Campo_Grande",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "DOV",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DOV",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DOV",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DOX",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DOX",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DOY",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DOY",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DPA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DPB",
    "countryCode": "CL",
    "timezone": "America/Punta_Arenas",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "DPB",
    "countryCode": "CL",
    "timezone": "America/Punta_Arenas",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "DPC",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "DPE",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "DPE",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "DPG",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "DPG",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "DPK",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DPK",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DPL",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DPL",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DPO",
    "countryCode": "AU",
    "timezone": "Australia/Hobart",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "DPO",
    "countryCode": "AU",
    "timezone": "Australia/Hobart",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "DPS",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DPS",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DPT",
    "countryCode": "RU",
    "timezone": "Asia/Vladivostok",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DPT",
    "countryCode": "RU",
    "timezone": "Asia/Vladivostok",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DPU",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DQA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DQA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DQM",
    "countryCode": "OM",
    "timezone": "Asia/Muscat",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "DQM",
    "countryCode": "OM",
    "timezone": "Asia/Muscat",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "DRA",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "DRA",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "DRB",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DRB",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DRC",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "DRC",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "DRD",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DRD",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DRE",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DRE",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DRF",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "DRG",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "DRG",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "DRH",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "DRH",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "DRI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DRI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DRJ",
    "countryCode": "SR",
    "timezone": "America/Paramaribo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "DRJ",
    "countryCode": "SR",
    "timezone": "America/Paramaribo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "DRK",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "DRK",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "DRM",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "DRN",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DRN",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DRO",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "DRO",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "DRP",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DRP",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DRR",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DRS",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "DRS",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "DRT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DRT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DRU",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "DRU",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "DRV",
    "countryCode": "MV",
    "timezone": "Indian/Maldives",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DRV",
    "countryCode": "MV",
    "timezone": "Indian/Maldives",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DRW",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "DRW",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "DRX",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "DRY",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DRY",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DSA",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "DSA",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "DSA",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "DSC",
    "countryCode": "CM",
    "timezone": "Africa/Douala",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "DSC",
    "countryCode": "CM",
    "timezone": "Africa/Douala",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "DSD",
    "countryCode": "GP",
    "timezone": "America/Guadeloupe",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "DSE",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DSE",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DSG",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DSI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DSK",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DSK",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DSL",
    "countryCode": "SL",
    "timezone": "Africa/Freetown",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "DSM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DSM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DSN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DSN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DSO",
    "countryCode": "KP",
    "timezone": "Asia/Pyongyang",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "DSO",
    "countryCode": "KP",
    "timezone": "Asia/Pyongyang",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "DSS",
    "countryCode": "SN",
    "timezone": "Africa/Dakar",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "DSS",
    "countryCode": "SN",
    "timezone": "Africa/Dakar",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "DST",
    "countryCode": "AE",
    "timezone": "Asia/Dubai",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "DSV",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DSV",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DSX",
    "countryCode": "TW",
    "timezone": "Asia/Taipei",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DSX",
    "countryCode": "TW",
    "timezone": "Asia/Taipei",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DSY",
    "countryCode": "KH",
    "timezone": "Asia/Phnom_Penh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "DTA",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "DTA",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "DTB",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "DTB",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "DTD",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DTD",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DTE",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DTE",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DTH",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "DTH",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "DTI",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "DTI",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "DTL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DTL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DTM",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "DTM",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "DTN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DTR",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "DTR",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "DTS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DTT",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DTU",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DTU",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DTW",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DTZ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "DUA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DUA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DUB",
    "countryCode": "IE",
    "timezone": "Europe/Dublin",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "DUB",
    "countryCode": "IE",
    "timezone": "Europe/Dublin",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "DUC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DUC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DUD",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "DUD",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "DUE",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "DUF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DUF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DUG",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "DUG",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "DUH",
    "countryCode": "AL",
    "timezone": "Europe/Tirane",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "DUH",
    "countryCode": "AL",
    "timezone": "Europe/Tirane",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "DUI",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "DUI",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "DUJ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DUJ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DUK",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "DUM",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "DUM",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "DUN",
    "countryCode": "GL",
    "timezone": "America/Thule",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "DUQ",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "DUQ",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "DUR",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "DUR",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "DUS",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "DUS",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "DUT",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "DUT",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "DVD",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DVK",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "DVK",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "DVL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DVL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DVN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DVN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DVO",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DVO",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DVP",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DVR",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "DVT",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "DWA",
    "countryCode": "MW",
    "timezone": "Africa/Blantyre",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "DWA",
    "countryCode": "MW",
    "timezone": "Africa/Blantyre",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "DWB",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DWB",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DWC",
    "countryCode": "AE",
    "timezone": "Asia/Dubai",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "DWD",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DWD",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DWH",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DWN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DWO",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DWO",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DWR",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "DWS",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DWS",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DXA",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "DXB",
    "countryCode": "AE",
    "timezone": "Asia/Dubai",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "DXB",
    "countryCode": "AE",
    "timezone": "Asia/Dubai",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "DXD",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DXD",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DXE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DXE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DXJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DXN",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DXR",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DXR",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DYA",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DYA",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DYG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DYG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DYL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DYL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "DYM",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DYM",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "DYN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DYN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DYR",
    "countryCode": "RU",
    "timezone": "Asia/Anadyr",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "DYR",
    "countryCode": "RU",
    "timezone": "Asia/Anadyr",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "DYS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "DYU",
    "countryCode": "TJ",
    "timezone": "Asia/Dushanbe",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DYU",
    "countryCode": "TJ",
    "timezone": "Asia/Dushanbe",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "DYW",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "DYW",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "DZA",
    "countryCode": "YT",
    "timezone": "Indian/Mayotte",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DZA",
    "countryCode": "YT",
    "timezone": "Indian/Mayotte",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DZA",
    "countryCode": "YT",
    "timezone": "Indian/Mayotte",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "DZH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "DZI",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "DZN",
    "countryCode": "KZ",
    "timezone": "Asia/Almaty",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "DZN",
    "countryCode": "KZ",
    "timezone": "Asia/Almaty",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "DZO",
    "countryCode": "UY",
    "timezone": "America/Montevideo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "DZO",
    "countryCode": "UY",
    "timezone": "America/Montevideo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "DZU",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "EAA",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "EAA",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "EAB",
    "countryCode": "YE",
    "timezone": "Asia/Aden",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "EAB",
    "countryCode": "YE",
    "timezone": "Asia/Aden",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "EAE",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "EAE",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "EAG",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EAL",
    "countryCode": "MH",
    "timezone": "Pacific/Kwajalein",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "EAL",
    "countryCode": "MH",
    "timezone": "Pacific/Kwajalein",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "EAM",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "EAM",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "EAN",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "EAN",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "EAP",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EAP",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EAR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "EAR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "EAS",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EAS",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EAT",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "EAT",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "EAU",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "EAU",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "EBA",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EBA",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EBB",
    "countryCode": "UG",
    "timezone": "Africa/Kampala",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "EBB",
    "countryCode": "UG",
    "timezone": "Africa/Kampala",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "EBD",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "EBD",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "EBG",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "EBG",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "EBH",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "EBH",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "EBJ",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EBJ",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EBL",
    "countryCode": "IQ",
    "timezone": "Asia/Baghdad",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "EBL",
    "countryCode": "IQ",
    "timezone": "Asia/Baghdad",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "EBM",
    "countryCode": "TN",
    "timezone": "Africa/Tunis",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "EBM",
    "countryCode": "TN",
    "timezone": "Africa/Tunis",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "EBN",
    "countryCode": "MH",
    "timezone": "Pacific/Kwajalein",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "EBN",
    "countryCode": "MH",
    "timezone": "Pacific/Kwajalein",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "EBO",
    "countryCode": "MH",
    "timezone": "Pacific/Majuro",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "EBO",
    "countryCode": "MH",
    "timezone": "Pacific/Majuro",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "EBR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "EBS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "EBS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "EBU",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EBU",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EBV",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EBW",
    "countryCode": "CM",
    "timezone": "Africa/Douala",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "EBW",
    "countryCode": "CM",
    "timezone": "Africa/Douala",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ECA",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ECC",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ECG",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ECG",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ECH",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "ECH",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "ECI",
    "countryCode": "NI",
    "timezone": "America/Managua",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "ECI",
    "countryCode": "NI",
    "timezone": "America/Managua",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "ECN",
    "countryCode": "CY",
    "timezone": "Asia/Nicosia",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "ECN",
    "countryCode": "CY",
    "timezone": "Asia/Nicosia",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "ECO",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ECO",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ECP",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ECP",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ECR",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ECR",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ECS",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "EDA",
    "countryCode": "US",
    "timezone": "America/Sitka",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "EDA",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "EDB",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "EDB",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "EDD",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "EDE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "EDE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "EDF",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "EDG",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "EDG",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "EDI",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "EDI",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "EDK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "EDK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "EDL",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "EDL",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "EDM",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EDM",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EDN",
    "countryCode": "RU",
    "timezone": "Asia/Vladivostok",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "EDN",
    "countryCode": "RU",
    "timezone": "Asia/Vladivostok",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "EDO",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "EDO",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "EDQ",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "EDQ",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "EDR",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "EDR",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "EDW",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "EDW",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "EEA",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "EEA",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "EED",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "EED",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "EEK",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "EEK",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "EEL",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EEM",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EEN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "EEN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "EEP",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EER",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EES",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "EES",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "EEU",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EFB",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "EFB",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "EFD",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "EFG",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "EFK",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "EFK",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "EFL",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "EFL",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "EFO",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "EFW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "EFW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "EGA",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "EGC",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EGC",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EGE",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "EGE",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "EGH",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "EGI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "EGL",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "EGM",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "EGM",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "EGN",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "EGN",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "EGO",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "EGO",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "EGP",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "EGP",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "EGS",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "EGS",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "EGV",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "EGV",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "EGX",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "EGX",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "EHD",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "EHL",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "EHL",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "EHM",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "EHT",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "EHT",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "EHU",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "EHU",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "EIA",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "EIB",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EIB",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EIE",
    "countryCode": "RU",
    "timezone": "Asia/Krasnoyarsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "EIE",
    "countryCode": "RU",
    "timezone": "Asia/Krasnoyarsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "EIH",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "EIH",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "EIK",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "EIK",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "EIL",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "EIN",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EIN",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EIS",
    "countryCode": "VG",
    "timezone": "America/Tortola",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "EIS",
    "countryCode": "VG",
    "timezone": "America/Tortola",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "EIY",
    "countryCode": "IL",
    "timezone": "Asia/Jerusalem",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "EIY",
    "countryCode": "IL",
    "timezone": "Asia/Jerusalem",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "EIZ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EJA",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "EJA",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "EJH",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "EJH",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "EJJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "EJN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "EJN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "EJQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "EJT",
    "countryCode": "MH",
    "timezone": "Pacific/Majuro",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "EJT",
    "countryCode": "MH",
    "timezone": "Pacific/Majuro",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "EKA",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "EKA",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "EKB",
    "countryCode": "KZ",
    "timezone": "Asia/Almaty",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "EKB",
    "countryCode": "KZ",
    "timezone": "Asia/Almaty",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "EKD",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "EKD",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "EKE",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "EKG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "EKI",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "EKI",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "EKN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "EKO",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "EKO",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "EKS",
    "countryCode": "RU",
    "timezone": "Asia/Sakhalin",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "EKS",
    "countryCode": "RU",
    "timezone": "Asia/Sakhalin",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "EKT",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EKT",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EKW",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "EKX",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "EKX",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ELA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ELA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ELB",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ELB",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ELC",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "ELC",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "ELD",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ELD",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ELE",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ELE",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ELF",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "ELF",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "ELG",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ELG",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ELH",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ELH",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ELI",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "ELI",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "ELJ",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ELK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ELK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ELL",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "ELL",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "ELM",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ELM",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ELN",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ELN",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ELO",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ELO",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ELP",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "ELP",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "ELQ",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ELQ",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ELR",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "ELS",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "ELS",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "ELT",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "ELT",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "ELU",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ELU",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ELU",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ELV",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "ELV",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "ELW",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "ELX",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "ELX",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "ELY",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ELY",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ELZ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ELZ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "EMA",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "EMB",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "EMD",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "EMD",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "EME",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EME",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EMG",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "EMI",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "EMJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "EMK",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "EMK",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "EML",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EML",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EMM",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "EMM",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "EMN",
    "countryCode": "MR",
    "timezone": "Africa/Nouakchott",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "EMN",
    "countryCode": "MR",
    "timezone": "Africa/Nouakchott",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "EMO",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "EMP",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "EMP",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "EMR",
    "countryCode": "GT",
    "timezone": "America/Guatemala",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "EMR",
    "countryCode": "GT",
    "timezone": "America/Guatemala",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "EMS",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "EMT",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "EMT",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "EMU",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EMX",
    "countryCode": "AR",
    "timezone": "America/Argentina/Catamarca",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "EMX",
    "countryCode": "AR",
    "timezone": "America/Argentina/Catamarca",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "EMY",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "ENA",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "ENA",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "ENB",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ENC",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "END",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ENE",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ENE",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ENF",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "ENF",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "ENH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ENH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ENI",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ENI",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ENJ",
    "countryCode": "GT",
    "timezone": "America/Guatemala",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "ENK",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "ENK",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "ENL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ENL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ENN",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "ENN",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "ENO",
    "countryCode": "PY",
    "timezone": "America/Asuncion",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "ENO",
    "countryCode": "PY",
    "timezone": "America/Asuncion",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "ENQ",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "ENS",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ENS",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ENT",
    "countryCode": "MH",
    "timezone": "Pacific/Kwajalein",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "ENT",
    "countryCode": "MH",
    "timezone": "Pacific/Kwajalein",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "ENU",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ENU",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ENV",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "ENV",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "ENW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ENW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ENY",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ENY",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ENY",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "EOH",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "EOI",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "EOI",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "EOK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "EOK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "EOR",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "EOS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "EOS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "EOZ",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "EOZ",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "EPA",
    "countryCode": "AR",
    "timezone": "America/Argentina/Buenos_Aires",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "EPA",
    "countryCode": "AR",
    "timezone": "America/Argentina/Buenos_Aires",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "EPG",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "EPH",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "EPH",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "EPI",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "EPK",
    "countryCode": "CY",
    "timezone": "Asia/Nicosia",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "EPL",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EPL",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EPL",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EPN",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "EPR",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "EPR",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "EPS",
    "countryCode": "DO",
    "timezone": "America/Santo_Domingo",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "EPS",
    "countryCode": "DO",
    "timezone": "America/Santo_Domingo",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "EPT",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "EPU",
    "countryCode": "EE",
    "timezone": "Europe/Tallinn",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "EPU",
    "countryCode": "EE",
    "timezone": "Europe/Tallinn",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "EQS",
    "countryCode": "AR",
    "timezone": "America/Argentina/Catamarca",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "EQS",
    "countryCode": "AR",
    "timezone": "America/Argentina/Catamarca",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ERA",
    "countryCode": "SO",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ERA",
    "countryCode": "SO",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ERB",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "ERB",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "ERC",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ERC",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ERD",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "ERD",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "ERE",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ERE",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ERF",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ERF",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ERG",
    "countryCode": "RU",
    "timezone": "Asia/Irkutsk",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ERG",
    "countryCode": "RU",
    "timezone": "Asia/Irkutsk",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ERH",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ERH",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ERI",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ERI",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ERL",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ERL",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ERM",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ERM",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ERN",
    "countryCode": "BR",
    "timezone": "America/Eirunepe",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ERN",
    "countryCode": "BR",
    "timezone": "America/Eirunepe",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ERO",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "ERO",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "ERQ",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ERQ",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ERQ",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ERR",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ERR",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ERS",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "ERT",
    "countryCode": "MN",
    "timezone": "Asia/Ulaanbaatar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ERU",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ERV",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ERV",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ERZ",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ERZ",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ESA",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ESA",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ESB",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ESB",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ESC",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ESC",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ESD",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ESD",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ESE",
    "countryCode": "MX",
    "timezone": "America/Tijuana",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ESE",
    "countryCode": "MX",
    "timezone": "America/Tijuana",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ESF",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ESG",
    "countryCode": "PY",
    "timezone": "America/Asuncion",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "ESG",
    "countryCode": "PY",
    "timezone": "America/Asuncion",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "ESH",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "ESH",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "ESI",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ESK",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ESK",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ESL",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ESL",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ESM",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ESM",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ESN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ESN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ESO",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "ESO",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "ESP",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ESP",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ESR",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "ESR",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "ESS",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ESS",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EST",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "EST",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ESU",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ESU",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ESW",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ESW",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ESZ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ETB",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ETB",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ETD",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "ETE",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ETH",
    "countryCode": "IL",
    "timezone": "Asia/Jerusalem",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "ETH",
    "countryCode": "IL",
    "timezone": "Asia/Jerusalem",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "ETL",
    "countryCode": "RU",
    "timezone": "Asia/Vladivostok",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ETL",
    "countryCode": "RU",
    "timezone": "Asia/Vladivostok",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ETM",
    "countryCode": "IL",
    "timezone": "Asia/Jerusalem",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "ETN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ETN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ETR",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ETR",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ETS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ETS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ETZ",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ETZ",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ETZ",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EUA",
    "countryCode": "TO",
    "timezone": "Pacific/Tongatapu",
    "offset": {
      "gmt": 13,
      "dst": 13
    }
  },
  {
    "code": "EUA",
    "countryCode": "TO",
    "timezone": "Pacific/Tongatapu",
    "offset": {
      "gmt": 13,
      "dst": 13
    }
  },
  {
    "code": "EUC",
    "countryCode": "AU",
    "timezone": "Australia/Eucla",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "EUC",
    "countryCode": "AU",
    "timezone": "Australia/Eucla",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "EUE",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "EUE",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "EUF",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "EUF",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "EUG",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "EUG",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "EUM",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EUM",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EUN",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "EUN",
    "countryCode": "EH",
    "timezone": "Africa/El_Aaiun",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "EUO",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "EUO",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "EUQ",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "EUQ",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "EUX",
    "countryCode": "BQ",
    "timezone": "America/Kralendijk",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "EUX",
    "countryCode": "BQ",
    "timezone": "America/Kralendijk",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "EVA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "EVA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "EVD",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "EVE",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EVE",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EVE",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EVG",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EVG",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EVH",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "EVH",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "EVI",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EVM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "EVM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "EVN",
    "countryCode": "AM",
    "timezone": "Asia/Yerevan",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "EVN",
    "countryCode": "AM",
    "timezone": "Asia/Yerevan",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "EVO",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EVO",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EVV",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "EVV",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "EVW",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "EVW",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "EVX",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EVX",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EWB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "EWB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "EWD",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "EWD",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "EWE",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "EWI",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "EWI",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "EWK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "EWK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "EWN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "EWN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "EWO",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "EWO",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "EWR",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "EWR",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "EWS",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EWS",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EWS",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EWV",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "EWY",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "EWY",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "EXA",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EXC",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "EXI",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "EXI",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "EXM",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "EXM",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "EXS",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "EXT",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "EXT",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "EYA",
    "countryCode": "RU",
    "timezone": "Asia/Yakutsk",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "EYA",
    "countryCode": "RU",
    "timezone": "Asia/Yakutsk",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "EYK",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "EYK",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "EYL",
    "countryCode": "ML",
    "timezone": "Africa/Bamako",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "EYL",
    "countryCode": "ML",
    "timezone": "Africa/Bamako",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "EYP",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "EYP",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "EYR",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "EYR",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "EYS",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "EYS",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "EYW",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "EYW",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "EZE",
    "countryCode": "AR",
    "timezone": "America/Argentina/Buenos_Aires",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "EZS",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "EZS",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "EZV",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "EZV",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "FAA",
    "countryCode": "GN",
    "timezone": "Africa/Conakry",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "FAA",
    "countryCode": "GN",
    "timezone": "Africa/Conakry",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "FAB",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "FAB",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "FAC",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "FAC",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "FAE",
    "countryCode": "FO",
    "timezone": "Atlantic/Faroe",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "FAE",
    "countryCode": "FO",
    "timezone": "Atlantic/Faroe",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "FAE",
    "countryCode": "FO",
    "timezone": "Atlantic/Faroe",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "FAF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FAF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FAG",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "FAG",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "FAH",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "FAH",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "FAI",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "FAI",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "FAJ",
    "countryCode": "PR",
    "timezone": "America/Puerto_Rico",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "FAJ",
    "countryCode": "PR",
    "timezone": "America/Puerto_Rico",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "FAK",
    "countryCode": "US",
    "timezone": "America/Sitka",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "FAK",
    "countryCode": "US",
    "timezone": "America/Sitka",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "FAL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FAM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FAM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FAO",
    "countryCode": "PT",
    "timezone": "Europe/Lisbon",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "FAO",
    "countryCode": "PT",
    "timezone": "Europe/Lisbon",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "FAQ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "FAR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FAR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FAS",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "FAS",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "FAT",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "FAT",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "FAU",
    "countryCode": "OM",
    "timezone": "Asia/Muscat",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "FAU",
    "countryCode": "OM",
    "timezone": "Asia/Muscat",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "FAV",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "FAV",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "FAY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FAY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FAZ",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "FAZ",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "FBA",
    "countryCode": "BR",
    "timezone": "America/Manaus",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "FBA",
    "countryCode": "BR",
    "timezone": "America/Manaus",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "FBD",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "FBD",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "FBE",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "FBE",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "FBG",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FBG",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FBK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "FBL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FBL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FBM",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "FBM",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "FBR",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "FBR",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "FBS",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "FBT",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FBY",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FBY",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FCA",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "FCA",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "FCB",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "FCB",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "FCH",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "FCM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FCN",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FCN",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FCO",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FCS",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "FCT",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "FCY",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FCY",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FDD",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FDE",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FDE",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FDF",
    "countryCode": "MQ",
    "timezone": "America/Martinique",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "FDF",
    "countryCode": "MQ",
    "timezone": "America/Martinique",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "FDH",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FDH",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FDK",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FDK",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FDR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FDR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FDU",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "FDU",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "FDY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FDY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FEA",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "FEB",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "FEB",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "FEC",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "FEC",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "FEG",
    "countryCode": "UZ",
    "timezone": "Asia/Tashkent",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "FEG",
    "countryCode": "UZ",
    "timezone": "Asia/Tashkent",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "FEJ",
    "countryCode": "BR",
    "timezone": "America/Rio_Branco",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "FEJ",
    "countryCode": "BR",
    "timezone": "America/Rio_Branco",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "FEK",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "FEL",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FEL",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FEN",
    "countryCode": "BR",
    "timezone": "America/Noronha",
    "offset": {
      "gmt": -2,
      "dst": -2
    }
  },
  {
    "code": "FEN",
    "countryCode": "BR",
    "timezone": "America/Noronha",
    "offset": {
      "gmt": -2,
      "dst": -2
    }
  },
  {
    "code": "FEP",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FEP",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FER",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "FES",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FET",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FET",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FEW",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "FEZ",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "FEZ",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "FFA",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FFA",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FFD",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "FFD",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "FFF",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "FFF",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "FFL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FFL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FFM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FFM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FFO",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FFT",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FFT",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FFU",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "FGD",
    "countryCode": "MR",
    "timezone": "Africa/Nouakchott",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "FGD",
    "countryCode": "MR",
    "timezone": "Africa/Nouakchott",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "FGI",
    "countryCode": "WS",
    "timezone": "Pacific/Apia",
    "offset": {
      "gmt": 13,
      "dst": 13
    }
  },
  {
    "code": "FGL",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "FGL",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "FGR",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FGU",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "FGU",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "FHU",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "FHZ",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "FHZ",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "FIC",
    "countryCode": "US",
    "timezone": "America/Sitka",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "FID",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FID",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FIE",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "FIE",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "FIG",
    "countryCode": "GN",
    "timezone": "Africa/Conakry",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "FIG",
    "countryCode": "GN",
    "timezone": "Africa/Conakry",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "FIH",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "FIH",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "FII",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "FIK",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "FIK",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "FIL",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "FIL",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "FIN",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "FIN",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "FIR",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FIS",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "FIV",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "FIV",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "FIZ",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "FIZ",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "FJR",
    "countryCode": "AE",
    "timezone": "Asia/Dubai",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "FJR",
    "countryCode": "AE",
    "timezone": "Asia/Dubai",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "FKB",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FKB",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FKB",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FKH",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "FKH",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "FKI",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "FKI",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "FKJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "FKJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "FKL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FKL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FKN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FKN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FKQ",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "FKQ",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "FKS",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "FKS",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "FLA",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "FLA",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "FLB",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "FLB",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "FLC",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "FLC",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "FLD",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FLE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FLF",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FLF",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FLG",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "FLH",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "FLH",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "FLI",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "FLI",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "FLJ",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "FLJ",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "FLL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FLL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FLM",
    "countryCode": "PY",
    "timezone": "America/Asuncion",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "FLM",
    "countryCode": "PY",
    "timezone": "America/Asuncion",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "FLN",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "FLN",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "FLO",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FLO",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FLP",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FLP",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FLR",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FLR",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FLS",
    "countryCode": "AU",
    "timezone": "Australia/Hobart",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "FLS",
    "countryCode": "AU",
    "timezone": "Australia/Hobart",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "FLT",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "FLT",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "FLU",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FLV",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FLW",
    "countryCode": "PT",
    "timezone": "Atlantic/Azores",
    "offset": {
      "gmt": -1,
      "dst": 0
    }
  },
  {
    "code": "FLW",
    "countryCode": "PT",
    "timezone": "Atlantic/Azores",
    "offset": {
      "gmt": -1,
      "dst": 0
    }
  },
  {
    "code": "FLX",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "FLY",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "FLZ",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "FLZ",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "FMA",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "FMA",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "FMC",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "FME",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FME",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FMG",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "FMH",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FMI",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "FMI",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "FMM",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FMM",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FMN",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "FMN",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "FMO",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FMO",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FMS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FMT",
    "countryCode": "MV",
    "timezone": "Indian/Maldives",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "FMT",
    "countryCode": "MV",
    "timezone": "Indian/Maldives",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "FMU",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "FMU",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "FMV",
    "countryCode": "UY",
    "timezone": "America/Montevideo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "FMY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FMY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FNA",
    "countryCode": "SL",
    "timezone": "Africa/Freetown",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "FNA",
    "countryCode": "SL",
    "timezone": "Africa/Freetown",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "FNB",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FNB",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FNC",
    "countryCode": "PT",
    "timezone": "Atlantic/Madeira",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "FNC",
    "countryCode": "PT",
    "timezone": "Atlantic/Madeira",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "FND",
    "countryCode": "MV",
    "timezone": "Indian/Maldives",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "FND",
    "countryCode": "MV",
    "timezone": "Indian/Maldives",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "FND",
    "countryCode": "MV",
    "timezone": "Indian/Maldives",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "FNE",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "FNE",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "FNG",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "FNG",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "FNH",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "FNI",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FNI",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FNJ",
    "countryCode": "KP",
    "timezone": "Asia/Pyongyang",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "FNJ",
    "countryCode": "KP",
    "timezone": "Asia/Pyongyang",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "FNK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "FNL",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "FNL",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "FNL",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "FNR",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "FNR",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "FNT",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FNT",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FNU",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FNU",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FOA",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "FOA",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "FOB",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "FOB",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "FOC",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "FOC",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "FOC",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "FOD",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FOD",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FOE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FOG",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FOG",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FOI",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FOK",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FOK",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FOM",
    "countryCode": "CM",
    "timezone": "Africa/Douala",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "FOM",
    "countryCode": "CM",
    "timezone": "Africa/Douala",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "FON",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "FON",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "FON",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "FOO",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "FOO",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "FOP",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FOP",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FOR",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "FOR",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "FOS",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "FOS",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "FOT",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "FOT",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "FOU",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "FOU",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "FOX",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "FOY",
    "countryCode": "LR",
    "timezone": "Africa/Monrovia",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "FOY",
    "countryCode": "LR",
    "timezone": "Africa/Monrovia",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "FPO",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FPO",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FPR",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FPR",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FPY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FPY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FRA",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FRA",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FRB",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "FRB",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "FRC",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "FRC",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "FRD",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "FRD",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "FRE",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "FRE",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "FRF",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FRF",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FRG",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FRG",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FRH",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FRH",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FRI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FRI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FRJ",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FRK",
    "countryCode": "SC",
    "timezone": "Indian/Mahe",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "FRK",
    "countryCode": "SC",
    "timezone": "Indian/Mahe",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "FRL",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FRL",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FRM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FRM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FRN",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "FRN",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "FRO",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FRO",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FRP",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "FRQ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "FRQ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "FRR",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FRR",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FRS",
    "countryCode": "GT",
    "timezone": "America/Guatemala",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "FRS",
    "countryCode": "GT",
    "timezone": "America/Guatemala",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "FRS",
    "countryCode": "GT",
    "timezone": "America/Guatemala",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "FRT",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "FRT",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "FRU",
    "countryCode": "KG",
    "timezone": "Asia/Bishkek",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "FRU",
    "countryCode": "KG",
    "timezone": "Asia/Bishkek",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "FRW",
    "countryCode": "BW",
    "timezone": "Africa/Gaborone",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "FRW",
    "countryCode": "BW",
    "timezone": "Africa/Gaborone",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "FRY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FRY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FRZ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FRZ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FSA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "FSC",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FSC",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "FSD",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FSD",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FSI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FSI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FSK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FSK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FSL",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "FSM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FSM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FSN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FSN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FSP",
    "countryCode": "PM",
    "timezone": "America/Miquelon",
    "offset": {
      "gmt": -3,
      "dst": -2
    }
  },
  {
    "code": "FSP",
    "countryCode": "PM",
    "timezone": "America/Miquelon",
    "offset": {
      "gmt": -3,
      "dst": -2
    }
  },
  {
    "code": "FSS",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "FSS",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "FST",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FST",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FSU",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "FSU",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "FSZ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "FSZ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "FTA",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "FTA",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "FTE",
    "countryCode": "AR",
    "timezone": "America/Argentina/Rio_Gallegos",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "FTE",
    "countryCode": "AR",
    "timezone": "America/Argentina/Rio_Gallegos",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "FTI",
    "countryCode": "AS",
    "timezone": "Pacific/Pago_Pago",
    "offset": {
      "gmt": -11,
      "dst": -11
    }
  },
  {
    "code": "FTI",
    "countryCode": "AS",
    "timezone": "Pacific/Pago_Pago",
    "offset": {
      "gmt": -11,
      "dst": -11
    }
  },
  {
    "code": "FTK",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FTK",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FTL",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "FTL",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "FTU",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "FTU",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "FTW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FTW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FTX",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "FTX",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "FTY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FUA",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "FUB",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "FUD",
    "countryCode": "CN",
    "timezone": "Asia/Harbin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "FUE",
    "countryCode": "ES",
    "timezone": "Atlantic/Canary",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "FUE",
    "countryCode": "ES",
    "timezone": "Atlantic/Canary",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "FUG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "FUG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "FUJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "FUJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "FUK",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "FUK",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "FUL",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "FUL",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "FUM",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "FUN",
    "countryCode": "TV",
    "timezone": "Pacific/Funafuti",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "FUN",
    "countryCode": "TV",
    "timezone": "Pacific/Funafuti",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "FUN",
    "countryCode": "TV",
    "timezone": "Pacific/Funafuti",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "FUO",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "FUO",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "FUS",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "FUS",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "FUT",
    "countryCode": "WF",
    "timezone": "Pacific/Wallis",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "FUT",
    "countryCode": "WF",
    "timezone": "Pacific/Wallis",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "FVL",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "FVM",
    "countryCode": "MV",
    "timezone": "Indian/Maldives",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "FVM",
    "countryCode": "MV",
    "timezone": "Indian/Maldives",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "FVR",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "FVR",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "FVS",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "FWA",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FWA",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FWH",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FWL",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "FWL",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "FWM",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "FXE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "FXM",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "FXM",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "FXO",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "FXO",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "FXY",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FXY",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FYG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "FYJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "FYJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "FYM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FYM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FYN",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "FYN",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "FYN",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "FYT",
    "countryCode": "TD",
    "timezone": "Africa/Ndjamena",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "FYT",
    "countryCode": "TD",
    "timezone": "Africa/Ndjamena",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "FYU",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "FYU",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "FYV",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FYV",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "FZB",
    "countryCode": "UY",
    "timezone": "America/Montevideo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "FZB",
    "countryCode": "UY",
    "timezone": "America/Montevideo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "FZL",
    "countryCode": "AZ",
    "timezone": "Asia/Baku",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "FZL",
    "countryCode": "AZ",
    "timezone": "Asia/Baku",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "FZO",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "FZO",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "GAA",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "GAA",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "GAB",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "GAB",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "GAC",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "GAC",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "GAD",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GAD",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GAE",
    "countryCode": "TN",
    "timezone": "Africa/Tunis",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "GAE",
    "countryCode": "TN",
    "timezone": "Africa/Tunis",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "GAF",
    "countryCode": "TN",
    "timezone": "Africa/Tunis",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "GAF",
    "countryCode": "TN",
    "timezone": "Africa/Tunis",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "GAG",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GAG",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GAH",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GAH",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GAI",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GAI",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GAJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "GAJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "GAK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "GAK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "GAL",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "GAL",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "GAM",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "GAM",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "GAN",
    "countryCode": "MV",
    "timezone": "Indian/Maldives",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "GAN",
    "countryCode": "MV",
    "timezone": "Indian/Maldives",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "GAO",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GAO",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GAP",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GAP",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GAQ",
    "countryCode": "ML",
    "timezone": "Africa/Bamako",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "GAQ",
    "countryCode": "ML",
    "timezone": "Africa/Bamako",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "GAR",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GAR",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GAS",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GAS",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GAT",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GAT",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GAU",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "GAU",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "GAV",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "GAW",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "GAX",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "GAX",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "GAY",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "GAY",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "GAZ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GAZ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GBA",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "GBA",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "GBA",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "GBB",
    "countryCode": "AZ",
    "timezone": "Asia/Baku",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "GBB",
    "countryCode": "AZ",
    "timezone": "Asia/Baku",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "GBC",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GBD",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GBD",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GBE",
    "countryCode": "BW",
    "timezone": "Africa/Gaborone",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "GBE",
    "countryCode": "BW",
    "timezone": "Africa/Gaborone",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "GBF",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GBG",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GBG",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GBH",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "GBH",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "GBI",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GBI",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "GBI",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "GBI",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GBJ",
    "countryCode": "GP",
    "timezone": "America/Guadeloupe",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "GBJ",
    "countryCode": "GP",
    "timezone": "America/Guadeloupe",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "GBK",
    "countryCode": "SL",
    "timezone": "Africa/Freetown",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "GBK",
    "countryCode": "SL",
    "timezone": "Africa/Freetown",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "GBL",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "GBL",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "GBL",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "GBM",
    "countryCode": "SO",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GBM",
    "countryCode": "SO",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GBN",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GBO",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GBP",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GBP",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GBQ",
    "countryCode": "BH",
    "timezone": "Asia/Bahrain",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "GBR",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GBR",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GBS",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "GBT",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GBT",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GBU",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "GBU",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "GBV",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GBW",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GBW",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GBX",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GBZ",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "GBZ",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "GCA",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "GCC",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "GCC",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "GCD",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "GCH",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GCH",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GCI",
    "countryCode": "GG",
    "timezone": "Europe/Guernsey",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "GCI",
    "countryCode": "GG",
    "timezone": "Europe/Guernsey",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "GCJ",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "GCK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GCK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GCM",
    "countryCode": "KY",
    "timezone": "America/Cayman",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "GCM",
    "countryCode": "KY",
    "timezone": "America/Cayman",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "GCM",
    "countryCode": "KY",
    "timezone": "America/Cayman",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "GCN",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "GCN",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "GCQ",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GCT",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "GCV",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -2,
      "dst": -3
    }
  },
  {
    "code": "GCW",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "GCW",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "GCY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GCY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GDA",
    "countryCode": "CF",
    "timezone": "Africa/Bangui",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "GDB",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "GDB",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "GDC",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GDD",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GDE",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GDE",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GDG",
    "countryCode": "RU",
    "timezone": "Asia/Yakutsk",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "GDG",
    "countryCode": "RU",
    "timezone": "Asia/Yakutsk",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "GDH",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "GDI",
    "countryCode": "CF",
    "timezone": "Africa/Bangui",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "GDI",
    "countryCode": "CF",
    "timezone": "Africa/Bangui",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "GDJ",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "GDJ",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "GDL",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "GDL",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "GDM",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GDM",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GDN",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GDN",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GDO",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "GDO",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "GDP",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "GDP",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "GDQ",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GDQ",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GDR",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "GDR",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "GDT",
    "countryCode": "TC",
    "timezone": "America/Grand_Turk",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GDT",
    "countryCode": "TC",
    "timezone": "America/Grand_Turk",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GDU",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GDU",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GDV",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "GDV",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "GDW",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GDW",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GDX",
    "countryCode": "RU",
    "timezone": "Asia/Magadan",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "GDX",
    "countryCode": "RU",
    "timezone": "Asia/Magadan",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "GDZ",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GDZ",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GEA",
    "countryCode": "NC",
    "timezone": "Pacific/Noumea",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "GEB",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "GEC",
    "countryCode": "CY",
    "timezone": "Asia/Famagusta",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "GED",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GED",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GEE",
    "countryCode": "AU",
    "timezone": "Australia/Hobart",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "GEE",
    "countryCode": "AU",
    "timezone": "Australia/Hobart",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "GEF",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "GEG",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "GEG",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "GEI",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GEK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "GEL",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "GEL",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "GEM",
    "countryCode": "GQ",
    "timezone": "Africa/Malabo",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "GEM",
    "countryCode": "GQ",
    "timezone": "Africa/Malabo",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "GEM",
    "countryCode": "GQ",
    "timezone": "Africa/Malabo",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "GEN",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GEN",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GEO",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "GEO",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "GEP",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GER",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GER",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GES",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GES",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GET",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GET",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GEV",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GEV",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GEW",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GEX",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "GEX",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "GEY",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "GEY",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "GFA",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "GFB",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "GFD",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GFD",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GFE",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "GFF",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "GFF",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "GFK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GFK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GFL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GFL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GFN",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "GFN",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "GFO",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "GFO",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "GFR",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GFY",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "GFY",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "GGA",
    "countryCode": "ES",
    "timezone": "Atlantic/Canary",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "GGA",
    "countryCode": "ES",
    "timezone": "Atlantic/Canary",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "GGB",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "GGB",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "GGC",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "GGC",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "GGD",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GGE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GGE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GGF",
    "countryCode": "BR",
    "timezone": "America/Santarem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "GGF",
    "countryCode": "BR",
    "timezone": "America/Santarem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "GGG",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GGG",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GGH",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "GGH",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "GGJ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "GGJ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "GGL",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "GGM",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GGM",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GGN",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "GGN",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "GGO",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "GGO",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "GGR",
    "countryCode": "SO",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GGR",
    "countryCode": "SO",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GGS",
    "countryCode": "AR",
    "timezone": "America/Argentina/Rio_Gallegos",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "GGS",
    "countryCode": "AR",
    "timezone": "America/Argentina/Rio_Gallegos",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "GGT",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GGT",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GGW",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "GGW",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "GGX",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GGZ",
    "countryCode": "AT",
    "timezone": "Europe/Vienna",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GHA",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "GHA",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "GHB",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GHB",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GHC",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GHC",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GHD",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GHE",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "GHE",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "GHF",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GHF",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GHK",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "GHK",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "GHM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GHM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GHN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GHN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GHS",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GHS",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GHT",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "GHT",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "GHU",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "GHU",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "GHV",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "GIA",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GIB",
    "countryCode": "GI",
    "timezone": "Europe/Gibraltar",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GIB",
    "countryCode": "GI",
    "timezone": "Europe/Gibraltar",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GIC",
    "countryCode": "AU",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GID",
    "countryCode": "BI",
    "timezone": "Africa/Bujumbura",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "GID",
    "countryCode": "BI",
    "timezone": "Africa/Bujumbura",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "GIF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GIF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GIG",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "GII",
    "countryCode": "GN",
    "timezone": "Africa/Conakry",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "GII",
    "countryCode": "GN",
    "timezone": "Africa/Conakry",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "GIJ",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GIL",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "GIL",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "GIM",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "GIR",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "GIR",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "GIS",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "GIS",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "GIT",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GIT",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GIU",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "GIU",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "GIY",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "GIY",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "GIZ",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GIZ",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GJA",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "GJA",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "GJB",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GJL",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "GJL",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "GJM",
    "countryCode": "BR",
    "timezone": "America/Porto_Velho",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "GJM",
    "countryCode": "BR",
    "timezone": "America/Porto_Velho",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "GJN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GJN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GJR",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "GJR",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "GJT",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "GJT",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "GKA",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GKA",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GKD",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GKD",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GKE",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GKE",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GKH",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "GKH",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "GKK",
    "countryCode": "MV",
    "timezone": "Indian/Maldives",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "GKK",
    "countryCode": "MV",
    "timezone": "Indian/Maldives",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "GKL",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GKN",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "GKN",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "GKO",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "GKT",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GKT",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GKW",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GLA",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "GLA",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "GLB",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "GLB",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "GLC",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GLD",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "GLD",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "GLE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GLE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GLF",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "GLF",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "GLG",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GLH",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GLH",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GLI",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "GLI",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "GLJ",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "GLJ",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "GLJ",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "GLK",
    "countryCode": "SO",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GLK",
    "countryCode": "SO",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GLL",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GLL",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GLM",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GLM",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GLN",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "GLN",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "GLO",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "GLO",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "GLP",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GLQ",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "GLR",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GLR",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GLS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GLS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GLT",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GLT",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GLU",
    "countryCode": "BT",
    "timezone": "Asia/Thimphu",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "GLU",
    "countryCode": "BT",
    "timezone": "Asia/Thimphu",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "GLV",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "GLV",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "GLW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GLW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GLX",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "GLX",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "GLY",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GLZ",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GLZ",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GMA",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "GMA",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "GMB",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GMB",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GMC",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "GMD",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "GMD",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "GME",
    "countryCode": "BY",
    "timezone": "Europe/Minsk",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GME",
    "countryCode": "BY",
    "timezone": "Europe/Minsk",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GMI",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GML",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "GMM",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "GMM",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "GMN",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "GMN",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "GMO",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "GMO",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "GMP",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "GMQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GMQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GMR",
    "countryCode": "PF",
    "timezone": "Pacific/Gambier",
    "offset": {
      "gmt": -9,
      "dst": -9
    }
  },
  {
    "code": "GMR",
    "countryCode": "PF",
    "timezone": "Pacific/Gambier",
    "offset": {
      "gmt": -9,
      "dst": -9
    }
  },
  {
    "code": "GMS",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "GMS",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "GMT",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "GMU",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GMV",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "GMV",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "GMZ",
    "countryCode": "ES",
    "timezone": "Atlantic/Canary",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "GNA",
    "countryCode": "BY",
    "timezone": "Europe/Minsk",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GNA",
    "countryCode": "BY",
    "timezone": "Europe/Minsk",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GNB",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GND",
    "countryCode": "GD",
    "timezone": "America/Grenada",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "GND",
    "countryCode": "GD",
    "timezone": "America/Grenada",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "GNE",
    "countryCode": "BE",
    "timezone": "Europe/Brussels",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GNF",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "GNF",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "GNG",
    "countryCode": "US",
    "timezone": "America/Boise",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "GNG",
    "countryCode": "US",
    "timezone": "America/Boise",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "GNH",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GNI",
    "countryCode": "TW",
    "timezone": "Asia/Taipei",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GNI",
    "countryCode": "TW",
    "timezone": "Asia/Taipei",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GNJ",
    "countryCode": "AZ",
    "timezone": "Asia/Baku",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "GNM",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "GNM",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "GNN",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GNN",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GNO",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GNO",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GNR",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "GNR",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "GNS",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "GNS",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "GNT",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "GNT",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "GNU",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "GNU",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "GNV",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GNV",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GNY",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GNZ",
    "countryCode": "BW",
    "timezone": "Africa/Gaborone",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "GNZ",
    "countryCode": "BW",
    "timezone": "Africa/Gaborone",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "GOA",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GOA",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GOB",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GOB",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GOB",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GOC",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GOE",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GOF",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GOG",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "GOG",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "GOH",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "GOH",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "GOI",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "GOI",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "GOI",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "GOI",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "GOJ",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GOJ",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GOK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GOK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GOL",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "GOL",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "GOM",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "GOM",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "GON",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GON",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GOO",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GOO",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GOP",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "GOP",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "GOQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GOQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GOR",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GOR",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GOS",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "GOT",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GOT",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GOU",
    "countryCode": "CM",
    "timezone": "Africa/Douala",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "GOU",
    "countryCode": "CM",
    "timezone": "Africa/Douala",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "GOV",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "GOV",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "GOX",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "GOX",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "GOY",
    "countryCode": "RU",
    "timezone": "Asia/Krasnoyarsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "GOY",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "GOY",
    "countryCode": "RU",
    "timezone": "Asia/Krasnoyarsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "GOZ",
    "countryCode": "BG",
    "timezone": "Europe/Sofia",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "GOZ",
    "countryCode": "BG",
    "timezone": "Europe/Sofia",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "GPA",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "GPA",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "GPB",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "GPB",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "GPD",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GPD",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GPI",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "GPI",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "GPL",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "GPL",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "GPN",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "GPN",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "GPO",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "GPO",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "GPP",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GPS",
    "countryCode": "EC",
    "timezone": "Pacific/Galapagos",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "GPS",
    "countryCode": "EC",
    "timezone": "Pacific/Galapagos",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "GPT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GPT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GPZ",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GPZ",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GQQ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GQQ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GRA",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "GRA",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "GRB",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GRB",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GRC",
    "countryCode": "LR",
    "timezone": "Africa/Monrovia",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "GRD",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GRD",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GRE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GRE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GRF",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "GRG",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "GRG",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "GRH",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GRI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GRI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GRJ",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "GRJ",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "GRK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GRL",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GRM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GRM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GRN",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "GRN",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "GRO",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GRO",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GRP",
    "countryCode": "BR",
    "timezone": "America/Araguaina",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "GRP",
    "countryCode": "BR",
    "timezone": "America/Araguaina",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "GRQ",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GRQ",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GRR",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GRR",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GRS",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GRS",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GRT",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "GRU",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "GRV",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GRV",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GRW",
    "countryCode": "PT",
    "timezone": "Atlantic/Azores",
    "offset": {
      "gmt": -1,
      "dst": 0
    }
  },
  {
    "code": "GRW",
    "countryCode": "PT",
    "timezone": "Atlantic/Azores",
    "offset": {
      "gmt": -1,
      "dst": 0
    }
  },
  {
    "code": "GRX",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GRX",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GRY",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "GRY",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "GRZ",
    "countryCode": "AT",
    "timezone": "Europe/Vienna",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GRZ",
    "countryCode": "AT",
    "timezone": "Europe/Vienna",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GSA",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GSA",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GSB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GSB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GSC",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GSC",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GSE",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GSH",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GSH",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GSI",
    "countryCode": "GF",
    "timezone": "America/Cayenne",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "GSI",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "GSI",
    "countryCode": "GF",
    "timezone": "America/Cayenne",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "GSJ",
    "countryCode": "GT",
    "timezone": "America/Guatemala",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "GSJ",
    "countryCode": "GT",
    "timezone": "America/Guatemala",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "GSL",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "GSM",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GSM",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GSN",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "GSN",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "GSO",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GSO",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GSP",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GSP",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GSQ",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "GSR",
    "countryCode": "SO",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GSS",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "GSS",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "GST",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "GST",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "GSU",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "GSU",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "GSV",
    "countryCode": "RU",
    "timezone": "Europe/Saratov",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "GSY",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "GTA",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "GTA",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "GTB",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GTB",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GTC",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GTC",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GTC",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GTE",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "GTE",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "GTF",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "GTF",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "GTG",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GTG",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GTI",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GTI",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GTK",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GTN",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "GTN",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "GTO",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GTO",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GTP",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "GTP",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "GTR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GTS",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "GTS",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "GTT",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GTT",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GTW",
    "countryCode": "CZ",
    "timezone": "Europe/Prague",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GTW",
    "countryCode": "CZ",
    "timezone": "Europe/Prague",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GTW",
    "countryCode": "CZ",
    "timezone": "Europe/Prague",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GTY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GTY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GTZ",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GUA",
    "countryCode": "GT",
    "timezone": "America/Guatemala",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "GUA",
    "countryCode": "GT",
    "timezone": "America/Guatemala",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "GUA",
    "countryCode": "GT",
    "timezone": "America/Guatemala",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "GUB",
    "countryCode": "MX",
    "timezone": "America/Mazatlan",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "GUB",
    "countryCode": "MX",
    "timezone": "America/Mazatlan",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "GUC",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "GUC",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "GUD",
    "countryCode": "ML",
    "timezone": "Africa/Bamako",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "GUD",
    "countryCode": "ML",
    "timezone": "Africa/Bamako",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "GUE",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GUF",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GUF",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GUG",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GUG",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GUH",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "GUH",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "GUI",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "GUI",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "GUJ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "GUL",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "GUL",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "GUM",
    "countryCode": "GU",
    "timezone": "Pacific/Guam",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GUM",
    "countryCode": "GU",
    "timezone": "Pacific/Guam",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GUO",
    "countryCode": "SO",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GUO",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "GUO",
    "countryCode": "SO",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GUP",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "GUP",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "GUQ",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "GUQ",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "GUR",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GUR",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GUR",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GUS",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GUT",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GUT",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GUU",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "GUU",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "GUV",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GUV",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GUW",
    "countryCode": "KZ",
    "timezone": "Asia/Atyrau",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "GUW",
    "countryCode": "KZ",
    "timezone": "Asia/Atyrau",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "GUX",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "GUX",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "GUY",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GUY",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GUZ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "GUZ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "GVA",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GVA",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GVE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GVE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GVI",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GVI",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GVL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GVL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GVN",
    "countryCode": "RU",
    "timezone": "Asia/Vladivostok",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GVP",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GVP",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GVR",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "GVR",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "GVT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GVT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GVW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GVW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GVX",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GVX",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GWA",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "GWA",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "GWD",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "GWD",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "GWE",
    "countryCode": "ZW",
    "timezone": "Africa/Harare",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "GWE",
    "countryCode": "ZW",
    "timezone": "Africa/Harare",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "GWL",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "GWL",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "GWN",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GWO",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GWO",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GWS",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "GWT",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GWT",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GWV",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GWV",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "GWY",
    "countryCode": "IE",
    "timezone": "Europe/Dublin",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "GWY",
    "countryCode": "IE",
    "timezone": "Europe/Dublin",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "GXA",
    "countryCode": "ID",
    "timezone": "Asia/Pontianak",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "GXA",
    "countryCode": "ID",
    "timezone": "Asia/Pontianak",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "GXF",
    "countryCode": "YE",
    "timezone": "Asia/Aden",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GXF",
    "countryCode": "YE",
    "timezone": "Asia/Aden",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GXG",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "GXG",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "GXH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GXH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GXQ",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "GXQ",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "GXX",
    "countryCode": "CM",
    "timezone": "Africa/Douala",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "GXX",
    "countryCode": "CM",
    "timezone": "Africa/Douala",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "GXY",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "GXY",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "GYA",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "GYA",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "GYA",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "GYB",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GYB",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GYD",
    "countryCode": "AZ",
    "timezone": "Asia/Baku",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "GYE",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "GYE",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "GYG",
    "countryCode": "RU",
    "timezone": "Asia/Yakutsk",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "GYG",
    "countryCode": "RU",
    "timezone": "Asia/Yakutsk",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "GYI",
    "countryCode": "RW",
    "timezone": "Africa/Kigali",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "GYL",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GYL",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GYL",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GYM",
    "countryCode": "MX",
    "timezone": "America/Hermosillo",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "GYM",
    "countryCode": "MX",
    "timezone": "America/Hermosillo",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "GYN",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "GYN",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "GYO",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "GYO",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "GYP",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GYP",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "GYR",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "GYR",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "GYS",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GYS",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GYU",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GYU",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GYY",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GYY",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "GYZ",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GYZ",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GZA",
    "countryCode": "PS",
    "timezone": "Asia/Gaza",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "GZG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GZG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "GZI",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "GZI",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "GZM",
    "countryCode": "MT",
    "timezone": "Europe/Malta",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "GZO",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "GZO",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "GZP",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GZP",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GZT",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GZT",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GZW",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "GZW",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "HAA",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HAA",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HAB",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HAB",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HAC",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "HAC",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "HAD",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HAD",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HAE",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "HAE",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "HAE",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "HAF",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "HAF",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "HAG",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HAH",
    "countryCode": "KM",
    "timezone": "Indian/Comoro",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "HAH",
    "countryCode": "KM",
    "timezone": "Indian/Comoro",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "HAI",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HAI",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HAJ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HAJ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HAK",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HAK",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HAL",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "HAL",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "HAM",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HAM",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HAN",
    "countryCode": "VN",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "HAN",
    "countryCode": "VN",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "HAO",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HAO",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HAP",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "HAP",
    "countryCode": "AU",
    "timezone": "Australia/Lindeman",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "HAQ",
    "countryCode": "MV",
    "timezone": "Indian/Maldives",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "HAQ",
    "countryCode": "MV",
    "timezone": "Indian/Maldives",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "HAR",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HAR",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HAS",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "HAS",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "HAT",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "HAU",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HAU",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HAV",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HAV",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HAW",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "HAW",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "HAX",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HAY",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "HAY",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "HAY",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "HAY",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "HAZ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "HBA",
    "countryCode": "AU",
    "timezone": "Australia/Hobart",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "HBA",
    "countryCode": "AU",
    "timezone": "Australia/Hobart",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "HBB",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "HBC",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "HBD",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "HBE",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "HBG",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HBG",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HBH",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "HBH",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "HBI",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HBI",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HBK",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "HBK",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "HBL",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "HBM",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HBM",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HBN",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "HBO",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HBO",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HBP",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HBQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HBQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HBQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HBR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HBR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HBT",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "HBU",
    "countryCode": "MN",
    "timezone": "Asia/Hovd",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "HBU",
    "countryCode": "MN",
    "timezone": "Asia/Hovd",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "HBV",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HBX",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "HBX",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "HBZ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "HCA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HCA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HCB",
    "countryCode": "US",
    "timezone": "America/Sitka",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "HCC",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HCC",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HCJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HCJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HCM",
    "countryCode": "SO",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "HCM",
    "countryCode": "SO",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "HCN",
    "countryCode": "TW",
    "timezone": "Asia/Taipei",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HCN",
    "countryCode": "TW",
    "timezone": "Asia/Taipei",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HCQ",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HCQ",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HCR",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "HCR",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "HCW",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HCZ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HDA",
    "countryCode": "US",
    "timezone": "America/Sitka",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "HDA",
    "countryCode": "US",
    "timezone": "America/Sitka",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "HDB",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HDB",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HDD",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "HDD",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "HDE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HDE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HDF",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HDF",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HDF",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HDG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HDG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HDH",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "HDH",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "HDK",
    "countryCode": "MV",
    "timezone": "Indian/Maldives",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "HDK",
    "countryCode": "MV",
    "timezone": "Indian/Maldives",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "HDM",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "HDM",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "HDN",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "HDN",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "HDO",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "HDO",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "HDQ",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "HDR",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "HDS",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "HDS",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "HDY",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "HDY",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "HEA",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "HEA",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "HEB",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "HEB",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "HEC",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "HED",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "HEE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HEE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HEH",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "HEH",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "HEI",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HEI",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HEI",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HEK",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HEK",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HEL",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "HEL",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "HEM",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "HEN",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "HEO",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "HEO",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "HER",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "HER",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "HES",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "HES",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "HET",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HET",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HEV",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HEV",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HEW",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HEW",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HEW",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HEX",
    "countryCode": "DO",
    "timezone": "America/Santo_Domingo",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "HEY",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HEZ",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HEZ",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HFA",
    "countryCode": "IL",
    "timezone": "Asia/Jerusalem",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "HFA",
    "countryCode": "IL",
    "timezone": "Asia/Jerusalem",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "HFD",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HFD",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HFE",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HFE",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HFE",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HFF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HFH",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "HFH",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "HFN",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "HFS",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HFS",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HFT",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HFT",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HGA",
    "countryCode": "SO",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "HGA",
    "countryCode": "SO",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "HGD",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "HGD",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "HGE",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "HGE",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "HGH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HGH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HGI",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "HGI",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "HGI",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "HGI",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "HGL",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HGL",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HGN",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "HGN",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "HGO",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "HGO",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "HGR",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HGR",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HGS",
    "countryCode": "SL",
    "timezone": "Africa/Freetown",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "HGT",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "HGT",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "HGT",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "HGT",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "HGU",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "HGU",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "HGZ",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "HHA",
    "countryCode": "CN",
    "timezone": "Asia/Chongqing",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HHE",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "HHE",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "HHH",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HHH",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HHI",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "HHK",
    "countryCode": "HK",
    "timezone": "Asia/Hong_Kong",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HHL",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HHN",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HHP",
    "countryCode": "HK",
    "timezone": "Asia/Hong_Kong",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HHQ",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "HHQ",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "HHR",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "HHR",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "HHS",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HHZ",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "HHZ",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "HIA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HIA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HIA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HIB",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HIB",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HID",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "HID",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "HIE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HIE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HIF",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "HIG",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "HIG",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "HIH",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "HII",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "HII",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "HIJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "HIJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "HIK",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "HIL",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "HIL",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "HIM",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "HIM",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "HIM",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "HIN",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "HIN",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "HIO",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "HIO",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "HIP",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "HIP",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "HIR",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "HIR",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "HIS",
    "countryCode": "AU",
    "timezone": "Australia/Lindeman",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "HIS",
    "countryCode": "AU",
    "timezone": "Australia/Lindeman",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "HIT",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "HIW",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "HIX",
    "countryCode": "PF",
    "timezone": "Pacific/Marquesas",
    "offset": {
      "gmt": -9,
      "dst": -9
    }
  },
  {
    "code": "HJB",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "HJB",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "HJJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HJJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HJR",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "HJR",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "HJT",
    "countryCode": "MN",
    "timezone": "Asia/Ulaanbaatar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HJT",
    "countryCode": "MN",
    "timezone": "Asia/Ulaanbaatar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HKA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HKB",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "HKB",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "HKC",
    "countryCode": "HK",
    "timezone": "Asia/Hong_Kong",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HKD",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "HKD",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "HKG",
    "countryCode": "HK",
    "timezone": "Asia/Hong_Kong",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HKG",
    "countryCode": "HK",
    "timezone": "Asia/Hong_Kong",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HKK",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "HKK",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "HKM",
    "countryCode": "HK",
    "timezone": "Asia/Hong_Kong",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HKN",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "HKN",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "HKP",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "HKR",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "HKS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HKT",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "HKT",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "HKV",
    "countryCode": "BG",
    "timezone": "Europe/Sofia",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "HKV",
    "countryCode": "BG",
    "timezone": "Europe/Sofia",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "HKV",
    "countryCode": "BG",
    "timezone": "Europe/Sofia",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "HKY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HKY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HLA",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "HLB",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HLB",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HLC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HLC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HLD",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HLD",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HLE",
    "countryCode": "SH",
    "timezone": "Atlantic/St_Helena",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "HLE",
    "countryCode": "SH",
    "timezone": "Atlantic/St_Helena",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "HLE",
    "countryCode": "SH",
    "timezone": "Atlantic/St_Helena",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "HLE",
    "countryCode": "SH",
    "timezone": "Atlantic/St_Helena",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "HLF",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HLF",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HLG",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HLG",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HLH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HLH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HLI",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "HLI",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "HLJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HLJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HLL",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HLM",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HLM",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HLN",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "HLN",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "HLO",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "HLO",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "HLP",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "HLR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "HLS",
    "countryCode": "AU",
    "timezone": "Australia/Hobart",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "HLS",
    "countryCode": "AU",
    "timezone": "Australia/Hobart",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "HLT",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "HLT",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "HLU",
    "countryCode": "NC",
    "timezone": "Pacific/Noumea",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "HLV",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "HLV",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "HLW",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "HLW",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "HLY",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "HLY",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "HLZ",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "HLZ",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "HMA",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "HMA",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "HMB",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "HMB",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "HME",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "HME",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "HMG",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "HMG",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "HMI",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "HMI",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "HMJ",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "HMJ",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "HMN",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "HMO",
    "countryCode": "MX",
    "timezone": "America/Hermosillo",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "HMO",
    "countryCode": "MX",
    "timezone": "America/Hermosillo",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "HMR",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HMR",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HMS",
    "countryCode": "ID",
    "timezone": "Asia/Pontianak",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "HMS",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "HMT",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "HMT",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "HMV",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HMV",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HMY",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "HMY",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "HNA",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "HNA",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "HNB",
    "countryCode": "US",
    "timezone": "America/Indiana/Vincennes",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HNB",
    "countryCode": "US",
    "timezone": "America/Indiana/Vincennes",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HNC",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HNC",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HND",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "HNE",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "HNE",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "HNG",
    "countryCode": "NC",
    "timezone": "Pacific/Noumea",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "HNH",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "HNH",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "HNI",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "HNK",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "HNL",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "HNL",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "HNM",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "HNM",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "HNN",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "HNN",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "HNO",
    "countryCode": "ME",
    "timezone": "Europe/Podgorica",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HNS",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "HNS",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "HNX",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "HNY",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HNY",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HNY",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HNZ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HOA",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "HOA",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "HOB",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "HOB",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "HOC",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "HOD",
    "countryCode": "YE",
    "timezone": "Asia/Aden",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "HOD",
    "countryCode": "YE",
    "timezone": "Asia/Aden",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "HOE",
    "countryCode": "LA",
    "timezone": "Asia/Vientiane",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "HOE",
    "countryCode": "LA",
    "timezone": "Asia/Vientiane",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "HOF",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "HOF",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "HOG",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HOG",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HOH",
    "countryCode": "AT",
    "timezone": "Europe/Vienna",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HOH",
    "countryCode": "AT",
    "timezone": "Europe/Vienna",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HOH",
    "countryCode": "AT",
    "timezone": "Europe/Vienna",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HOI",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "HOI",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "HOK",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "HOL",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "HOM",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "HOM",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "HON",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HON",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HOO",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "HOO",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "HOO",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "HOP",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HOP",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HOQ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HOQ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HOR",
    "countryCode": "PT",
    "timezone": "Atlantic/Azores",
    "offset": {
      "gmt": -1,
      "dst": 0
    }
  },
  {
    "code": "HOR",
    "countryCode": "PT",
    "timezone": "Atlantic/Azores",
    "offset": {
      "gmt": -1,
      "dst": 0
    }
  },
  {
    "code": "HOS",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "HOS",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "HOT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HOT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HOU",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HOU",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HOV",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HOV",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HOW",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "HOX",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "HOX",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "HOY",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "HPA",
    "countryCode": "TO",
    "timezone": "Pacific/Tongatapu",
    "offset": {
      "gmt": 13,
      "dst": 13
    }
  },
  {
    "code": "HPA",
    "countryCode": "TO",
    "timezone": "Pacific/Tongatapu",
    "offset": {
      "gmt": 13,
      "dst": 13
    }
  },
  {
    "code": "HPB",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "HPB",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "HPE",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "HPG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HPG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HPG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HPH",
    "countryCode": "VN",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "HPH",
    "countryCode": "VN",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "HPN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HPN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HPP",
    "countryCode": "KH",
    "timezone": "Asia/Phnom_Penh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "HPT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HPT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HPV",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "HPV",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "HPY",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HPY",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HQL",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "HQL",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "HQM",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "HQM",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "HQQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HRA",
    "countryCode": "CZ",
    "timezone": "Europe/Prague",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HRA",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "HRA",
    "countryCode": "CZ",
    "timezone": "Europe/Prague",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HRB",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HRB",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HRC",
    "countryCode": "KZ",
    "timezone": "Asia/Almaty",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "HRD",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HRE",
    "countryCode": "ZW",
    "timezone": "Africa/Harare",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "HRE",
    "countryCode": "ZW",
    "timezone": "Africa/Harare",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "HRF",
    "countryCode": "MV",
    "timezone": "Indian/Maldives",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "HRF",
    "countryCode": "MV",
    "timezone": "Indian/Maldives",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "HRF",
    "countryCode": "MV",
    "timezone": "Indian/Maldives",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "HRG",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "HRG",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "HRH",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "HRH",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "HRI",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "HRI",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "HRJ",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "HRK",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "HRK",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "HRL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HRL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HRM",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "HRM",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "HRN",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "HRN",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "HRO",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HRO",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HRR",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "HRS",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "HRS",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "HRT",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "HRT",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "HRY",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "HRY",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "HRZ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "HRZ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "HSA",
    "countryCode": "KZ",
    "timezone": "Asia/Almaty",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "HSA",
    "countryCode": "KZ",
    "timezone": "Asia/Almaty",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "HSB",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "HSC",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HSC",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HSD",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HSD",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HSF",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HSF",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HSG",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "HSG",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "HSH",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "HSI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HSI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HSJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HSK",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HSK",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HSL",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "HSL",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "HSM",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "HSM",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "HSN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HSN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HSP",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HSP",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HSQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HSR",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "HSS",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "HSS",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "HST",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HST",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HSV",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HSV",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HSZ",
    "countryCode": "TW",
    "timezone": "Asia/Taipei",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HSZ",
    "countryCode": "TW",
    "timezone": "Asia/Taipei",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HTA",
    "countryCode": "RU",
    "timezone": "Asia/Yakutsk",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "HTA",
    "countryCode": "RU",
    "timezone": "Asia/Chita",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "HTB",
    "countryCode": "GP",
    "timezone": "America/Guadeloupe",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "HTB",
    "countryCode": "GP",
    "timezone": "America/Guadeloupe",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "HTG",
    "countryCode": "RU",
    "timezone": "Asia/Krasnoyarsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "HTG",
    "countryCode": "RU",
    "timezone": "Asia/Krasnoyarsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "HTH",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "HTH",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "HTI",
    "countryCode": "AU",
    "timezone": "Australia/Lindeman",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "HTI",
    "countryCode": "AU",
    "timezone": "Australia/Lindeman",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "HTL",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HTL",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HTM",
    "countryCode": "MN",
    "timezone": "Asia/Ulaanbaatar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HTM",
    "countryCode": "MN",
    "timezone": "Asia/Ulaanbaatar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HTN",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "HTN",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "HTO",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HTO",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HTR",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "HTR",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "HTS",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HTS",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HTT",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HTT",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "HTT",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HTU",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "HTU",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "HTV",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HTV",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HTW",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HTW",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HTY",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "HTY",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "HTZ",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "HTZ",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "HUA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HUB",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "HUC",
    "countryCode": "PR",
    "timezone": "America/Puerto_Rico",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "HUC",
    "countryCode": "PR",
    "timezone": "America/Puerto_Rico",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "HUD",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HUD",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HUE",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "HUE",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "HUF",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HUF",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HUG",
    "countryCode": "GT",
    "timezone": "America/Guatemala",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "HUG",
    "countryCode": "GT",
    "timezone": "America/Guatemala",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "HUH",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "HUH",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "HUH",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "HUI",
    "countryCode": "VN",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "HUI",
    "countryCode": "VN",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "HUJ",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HUJ",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HUK",
    "countryCode": "BW",
    "timezone": "Africa/Gaborone",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "HUK",
    "countryCode": "BW",
    "timezone": "Africa/Gaborone",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "HUL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HUL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HUM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HUM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HUN",
    "countryCode": "TW",
    "timezone": "Asia/Taipei",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HUN",
    "countryCode": "TW",
    "timezone": "Asia/Taipei",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HUO",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HUO",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HUQ",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "HUS",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "HUS",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "HUT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HUT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HUU",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "HUU",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "HUV",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HUV",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HUW",
    "countryCode": "BR",
    "timezone": "America/Manaus",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "HUW",
    "countryCode": "BR",
    "timezone": "America/Manaus",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "HUX",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "HUX",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "HUY",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "HUY",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "HUZ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HUZ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HVA",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "HVA",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "HVB",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "HVB",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "HVD",
    "countryCode": "MN",
    "timezone": "Asia/Hovd",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "HVD",
    "countryCode": "MN",
    "timezone": "Asia/Hovd",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "HVE",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "HVG",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HVG",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HVK",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "HVK",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "HVN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HVN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HVR",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "HVR",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "HVS",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HVS",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HVT",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "HVZ",
    "countryCode": "HU",
    "timezone": "Europe/Budapest",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HWA",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "HWD",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "HWD",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "HWI",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "HWK",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "HWK",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "HWN",
    "countryCode": "ZW",
    "timezone": "Africa/Harare",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "HWO",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HWO",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HXD",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HXD",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HXN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HXX",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "HXX",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "HYA",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HYA",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HYC",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "HYC",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "HYD",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "HYD",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "HYF",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "HYG",
    "countryCode": "US",
    "timezone": "America/Metlakatla",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "HYG",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "HYL",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "HYL",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "HYN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HYN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HYR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HYR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HYS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HYS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HYV",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "HZA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HZA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HZB",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HZB",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "HZC",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HZC",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HZD",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HZG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HZG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HZH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HZH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HZI",
    "countryCode": "MO",
    "timezone": "Asia/Macau",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HZK",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "HZK",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "HZL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HZL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "HZP",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "HZP",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "HZR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "HZU",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "HZV",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "IAA",
    "countryCode": "RU",
    "timezone": "Asia/Krasnoyarsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "IAA",
    "countryCode": "RU",
    "timezone": "Asia/Krasnoyarsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "IAB",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "IAD",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "IAG",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "IAG",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "IAH",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "IAL",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "IAL",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "IAM",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "IAM",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "IAN",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "IAN",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "IAO",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "IAO",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "IAQ",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "IAQ",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "IAR",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "IAR",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "IAR",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "IAS",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "IAS",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "IAU",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "IAU",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "IBA",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "IBA",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "IBB",
    "countryCode": "EC",
    "timezone": "Pacific/Galapagos",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "IBD",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "IBE",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "IBE",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "IBI",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "IBL",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "IBL",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "IBM",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "IBM",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "IBO",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "IBP",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "IBP",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "IBR",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "IBR",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "IBT",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "IBZ",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "IBZ",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ICA",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "ICA",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "ICC",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "ICC",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "ICI",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "ICI",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "ICK",
    "countryCode": "SR",
    "timezone": "America/Paramaribo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ICK",
    "countryCode": "SR",
    "timezone": "America/Paramaribo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ICL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ICL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ICN",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "ICO",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ICO",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ICR",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ICR",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ICS",
    "countryCode": "US",
    "timezone": "America/Boise",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "ICT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ICT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ICY",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "ICY",
    "countryCode": "US",
    "timezone": "America/Yakutat",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "IDA",
    "countryCode": "US",
    "timezone": "America/Boise",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "IDA",
    "countryCode": "US",
    "timezone": "America/Boise",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "IDB",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "IDB",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "IDC",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "IDF",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "IDF",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "IDG",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "IDG",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "IDH",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "IDH",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "IDI",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "IDI",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "IDK",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "IDN",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "IDO",
    "countryCode": "BR",
    "timezone": "America/Araguaina",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "IDO",
    "countryCode": "BR",
    "timezone": "America/Araguaina",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "IDP",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "IDP",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "IDR",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IDR",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IDY",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "IDY",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "IEG",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "IEG",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "IEG",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "IEJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "IEJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "IES",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "IES",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "IEV",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "IEV",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "IFA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "IFA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "IFF",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "IFF",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "IFH",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "IFJ",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "IFJ",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "IFL",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "IFL",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "IFN",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "IFN",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "IFO",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "IFO",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "IFP",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "IFP",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "IFU",
    "countryCode": "MV",
    "timezone": "Indian/Maldives",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IFU",
    "countryCode": "MV",
    "timezone": "Indian/Maldives",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IGA",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "IGA",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "IGA",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "IGB",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "IGB",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "IGD",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "IGD",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "IGE",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "IGG",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "IGG",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "IGH",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "IGH",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "IGL",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "IGM",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "IGM",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "IGN",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "IGN",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "IGO",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "IGR",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "IGR",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "IGS",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "IGS",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "IGS",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "IGT",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "IGT",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "IGU",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "IGU",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "IHA",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "IHC",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "IHC",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "IHN",
    "countryCode": "YE",
    "timezone": "Asia/Aden",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "IHN",
    "countryCode": "YE",
    "timezone": "Asia/Aden",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "IHO",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "IHO",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "IHR",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "IHR",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "IHU",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "IHU",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "IIA",
    "countryCode": "IE",
    "timezone": "Europe/Dublin",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "IIA",
    "countryCode": "IE",
    "timezone": "Europe/Dublin",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "IIL",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "IIL",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "IIN",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "IIS",
    "countryCode": "PG",
    "timezone": "Pacific/Bougainville",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "IIS",
    "countryCode": "PG",
    "timezone": "Pacific/Bougainville",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "IJK",
    "countryCode": "RU",
    "timezone": "Europe/Samara",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "IJK",
    "countryCode": "RU",
    "timezone": "Europe/Samara",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "IJU",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "IJX",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "IJX",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "IKA",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "IKB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "IKE",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "IKE",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "IKI",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "IKI",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "IKK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "IKK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "IKL",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "IKL",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "IKO",
    "countryCode": "US",
    "timezone": "America/Adak",
    "offset": {
      "gmt": -10,
      "dst": -9
    }
  },
  {
    "code": "IKO",
    "countryCode": "US",
    "timezone": "America/Adak",
    "offset": {
      "gmt": -10,
      "dst": -9
    }
  },
  {
    "code": "IKP",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "IKS",
    "countryCode": "RU",
    "timezone": "Asia/Yakutsk",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "IKS",
    "countryCode": "RU",
    "timezone": "Asia/Yakutsk",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "IKT",
    "countryCode": "RU",
    "timezone": "Asia/Irkutsk",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "IKT",
    "countryCode": "RU",
    "timezone": "Asia/Irkutsk",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "IKU",
    "countryCode": "KG",
    "timezone": "Asia/Bishkek",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "IKU",
    "countryCode": "KG",
    "timezone": "Asia/Bishkek",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "ILA",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "ILB",
    "countryCode": "BR",
    "timezone": "America/Campo_Grande",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "ILD",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ILD",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ILE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ILE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ILF",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ILF",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ILG",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ILG",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ILH",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ILH",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ILI",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "ILI",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "ILK",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ILK",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ILL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ILL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ILM",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ILM",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ILN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ILN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ILO",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ILO",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ILP",
    "countryCode": "NC",
    "timezone": "Pacific/Noumea",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "ILP",
    "countryCode": "NC",
    "timezone": "Pacific/Noumea",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "ILQ",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ILQ",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ILR",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ILR",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ILS",
    "countryCode": "SV",
    "timezone": "America/El_Salvador",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "ILU",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ILU",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ILX",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ILY",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "ILY",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "ILZ",
    "countryCode": "SK",
    "timezone": "Europe/Bratislava",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ILZ",
    "countryCode": "SK",
    "timezone": "Europe/Bratislava",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "IMA",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "IMB",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "IMB",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "IMD",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "IMF",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IMF",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IMG",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "IMI",
    "countryCode": "MH",
    "timezone": "Pacific/Majuro",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "IMK",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IMK",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IML",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "IML",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "IMM",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "IMM",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "IMN",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "IMO",
    "countryCode": "CF",
    "timezone": "Africa/Bangui",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "IMO",
    "countryCode": "CF",
    "timezone": "Africa/Bangui",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "IMP",
    "countryCode": "BR",
    "timezone": "America/Araguaina",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "IMP",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "IMQ",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "IMQ",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "IMR",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "IMT",
    "countryCode": "US",
    "timezone": "America/Menominee",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "IMT",
    "countryCode": "US",
    "timezone": "America/Menominee",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "INA",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "INA",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "INB",
    "countryCode": "BZ",
    "timezone": "America/Belize",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "INB",
    "countryCode": "BZ",
    "timezone": "America/Belize",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "INB",
    "countryCode": "BZ",
    "timezone": "America/Belize",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "INC",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "INC",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "IND",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "IND",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "INE",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "INF",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "INF",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ING",
    "countryCode": "AR",
    "timezone": "America/Argentina/Rio_Gallegos",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "INH",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "INH",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "INI",
    "countryCode": "RS",
    "timezone": "Europe/Belgrade",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "INI",
    "countryCode": "RS",
    "timezone": "Europe/Belgrade",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "INJ",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "INJ",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "INK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "INK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "INL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "INL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "INM",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "INM",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "INN",
    "countryCode": "AT",
    "timezone": "Europe/Vienna",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "INN",
    "countryCode": "AT",
    "timezone": "Europe/Vienna",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "INO",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "INO",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "INP",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "INQ",
    "countryCode": "IE",
    "timezone": "Europe/Dublin",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "INQ",
    "countryCode": "IE",
    "timezone": "Europe/Dublin",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "INR",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "INS",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "INS",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "INT",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "INT",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "INU",
    "countryCode": "NR",
    "timezone": "Pacific/Nauru",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "INU",
    "countryCode": "NR",
    "timezone": "Pacific/Nauru",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "INU",
    "countryCode": "NR",
    "timezone": "Pacific/Nauru",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "INV",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "INV",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "INW",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "INW",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "INX",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "INX",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "INY",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "INY",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "INZ",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "INZ",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "IOA",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "IOA",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "IOB",
    "countryCode": "AT",
    "timezone": "Europe/Vienna",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "IOK",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "IOM",
    "countryCode": "IM",
    "timezone": "Europe/Isle_of_Man",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "IOM",
    "countryCode": "IM",
    "timezone": "Europe/Isle_of_Man",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "ION",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ION",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "IOP",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "IOQ",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "IOQ",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "IOR",
    "countryCode": "IE",
    "timezone": "Europe/Dublin",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "IOS",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "IOS",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "IOT",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "IOT",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "IOU",
    "countryCode": "NC",
    "timezone": "Pacific/Noumea",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "IOW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "IOW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "IPA",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "IPA",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "IPC",
    "countryCode": "CL",
    "timezone": "Pacific/Easter",
    "offset": {
      "gmt": -5,
      "dst": -6
    }
  },
  {
    "code": "IPC",
    "countryCode": "CL",
    "timezone": "Pacific/Easter",
    "offset": {
      "gmt": -5,
      "dst": -6
    }
  },
  {
    "code": "IPE",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "IPE",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "IPG",
    "countryCode": "BR",
    "timezone": "America/Manaus",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "IPG",
    "countryCode": "BR",
    "timezone": "America/Manaus",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "IPH",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "IPH",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "IPI",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "IPI",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "IPL",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "IPL",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "IPN",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "IPN",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "IPR",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "IPT",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "IPT",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "IPU",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "IPW",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "IPW",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "IPZ",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "IPZ",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "IQA",
    "countryCode": "IQ",
    "timezone": "Asia/Baghdad",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "IQM",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "IQM",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "IQN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "IQN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "IQQ",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "IQQ",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "IQT",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "IQT",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "IRA",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "IRA",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "IRB",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "IRC",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "IRC",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "IRD",
    "countryCode": "BD",
    "timezone": "Asia/Dhaka",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "IRD",
    "countryCode": "BD",
    "timezone": "Asia/Dhaka",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "IRE",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "IRG",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "IRG",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "IRI",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "IRI",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "IRJ",
    "countryCode": "AR",
    "timezone": "America/Argentina/La_Rioja",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "IRJ",
    "countryCode": "AR",
    "timezone": "America/Argentina/La_Rioja",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "IRK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "IRK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "IRM",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IRM",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IRN",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "IRO",
    "countryCode": "CF",
    "timezone": "Africa/Bangui",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "IRO",
    "countryCode": "CF",
    "timezone": "Africa/Bangui",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "IRP",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "IRP",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "IRR",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "IRS",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "IRS",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "IRT",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "IRU",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IRU",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IRZ",
    "countryCode": "BR",
    "timezone": "America/Manaus",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "IRZ",
    "countryCode": "BR",
    "timezone": "America/Manaus",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "ISA",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ISA",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ISB",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "ISB",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "ISB",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "ISC",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "ISC",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "ISD",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ISE",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ISE",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ISG",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "ISG",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "ISH",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ISI",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ISI",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ISJ",
    "countryCode": "MX",
    "timezone": "America/Cancun",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ISJ",
    "countryCode": "MX",
    "timezone": "America/Cancun",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ISK",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "ISK",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "ISK",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "ISK",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "ISL",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ISL",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "ISL",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ISL",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "ISM",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ISN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ISN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ISO",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ISO",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ISP",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ISP",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ISQ",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ISR",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ISS",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ISS",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "IST",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "IST",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "IST",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ISU",
    "countryCode": "IQ",
    "timezone": "Asia/Baghdad",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ISU",
    "countryCode": "IQ",
    "timezone": "Asia/Baghdad",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ISW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ISW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ITA",
    "countryCode": "BR",
    "timezone": "America/Manaus",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "ITA",
    "countryCode": "BR",
    "timezone": "America/Manaus",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "ITB",
    "countryCode": "BR",
    "timezone": "America/Santarem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ITB",
    "countryCode": "BR",
    "timezone": "America/Santarem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ITE",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ITE",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ITH",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ITH",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ITI",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ITI",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ITJ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ITK",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ITM",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "ITN",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ITN",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ITO",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "ITO",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "ITP",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ITP",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ITQ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ITQ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ITR",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ITR",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ITT",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ITU",
    "countryCode": "RU",
    "timezone": "Asia/Magadan",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "ITU",
    "countryCode": "RU",
    "timezone": "Asia/Sakhalin",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "IUE",
    "countryCode": "NU",
    "timezone": "Pacific/Niue",
    "offset": {
      "gmt": -11,
      "dst": -11
    }
  },
  {
    "code": "IUE",
    "countryCode": "NU",
    "timezone": "Pacific/Niue",
    "offset": {
      "gmt": -11,
      "dst": -11
    }
  },
  {
    "code": "IUI",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "IUI",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "IUL",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "IUM",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "IUO",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "IUO",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "IUP",
    "countryCode": "BR",
    "timezone": "America/Manaus",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "IUP",
    "countryCode": "BR",
    "timezone": "America/Manaus",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "IUS",
    "countryCode": "PG",
    "timezone": "Pacific/Bougainville",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "IVA",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "IVA",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "IVC",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "IVC",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "IVG",
    "countryCode": "ME",
    "timezone": "Europe/Podgorica",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "IVG",
    "countryCode": "ME",
    "timezone": "Europe/Podgorica",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "IVH",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "IVH",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "IVI",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "IVL",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "IVL",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "IVO",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "IVR",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "IVR",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "IVW",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "IWA",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "IWA",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "IWD",
    "countryCode": "US",
    "timezone": "America/Menominee",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "IWD",
    "countryCode": "US",
    "timezone": "America/Menominee",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "IWJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "IWJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "IWK",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "IWK",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "IWO",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "IWO",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "IWS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "IXA",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IXA",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IXB",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IXB",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IXC",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IXC",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IXD",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IXD",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IXE",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IXE",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IXG",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IXG",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IXH",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IXH",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IXI",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IXI",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IXJ",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IXJ",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IXK",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IXK",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IXL",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IXL",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IXM",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IXM",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IXN",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IXN",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IXP",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IXQ",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IXR",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IXR",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IXS",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IXS",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IXT",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IXU",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IXU",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IXV",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IXV",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IXW",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IXW",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IXX",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IXX",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IXY",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IXY",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IXZ",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IXZ",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "IYK",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "IYK",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "IZA",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "IZE",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "IZE",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "IZM",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "IZO",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "IZO",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "IZT",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "IZT",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "JAA",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "JAA",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "JAB",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "JAB",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "JAC",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "JAC",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "JAD",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JAE",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "JAE",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "JAF",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "JAF",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "JAG",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "JAG",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "JAH",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "JAH",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "JAI",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "JAI",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "JAK",
    "countryCode": "HT",
    "timezone": "America/Port-au-Prince",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "JAK",
    "countryCode": "HT",
    "timezone": "America/Port-au-Prince",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "JAL",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "JAL",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "JAM",
    "countryCode": "BG",
    "timezone": "Europe/Sofia",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "JAM",
    "countryCode": "BG",
    "timezone": "Europe/Sofia",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "JAN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "JAN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "JAO",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "JAP",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "JAP",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "JAP",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "JAQ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "JAR",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "JAR",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "JAS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "JAT",
    "countryCode": "MH",
    "timezone": "Pacific/Majuro",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "JAT",
    "countryCode": "MH",
    "timezone": "Pacific/Majuro",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "JAU",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "JAU",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "JAV",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "JAV",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "JAW",
    "countryCode": "BR",
    "timezone": "America/Recife",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JAW",
    "countryCode": "BR",
    "timezone": "America/Recife",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JAX",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "JAX",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "JBB",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "JBB",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "JBC",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "JBD",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JBK",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "JBK",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "JBK",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "JBP",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "JBQ",
    "countryCode": "DO",
    "timezone": "America/Santo_Domingo",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "JBR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "JBR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "JBS",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JBS",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "JBS",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JBT",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "JBU",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "JCA",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "JCB",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JCB",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JCC",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "JCD",
    "countryCode": "VI",
    "timezone": "America/St_Thomas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "JCE",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "JCH",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "JCH",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "JCI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "JCJ",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "JCJ",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "JCK",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "JCK",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "JCL",
    "countryCode": "CZ",
    "timezone": "Europe/Prague",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "JCL",
    "countryCode": "CZ",
    "timezone": "Europe/Prague",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "JCM",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JCM",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JCN",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "JCN",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "JCO",
    "countryCode": "MT",
    "timezone": "Europe/Malta",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "JCO",
    "countryCode": "MT",
    "timezone": "Europe/Malta",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "JCO",
    "countryCode": "MT",
    "timezone": "Europe/Malta",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "JCR",
    "countryCode": "BR",
    "timezone": "America/Santarem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JCR",
    "countryCode": "BR",
    "timezone": "America/Santarem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JCS",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JCS",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JCT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "JCU",
    "countryCode": "ES",
    "timezone": "Africa/Ceuta",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "JCU",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "JCX",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "JCY",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "JDA",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "JDA",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "JDB",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "JDE",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JDF",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JDF",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JDG",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "JDH",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "JDH",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "JDK",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "JDM",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "JDN",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "JDN",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "JDO",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JDO",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JDP",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "JDR",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JDR",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JDT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "JDX",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "JDY",
    "countryCode": "US",
    "timezone": "America/Boise",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "JDZ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JDZ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JEA",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "JEA",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "JEC",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "JED",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "JED",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "JEE",
    "countryCode": "HT",
    "timezone": "America/Port-au-Prince",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "JEE",
    "countryCode": "HT",
    "timezone": "America/Port-au-Prince",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "JEF",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "JEF",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "JEG",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "JEG",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "JEJ",
    "countryCode": "MH",
    "timezone": "Pacific/Kwajalein",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "JEJ",
    "countryCode": "MH",
    "timezone": "Pacific/Majuro",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "JEK",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "JEM",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "JEQ",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JER",
    "countryCode": "JE",
    "timezone": "Europe/Jersey",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "JER",
    "countryCode": "JE",
    "timezone": "Europe/Jersey",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "JER",
    "countryCode": "JE",
    "timezone": "Europe/Jersey",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "JEV",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "JFK",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "JFM",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JFM",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JFN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "JFN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "JFR",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "JFR",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "JGA",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "JGA",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "JGB",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "JGB",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "JGC",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "JGD",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JGD",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JGE",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "JGN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JGN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JGO",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "JGO",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "JGQ",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "JGR",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "JGR",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "JGS",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JGS",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JGS",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JGX",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "JHB",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JHB",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JHC",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "JHC",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "JHE",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "JHF",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JHG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JHG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JHL",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "JHM",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "JHM",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "JHQ",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "JHS",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "JHS",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "JHW",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "JHW",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "JHY",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "JIA",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "JIA",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "JIB",
    "countryCode": "DJ",
    "timezone": "Africa/Djibouti",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "JIB",
    "countryCode": "DJ",
    "timezone": "Africa/Djibouti",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "JIC",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JIC",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JID",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "JIJ",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "JIJ",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "JIJ",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "JIK",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "JIK",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "JIK",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "JIL",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JIL",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JIM",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "JIM",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "JIN",
    "countryCode": "UG",
    "timezone": "Africa/Kampala",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "JIN",
    "countryCode": "UG",
    "timezone": "Africa/Kampala",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "JIO",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "JIO",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "JIO",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "JIP",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "JIP",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "JIQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JIQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JIR",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "JIR",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "JIU",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JIU",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JIW",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "JIW",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "JJA",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "JJD",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JJD",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JJG",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JJG",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JJI",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "JJI",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "JJJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JJL",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JJL",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JJM",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "JJM",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "JJM",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "JJN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JJN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JJN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JJU",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "JJU",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "JKG",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "JKG",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "JKH",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "JKH",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "JKL",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "JKL",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "JKR",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "JKR",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "JKT",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "JKV",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "JKV",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "JLA",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "JLA",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "JLB",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "JLD",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "JLH",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "JLH",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "JLL",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JLN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "JLN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "JLP",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "JLR",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "JLR",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "JLS",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JLS",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JLX",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "JMA",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JMA",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JMB",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "JMB",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "JMC",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "JMC",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "JMH",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "JMJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JMJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JMJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JMK",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "JMK",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "JMM",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "JMN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "JMO",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "JMO",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "JMS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "JMS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "JMU",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JMU",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JMY",
    "countryCode": "SL",
    "timezone": "Africa/Freetown",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "JNA",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JNB",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "JNB",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "JNG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JNG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JNI",
    "countryCode": "AR",
    "timezone": "America/Argentina/Buenos_Aires",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JNI",
    "countryCode": "AR",
    "timezone": "America/Argentina/Buenos_Aires",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JNJ",
    "countryCode": "OM",
    "timezone": "Asia/Muscat",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "JNJ",
    "countryCode": "OM",
    "timezone": "Asia/Muscat",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "JNN",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "JNN",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "JNP",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "JNS",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "JNS",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "JNU",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "JNU",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "JNX",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "JNX",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "JNZ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JNZ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JNZ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JOC",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "JOE",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "JOE",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "JOG",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "JOG",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "JOH",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "JOI",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JOI",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JOJ",
    "countryCode": "CA",
    "timezone": "America/Cambridge_Bay",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "JOK",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "JOK",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "JOL",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JOL",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JOM",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "JON",
    "countryCode": "UM",
    "timezone": "Pacific/Johnston",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "JON",
    "countryCode": "UM",
    "timezone": "Pacific/Johnston",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "JOP",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "JOR",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "JOR",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "JOS",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "JOS",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "JOT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "JOT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "JPA",
    "countryCode": "BR",
    "timezone": "America/Recife",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JPA",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JPD",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "JPE",
    "countryCode": "BR",
    "timezone": "America/Belem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JPE",
    "countryCode": "BR",
    "timezone": "America/Belem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JPN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "JPO",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JPO",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JPR",
    "countryCode": "BR",
    "timezone": "America/Porto_Velho",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "JPR",
    "countryCode": "BR",
    "timezone": "America/Porto_Velho",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "JPU",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "JPY",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JPY",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JQA",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "JQA",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "JQE",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "JQE",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "JQF",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "JQJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JQJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JQO",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "JRA",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "JRB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "JRC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "JRD",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "JRF",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "JRF",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "JRG",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "JRG",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "JRH",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "JRH",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "JRK",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "JRK",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "JRN",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "JRN",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "JRO",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "JRO",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "JRS",
    "countryCode": "IL",
    "timezone": "Asia/Jerusalem",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "JRS",
    "countryCode": "IL",
    "timezone": "Asia/Jerusalem",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "JRT",
    "countryCode": "BR",
    "timezone": "America/Santarem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JRT",
    "countryCode": "BR",
    "timezone": "America/Santarem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JSA",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "JSA",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "JSB",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JSB",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JSD",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "JSG",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "JSH",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "JSH",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "JSI",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "JSI",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "JSI",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "JSJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JSJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JSK",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "JSK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "JSK",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "JSL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "JSM",
    "countryCode": "AR",
    "timezone": "America/Argentina/Catamarca",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JSM",
    "countryCode": "AR",
    "timezone": "America/Argentina/Catamarca",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JSN",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "JSO",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JSO",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "JSO",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "JSO",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JSP",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "JSP",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "JSR",
    "countryCode": "BD",
    "timezone": "Asia/Dhaka",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "JSR",
    "countryCode": "BD",
    "timezone": "Asia/Dhaka",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "JSS",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "JST",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "JST",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "JSU",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "JSU",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "JSY",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "JSY",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "JTA",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JTA",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JTC",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JTC",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JTI",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JTI",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JTN",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JTN",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JTO",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "JTR",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "JTR",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "JTY",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "JTY",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "JUA",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "JUA",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "JUB",
    "countryCode": "SS",
    "timezone": "Africa/Juba",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "JUB",
    "countryCode": "SS",
    "timezone": "Africa/Juba",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "JUC",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "JUH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JUH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JUI",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "JUI",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "JUJ",
    "countryCode": "AR",
    "timezone": "America/Argentina/Jujuy",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JUJ",
    "countryCode": "AR",
    "timezone": "America/Argentina/Jujuy",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "JUK",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "JUK",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "JUL",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "JUL",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "JUM",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "JUM",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "JUN",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "JUN",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "JUO",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "JUO",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "JUR",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JUR",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JUT",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "JUU",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "JUU",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "JUV",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "JUV",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "JUZ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JUZ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JVA",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "JVA",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "JVI",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "JVI",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "JVL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "JVL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "JWA",
    "countryCode": "BW",
    "timezone": "Africa/Gaborone",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "JWA",
    "countryCode": "BW",
    "timezone": "Africa/Gaborone",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "JWC",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "JWH",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "JWN",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "JWN",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "JWO",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "JWO",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "JXA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JXA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JXN",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "JXN",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "JXS",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JXS",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JYQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JYQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JYQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JYR",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "JYV",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "JYV",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "JZH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "JZH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KAA",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KAA",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KAB",
    "countryCode": "ZW",
    "timezone": "Africa/Harare",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KAB",
    "countryCode": "ZW",
    "timezone": "Africa/Harare",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KAC",
    "countryCode": "SY",
    "timezone": "Asia/Damascus",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KAC",
    "countryCode": "SY",
    "timezone": "Asia/Damascus",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KAD",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "KAD",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "KAE",
    "countryCode": "US",
    "timezone": "America/Sitka",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KAE",
    "countryCode": "US",
    "timezone": "America/Sitka",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KAF",
    "countryCode": "PG",
    "timezone": "Pacific/Bougainville",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "KAG",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KAH",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "KAI",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "KAI",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "KAJ",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KAJ",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KAK",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KAL",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KAL",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KAM",
    "countryCode": "YE",
    "timezone": "Asia/Aden",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "KAN",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "KAN",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "KAO",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KAO",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KAP",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KAP",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KAQ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KAR",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "KAR",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "KAS",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KAS",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KAT",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "KAT",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "KAU",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KAU",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KAV",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "KAW",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "KAW",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "KAX",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KAX",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KAY",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "KAZ",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KAZ",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KBA",
    "countryCode": "SL",
    "timezone": "Africa/Freetown",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "KBA",
    "countryCode": "SL",
    "timezone": "Africa/Freetown",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "KBB",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KBC",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KBC",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KBD",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KBD",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KBE",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KBE",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KBE",
    "countryCode": "US",
    "timezone": "America/Sitka",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KBF",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KBG",
    "countryCode": "UG",
    "timezone": "Africa/Kampala",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KBG",
    "countryCode": "UG",
    "timezone": "Africa/Kampala",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KBH",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KBH",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KBH",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KBH",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KBI",
    "countryCode": "CM",
    "timezone": "Africa/Douala",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "KBI",
    "countryCode": "CM",
    "timezone": "Africa/Douala",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "KBJ",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KBK",
    "countryCode": "US",
    "timezone": "America/Sitka",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KBK",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KBL",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "KBL",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "KBM",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KBM",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KBN",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KBN",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KBO",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KBO",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KBP",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KBQ",
    "countryCode": "MW",
    "timezone": "Africa/Blantyre",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KBQ",
    "countryCode": "MW",
    "timezone": "Africa/Blantyre",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KBR",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KBR",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KBS",
    "countryCode": "SL",
    "timezone": "Africa/Freetown",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "KBS",
    "countryCode": "SL",
    "timezone": "Africa/Freetown",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "KBT",
    "countryCode": "MH",
    "timezone": "Pacific/Majuro",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "KBU",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KBU",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KBV",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KBV",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KBW",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KBX",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KBY",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "KBY",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "KBZ",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "KBZ",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "KCA",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "KCA",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "KCB",
    "countryCode": "SR",
    "timezone": "America/Paramaribo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "KCB",
    "countryCode": "SR",
    "timezone": "America/Paramaribo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "KCC",
    "countryCode": "US",
    "timezone": "America/Metlakatla",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KCC",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KCD",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KCE",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KCE",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KCF",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KCF",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KCG",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KCH",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KCH",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KCI",
    "countryCode": "TL",
    "timezone": "Asia/Dili",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KCI",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KCI",
    "countryCode": "TL",
    "timezone": "Asia/Dili",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KCI",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KCJ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KCK",
    "countryCode": "RU",
    "timezone": "Asia/Irkutsk",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KCK",
    "countryCode": "RU",
    "timezone": "Asia/Irkutsk",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KCL",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KCL",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KCM",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KCM",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KCN",
    "countryCode": "US",
    "timezone": "America/Adak",
    "offset": {
      "gmt": -10,
      "dst": -9
    }
  },
  {
    "code": "KCN",
    "countryCode": "US",
    "timezone": "America/Adak",
    "offset": {
      "gmt": -10,
      "dst": -9
    }
  },
  {
    "code": "KCO",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KCO",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KCP",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KCQ",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KCR",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KCR",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KCS",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KCT",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KCT",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KCU",
    "countryCode": "UG",
    "timezone": "Africa/Kampala",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KCU",
    "countryCode": "UG",
    "timezone": "Africa/Kampala",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KCY",
    "countryCode": "RU",
    "timezone": "Asia/Krasnoyarsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KCZ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KCZ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KDA",
    "countryCode": "SN",
    "timezone": "Africa/Dakar",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "KDA",
    "countryCode": "SN",
    "timezone": "Africa/Dakar",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "KDB",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KDB",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KDC",
    "countryCode": "BJ",
    "timezone": "Africa/Porto-Novo",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "KDC",
    "countryCode": "BJ",
    "timezone": "Africa/Porto-Novo",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "KDD",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KDD",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KDE",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KDE",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KDF",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "KDH",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "KDH",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "KDI",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KDI",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KDJ",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "KDK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KDL",
    "countryCode": "EE",
    "timezone": "Europe/Tallinn",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KDL",
    "countryCode": "EE",
    "timezone": "Europe/Tallinn",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KDM",
    "countryCode": "MV",
    "timezone": "Indian/Maldives",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KDM",
    "countryCode": "MV",
    "timezone": "Indian/Maldives",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KDN",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "KDO",
    "countryCode": "MV",
    "timezone": "Indian/Maldives",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KDO",
    "countryCode": "MV",
    "timezone": "Indian/Maldives",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KDP",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KDP",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KDQ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KDQ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KDR",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KDR",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KDS",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KDT",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KDT",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KDU",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KDU",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KDV",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "KDV",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "KDV",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "KDW",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KDW",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KDX",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KDX",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KDY",
    "countryCode": "RU",
    "timezone": "Asia/Vladivostok",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KDY",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KDY",
    "countryCode": "RU",
    "timezone": "Asia/Vladivostok",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KDY",
    "countryCode": "RU",
    "timezone": "Asia/Vladivostok",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KDZ",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KEA",
    "countryCode": "TM",
    "timezone": "Asia/Ashgabat",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KEA",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KEA",
    "countryCode": "TM",
    "timezone": "Asia/Ashgabat",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KEB",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KEB",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KEC",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KEC",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KED",
    "countryCode": "MR",
    "timezone": "Africa/Nouakchott",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "KED",
    "countryCode": "MR",
    "timezone": "Africa/Nouakchott",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "KEE",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "KEE",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "KEF",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "KEG",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KEG",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KEH",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "KEH",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "KEI",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KEJ",
    "countryCode": "RU",
    "timezone": "Asia/Novokuznetsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KEJ",
    "countryCode": "RU",
    "timezone": "Asia/Novokuznetsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KEK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KEK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KEL",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KEL",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KEM",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KEM",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KEN",
    "countryCode": "SL",
    "timezone": "Africa/Freetown",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "KEN",
    "countryCode": "SL",
    "timezone": "Africa/Freetown",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "KEO",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "KEO",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "KEP",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KEP",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KEQ",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KER",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KER",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KES",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "KET",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "KET",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "KEU",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KEU",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KEU",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KEV",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KEV",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KEV",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KEW",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "KEW",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "KEX",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KEX",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KEY",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KEY",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KEZ",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KFA",
    "countryCode": "MR",
    "timezone": "Africa/Nouakchott",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "KFA",
    "countryCode": "MR",
    "timezone": "Africa/Nouakchott",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "KFE",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KFE",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KFG",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KFI",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KFM",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "KFM",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "KFM",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "KFP",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KFP",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KFS",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KFS",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KFZ",
    "countryCode": "AL",
    "timezone": "Europe/Tirane",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KFZ",
    "countryCode": "AL",
    "timezone": "Europe/Tirane",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KGA",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KGA",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KGB",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KGC",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "KGC",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "KGD",
    "countryCode": "RU",
    "timezone": "Europe/Kaliningrad",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KGD",
    "countryCode": "RU",
    "timezone": "Europe/Kaliningrad",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KGE",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "KGE",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "KGF",
    "countryCode": "KZ",
    "timezone": "Asia/Almaty",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "KGF",
    "countryCode": "KZ",
    "timezone": "Asia/Almaty",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "KGG",
    "countryCode": "SN",
    "timezone": "Africa/Dakar",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "KGH",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KGI",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KGI",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KGJ",
    "countryCode": "MW",
    "timezone": "Africa/Blantyre",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KGJ",
    "countryCode": "MW",
    "timezone": "Africa/Blantyre",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KGK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KGK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KGL",
    "countryCode": "RW",
    "timezone": "Africa/Kigali",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KGL",
    "countryCode": "RW",
    "timezone": "Africa/Kigali",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KGM",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KGM",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KGN",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "KGN",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "KGO",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KGO",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KGP",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KGP",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KGQ",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "KGQ",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "KGR",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KGS",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KGS",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KGT",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KGT",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KGU",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KGU",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KGV",
    "countryCode": "AT",
    "timezone": "Europe/Vienna",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KGW",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KGW",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KGX",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KGX",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KGY",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KGY",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KGZ",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KGZ",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KHA",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KHA",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KHC",
    "countryCode": "UA",
    "timezone": "Europe/Simferopol",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KHC",
    "countryCode": "UA",
    "timezone": "Europe/Simferopol",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KHD",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KHD",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KHE",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KHE",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KHG",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "KHG",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "KHH",
    "countryCode": "TW",
    "timezone": "Asia/Taipei",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KHH",
    "countryCode": "TW",
    "timezone": "Asia/Taipei",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KHI",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KHI",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KHJ",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KHJ",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KHK",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KHK",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KHL",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KHM",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "KHM",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "KHN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KHN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KHO",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KHQ",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "KHQ",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "KHR",
    "countryCode": "MN",
    "timezone": "Asia/Ulaanbaatar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KHR",
    "countryCode": "MN",
    "timezone": "Asia/Ulaanbaatar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KHS",
    "countryCode": "OM",
    "timezone": "Asia/Muscat",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "KHS",
    "countryCode": "OM",
    "timezone": "Asia/Muscat",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "KHT",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "KHT",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "KHU",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KHU",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KHV",
    "countryCode": "RU",
    "timezone": "Asia/Vladivostok",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KHV",
    "countryCode": "RU",
    "timezone": "Asia/Vladivostok",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KHW",
    "countryCode": "BW",
    "timezone": "Africa/Gaborone",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KHW",
    "countryCode": "BW",
    "timezone": "Africa/Gaborone",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KHX",
    "countryCode": "UG",
    "timezone": "Africa/Kampala",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KHX",
    "countryCode": "UG",
    "timezone": "Africa/Kampala",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KHY",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KHY",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KHZ",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "KHZ",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "KIA",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KIB",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KIB",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KIC",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "KIC",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "KID",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KID",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KIE",
    "countryCode": "PG",
    "timezone": "Pacific/Bougainville",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "KIE",
    "countryCode": "PG",
    "timezone": "Pacific/Bougainville",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "KIF",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "KIF",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "KIG",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KIH",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KIH",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KII",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KIJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KIJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KIK",
    "countryCode": "IQ",
    "timezone": "Asia/Baghdad",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KIK",
    "countryCode": "IQ",
    "timezone": "Asia/Baghdad",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KIL",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KIL",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KIM",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KIM",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KIN",
    "countryCode": "JM",
    "timezone": "America/Jamaica",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "KIN",
    "countryCode": "JM",
    "timezone": "America/Jamaica",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "KIO",
    "countryCode": "MH",
    "timezone": "Pacific/Majuro",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "KIO",
    "countryCode": "MH",
    "timezone": "Pacific/Majuro",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "KIP",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "KIQ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KIR",
    "countryCode": "IE",
    "timezone": "Europe/Dublin",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "KIR",
    "countryCode": "IE",
    "timezone": "Europe/Dublin",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "KIS",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KIS",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KIT",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KIT",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KIU",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KIU",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KIV",
    "countryCode": "MD",
    "timezone": "Europe/Chisinau",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KIV",
    "countryCode": "MD",
    "timezone": "Europe/Chisinau",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KIW",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KIW",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KIX",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KIY",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KIY",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KIZ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KJA",
    "countryCode": "RU",
    "timezone": "Asia/Krasnoyarsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KJA",
    "countryCode": "RU",
    "timezone": "Asia/Krasnoyarsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KJB",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KJB",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KJH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KJH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KJH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KJI",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "KJI",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "KJJ",
    "countryCode": "UG",
    "timezone": "Africa/Kampala",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KJJ",
    "countryCode": "UG",
    "timezone": "Africa/Kampala",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KJK",
    "countryCode": "BE",
    "timezone": "Europe/Brussels",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KJK",
    "countryCode": "BE",
    "timezone": "Europe/Brussels",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KJN",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KJP",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KJP",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KJR",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KJT",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KJT",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KJU",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KJX",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KJX",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KKA",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KKA",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KKB",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KKB",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KKC",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KKC",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KKD",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KKD",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KKE",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "KKE",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "KKF",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KKG",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "KKH",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KKH",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KKI",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KKI",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KKJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KKJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KKK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KKK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KKL",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KKM",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KKM",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KKN",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KKN",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KKO",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "KKO",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "KKP",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KKQ",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KKR",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "KKR",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "KKS",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KKS",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KKT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "KKT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "KKU",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KKU",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KKW",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "KKW",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "KKX",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KKX",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KKY",
    "countryCode": "IE",
    "timezone": "Europe/Dublin",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "KKY",
    "countryCode": "IE",
    "timezone": "Europe/Dublin",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "KKZ",
    "countryCode": "KH",
    "timezone": "Asia/Phnom_Penh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KLA",
    "countryCode": "UG",
    "timezone": "Africa/Kampala",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KLB",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KLB",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KLC",
    "countryCode": "SN",
    "timezone": "Africa/Dakar",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "KLC",
    "countryCode": "SN",
    "timezone": "Africa/Dakar",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "KLD",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KLD",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KLE",
    "countryCode": "CM",
    "timezone": "Africa/Douala",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "KLF",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KLF",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KLG",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KLG",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KLH",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KLH",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KLI",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "KLJ",
    "countryCode": "LT",
    "timezone": "Europe/Vilnius",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KLJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KLJ",
    "countryCode": "LT",
    "timezone": "Europe/Vilnius",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KLK",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KLK",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KLL",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KLL",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KLM",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KLM",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KLN",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KLN",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KLO",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KLO",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KLP",
    "countryCode": "ID",
    "timezone": "Asia/Pontianak",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KLP",
    "countryCode": "US",
    "timezone": "America/Sitka",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KLP",
    "countryCode": "ID",
    "timezone": "Asia/Pontianak",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KLQ",
    "countryCode": "ID",
    "timezone": "Asia/Pontianak",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KLR",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KLR",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KLS",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "KLS",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "KLT",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KLT",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KLU",
    "countryCode": "AT",
    "timezone": "Europe/Vienna",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KLU",
    "countryCode": "AT",
    "timezone": "Europe/Vienna",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KLV",
    "countryCode": "CZ",
    "timezone": "Europe/Prague",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KLV",
    "countryCode": "CZ",
    "timezone": "Europe/Prague",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KLW",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KLW",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KLX",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KLX",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KLY",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KLY",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KLZ",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KLZ",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KMA",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KMA",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KMB",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KMC",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KMC",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KMD",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "KME",
    "countryCode": "RW",
    "timezone": "Africa/Kigali",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KME",
    "countryCode": "RW",
    "timezone": "Africa/Kigali",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KMF",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KMG",
    "countryCode": "CN",
    "timezone": "Asia/Chongqing",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "KMG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KMG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KMH",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KMH",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KMI",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KMI",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KMJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KMJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KMK",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "KMK",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "KML",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KMM",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KMM",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KMN",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KMN",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KMO",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KMO",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KMP",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KMP",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KMQ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KMQ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KMR",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KMS",
    "countryCode": "GH",
    "timezone": "Africa/Accra",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "KMS",
    "countryCode": "GH",
    "timezone": "Africa/Accra",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "KMT",
    "countryCode": "KH",
    "timezone": "Asia/Phnom_Penh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KMU",
    "countryCode": "SO",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KMU",
    "countryCode": "SO",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KMV",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "KMV",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "KMW",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KMW",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KMX",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KMX",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KMY",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KMY",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KMZ",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KMZ",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KNA",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "KNA",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "KNB",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "KNB",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "KNC",
    "countryCode": "CN",
    "timezone": "Asia/Harbin",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KND",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KND",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KNE",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KNF",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "KNF",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "KNG",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KNG",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KNH",
    "countryCode": "TW",
    "timezone": "Asia/Taipei",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KNH",
    "countryCode": "TW",
    "timezone": "Asia/Taipei",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KNH",
    "countryCode": "TW",
    "timezone": "Asia/Taipei",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KNI",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KNI",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KNJ",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "KNK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KNK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KNL",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KNM",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KNM",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KNN",
    "countryCode": "GN",
    "timezone": "Africa/Conakry",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "KNN",
    "countryCode": "GN",
    "timezone": "Africa/Conakry",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "KNO",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KNP",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "KNP",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "KNQ",
    "countryCode": "NC",
    "timezone": "Pacific/Noumea",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "KNQ",
    "countryCode": "NC",
    "timezone": "Pacific/Noumea",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "KNR",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KNR",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KNS",
    "countryCode": "AU",
    "timezone": "Australia/Hobart",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "KNS",
    "countryCode": "AU",
    "timezone": "Australia/Hobart",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "KNT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "KNT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "KNU",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KNU",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KNV",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "KNW",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KNW",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KNX",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KNX",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KNY",
    "countryCode": "RU",
    "timezone": "Asia/Krasnoyarsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KNY",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "KNY",
    "countryCode": "RU",
    "timezone": "Asia/Krasnoyarsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KNZ",
    "countryCode": "ML",
    "timezone": "Africa/Bamako",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "KNZ",
    "countryCode": "ML",
    "timezone": "Africa/Bamako",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "KOA",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "KOA",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "KOC",
    "countryCode": "NC",
    "timezone": "Pacific/Noumea",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "KOC",
    "countryCode": "NC",
    "timezone": "Pacific/Noumea",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "KOD",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KOD",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KOE",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KOE",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KOF",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KOF",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KOG",
    "countryCode": "LA",
    "timezone": "Asia/Vientiane",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KOH",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KOI",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "KOI",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "KOJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KOJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KOK",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KOK",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KOK",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KOL",
    "countryCode": "CF",
    "timezone": "Africa/Bangui",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "KOM",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KOM",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KON",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KOO",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KOO",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KOP",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KOP",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KOQ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KOQ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KOR",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KOR",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KOS",
    "countryCode": "KH",
    "timezone": "Asia/Phnom_Penh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KOS",
    "countryCode": "KH",
    "timezone": "Asia/Phnom_Penh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KOT",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KOT",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KOU",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "KOU",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "KOV",
    "countryCode": "KZ",
    "timezone": "Asia/Almaty",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "KOV",
    "countryCode": "KZ",
    "timezone": "Asia/Almaty",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "KOW",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KOW",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KOX",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KOY",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KOY",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KOZ",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KOZ",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KPA",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KPB",
    "countryCode": "US",
    "timezone": "America/Metlakatla",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KPB",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KPC",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KPC",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KPD",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "KPE",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KPF",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KPG",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "KPH",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KPH",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KPH",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KPH",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KPI",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KPI",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KPK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KPL",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KPL",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KPM",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KPM",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KPN",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KPN",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KPO",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KPO",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KPP",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KPP",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KPR",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KPR",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KPS",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "KPS",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "KPT",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "KPT",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "KPV",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KPV",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KPW",
    "countryCode": "RU",
    "timezone": "Asia/Anadyr",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "KPW",
    "countryCode": "RU",
    "timezone": "Asia/Anadyr",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "KPY",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KPY",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KQA",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KQA",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KQB",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "KQH",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KQH",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KQH",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KQL",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KQR",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KQR",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KQT",
    "countryCode": "TJ",
    "timezone": "Asia/Dushanbe",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KQT",
    "countryCode": "TJ",
    "timezone": "Asia/Dushanbe",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KRA",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "KRA",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "KRB",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KRB",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KRC",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KRC",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KRD",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KRE",
    "countryCode": "BI",
    "timezone": "Africa/Bujumbura",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KRE",
    "countryCode": "BI",
    "timezone": "Africa/Bujumbura",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KRF",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KRF",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KRG",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "KRH",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "KRH",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "KRI",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KRI",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KRJ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KRK",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KRK",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KRL",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "KRL",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "KRM",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "KRM",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "KRN",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KRN",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KRO",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KRO",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KRP",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KRP",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KRQ",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KRR",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KRR",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KRS",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KRS",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KRT",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KRT",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KRU",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KRU",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KRV",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KRV",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KRW",
    "countryCode": "TM",
    "timezone": "Asia/Ashgabat",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KRW",
    "countryCode": "TM",
    "timezone": "Asia/Ashgabat",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KRX",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KRY",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "KRY",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "KRZ",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "KRZ",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "KSA",
    "countryCode": "FM",
    "timezone": "Pacific/Kosrae",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "KSA",
    "countryCode": "FM",
    "timezone": "Pacific/Kosrae",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "KSB",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KSC",
    "countryCode": "SK",
    "timezone": "Europe/Bratislava",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KSC",
    "countryCode": "SK",
    "timezone": "Europe/Bratislava",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KSD",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KSD",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KSE",
    "countryCode": "UG",
    "timezone": "Africa/Kampala",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KSE",
    "countryCode": "UG",
    "timezone": "Africa/Kampala",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KSF",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KSF",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KSG",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KSH",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KSH",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KSI",
    "countryCode": "GN",
    "timezone": "Africa/Conakry",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "KSI",
    "countryCode": "GN",
    "timezone": "Africa/Conakry",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "KSJ",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KSJ",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KSK",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KSL",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KSL",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KSM",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KSM",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KSN",
    "countryCode": "KZ",
    "timezone": "Asia/Qostanay",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "KSN",
    "countryCode": "KZ",
    "timezone": "Asia/Qostanay",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "KSO",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KSO",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KSP",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KSQ",
    "countryCode": "UZ",
    "timezone": "Asia/Samarkand",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KSQ",
    "countryCode": "UZ",
    "timezone": "Asia/Samarkand",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KSR",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KSR",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KSR",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KSR",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KSS",
    "countryCode": "ML",
    "timezone": "Africa/Bamako",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "KST",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KST",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KST",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KSU",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KSU",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KSV",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KSV",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KSW",
    "countryCode": "IL",
    "timezone": "Asia/Jerusalem",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KSX",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KSY",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KSY",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KSZ",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KSZ",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KTA",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KTA",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KTB",
    "countryCode": "US",
    "timezone": "America/Metlakatla",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KTB",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KTC",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "KTC",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "KTD",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KTD",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KTE",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KTE",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KTF",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "KTF",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "KTG",
    "countryCode": "ID",
    "timezone": "Asia/Pontianak",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KTG",
    "countryCode": "ID",
    "timezone": "Asia/Pontianak",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KTH",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KTI",
    "countryCode": "KH",
    "timezone": "Asia/Phnom_Penh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KTJ",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KTJ",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KTK",
    "countryCode": "PG",
    "timezone": "Pacific/Bougainville",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "KTL",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KTL",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KTM",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KTM",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KTN",
    "countryCode": "US",
    "timezone": "America/Sitka",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KTN",
    "countryCode": "US",
    "timezone": "America/Sitka",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KTO",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "KTO",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "KTP",
    "countryCode": "JM",
    "timezone": "America/Jamaica",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "KTQ",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KTQ",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KTR",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KTR",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KTS",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KTS",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KTT",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KTT",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KTU",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KTU",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KTV",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "KTV",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "KTW",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KTW",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KTX",
    "countryCode": "ML",
    "timezone": "Africa/Bamako",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "KTX",
    "countryCode": "ML",
    "timezone": "Africa/Bamako",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "KTY",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KTY",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KTZ",
    "countryCode": "HK",
    "timezone": "Asia/Hong_Kong",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KUA",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KUA",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KUB",
    "countryCode": "BN",
    "timezone": "Asia/Brunei",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KUC",
    "countryCode": "KI",
    "timezone": "Pacific/Tarawa",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "KUC",
    "countryCode": "KI",
    "timezone": "Pacific/Tarawa",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "KUD",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KUD",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KUE",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "KUF",
    "countryCode": "RU",
    "timezone": "Europe/Samara",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "KUF",
    "countryCode": "RU",
    "timezone": "Europe/Samara",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "KUG",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KUG",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KUH",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KUH",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KUI",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "KUJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KUJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KUK",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KUK",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KUL",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KUL",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KUM",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KUM",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KUN",
    "countryCode": "LT",
    "timezone": "Europe/Vilnius",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KUN",
    "countryCode": "LT",
    "timezone": "Europe/Vilnius",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KUO",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KUO",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KUP",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KUP",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KUQ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KUQ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KUR",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "KUR",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "KUS",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "KUS",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "KUT",
    "countryCode": "GE",
    "timezone": "Asia/Tbilisi",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "KUT",
    "countryCode": "GE",
    "timezone": "Asia/Tbilisi",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "KUU",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KUU",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KUV",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KUV",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KUW",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KUX",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KUX",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KUY",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KUZ",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KUZ",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "KUZ",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "KVA",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KVA",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KVB",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KVB",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KVC",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KVC",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KVD",
    "countryCode": "AZ",
    "timezone": "Asia/Baku",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "KVE",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KVG",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KVG",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KVK",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KVK",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KVK",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KVL",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KVL",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KVM",
    "countryCode": "RU",
    "timezone": "Asia/Anadyr",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "KVM",
    "countryCode": "RU",
    "timezone": "Asia/Anadyr",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "KVN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KVN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KVO",
    "countryCode": "RS",
    "timezone": "Europe/Belgrade",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KVO",
    "countryCode": "RS",
    "timezone": "Europe/Belgrade",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KVO",
    "countryCode": "RS",
    "timezone": "Europe/Belgrade",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KVR",
    "countryCode": "RU",
    "timezone": "Asia/Vladivostok",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KVR",
    "countryCode": "RU",
    "timezone": "Asia/Vladivostok",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KVU",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "KVU",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "KVX",
    "countryCode": "RU",
    "timezone": "Europe/Kirov",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KVX",
    "countryCode": "RU",
    "timezone": "Europe/Kirov",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KVX",
    "countryCode": "RU",
    "timezone": "Europe/Kirov",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KWA",
    "countryCode": "MH",
    "timezone": "Pacific/Kwajalein",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "KWA",
    "countryCode": "MH",
    "timezone": "Pacific/Kwajalein",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "KWB",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KWB",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KWD",
    "countryCode": "CF",
    "timezone": "Africa/Bangui",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "KWE",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KWE",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KWF",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KWF",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KWG",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KWG",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KWG",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KWH",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "KWH",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "KWI",
    "countryCode": "KW",
    "timezone": "Asia/Kuwait",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KWI",
    "countryCode": "KW",
    "timezone": "Asia/Kuwait",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KWJ",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KWJ",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "KWK",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KWK",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KWL",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KWL",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KWM",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KWM",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KWN",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KWN",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KWO",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KWP",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KWP",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KWQ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KWR",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "KWS",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "KWT",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KWT",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KWU",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "KWV",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KWX",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KWY",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KWY",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KWZ",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KWZ",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KXA",
    "countryCode": "US",
    "timezone": "America/Metlakatla",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KXA",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KXB",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KXB",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KXD",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KXD",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KXE",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KXE",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "KXF",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "KXF",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "KXK",
    "countryCode": "RU",
    "timezone": "Asia/Vladivostok",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KXK",
    "countryCode": "RU",
    "timezone": "Asia/Vladivostok",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KXN",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "KXN",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "KXO",
    "countryCode": "UG",
    "timezone": "Africa/Kampala",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KXO",
    "countryCode": "UG",
    "timezone": "Africa/Kampala",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KXR",
    "countryCode": "PG",
    "timezone": "Pacific/Bougainville",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "KXU",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "KXU",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "KYA",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KYA",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KYB",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "KYB",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KYB",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KYD",
    "countryCode": "TW",
    "timezone": "Asia/Taipei",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KYD",
    "countryCode": "TW",
    "timezone": "Asia/Taipei",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KYE",
    "countryCode": "LB",
    "timezone": "Asia/Beirut",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KYE",
    "countryCode": "LB",
    "timezone": "Asia/Beirut",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KYF",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "KYI",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "KYK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KYK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KYL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "KYL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "KYN",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "KYN",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "KYO",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "KYP",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "KYP",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "KYS",
    "countryCode": "ML",
    "timezone": "Africa/Bamako",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "KYS",
    "countryCode": "ML",
    "timezone": "Africa/Bamako",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "KYT",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "KYU",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KYU",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KYX",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KYY",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "KYZ",
    "countryCode": "RU",
    "timezone": "Asia/Krasnoyarsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KYZ",
    "countryCode": "RU",
    "timezone": "Asia/Krasnoyarsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KZB",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KZB",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KZC",
    "countryCode": "KH",
    "timezone": "Asia/Phnom_Penh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KZC",
    "countryCode": "KH",
    "timezone": "Asia/Phnom_Penh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KZD",
    "countryCode": "KH",
    "timezone": "Asia/Phnom_Penh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KZF",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "KZG",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KZG",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "KZH",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "KZI",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KZI",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KZK",
    "countryCode": "KH",
    "timezone": "Asia/Phnom_Penh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "KZN",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KZN",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KZO",
    "countryCode": "KZ",
    "timezone": "Asia/Qyzylorda",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KZO",
    "countryCode": "KZ",
    "timezone": "Asia/Qyzylorda",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "KZR",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KZR",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "KZS",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KZS",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "KZZ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LAA",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "LAA",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "LAB",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "LAC",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LAC",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LAD",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "LAD",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "LAE",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "LAE",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "LAF",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LAF",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LAG",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "LAG",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "LAH",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "LAH",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "LAI",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LAI",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LAJ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "LAJ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "LAK",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "LAK",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "LAL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LAL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LAM",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "LAM",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "LAN",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LAN",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LAO",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LAO",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LAP",
    "countryCode": "MX",
    "timezone": "America/Mazatlan",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "LAP",
    "countryCode": "MX",
    "timezone": "America/Mazatlan",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "LAQ",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LAQ",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LAR",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "LAR",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "LAS",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "LAS",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "LAT",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "LAT",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "LAU",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "LAU",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "LAU",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "LAV",
    "countryCode": "WS",
    "timezone": "Pacific/Apia",
    "offset": {
      "gmt": 14,
      "dst": 13
    }
  },
  {
    "code": "LAV",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LAW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LAW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LAX",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "LAX",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "LAY",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LAY",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LAZ",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "LBA",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "LBA",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "LBB",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LBB",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LBC",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LBC",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LBD",
    "countryCode": "TJ",
    "timezone": "Asia/Dushanbe",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "LBD",
    "countryCode": "TJ",
    "timezone": "Asia/Dushanbe",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "LBE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LBE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LBF",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LBF",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LBG",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LBH",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "LBI",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LBI",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LBJ",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LBJ",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LBK",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "LBL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LBL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LBM",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LBN",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "LBN",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "LBO",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LBO",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LBP",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LBP",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LBQ",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "LBQ",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "LBR",
    "countryCode": "BR",
    "timezone": "America/Manaus",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "LBR",
    "countryCode": "BR",
    "timezone": "America/Manaus",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "LBS",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "LBS",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "LBT",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LBT",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LBU",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LBU",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LBV",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "LBV",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "LBW",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LBW",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LBX",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LBX",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LBY",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LBY",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LBZ",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "LBZ",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "LCA",
    "countryCode": "CY",
    "timezone": "Asia/Nicosia",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "LCA",
    "countryCode": "CY",
    "timezone": "Asia/Nicosia",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "LCB",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "LCB",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "LCC",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LCC",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LCD",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LCD",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LCE",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "LCE",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "LCF",
    "countryCode": "GT",
    "timezone": "America/Guatemala",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "LCF",
    "countryCode": "GT",
    "timezone": "America/Guatemala",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "LCG",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LCG",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LCH",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LCH",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LCI",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LCI",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LCJ",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LCJ",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LCK",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LCL",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LCL",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LCM",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "LCM",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "LCN",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "LCN",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "LCO",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "LCP",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "LCQ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LCQ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LCR",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "LCR",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "LCS",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LCS",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "LCT",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LCT",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LCT",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LCV",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LCV",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LCX",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LCX",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LCY",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "LCZ",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LDA",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "LDA",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "LDB",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "LDB",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "LDC",
    "countryCode": "AU",
    "timezone": "Australia/Lindeman",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "LDE",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LDG",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "LDG",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "LDH",
    "countryCode": "AU",
    "timezone": "Australia/Lord_Howe",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "LDH",
    "countryCode": "AU",
    "timezone": "Australia/Lord_Howe",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "LDI",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "LDI",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "LDJ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LDJ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LDK",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LDK",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LDM",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LDM",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LDN",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "LDN",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "LDO",
    "countryCode": "SR",
    "timezone": "America/Paramaribo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "LDO",
    "countryCode": "SR",
    "timezone": "America/Paramaribo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "LDR",
    "countryCode": "YE",
    "timezone": "Asia/Aden",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "LDR",
    "countryCode": "YE",
    "timezone": "Asia/Aden",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "LDS",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LDS",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LDU",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LDU",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LDV",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LDV",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LDW",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LDX",
    "countryCode": "GF",
    "timezone": "America/Cayenne",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "LDX",
    "countryCode": "GF",
    "timezone": "America/Cayenne",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "LDY",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "LDY",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "LDZ",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LDZ",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LEA",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LEA",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LEB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LEB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LEC",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "LEC",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "LED",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "LED",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "LEE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LEE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LEF",
    "countryCode": "LS",
    "timezone": "Africa/Maseru",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LEG",
    "countryCode": "MR",
    "timezone": "Africa/Nouakchott",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "LEH",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LEH",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LEI",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LEI",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LEJ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LEJ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LEK",
    "countryCode": "GN",
    "timezone": "Africa/Conakry",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "LEK",
    "countryCode": "GN",
    "timezone": "Africa/Conakry",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "LEL",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "LEL",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "LEM",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "LEN",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LEN",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LEO",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "LEP",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "LEP",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "LEQ",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "LEQ",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "LER",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LER",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LES",
    "countryCode": "LS",
    "timezone": "Africa/Maseru",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LES",
    "countryCode": "LS",
    "timezone": "Africa/Maseru",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LET",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "LET",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "LEU",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LEV",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "LEV",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "LEW",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LEW",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LEX",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LEX",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LEY",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LEY",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LEZ",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "LFA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LFA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LFB",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LFH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LFH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LFI",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LFI",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LFK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LFK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LFM",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "LFM",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "LFN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LFN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LFO",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "LFP",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "LFQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LFQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LFQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LFR",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "LFR",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "LFT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LFT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LFW",
    "countryCode": "TG",
    "timezone": "Africa/Lome",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "LFW",
    "countryCode": "TG",
    "timezone": "Africa/Lome",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "LGA",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LGB",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "LGB",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "LGC",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LGC",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LGD",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "LGD",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "LGE",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LGF",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "LGG",
    "countryCode": "BE",
    "timezone": "Europe/Brussels",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LGG",
    "countryCode": "BE",
    "timezone": "Europe/Brussels",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LGH",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "LGH",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "LGI",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LGI",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LGI",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LGJ",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "LGK",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LGK",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LGK",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LGL",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LGL",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LGM",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "LGN",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "LGO",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LGO",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LGP",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LGP",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LGQ",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "LGQ",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "LGR",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "LGR",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "LGS",
    "countryCode": "AR",
    "timezone": "America/Argentina/Mendoza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "LGS",
    "countryCode": "AR",
    "timezone": "America/Argentina/Mendoza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "LGT",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "LGT",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "LGU",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "LGU",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "LGV",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LGW",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "LGX",
    "countryCode": "SO",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "LGX",
    "countryCode": "SO",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "LGY",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "LGZ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LGZ",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "LGZ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LHA",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LHA",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LHB",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "LHB",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "LHC",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LHC",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LHE",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "LHE",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "LHG",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "LHG",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "LHI",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "LHI",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "LHK",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LHK",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LHN",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "LHN",
    "countryCode": "TW",
    "timezone": "Asia/Taipei",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LHN",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "LHP",
    "countryCode": "PG",
    "timezone": "Pacific/Bougainville",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "LHR",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "LHS",
    "countryCode": "AR",
    "timezone": "America/Argentina/Rio_Gallegos",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "LHS",
    "countryCode": "AR",
    "timezone": "America/Argentina/Rio_Gallegos",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "LHU",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LHU",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LHV",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LHV",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LHW",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LHW",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LIA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LIA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LIB",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "LIC",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "LIC",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "LID",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LID",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LIE",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "LIE",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "LIF",
    "countryCode": "NC",
    "timezone": "Pacific/Noumea",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "LIF",
    "countryCode": "NC",
    "timezone": "Pacific/Noumea",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "LIG",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LIG",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LIH",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "LIH",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "LIH",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "LII",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "LII",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "LIJ",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "LIJ",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "LIK",
    "countryCode": "MH",
    "timezone": "Pacific/Kwajalein",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "LIK",
    "countryCode": "MH",
    "timezone": "Pacific/Kwajalein",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "LIL",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LIL",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LIM",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "LIM",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "LIN",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LIO",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "LIO",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "LIP",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "LIP",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "LIQ",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "LIQ",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "LIR",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "LIR",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "LIS",
    "countryCode": "PT",
    "timezone": "Europe/Lisbon",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "LIS",
    "countryCode": "PT",
    "timezone": "Europe/Lisbon",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "LIT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LIT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LIU",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LIU",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LIV",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "LIW",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "LIW",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "LIX",
    "countryCode": "MW",
    "timezone": "Africa/Blantyre",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LIX",
    "countryCode": "MW",
    "timezone": "Africa/Blantyre",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LIY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LIZ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LJA",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LJA",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LJG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LJG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LJN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LJN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LJR",
    "countryCode": "SI",
    "timezone": "Europe/Ljubljana",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LJU",
    "countryCode": "SI",
    "timezone": "Europe/Ljubljana",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LJU",
    "countryCode": "SI",
    "timezone": "Europe/Ljubljana",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LKA",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LKA",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LKB",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "LKB",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "LKC",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "LKD",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "LKE",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "LKG",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "LKG",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "LKH",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LKH",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LKI",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "LKI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LKI",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "LKJ",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LKK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "LKK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "LKL",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LKL",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LKN",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LKN",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LKO",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "LKO",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "LKP",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LKP",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LKR",
    "countryCode": "SO",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "LKR",
    "countryCode": "SO",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "LKS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LKU",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "LKV",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "LKV",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "LKW",
    "countryCode": "OM",
    "timezone": "Asia/Muscat",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "LKY",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "LKY",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "LKZ",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "LKZ",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "LLA",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LLA",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LLB",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LLB",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LLC",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LLC",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LLD",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LLD",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LLE",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LLF",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LLF",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LLG",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "LLG",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "LLH",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "LLH",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "LLI",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "LLI",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "LLJ",
    "countryCode": "BD",
    "timezone": "Asia/Dhaka",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "LLJ",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "LLJ",
    "countryCode": "BD",
    "timezone": "Asia/Dhaka",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "LLJ",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "LLK",
    "countryCode": "AZ",
    "timezone": "Asia/Baku",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "LLK",
    "countryCode": "AZ",
    "timezone": "Asia/Baku",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "LLL",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LLL",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LLM",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LLM",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "LLM",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LLM",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "LLN",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "LLN",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "LLO",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LLO",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LLP",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "LLQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LLQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LLS",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "LLS",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "LLT",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "LLT",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "LLU",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "LLU",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "LLV",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LLV",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LLW",
    "countryCode": "MW",
    "timezone": "Africa/Blantyre",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LLW",
    "countryCode": "MW",
    "timezone": "Africa/Blantyre",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LLX",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LLX",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LLY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LLY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LLZ",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "LLZ",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "LMA",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "LMA",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "LMB",
    "countryCode": "MW",
    "timezone": "Africa/Blantyre",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LMB",
    "countryCode": "MW",
    "timezone": "Africa/Blantyre",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LMC",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "LMC",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "LMD",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "LMD",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "LME",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LME",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LMG",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "LMH",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "LMI",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "LMI",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "LMJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "LMK",
    "countryCode": "IE",
    "timezone": "Europe/Dublin",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "LML",
    "countryCode": "MH",
    "timezone": "Pacific/Kwajalein",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "LML",
    "countryCode": "MH",
    "timezone": "Pacific/Kwajalein",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "LMM",
    "countryCode": "MX",
    "timezone": "America/Mazatlan",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "LMM",
    "countryCode": "MX",
    "timezone": "America/Mazatlan",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "LMN",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LMN",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LMO",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "LMO",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "LMP",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LMP",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LMQ",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LMQ",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LMR",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LMR",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LMS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LMT",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "LMT",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "LMU",
    "countryCode": "ID",
    "timezone": "Asia/Pontianak",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "LMU",
    "countryCode": "ID",
    "timezone": "Asia/Pontianak",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "LMV",
    "countryCode": "MV",
    "timezone": "Indian/Maldives",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "LMX",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "LMY",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "LMY",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "LMZ",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LNA",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LNB",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "LNB",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "LNC",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "LND",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "LND",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "LNE",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "LNE",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "LNF",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "LNG",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "LNH",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "LNI",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "LNJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LNJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LNK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LNK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LNL",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LNL",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LNM",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "LNN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LNN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LNO",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LNO",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LNP",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LNP",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LNQ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "LNR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LNR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LNS",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LNS",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LNU",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LNU",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LNV",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "LNV",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "LNV",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "LNX",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "LNX",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "LNY",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "LNY",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "LNZ",
    "countryCode": "AT",
    "timezone": "Europe/Vienna",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LNZ",
    "countryCode": "AT",
    "timezone": "Europe/Vienna",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LOA",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "LOA",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "LOB",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "LOB",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "LOC",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "LOC",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "LOD",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "LOD",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "LOE",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "LOE",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "LOF",
    "countryCode": "MH",
    "timezone": "Pacific/Majuro",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "LOH",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "LOH",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "LOI",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "LOI",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "LOK",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "LOK",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "LOL",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "LOL",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "LOM",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "LOM",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "LON",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "LOO",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "LOO",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "LOP",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LOP",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LOP",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LOQ",
    "countryCode": "BW",
    "timezone": "Africa/Gaborone",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LOQ",
    "countryCode": "BW",
    "timezone": "Africa/Gaborone",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LOR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LOS",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "LOS",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "LOT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LOT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LOU",
    "countryCode": "US",
    "timezone": "America/Kentucky/Louisville",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LOV",
    "countryCode": "MX",
    "timezone": "America/Monterrey",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "LOV",
    "countryCode": "MX",
    "timezone": "America/Monterrey",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "LOW",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LOW",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LOX",
    "countryCode": "GT",
    "timezone": "America/Guatemala",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "LOY",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "LOY",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "LOZ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LOZ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LPA",
    "countryCode": "ES",
    "timezone": "Atlantic/Canary",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "LPA",
    "countryCode": "ES",
    "timezone": "Atlantic/Canary",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "LPB",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "LPB",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "LPC",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "LPC",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "LPD",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "LPD",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "LPE",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "LPE",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "LPF",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LPF",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LPG",
    "countryCode": "AR",
    "timezone": "America/Argentina/Buenos_Aires",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "LPG",
    "countryCode": "AR",
    "timezone": "America/Argentina/Buenos_Aires",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "LPI",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LPI",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LPJ",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "LPK",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "LPK",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "LPL",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "LPL",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "LPM",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "LPM",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "LPN",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "LPN",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "LPO",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LPP",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "LPP",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "LPQ",
    "countryCode": "LA",
    "timezone": "Asia/Vientiane",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "LPQ",
    "countryCode": "LA",
    "timezone": "Asia/Vientiane",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "LPS",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "LPS",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "LPT",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "LPT",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "LPU",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LPU",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LPW",
    "countryCode": "US",
    "timezone": "America/Sitka",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "LPX",
    "countryCode": "LV",
    "timezone": "Europe/Riga",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "LPX",
    "countryCode": "LV",
    "timezone": "Europe/Riga",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "LPY",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LPY",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LQK",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LQK",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LQM",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "LQM",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "LQN",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "LQN",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "LQP",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LQP",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LQQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LQQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LQS",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LQS",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LRA",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "LRB",
    "countryCode": "LS",
    "timezone": "Africa/Maseru",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LRB",
    "countryCode": "LS",
    "timezone": "Africa/Maseru",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LRD",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LRD",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LRE",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "LRE",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "LRF",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LRF",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LRG",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "LRH",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LRH",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LRI",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "LRJ",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LRJ",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LRK",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "LRL",
    "countryCode": "TG",
    "timezone": "Africa/Lome",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "LRL",
    "countryCode": "TG",
    "timezone": "Africa/Lome",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "LRM",
    "countryCode": "DO",
    "timezone": "America/Santo_Domingo",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "LRM",
    "countryCode": "DO",
    "timezone": "America/Santo_Domingo",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "LRN",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "LRO",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "LRO",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "LRQ",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LRQ",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LRR",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "LRR",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "LRS",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "LRS",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "LRT",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LRT",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LRU",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "LRU",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "LRV",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "LRV",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "LSA",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "LSA",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "LSB",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "LSC",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "LSC",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "LSD",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LSE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LSE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LSF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LSH",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "LSH",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "LSI",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "LSJ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "LSK",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "LSK",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "LSL",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "LSL",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "LSM",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LSM",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LSN",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "LSN",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "LSO",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LSO",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LSP",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "LSP",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "LSP",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "LSQ",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "LSQ",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "LSR",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "LSR",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "LSR",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "LSR",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "LSS",
    "countryCode": "GP",
    "timezone": "America/Guadeloupe",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "LSS",
    "countryCode": "GP",
    "timezone": "America/Guadeloupe",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "LST",
    "countryCode": "AU",
    "timezone": "Australia/Hobart",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "LST",
    "countryCode": "AU",
    "timezone": "Australia/Hobart",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "LSU",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LSU",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LSV",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "LSW",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "LSW",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "LSX",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "LSX",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "LSY",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "LSY",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "LSZ",
    "countryCode": "HR",
    "timezone": "Europe/Zagreb",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LSZ",
    "countryCode": "HR",
    "timezone": "Europe/Zagreb",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LTA",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LTA",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LTB",
    "countryCode": "AU",
    "timezone": "Australia/Hobart",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "LTC",
    "countryCode": "TD",
    "timezone": "Africa/Ndjamena",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "LTC",
    "countryCode": "TD",
    "timezone": "Africa/Ndjamena",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "LTD",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LTD",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LTF",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "LTG",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "LTG",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "LTH",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "LTH",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "LTI",
    "countryCode": "MN",
    "timezone": "Asia/Hovd",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "LTI",
    "countryCode": "MN",
    "timezone": "Asia/Hovd",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "LTK",
    "countryCode": "SY",
    "timezone": "Asia/Damascus",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "LTK",
    "countryCode": "SY",
    "timezone": "Asia/Damascus",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "LTL",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "LTL",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "LTM",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "LTM",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "LTN",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "LTO",
    "countryCode": "MX",
    "timezone": "America/Mazatlan",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "LTO",
    "countryCode": "MX",
    "timezone": "America/Mazatlan",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "LTP",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "LTQ",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LTQ",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LTR",
    "countryCode": "IE",
    "timezone": "Europe/Dublin",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "LTR",
    "countryCode": "IE",
    "timezone": "Europe/Dublin",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "LTS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LTS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LTT",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LTT",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LTU",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "LTU",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "LTV",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "LTW",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LTW",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LTX",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "LTX",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "LTZ",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LUA",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "LUA",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "LUB",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "LUB",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "LUC",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "LUC",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "LUD",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LUD",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LUE",
    "countryCode": "SK",
    "timezone": "Europe/Bratislava",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LUF",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "LUG",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LUG",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LUH",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "LUH",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "LUI",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "LUI",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "LUJ",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LUK",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LUL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LUL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LUM",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LUM",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LUN",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LUN",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LUO",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "LUO",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "LUP",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "LUP",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "LUQ",
    "countryCode": "AR",
    "timezone": "America/Argentina/San_Luis",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "LUQ",
    "countryCode": "AR",
    "timezone": "America/Argentina/San_Luis",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "LUR",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "LUS",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "LUS",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "LUT",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "LUU",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "LUU",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "LUV",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "LUV",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "LUW",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LUW",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LUX",
    "countryCode": "LU",
    "timezone": "Europe/Luxembourg",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LUX",
    "countryCode": "LU",
    "timezone": "Europe/Luxembourg",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LUY",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LUY",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LUZ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LUZ",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LUZ",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LVA",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LVA",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LVB",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "LVB",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "LVD",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "LVI",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LVI",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LVK",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "LVK",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "LVL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LVL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LVM",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "LVM",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "LVO",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LVO",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LVP",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "LVP",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "LVR",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "LVR",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "LVS",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "LVS",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "LWA",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LWB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LWB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LWC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LWC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LWE",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LWE",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LWG",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LWH",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "LWI",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "LWK",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "LWL",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "LWL",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "LWM",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LWM",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LWN",
    "countryCode": "AM",
    "timezone": "Asia/Yerevan",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "LWN",
    "countryCode": "AM",
    "timezone": "Asia/Yerevan",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "LWO",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "LWO",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "LWR",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LWR",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LWS",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "LWS",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "LWT",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "LWT",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "LWV",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LWV",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LWY",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LWY",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LXA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LXA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LXC",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "LXG",
    "countryCode": "LA",
    "timezone": "Asia/Vientiane",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "LXG",
    "countryCode": "LA",
    "timezone": "Asia/Vientiane",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "LXI",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LXL",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "LXN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LXN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LXR",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "LXR",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "LXS",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "LXS",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "LXU",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LXU",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LXV",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "LXV",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "LYA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LYA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LYB",
    "countryCode": "KY",
    "timezone": "America/Cayman",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "LYB",
    "countryCode": "KY",
    "timezone": "America/Cayman",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "LYC",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LYC",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LYE",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "LYE",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "LYG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LYG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LYH",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LYH",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LYI",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LYI",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LYK",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LYN",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LYO",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LYO",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LYP",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "LYP",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "LYR",
    "countryCode": "SJ",
    "timezone": "Arctic/Longyearbyen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LYR",
    "countryCode": "SJ",
    "timezone": "Arctic/Longyearbyen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LYS",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LYS",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LYT",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "LYU",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LYU",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "LYX",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "LYX",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "LZA",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LZA",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "LZB",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LZC",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "LZC",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "LZD",
    "countryCode": "CN",
    "timezone": "Asia/Chongqing",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LZD",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LZG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LZG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LZH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LZH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LZI",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "LZI",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "LZM",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "LZM",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "LZN",
    "countryCode": "TW",
    "timezone": "Asia/Taipei",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LZN",
    "countryCode": "TW",
    "timezone": "Asia/Taipei",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LZO",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LZO",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LZO",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LZO",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LZO",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LZR",
    "countryCode": "AU",
    "timezone": "Australia/Lindeman",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "LZR",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "LZS",
    "countryCode": "AT",
    "timezone": "Europe/Vienna",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "LZU",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LZU",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "LZX",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LZY",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "LZY",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MAA",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "MAA",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "MAB",
    "countryCode": "BR",
    "timezone": "America/Araguaina",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MAB",
    "countryCode": "BR",
    "timezone": "America/Belem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MAC",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MAD",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MAD",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MAE",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "MAE",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "MAF",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MAF",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MAG",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MAG",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MAH",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MAH",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MAH",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MAI",
    "countryCode": "MW",
    "timezone": "Africa/Blantyre",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MAJ",
    "countryCode": "MH",
    "timezone": "Pacific/Majuro",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "MAJ",
    "countryCode": "MH",
    "timezone": "Pacific/Majuro",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "MAK",
    "countryCode": "SS",
    "timezone": "Africa/Juba",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MAK",
    "countryCode": "SS",
    "timezone": "Africa/Juba",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MAL",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MAL",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MAM",
    "countryCode": "MX",
    "timezone": "America/Matamoros",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MAM",
    "countryCode": "MX",
    "timezone": "America/Matamoros",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MAN",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "MAN",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "MAO",
    "countryCode": "BR",
    "timezone": "America/Manaus",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "MAO",
    "countryCode": "BR",
    "timezone": "America/Manaus",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "MAP",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MAP",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MAQ",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "MAQ",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "MAR",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "MAR",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "MAS",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MAS",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MAT",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MAT",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MAU",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "MAU",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "MAV",
    "countryCode": "MH",
    "timezone": "Pacific/Majuro",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "MAV",
    "countryCode": "MH",
    "timezone": "Pacific/Majuro",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "MAW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MAW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MAX",
    "countryCode": "SN",
    "timezone": "Africa/Dakar",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "MAX",
    "countryCode": "SN",
    "timezone": "Africa/Dakar",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "MAY",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MAY",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MAZ",
    "countryCode": "PR",
    "timezone": "America/Puerto_Rico",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "MAZ",
    "countryCode": "PR",
    "timezone": "America/Puerto_Rico",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "MBA",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MBA",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MBB",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MBB",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MBC",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MBC",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MBD",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MBD",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MBE",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MBE",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MBF",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "MBG",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MBG",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MBH",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MBH",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MBI",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MBI",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MBJ",
    "countryCode": "JM",
    "timezone": "America/Jamaica",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MBJ",
    "countryCode": "JM",
    "timezone": "America/Jamaica",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MBK",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "MBK",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "MBL",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MBL",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MBM",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MBM",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MBN",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MBO",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MBO",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MBP",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MBP",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MBQ",
    "countryCode": "UG",
    "timezone": "Africa/Kampala",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MBQ",
    "countryCode": "UG",
    "timezone": "Africa/Kampala",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MBR",
    "countryCode": "MR",
    "timezone": "Africa/Nouakchott",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "MBR",
    "countryCode": "MR",
    "timezone": "Africa/Nouakchott",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "MBS",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MBS",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MBT",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MBT",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MBU",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "MBU",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "MBV",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MBW",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "MBW",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "MBX",
    "countryCode": "SI",
    "timezone": "Europe/Ljubljana",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MBX",
    "countryCode": "SI",
    "timezone": "Europe/Ljubljana",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MBY",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MBZ",
    "countryCode": "BR",
    "timezone": "America/Manaus",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "MBZ",
    "countryCode": "BR",
    "timezone": "America/Manaus",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "MCA",
    "countryCode": "GN",
    "timezone": "Africa/Conakry",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "MCA",
    "countryCode": "GN",
    "timezone": "Africa/Conakry",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "MCB",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MCB",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MCC",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "MCD",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MCD",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MCE",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "MCE",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "MCF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MCG",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "MCG",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "MCH",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MCH",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MCI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MCJ",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MCJ",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MCK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MCK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MCL",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "MCL",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "MCM",
    "countryCode": "MC",
    "timezone": "Europe/Monaco",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MCM",
    "countryCode": "MC",
    "timezone": "Europe/Monaco",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MCN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MCN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MCO",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MCP",
    "countryCode": "BR",
    "timezone": "America/Belem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MCP",
    "countryCode": "BR",
    "timezone": "America/Belem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MCQ",
    "countryCode": "HU",
    "timezone": "Europe/Budapest",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MCQ",
    "countryCode": "HU",
    "timezone": "Europe/Budapest",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MCR",
    "countryCode": "GT",
    "timezone": "America/Guatemala",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "MCR",
    "countryCode": "SG",
    "timezone": "Asia/Singapore",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MCS",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MCS",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MCT",
    "countryCode": "OM",
    "timezone": "Asia/Muscat",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "MCT",
    "countryCode": "OM",
    "timezone": "Asia/Muscat",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "MCU",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MCU",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MCV",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MCV",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MCW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MCW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MCX",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MCX",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MCY",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MCY",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MCY",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MCZ",
    "countryCode": "BR",
    "timezone": "America/Maceio",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MCZ",
    "countryCode": "BR",
    "timezone": "America/Maceio",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MDA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MDB",
    "countryCode": "BZ",
    "timezone": "America/Belize",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "MDC",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MDC",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MDD",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MDE",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MDE",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MDF",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MDF",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MDG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MDG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MDH",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MDH",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MDI",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MDI",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MDJ",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "MDJ",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "MDK",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MDK",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MDL",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "MDL",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "MDM",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MDN",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MDN",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MDO",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "MDP",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MDP",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MDQ",
    "countryCode": "AR",
    "timezone": "America/Argentina/Buenos_Aires",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MDQ",
    "countryCode": "AR",
    "timezone": "America/Argentina/Buenos_Aires",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MDR",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "MDR",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MDR",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "MDS",
    "countryCode": "TC",
    "timezone": "America/Grand_Turk",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MDS",
    "countryCode": "TC",
    "timezone": "America/Grand_Turk",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MDT",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MDU",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MDU",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MDV",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "MDW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MDX",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MDX",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MDY",
    "countryCode": "UM",
    "timezone": "Pacific/Midway",
    "offset": {
      "gmt": -11,
      "dst": -11
    }
  },
  {
    "code": "MDY",
    "countryCode": "UM",
    "timezone": "Pacific/Midway",
    "offset": {
      "gmt": -11,
      "dst": -11
    }
  },
  {
    "code": "MDZ",
    "countryCode": "AR",
    "timezone": "America/Argentina/Mendoza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MDZ",
    "countryCode": "AR",
    "timezone": "America/Argentina/Mendoza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MEA",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MEA",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MEB",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "MEC",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MEC",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MED",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MED",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MEE",
    "countryCode": "NC",
    "timezone": "Pacific/Noumea",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "MEE",
    "countryCode": "NC",
    "timezone": "Pacific/Noumea",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "MEF",
    "countryCode": "TD",
    "timezone": "Africa/Ndjamena",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MEG",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MEG",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MEH",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MEH",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MEI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MEI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MEJ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MEJ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MEK",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MEK",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MEL",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "MEL",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "MEM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MEM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MEN",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MEN",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MEO",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MEO",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MEP",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MEP",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MEQ",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "MEQ",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "MER",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "MES",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "MES",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "MET",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MEU",
    "countryCode": "BR",
    "timezone": "America/Santarem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MEU",
    "countryCode": "BR",
    "timezone": "America/Santarem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MEV",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "MEV",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "MEW",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MEW",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MEX",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "MEX",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "MEY",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "MEY",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "MEZ",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MEZ",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MFA",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MFA",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MFB",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MFC",
    "countryCode": "LS",
    "timezone": "Africa/Maseru",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MFC",
    "countryCode": "LS",
    "timezone": "Africa/Maseru",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MFD",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MFD",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MFE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MFE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MFF",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MFF",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MFG",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "MFG",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "MFH",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "MFH",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "MFI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MFI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MFJ",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "MFJ",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "MFK",
    "countryCode": "TW",
    "timezone": "Asia/Taipei",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MFK",
    "countryCode": "TW",
    "timezone": "Asia/Taipei",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MFL",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MFM",
    "countryCode": "MO",
    "timezone": "Asia/Macau",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MFM",
    "countryCode": "MO",
    "timezone": "Asia/Macau",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MFN",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "MFN",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "MFO",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MFO",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MFP",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MFQ",
    "countryCode": "NE",
    "timezone": "Africa/Niamey",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MFQ",
    "countryCode": "NE",
    "timezone": "Africa/Niamey",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MFR",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "MFR",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "MFS",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MFS",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MFT",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MFT",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MFU",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MFU",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MFV",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MFV",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MFW",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MFW",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MFX",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MFX",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MFY",
    "countryCode": "YE",
    "timezone": "Asia/Aden",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MFZ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MFZ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MGA",
    "countryCode": "NI",
    "timezone": "America/Managua",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "MGA",
    "countryCode": "NI",
    "timezone": "America/Managua",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "MGB",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "MGB",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "MGC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MGC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MGD",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "MGD",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "MGE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MGE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MGF",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MGF",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MGG",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MGG",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MGH",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MGH",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MGI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "MGJ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MGJ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MGK",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "MGK",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "MGL",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MGL",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MGM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MGM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MGN",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MGN",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MGO",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MGP",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MGQ",
    "countryCode": "SO",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MGQ",
    "countryCode": "SO",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MGR",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MGR",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MGS",
    "countryCode": "CK",
    "timezone": "Pacific/Rarotonga",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "MGT",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MGT",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MGU",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "MGU",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "MGV",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MGW",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MGW",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MGX",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MGX",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MGY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MGZ",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "MGZ",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "MHA",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "MHA",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "MHB",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "MHC",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "MHC",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "MHD",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MHD",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MHE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MHE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MHF",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MHG",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MHG",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MHH",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MHH",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MHI",
    "countryCode": "DJ",
    "timezone": "Africa/Djibouti",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MHI",
    "countryCode": "DJ",
    "timezone": "Africa/Djibouti",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MHJ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MHK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MHK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MHL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MHL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MHM",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "MHM",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "MHM",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "MHM",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "MHN",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "MHN",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "MHO",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MHP",
    "countryCode": "BY",
    "timezone": "Europe/Minsk",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MHQ",
    "countryCode": "AX",
    "timezone": "Europe/Mariehamn",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "MHQ",
    "countryCode": "AX",
    "timezone": "Europe/Mariehamn",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "MHR",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "MHS",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "MHS",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "MHT",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MHT",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MHU",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "MHU",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "MHV",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "MHV",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "MHW",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "MHW",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "MHX",
    "countryCode": "CK",
    "timezone": "Pacific/Rarotonga",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "MHY",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MHY",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MHZ",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "MHZ",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "MIA",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MIA",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MIB",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MIC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MID",
    "countryCode": "MX",
    "timezone": "America/Merida",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "MID",
    "countryCode": "MX",
    "timezone": "America/Merida",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "MIE",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MIE",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MIF",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MIG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MIG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MIH",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MII",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MII",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MIJ",
    "countryCode": "MH",
    "timezone": "Pacific/Majuro",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "MIK",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "MIK",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "MIL",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MIM",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "MIM",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "MIN",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "MIN",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "MIO",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MIO",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MIP",
    "countryCode": "IL",
    "timezone": "Asia/Jerusalem",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "MIP",
    "countryCode": "IL",
    "timezone": "Asia/Jerusalem",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "MIQ",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MIR",
    "countryCode": "TN",
    "timezone": "Africa/Tunis",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MIR",
    "countryCode": "TN",
    "timezone": "Africa/Tunis",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MIS",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MIS",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MIT",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "MIT",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "MIU",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MIU",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MIV",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MIV",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MIW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MIW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MIX",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MIY",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MIY",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MIZ",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MIZ",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MJA",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MJA",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MJB",
    "countryCode": "MH",
    "timezone": "Pacific/Majuro",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "MJB",
    "countryCode": "MH",
    "timezone": "Pacific/Majuro",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "MJC",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "MJC",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "MJD",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "MJD",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "MJE",
    "countryCode": "MH",
    "timezone": "Pacific/Kwajalein",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "MJE",
    "countryCode": "MH",
    "timezone": "Pacific/Majuro",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "MJF",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MJF",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MJG",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MJG",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MJH",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MJH",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MJI",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MJJ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MJK",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MJK",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MJL",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MJL",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MJM",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MJM",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MJN",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MJN",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MJO",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MJO",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MJP",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MJP",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MJQ",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MJQ",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MJR",
    "countryCode": "AR",
    "timezone": "America/Argentina/Buenos_Aires",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MJR",
    "countryCode": "AR",
    "timezone": "America/Argentina/Buenos_Aires",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MJS",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MJS",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MJS",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MJT",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "MJT",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "MJU",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MJU",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MJV",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MJV",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MJV",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MJW",
    "countryCode": "ZW",
    "timezone": "Africa/Harare",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MJX",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MJX",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MJY",
    "countryCode": "RU",
    "timezone": "Asia/Krasnoyarsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "MJY",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "MJY",
    "countryCode": "RU",
    "timezone": "Asia/Krasnoyarsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "MJZ",
    "countryCode": "RU",
    "timezone": "Asia/Yakutsk",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MJZ",
    "countryCode": "RU",
    "timezone": "Asia/Yakutsk",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MKA",
    "countryCode": "CZ",
    "timezone": "Europe/Prague",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MKB",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MKC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MKC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MKD",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MKD",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MKE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MKE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MKF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MKF",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MKG",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MKG",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MKH",
    "countryCode": "LS",
    "timezone": "Africa/Maseru",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MKH",
    "countryCode": "LS",
    "timezone": "Africa/Maseru",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MKI",
    "countryCode": "CF",
    "timezone": "Africa/Bangui",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MKI",
    "countryCode": "CF",
    "timezone": "Africa/Bangui",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MKJ",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MKJ",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MKK",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "MKK",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "MKL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MKL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MKM",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MKM",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MKN",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MKO",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MKO",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MKP",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "MKP",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "MKQ",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MKQ",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MKR",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MKR",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MKS",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MKS",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MKT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MKT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MKU",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MKU",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MKV",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MKW",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MKW",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MKX",
    "countryCode": "YE",
    "timezone": "Asia/Aden",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MKY",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MKY",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MKZ",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MKZ",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MLA",
    "countryCode": "MT",
    "timezone": "Europe/Malta",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MLA",
    "countryCode": "MT",
    "timezone": "Europe/Malta",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MLA",
    "countryCode": "MT",
    "timezone": "Europe/Malta",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MLA",
    "countryCode": "MT",
    "timezone": "Europe/Malta",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MLA",
    "countryCode": "MT",
    "timezone": "Europe/Malta",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MLB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MLB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MLC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MLC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MLD",
    "countryCode": "US",
    "timezone": "America/Boise",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "MLE",
    "countryCode": "MV",
    "timezone": "Indian/Maldives",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "MLE",
    "countryCode": "MV",
    "timezone": "Indian/Maldives",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "MLF",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "MLF",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "MLG",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "MLG",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "MLH",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MLH",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MLI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MLI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MLJ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MLJ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MLK",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "MLK",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "MLL",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "MLL",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "MLM",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "MLM",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "MLN",
    "countryCode": "ES",
    "timezone": "Africa/Ceuta",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MLN",
    "countryCode": "ES",
    "timezone": "Africa/Ceuta",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MLO",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "MLO",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "MLP",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MLP",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MLQ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MLR",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "MLR",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "MLS",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "MLS",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "MLT",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MLT",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MLU",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MLU",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MLV",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MLW",
    "countryCode": "LR",
    "timezone": "Africa/Monrovia",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "MLW",
    "countryCode": "LR",
    "timezone": "Africa/Monrovia",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "MLX",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MLX",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MLY",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "MLY",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "MLZ",
    "countryCode": "UY",
    "timezone": "America/Montevideo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MLZ",
    "countryCode": "UY",
    "timezone": "America/Montevideo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MMA",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MMB",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MMB",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MMB",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MMC",
    "countryCode": "MX",
    "timezone": "America/Monterrey",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "MMC",
    "countryCode": "MX",
    "timezone": "America/Monterrey",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "MMD",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MMD",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MME",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "MME",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "MMF",
    "countryCode": "CM",
    "timezone": "Africa/Douala",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MMF",
    "countryCode": "CM",
    "timezone": "Africa/Douala",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MMG",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MMG",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MMH",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "MMH",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "MMI",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MMI",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MMJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MMJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MMK",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MMK",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MML",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MML",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MMM",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MMM",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MMN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MMN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MMO",
    "countryCode": "CV",
    "timezone": "Atlantic/Cape_Verde",
    "offset": {
      "gmt": -1,
      "dst": -1
    }
  },
  {
    "code": "MMO",
    "countryCode": "CV",
    "timezone": "Atlantic/Cape_Verde",
    "offset": {
      "gmt": -1,
      "dst": -1
    }
  },
  {
    "code": "MMP",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MMQ",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MMQ",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MMR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "MMS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MMS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MMT",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MMU",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MMU",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MMV",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MMV",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MMW",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MMX",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MMY",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MMY",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MMZ",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "MMZ",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "MNA",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MNA",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MNB",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MNB",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MNC",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MNC",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MND",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MNE",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "MNE",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "MNF",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "MNF",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "MNG",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MNG",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MNH",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "MNH",
    "countryCode": "OM",
    "timezone": "Asia/Muscat",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "MNH",
    "countryCode": "OM",
    "timezone": "Asia/Muscat",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "MNH",
    "countryCode": "OM",
    "timezone": "Asia/Muscat",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "MNH",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "MNI",
    "countryCode": "MS",
    "timezone": "America/Montserrat",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "MNI",
    "countryCode": "MS",
    "timezone": "America/Montserrat",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "MNJ",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MNJ",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MNK",
    "countryCode": "KI",
    "timezone": "Pacific/Tarawa",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "MNK",
    "countryCode": "KI",
    "timezone": "Pacific/Tarawa",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "MNL",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MNL",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MNM",
    "countryCode": "US",
    "timezone": "America/Menominee",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MNM",
    "countryCode": "US",
    "timezone": "America/Menominee",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MNN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MNN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MNO",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MNO",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MNP",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MNP",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MNQ",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MNQ",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MNR",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MNR",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MNS",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MNS",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MNT",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "MNT",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "MNU",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "MNU",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "MNV",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MNW",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MNW",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MNX",
    "countryCode": "BR",
    "timezone": "America/Manaus",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "MNX",
    "countryCode": "BR",
    "timezone": "America/Manaus",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "MNY",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "MNY",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "MNZ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MNZ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MOA",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MOA",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MOB",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MOB",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MOC",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MOC",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MOD",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "MOD",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "MOE",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "MOF",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MOF",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MOG",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "MOG",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "MOH",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "MOH",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MOH",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MOI",
    "countryCode": "CK",
    "timezone": "Pacific/Rarotonga",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "MOI",
    "countryCode": "CK",
    "timezone": "Pacific/Rarotonga",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "MOJ",
    "countryCode": "SR",
    "timezone": "America/Paramaribo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MOJ",
    "countryCode": "SR",
    "timezone": "America/Paramaribo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MOK",
    "countryCode": "UZ",
    "timezone": "Asia/Samarkand",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "MOK",
    "countryCode": "UZ",
    "timezone": "Asia/Samarkand",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "MOL",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MOL",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MOL",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MOM",
    "countryCode": "MR",
    "timezone": "Africa/Nouakchott",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "MOM",
    "countryCode": "MR",
    "timezone": "Africa/Nouakchott",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "MON",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "MON",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "MOO",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "MOO",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "MOP",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MOP",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MOQ",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MOQ",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MOR",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MOR",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MOS",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "MOT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MOT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MOU",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "MOU",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "MOV",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MOV",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MOW",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MOX",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MOX",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MOY",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MOY",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MOZ",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "MOZ",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "MPA",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MPA",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MPB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MPB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MPC",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "MPD",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "MPD",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "MPD",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "MPE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MPE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MPF",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MPG",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MPH",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MPH",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MPI",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MPJ",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MPJ",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MPK",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MPL",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MPL",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MPM",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MPM",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MPN",
    "countryCode": "FK",
    "timezone": "Atlantic/Stanley",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MPN",
    "countryCode": "FK",
    "timezone": "Atlantic/Stanley",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MPO",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MPO",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MPP",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MPP",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MPQ",
    "countryCode": "JO",
    "timezone": "Asia/Amman",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MPR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MPS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MPS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MPT",
    "countryCode": "TL",
    "timezone": "Asia/Dili",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MPU",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MPV",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MPV",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MPV",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MPW",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "MPW",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "MPX",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MPY",
    "countryCode": "GF",
    "timezone": "America/Cayenne",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MPY",
    "countryCode": "GF",
    "timezone": "America/Cayenne",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MPZ",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MPZ",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MQA",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MQA",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MQB",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MQB",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MQC",
    "countryCode": "PM",
    "timezone": "America/Miquelon",
    "offset": {
      "gmt": -3,
      "dst": -2
    }
  },
  {
    "code": "MQC",
    "countryCode": "PM",
    "timezone": "America/Miquelon",
    "offset": {
      "gmt": -3,
      "dst": -2
    }
  },
  {
    "code": "MQD",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MQD",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MQE",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MQF",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "MQF",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "MQG",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MQG",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MQH",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MQI",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MQJ",
    "countryCode": "RU",
    "timezone": "Asia/Magadan",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "MQJ",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "MQJ",
    "countryCode": "RU",
    "timezone": "Asia/Magadan",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "MQK",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "MQL",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "MQL",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "MQM",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MQM",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MQN",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MQN",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MQO",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MQP",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MQQ",
    "countryCode": "TD",
    "timezone": "Africa/Ndjamena",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MQQ",
    "countryCode": "TD",
    "timezone": "Africa/Ndjamena",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MQR",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MQS",
    "countryCode": "VC",
    "timezone": "America/St_Vincent",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "MQS",
    "countryCode": "VC",
    "timezone": "America/St_Vincent",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "MQT",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MQT",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MQU",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MQU",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MQV",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MQV",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MQW",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MQX",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MQX",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MQY",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MQY",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MQZ",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MQZ",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MRA",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MRA",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MRB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MRB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MRC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MRC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MRC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MRD",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "MRD",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "MRE",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MRE",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MRF",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MRF",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MRG",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MRG",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MRH",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MRI",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "MRJ",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "MRK",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MRK",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MRL",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MRM",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MRN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MRN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MRO",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "MRO",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "MRP",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "MRP",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "MRQ",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MRQ",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MRR",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MRR",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MRS",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MRS",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MRT",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MRU",
    "countryCode": "MU",
    "timezone": "Indian/Mauritius",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "MRU",
    "countryCode": "MU",
    "timezone": "Indian/Mauritius",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "MRV",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MRV",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MRW",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MRW",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MRX",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MRX",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MRY",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "MRY",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "MRZ",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "MRZ",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "MSA",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MSA",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MSB",
    "countryCode": "MF",
    "timezone": "America/Marigot",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "MSB",
    "countryCode": "SI",
    "timezone": "Europe/Ljubljana",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MSC",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "MSC",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "MSD",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "MSD",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "MSE",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "MSE",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "MSF",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MSG",
    "countryCode": "LS",
    "timezone": "Africa/Maseru",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MSH",
    "countryCode": "OM",
    "timezone": "Asia/Muscat",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "MSH",
    "countryCode": "OM",
    "timezone": "Asia/Muscat",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "MSI",
    "countryCode": "ID",
    "timezone": "Asia/Pontianak",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "MSJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MSJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MSK",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MSK",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MSK",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MSK",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MSL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MSL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MSM",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MSM",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MSN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MSN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MSO",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "MSO",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "MSP",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MSP",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MSQ",
    "countryCode": "BY",
    "timezone": "Europe/Minsk",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MSQ",
    "countryCode": "BY",
    "timezone": "Europe/Minsk",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MSR",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MSR",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MSS",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MSS",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MST",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MST",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MSU",
    "countryCode": "LS",
    "timezone": "Africa/Maseru",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MSU",
    "countryCode": "LS",
    "timezone": "Africa/Maseru",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MSV",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MSV",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MSW",
    "countryCode": "ER",
    "timezone": "Africa/Asmara",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MSW",
    "countryCode": "ER",
    "timezone": "Africa/Asmara",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MSX",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MSX",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MSY",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MSY",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MSZ",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MSZ",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MTA",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "MTA",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "MTB",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MTB",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MTC",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MTC",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MTD",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MTE",
    "countryCode": "BR",
    "timezone": "America/Santarem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MTE",
    "countryCode": "BR",
    "timezone": "America/Santarem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MTF",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MTF",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MTG",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "MTH",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MTH",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MTI",
    "countryCode": "CV",
    "timezone": "Atlantic/Cape_Verde",
    "offset": {
      "gmt": -1,
      "dst": -1
    }
  },
  {
    "code": "MTJ",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "MTJ",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "MTK",
    "countryCode": "KI",
    "timezone": "Pacific/Tarawa",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "MTK",
    "countryCode": "KI",
    "timezone": "Pacific/Tarawa",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "MTL",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "MTL",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "MTM",
    "countryCode": "US",
    "timezone": "America/Metlakatla",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "MTM",
    "countryCode": "US",
    "timezone": "America/Metlakatla",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "MTN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MTO",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MTP",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MTP",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MTQ",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MTQ",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MTR",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MTR",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MTS",
    "countryCode": "SZ",
    "timezone": "Africa/Mbabane",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MTS",
    "countryCode": "SZ",
    "timezone": "Africa/Mbabane",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MTS",
    "countryCode": "SZ",
    "timezone": "Africa/Mbabane",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MTT",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "MTT",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "MTU",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MTU",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MTV",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "MTV",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "MTW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MTW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MTX",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "MTY",
    "countryCode": "MX",
    "timezone": "America/Monterrey",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "MTY",
    "countryCode": "MX",
    "timezone": "America/Monterrey",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "MTZ",
    "countryCode": "IL",
    "timezone": "Asia/Jerusalem",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "MTZ",
    "countryCode": "IL",
    "timezone": "Asia/Jerusalem",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "MUA",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "MUA",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "MUB",
    "countryCode": "BW",
    "timezone": "Africa/Gaborone",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MUB",
    "countryCode": "BW",
    "timezone": "Africa/Gaborone",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MUC",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MUC",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MUD",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MUD",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MUE",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "MUE",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "MUF",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MUF",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MUG",
    "countryCode": "MX",
    "timezone": "America/Mazatlan",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "MUG",
    "countryCode": "MX",
    "timezone": "America/Mazatlan",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "MUH",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "MUH",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "MUI",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MUI",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MUJ",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MUK",
    "countryCode": "CK",
    "timezone": "Pacific/Rarotonga",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "MUK",
    "countryCode": "CK",
    "timezone": "Pacific/Rarotonga",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "MUL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MUM",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MUN",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "MUN",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "MUO",
    "countryCode": "US",
    "timezone": "America/Boise",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "MUO",
    "countryCode": "US",
    "timezone": "America/Boise",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "MUP",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MUQ",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MUR",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MUR",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MUS",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MUS",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MUT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MUT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MUU",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MUU",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MUV",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "MUW",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MUW",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MUX",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "MUX",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "MUY",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MUY",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MUZ",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MUZ",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MVA",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "MVA",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "MVB",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MVB",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MVC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MVC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MVD",
    "countryCode": "UY",
    "timezone": "America/Montevideo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MVD",
    "countryCode": "UY",
    "timezone": "America/Montevideo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MVE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MVE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MVF",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MVF",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MVG",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MVH",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "MVI",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MVI",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MVJ",
    "countryCode": "JM",
    "timezone": "America/Jamaica",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MVJ",
    "countryCode": "JM",
    "timezone": "America/Jamaica",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MVK",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "MVK",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "MVL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MVL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MVM",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "MVM",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "MVN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MVN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MVO",
    "countryCode": "TD",
    "timezone": "Africa/Ndjamena",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MVO",
    "countryCode": "TD",
    "timezone": "Africa/Ndjamena",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MVP",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MVP",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MVQ",
    "countryCode": "BY",
    "timezone": "Europe/Minsk",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MVQ",
    "countryCode": "BY",
    "timezone": "Europe/Minsk",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MVR",
    "countryCode": "CM",
    "timezone": "Africa/Douala",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MVR",
    "countryCode": "CM",
    "timezone": "Africa/Douala",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MVS",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MVT",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "MVT",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "MVU",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MVU",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MVV",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MVV",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MVW",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "MVW",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "MVX",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MVX",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MVY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MVY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MVY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MVZ",
    "countryCode": "ZW",
    "timezone": "Africa/Harare",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MVZ",
    "countryCode": "ZW",
    "timezone": "Africa/Harare",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MWA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MWA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MWB",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MWB",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MWC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MWD",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "MWD",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "MWE",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MWE",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MWF",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "MWF",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "MWG",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MWG",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MWH",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "MWH",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "MWI",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MWJ",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "MWJ",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "MWK",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "MWK",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "MWL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MWL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MWM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MWN",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MWN",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MWO",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MWO",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MWP",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "MWQ",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "MWQ",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "MWR",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MWR",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MWS",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MWS",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "MWS",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "MWT",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "MWT",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "MWU",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MWV",
    "countryCode": "KH",
    "timezone": "Asia/Phnom_Penh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "MWW",
    "countryCode": "BE",
    "timezone": "Europe/Brussels",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MWX",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MWX",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MWY",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MWZ",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MWZ",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MXA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MXB",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MXB",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MXC",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "MXC",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "MXD",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MXD",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MXE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MXE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MXF",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MXG",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MXH",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MXH",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MXI",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MXI",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MXJ",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MXJ",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MXK",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MXL",
    "countryCode": "MX",
    "timezone": "America/Tijuana",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "MXL",
    "countryCode": "MX",
    "timezone": "America/Tijuana",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "MXM",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MXM",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MXN",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MXN",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MXO",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MXO",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "MXP",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MXQ",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MXQ",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MXQ",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "MXR",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "MXR",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "MXS",
    "countryCode": "WS",
    "timezone": "Pacific/Apia",
    "offset": {
      "gmt": 13,
      "dst": 13
    }
  },
  {
    "code": "MXT",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MXT",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MXU",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MXU",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MXV",
    "countryCode": "MN",
    "timezone": "Asia/Ulaanbaatar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MXV",
    "countryCode": "MN",
    "timezone": "Asia/Ulaanbaatar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MXW",
    "countryCode": "MN",
    "timezone": "Asia/Ulaanbaatar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MXW",
    "countryCode": "MN",
    "timezone": "Asia/Ulaanbaatar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MXX",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MXX",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MXY",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "MXY",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "MXZ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MXZ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MYA",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "MYA",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "MYB",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MYB",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MYC",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "MYC",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "MYD",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MYD",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MYE",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MYE",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MYF",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "MYG",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MYG",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MYH",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "MYI",
    "countryCode": "AU",
    "timezone": "Australia/Lindeman",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MYI",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MYJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MYJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "MYK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "MYK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "MYL",
    "countryCode": "US",
    "timezone": "America/Boise",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "MYL",
    "countryCode": "US",
    "timezone": "America/Boise",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "MYM",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "MYM",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "MYN",
    "countryCode": "YE",
    "timezone": "Asia/Aden",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MYN",
    "countryCode": "YE",
    "timezone": "Asia/Aden",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MYO",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MYP",
    "countryCode": "TM",
    "timezone": "Asia/Ashgabat",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "MYP",
    "countryCode": "TM",
    "timezone": "Asia/Ashgabat",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "MYQ",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "MYQ",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "MYR",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MYR",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MYT",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "MYT",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "MYU",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "MYU",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "MYV",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "MYV",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "MYW",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MYW",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MYX",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MYY",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MYY",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MYZ",
    "countryCode": "MW",
    "timezone": "Africa/Blantyre",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MYZ",
    "countryCode": "MW",
    "timezone": "Africa/Blantyre",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MZA",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "MZA",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MZA",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MZB",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MZB",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MZC",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MZC",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MZD",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MZD",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MZE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MZE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MZF",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MZG",
    "countryCode": "TW",
    "timezone": "Asia/Taipei",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MZG",
    "countryCode": "TW",
    "timezone": "Asia/Taipei",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MZG",
    "countryCode": "TW",
    "timezone": "Asia/Taipei",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MZH",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MZH",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MZI",
    "countryCode": "ML",
    "timezone": "Africa/Bamako",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "MZI",
    "countryCode": "ML",
    "timezone": "Africa/Bamako",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "MZJ",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "MZJ",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "MZK",
    "countryCode": "KI",
    "timezone": "Pacific/Tarawa",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "MZK",
    "countryCode": "KI",
    "timezone": "Pacific/Tarawa",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "MZL",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MZL",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "MZM",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "MZN",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MZN",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "MZO",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MZO",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MZP",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "MZQ",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MZQ",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MZR",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "MZR",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "MZS",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "MZS",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MZS",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "MZT",
    "countryCode": "MX",
    "timezone": "America/Mazatlan",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "MZT",
    "countryCode": "MX",
    "timezone": "America/Mazatlan",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "MZU",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "MZU",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "MZV",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MZV",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "MZW",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MZW",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "MZX",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "MZY",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MZY",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "MZZ",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "MZZ",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "NAA",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "NAA",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "NAB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "NAC",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "NAC",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "NAD",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "NAE",
    "countryCode": "BJ",
    "timezone": "Africa/Porto-Novo",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "NAE",
    "countryCode": "BJ",
    "timezone": "Africa/Porto-Novo",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "NAF",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NAG",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "NAG",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "NAH",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NAH",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NAI",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "NAI",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "NAJ",
    "countryCode": "AZ",
    "timezone": "Asia/Baku",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "NAJ",
    "countryCode": "AZ",
    "timezone": "Asia/Baku",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "NAK",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "NAK",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "NAL",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NAL",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NAM",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "NAM",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "NAN",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "NAN",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "NAO",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NAO",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NAP",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "NAP",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "NAQ",
    "countryCode": "GL",
    "timezone": "America/Thule",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "NAQ",
    "countryCode": "GL",
    "timezone": "America/Thule",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "NAR",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "NAR",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "NAS",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "NAS",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "NAT",
    "countryCode": "BR",
    "timezone": "America/Recife",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "NAT",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "NAT",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "NAU",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "NAU",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "NAV",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NAV",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NAW",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "NAW",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "NAX",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "NAY",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NAZ",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "NBA",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "NBB",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "NBB",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "NBC",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NBC",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NBE",
    "countryCode": "TN",
    "timezone": "Africa/Tunis",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "NBE",
    "countryCode": "TN",
    "timezone": "Africa/Tunis",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "NBG",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "NBH",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "NBH",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "NBJ",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "NBL",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "NBL",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "NBN",
    "countryCode": "GQ",
    "timezone": "Africa/Malabo",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "NBN",
    "countryCode": "GQ",
    "timezone": "Africa/Malabo",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "NBO",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NBO",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NBP",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "NBR",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "NBS",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NBS",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NBU",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "NBU",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "NBV",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "NBV",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "NBW",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "NBX",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "NBX",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "NCA",
    "countryCode": "TC",
    "timezone": "America/Grand_Turk",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "NCA",
    "countryCode": "TC",
    "timezone": "America/Grand_Turk",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "NCE",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "NCE",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "NCG",
    "countryCode": "MX",
    "timezone": "America/Chihuahua",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "NCG",
    "countryCode": "MX",
    "timezone": "America/Chihuahua",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "NCH",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NCH",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NCI",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "NCJ",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "NCJ",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "NCL",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "NCL",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "NCN",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "NCN",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "NCO",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "NCO",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "NCP",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "NCP",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "NCR",
    "countryCode": "NI",
    "timezone": "America/Managua",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "NCR",
    "countryCode": "NI",
    "timezone": "America/Managua",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "NCS",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "NCS",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "NCT",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "NCT",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "NCU",
    "countryCode": "UZ",
    "timezone": "Asia/Samarkand",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "NCU",
    "countryCode": "UZ",
    "timezone": "Asia/Samarkand",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "NCY",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "NCY",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "NDA",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "NDA",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "NDB",
    "countryCode": "MR",
    "timezone": "Africa/Nouakchott",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "NDB",
    "countryCode": "MR",
    "timezone": "Africa/Nouakchott",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "NDC",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "NDC",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "NDD",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "NDD",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "NDE",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NDE",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NDF",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "NDF",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "NDG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NDG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NDH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NDI",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "NDJ",
    "countryCode": "TD",
    "timezone": "Africa/Ndjamena",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "NDJ",
    "countryCode": "TD",
    "timezone": "Africa/Ndjamena",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "NDK",
    "countryCode": "MH",
    "timezone": "Pacific/Majuro",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "NDK",
    "countryCode": "MH",
    "timezone": "Pacific/Majuro",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "NDL",
    "countryCode": "CF",
    "timezone": "Africa/Bangui",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "NDL",
    "countryCode": "CF",
    "timezone": "Africa/Bangui",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "NDM",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NDN",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "NDO",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "NDO",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "NDP",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "NDR",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "NDR",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "NDS",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NDS",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NDU",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "NDU",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "NDV",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "NDY",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "NDY",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "NEA",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "NEC",
    "countryCode": "AR",
    "timezone": "America/Argentina/Buenos_Aires",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "NEC",
    "countryCode": "AR",
    "timezone": "America/Argentina/Buenos_Aires",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "NEF",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "NEF",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "NEG",
    "countryCode": "JM",
    "timezone": "America/Jamaica",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "NEG",
    "countryCode": "JM",
    "timezone": "America/Jamaica",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "NEI",
    "countryCode": "RU",
    "timezone": "Asia/Vladivostok",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "NEI",
    "countryCode": "RU",
    "timezone": "Asia/Vladivostok",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "NEJ",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NEJ",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NEK",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NEK",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NEL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "NEL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "NEN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "NER",
    "countryCode": "RU",
    "timezone": "Asia/Yakutsk",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "NER",
    "countryCode": "RU",
    "timezone": "Asia/Yakutsk",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "NES",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "NET",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "NEU",
    "countryCode": "LA",
    "timezone": "Asia/Vientiane",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "NEU",
    "countryCode": "LA",
    "timezone": "Asia/Vientiane",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "NEV",
    "countryCode": "KN",
    "timezone": "America/St_Kitts",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "NEV",
    "countryCode": "KN",
    "timezone": "America/St_Kitts",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "NEW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "NFF",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "NFG",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "NFG",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "NFL",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "NFL",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "NFO",
    "countryCode": "TO",
    "timezone": "Pacific/Tongatapu",
    "offset": {
      "gmt": 13,
      "dst": 13
    }
  },
  {
    "code": "NFO",
    "countryCode": "TO",
    "timezone": "Pacific/Tongatapu",
    "offset": {
      "gmt": 13,
      "dst": 13
    }
  },
  {
    "code": "NFR",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "NGA",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "NGA",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "NGB",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NGB",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NGC",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "NGD",
    "countryCode": "VG",
    "timezone": "America/Tortola",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "NGD",
    "countryCode": "VG",
    "timezone": "America/Tortola",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "NGE",
    "countryCode": "CM",
    "timezone": "Africa/Douala",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "NGE",
    "countryCode": "CM",
    "timezone": "Africa/Douala",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "NGF",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "NGF",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "NGI",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "NGI",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "NGK",
    "countryCode": "RU",
    "timezone": "Asia/Sakhalin",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "NGK",
    "countryCode": "RU",
    "timezone": "Asia/Sakhalin",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "NGL",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "NGM",
    "countryCode": "GU",
    "timezone": "Pacific/Guam",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "NGN",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "NGN",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "NGO",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "NGO",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "NGP",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "NGQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NGQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NGR",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "NGR",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "NGS",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "NGS",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "NGU",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "NGW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "NGX",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "NGX",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "NGY",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "NGZ",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "NHA",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "NHA",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "NHD",
    "countryCode": "AE",
    "timezone": "Asia/Dubai",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "NHF",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "NHF",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "NHG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NHK",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "NHK",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "NHR",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "NHS",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "NHS",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "NHT",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "NHT",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "NHV",
    "countryCode": "PF",
    "timezone": "Pacific/Marquesas",
    "offset": {
      "gmt": -9,
      "dst": -9
    }
  },
  {
    "code": "NHV",
    "countryCode": "PF",
    "timezone": "Pacific/Marquesas",
    "offset": {
      "gmt": -9,
      "dst": -9
    }
  },
  {
    "code": "NHX",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "NHX",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "NHZ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "NHZ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "NIA",
    "countryCode": "LR",
    "timezone": "Africa/Monrovia",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "NIB",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "NIB",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "NIC",
    "countryCode": "CY",
    "timezone": "Asia/Nicosia",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "NIC",
    "countryCode": "CY",
    "timezone": "Asia/Nicosia",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "NID",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "NIE",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "NIF",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NIF",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NIG",
    "countryCode": "KI",
    "timezone": "Pacific/Tarawa",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "NIG",
    "countryCode": "KI",
    "timezone": "Pacific/Tarawa",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "NIK",
    "countryCode": "SN",
    "timezone": "Africa/Dakar",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "NIM",
    "countryCode": "NE",
    "timezone": "Africa/Niamey",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "NIM",
    "countryCode": "NE",
    "timezone": "Africa/Niamey",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "NIN",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "NIO",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "NIO",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "NIP",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "NIQ",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "NIQ",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "NIR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "NIR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "NIS",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "NIT",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "NIT",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "NIU",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "NIU",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "NIX",
    "countryCode": "ML",
    "timezone": "Africa/Bamako",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "NIX",
    "countryCode": "ML",
    "timezone": "Africa/Bamako",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "NJA",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "NJA",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "NJB",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NJB",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NJC",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "NJC",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "NJF",
    "countryCode": "IQ",
    "timezone": "Asia/Baghdad",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NJF",
    "countryCode": "IQ",
    "timezone": "Asia/Baghdad",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NJJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NJJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NJK",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "NKA",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "NKA",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "NKB",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NKC",
    "countryCode": "MR",
    "timezone": "Africa/Nouakchott",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "NKC",
    "countryCode": "MR",
    "timezone": "Africa/Nouakchott",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "NKD",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "NKG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NKG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NKI",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "NKI",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "NKJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NKL",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "NKL",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "NKM",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "NKN",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "NKO",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NKP",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "NKP",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "NKS",
    "countryCode": "CM",
    "timezone": "Africa/Douala",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "NKS",
    "countryCode": "CM",
    "timezone": "Africa/Douala",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "NKT",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NKT",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NKT",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NKU",
    "countryCode": "LS",
    "timezone": "Africa/Maseru",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "NKU",
    "countryCode": "LS",
    "timezone": "Africa/Maseru",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "NKV",
    "countryCode": "US",
    "timezone": "America/Metlakatla",
    "offset": {
      "gmt": -8,
      "dst": -8
    }
  },
  {
    "code": "NKW",
    "countryCode": "IO",
    "timezone": "Indian/Chagos",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "NKW",
    "countryCode": "IO",
    "timezone": "Indian/Chagos",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "NKX",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "NKY",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "NKY",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "NLA",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "NLA",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "NLC",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "NLC",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "NLD",
    "countryCode": "MX",
    "timezone": "America/Matamoros",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "NLD",
    "countryCode": "MX",
    "timezone": "America/Matamoros",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "NLE",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "NLE",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "NLF",
    "countryCode": "AU",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "NLF",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "NLG",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "NLG",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "NLH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NLH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NLH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NLI",
    "countryCode": "RU",
    "timezone": "Asia/Vladivostok",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "NLI",
    "countryCode": "RU",
    "timezone": "Asia/Vladivostok",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "NLK",
    "countryCode": "NF",
    "timezone": "Pacific/Norfolk",
    "offset": {
      "gmt": 12,
      "dst": 11
    }
  },
  {
    "code": "NLK",
    "countryCode": "NF",
    "timezone": "Pacific/Norfolk",
    "offset": {
      "gmt": 12,
      "dst": 11
    }
  },
  {
    "code": "NLL",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NLL",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NLN",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "NLO",
    "countryCode": "CD",
    "timezone": "Africa/Kinshasa",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "NLP",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "NLP",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "NLS",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NLT",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "NLT",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "NLU",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "NLU",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "NLV",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "NLV",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "NMA",
    "countryCode": "UZ",
    "timezone": "Asia/Tashkent",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "NMA",
    "countryCode": "UZ",
    "timezone": "Asia/Tashkent",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "NMB",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "NMB",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "NMC",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "NMC",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "NME",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "NME",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "NMF",
    "countryCode": "MV",
    "timezone": "Indian/Maldives",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "NMF",
    "countryCode": "MV",
    "timezone": "Indian/Maldives",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "NMG",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "NMG",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "NML",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "NML",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "NMN",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "NMP",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "NMR",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "NMS",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "NMT",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "NMT",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "NMU",
    "countryCode": "MH",
    "timezone": "Pacific/Kwajalein",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "NNA",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "NNA",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "NNB",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "NNB",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "NND",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "NNE",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "NNE",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "NNG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NNG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NNI",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "NNI",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "NNK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "NNK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "NNL",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "NNL",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "NNM",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NNM",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NNR",
    "countryCode": "IE",
    "timezone": "Europe/Dublin",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "NNR",
    "countryCode": "IE",
    "timezone": "Europe/Dublin",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "NNT",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "NNT",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "NNU",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "NNU",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "NNX",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NNX",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NNY",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NNY",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NOA",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "NOA",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "NOB",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "NOB",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "NOC",
    "countryCode": "IE",
    "timezone": "Europe/Dublin",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "NOC",
    "countryCode": "IE",
    "timezone": "Europe/Dublin",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "NOC",
    "countryCode": "IE",
    "timezone": "Europe/Dublin",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "NOD",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "NOD",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "NOG",
    "countryCode": "MX",
    "timezone": "America/Hermosillo",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "NOG",
    "countryCode": "MX",
    "timezone": "America/Hermosillo",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "NOH",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "NOI",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NOJ",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "NOJ",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "NOK",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "NOK",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "NOL",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "NOM",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "NON",
    "countryCode": "KI",
    "timezone": "Pacific/Tarawa",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "NON",
    "countryCode": "KI",
    "timezone": "Pacific/Tarawa",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "NOO",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "NOP",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NOP",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NOR",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "NOR",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "NOS",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NOS",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NOT",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "NOT",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "NOU",
    "countryCode": "NC",
    "timezone": "Pacific/Noumea",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "NOU",
    "countryCode": "NC",
    "timezone": "Pacific/Noumea",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "NOV",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "NOV",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "NOZ",
    "countryCode": "RU",
    "timezone": "Asia/Novokuznetsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "NOZ",
    "countryCode": "RU",
    "timezone": "Asia/Novokuznetsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "NOZ",
    "countryCode": "RU",
    "timezone": "Asia/Novokuznetsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "NPA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "NPD",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "NPE",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "NPE",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "NPE",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "NPG",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "NPH",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "NPL",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "NPL",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "NPO",
    "countryCode": "ID",
    "timezone": "Asia/Pontianak",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "NPO",
    "countryCode": "ID",
    "timezone": "Asia/Pontianak",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "NPP",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "NPR",
    "countryCode": "BR",
    "timezone": "America/Santarem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "NPR",
    "countryCode": "BR",
    "timezone": "America/Santarem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "NPT",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "NPT",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "NPU",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "NPU",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "NPY",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NPY",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NQA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "NQA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "NQI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "NQI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "NQL",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "NQN",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "NQN",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "NQT",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "NQT",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "NQU",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "NQU",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "NQX",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "NQY",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "NQY",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "NQZ",
    "countryCode": "KZ",
    "timezone": "Asia/Almaty",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "NQZ",
    "countryCode": "KZ",
    "timezone": "Asia/Almaty",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "NRA",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "NRA",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "NRB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "NRB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "NRC",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "NRC",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "NRD",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "NRD",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "NRE",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "NRE",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "NRG",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NRG",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NRI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "NRI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "NRK",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "NRK",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "NRL",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "NRL",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "NRM",
    "countryCode": "ML",
    "timezone": "Africa/Bamako",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "NRM",
    "countryCode": "ML",
    "timezone": "Africa/Bamako",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "NRN",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "NRN",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "NRR",
    "countryCode": "PR",
    "timezone": "America/Puerto_Rico",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "NRR",
    "countryCode": "PR",
    "timezone": "America/Puerto_Rico",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "NRS",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "NRS",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "NRT",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "NRY",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "NSA",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "NSB",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "NSB",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "NSE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "NSE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "NSF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "NSH",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NSH",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NSI",
    "countryCode": "CM",
    "timezone": "Africa/Douala",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "NSK",
    "countryCode": "RU",
    "timezone": "Asia/Krasnoyarsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "NSK",
    "countryCode": "RU",
    "timezone": "Asia/Krasnoyarsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "NSL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "NSM",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NSM",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NSN",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "NSN",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "NSO",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "NSO",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "NSP",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NSQ",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "NSQ",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "NSR",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "NSR",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "NST",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "NST",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "NSV",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "NSV",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "NSX",
    "countryCode": "VG",
    "timezone": "America/Tortola",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "NSY",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "NSY",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "NSZ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NSZ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NTA",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "NTA",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "NTB",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "NTB",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "NTC",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "NTD",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "NTD",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "NTE",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "NTE",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "NTG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NTG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NTI",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "NTI",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "NTJ",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "NTL",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "NTL",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "NTM",
    "countryCode": "BR",
    "timezone": "America/Araguaina",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "NTM",
    "countryCode": "BR",
    "timezone": "America/Araguaina",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "NTN",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "NTN",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "NTO",
    "countryCode": "CV",
    "timezone": "Atlantic/Cape_Verde",
    "offset": {
      "gmt": -1,
      "dst": -1
    }
  },
  {
    "code": "NTQ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "NTQ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "NTR",
    "countryCode": "MX",
    "timezone": "America/Monterrey",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "NTT",
    "countryCode": "TO",
    "timezone": "Pacific/Tongatapu",
    "offset": {
      "gmt": 13,
      "dst": 13
    }
  },
  {
    "code": "NTT",
    "countryCode": "TO",
    "timezone": "Pacific/Tongatapu",
    "offset": {
      "gmt": 13,
      "dst": 13
    }
  },
  {
    "code": "NTU",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "NTX",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "NTX",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "NTY",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "NTY",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "NUA",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "NUA",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "NUB",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "NUB",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "NUD",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "NUE",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "NUE",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "NUF",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "NUG",
    "countryCode": "PG",
    "timezone": "Pacific/Bougainville",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "NUH",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "NUH",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "NUI",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "NUI",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "NUJ",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "NUK",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "NUK",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "NUL",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "NUL",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "NUM",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NUM",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NUN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "NUP",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "NUP",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "NUQ",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "NUQ",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "NUR",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "NUS",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "NUS",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "NUT",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "NUU",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NUU",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NUW",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "NUW",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "NUX",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "NUX",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "NVA",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "NVA",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "NVD",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "NVG",
    "countryCode": "NI",
    "timezone": "America/Managua",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "NVG",
    "countryCode": "NI",
    "timezone": "America/Managua",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "NVI",
    "countryCode": "UZ",
    "timezone": "Asia/Samarkand",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "NVI",
    "countryCode": "UZ",
    "timezone": "Asia/Samarkand",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "NVJ",
    "countryCode": "MX",
    "timezone": "America/Hermosillo",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "NVJ",
    "countryCode": "MX",
    "timezone": "America/Hermosillo",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "NVK",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "NVK",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "NVM",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "NVM",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "NVN",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "NVN",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "NVP",
    "countryCode": "BR",
    "timezone": "America/Manaus",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "NVP",
    "countryCode": "BR",
    "timezone": "America/Manaus",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "NVR",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NVR",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NVS",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "NVS",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "NVT",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "NVT",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "NVY",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "NWA",
    "countryCode": "KM",
    "timezone": "Indian/Comoro",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NWA",
    "countryCode": "KM",
    "timezone": "Indian/Comoro",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NWA",
    "countryCode": "KM",
    "timezone": "Indian/Comoro",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NWH",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "NWI",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "NWI",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "NWP",
    "countryCode": "CA",
    "timezone": "America/St_Johns",
    "offset": {
      "gmt": -3,
      "dst": -2
    }
  },
  {
    "code": "NWP",
    "countryCode": "CA",
    "timezone": "America/St_Johns",
    "offset": {
      "gmt": -3,
      "dst": -2
    }
  },
  {
    "code": "NWS",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "NWT",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "NWU",
    "countryCode": "BM",
    "timezone": "Atlantic/Bermuda",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "NXX",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "NXX",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "NYA",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "NYA",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "NYC",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "NYE",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NYE",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NYG",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "NYG",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "NYI",
    "countryCode": "GH",
    "timezone": "Africa/Accra",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "NYI",
    "countryCode": "GH",
    "timezone": "Africa/Accra",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "NYK",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NYK",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NYM",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "NYM",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "NYN",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "NYN",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "NYO",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "NYR",
    "countryCode": "RU",
    "timezone": "Asia/Yakutsk",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "NYR",
    "countryCode": "RU",
    "timezone": "Asia/Yakutsk",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "NYS",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "NYT",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "NYT",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "NYU",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "NYU",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "NYW",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "NYW",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "NZA",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "NZA",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "NZC",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "NZC",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "NZE",
    "countryCode": "GN",
    "timezone": "Africa/Conakry",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "NZE",
    "countryCode": "GN",
    "timezone": "Africa/Conakry",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "NZG",
    "countryCode": "RU",
    "timezone": "Asia/Irkutsk",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NZG",
    "countryCode": "RU",
    "timezone": "Asia/Irkutsk",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NZH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NZH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NZL",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NZL",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "NZO",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "NZW",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "NZY",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "OAA",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "OAA",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "OAG",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "OAG",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "OAH",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "OAH",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "OAI",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "OAJ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "OAJ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "OAK",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "OAK",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "OAL",
    "countryCode": "BR",
    "timezone": "America/Porto_Velho",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "OAL",
    "countryCode": "BR",
    "timezone": "America/Porto_Velho",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "OAM",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "OAM",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "OAN",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "OAN",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "OAR",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "OAS",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "OAS",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "OAX",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "OAX",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "OAZ",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "OBA",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "OBA",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "OBC",
    "countryCode": "DJ",
    "timezone": "Africa/Djibouti",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "OBC",
    "countryCode": "DJ",
    "timezone": "Africa/Djibouti",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "OBD",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "OBD",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "OBE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "OBE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "OBF",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OBF",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OBI",
    "countryCode": "BR",
    "timezone": "America/Santarem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "OBI",
    "countryCode": "BR",
    "timezone": "America/Santarem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "OBL",
    "countryCode": "BE",
    "timezone": "Europe/Brussels",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OBL",
    "countryCode": "BE",
    "timezone": "Europe/Brussels",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OBM",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "OBM",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "OBN",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "OBN",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "OBO",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "OBO",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "OBS",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OBS",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OBT",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "OBU",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "OBU",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "OBX",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "OBX",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "OBY",
    "countryCode": "GL",
    "timezone": "America/Scoresbysund",
    "offset": {
      "gmt": -1,
      "dst": -1
    }
  },
  {
    "code": "OBY",
    "countryCode": "GL",
    "timezone": "America/Scoresbysund",
    "offset": {
      "gmt": -1,
      "dst": -1
    }
  },
  {
    "code": "OBZ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OCA",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "OCA",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "OCC",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "OCC",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "OCD",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "OCD",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "OCE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "OCE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "OCF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "OCF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "OCH",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "OCH",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "OCI",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "OCJ",
    "countryCode": "JM",
    "timezone": "America/Jamaica",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "OCJ",
    "countryCode": "JM",
    "timezone": "America/Jamaica",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "OCJ",
    "countryCode": "JM",
    "timezone": "America/Jamaica",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "OCM",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "OCM",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "OCN",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "OCN",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "OCS",
    "countryCode": "GQ",
    "timezone": "Africa/Malabo",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "OCS",
    "countryCode": "GQ",
    "timezone": "Africa/Malabo",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "OCV",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "OCW",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ODA",
    "countryCode": "CF",
    "timezone": "Africa/Bangui",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ODA",
    "countryCode": "CF",
    "timezone": "Africa/Bangui",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ODB",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ODB",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ODC",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ODC",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ODD",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "ODD",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "ODE",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ODE",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ODH",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "ODH",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "ODJ",
    "countryCode": "CF",
    "timezone": "Africa/Bangui",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ODJ",
    "countryCode": "CF",
    "timezone": "Africa/Bangui",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ODL",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "ODL",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "ODM",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ODM",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ODN",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ODN",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ODO",
    "countryCode": "RU",
    "timezone": "Asia/Irkutsk",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ODO",
    "countryCode": "RU",
    "timezone": "Asia/Irkutsk",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ODR",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ODR",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ODS",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "ODS",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "ODT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ODT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ODW",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ODW",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ODY",
    "countryCode": "LA",
    "timezone": "Asia/Vientiane",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "ODY",
    "countryCode": "LA",
    "timezone": "Asia/Vientiane",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "OEA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "OEA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "OEC",
    "countryCode": "TL",
    "timezone": "Asia/Dili",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "OEC",
    "countryCode": "TL",
    "timezone": "Asia/Dili",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "OEL",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "OEL",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "OEM",
    "countryCode": "SR",
    "timezone": "America/Paramaribo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "OEM",
    "countryCode": "SR",
    "timezone": "America/Paramaribo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "OEO",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "OEO",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "OER",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OER",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OES",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "OES",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "OFF",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "OFI",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "OFI",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "OFJ",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "OFJ",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "OFK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "OFK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "OFU",
    "countryCode": "AS",
    "timezone": "Pacific/Pago_Pago",
    "offset": {
      "gmt": -11,
      "dst": -11
    }
  },
  {
    "code": "OFU",
    "countryCode": "AS",
    "timezone": "Pacific/Pago_Pago",
    "offset": {
      "gmt": -11,
      "dst": -11
    }
  },
  {
    "code": "OGA",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "OGA",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "OGB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "OGB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "OGD",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "OGD",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "OGE",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "OGG",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "OGG",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "OGL",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "OGM",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "OGN",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "OGO",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "OGO",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "OGR",
    "countryCode": "TD",
    "timezone": "Africa/Ndjamena",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "OGR",
    "countryCode": "TD",
    "timezone": "Africa/Ndjamena",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "OGS",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "OGS",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "OGU",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "OGU",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "OGU",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "OGV",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "OGX",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "OGX",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "OGZ",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "OGZ",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "OHA",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "OHA",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "OHB",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "OHC",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "OHC",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "OHD",
    "countryCode": "MK",
    "timezone": "Europe/Skopje",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OHD",
    "countryCode": "MK",
    "timezone": "Europe/Skopje",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OHE",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "OHE",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "OHH",
    "countryCode": "RU",
    "timezone": "Asia/Sakhalin",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "OHH",
    "countryCode": "RU",
    "timezone": "Asia/Sakhalin",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "OHI",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "OHI",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "OHO",
    "countryCode": "RU",
    "timezone": "Asia/Vladivostok",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "OHO",
    "countryCode": "RU",
    "timezone": "Asia/Vladivostok",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "OHR",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OHR",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OHS",
    "countryCode": "OM",
    "timezone": "Asia/Muscat",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "OHS",
    "countryCode": "OM",
    "timezone": "Asia/Muscat",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "OHT",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "OHT",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "OIA",
    "countryCode": "BR",
    "timezone": "America/Santarem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "OIC",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "OIC",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "OIL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "OIL",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OIL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "OIM",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "OIM",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "OIR",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "OIR",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "OIT",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "OIT",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "OJC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "OJU",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "OJU",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "OJU",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "OKA",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "OKA",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "OKB",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "OKC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "OKC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "OKD",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "OKE",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "OKE",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "OKF",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "OKF",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "OKG",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "OKG",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "OKH",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "OKH",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "OKI",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "OKI",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "OKJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "OKJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "OKK",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "OKK",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "OKL",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "OKL",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "OKM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "OKM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "OKN",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "OKN",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "OKO",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "OKP",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "OKQ",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "OKQ",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "OKR",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "OKR",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "OKS",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "OKT",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "OKT",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "OKU",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "OKV",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "OKV",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "OKY",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "OKY",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "OLA",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OLA",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OLB",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OLB",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OLC",
    "countryCode": "BR",
    "timezone": "America/Manaus",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "OLC",
    "countryCode": "BR",
    "timezone": "America/Manaus",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "OLD",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "OLE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "OLE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "OLF",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "OLF",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "OLG",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "OLH",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "OLH",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "OLI",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "OLJ",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "OLJ",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "OLK",
    "countryCode": "PY",
    "timezone": "America/Asuncion",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "OLK",
    "countryCode": "PY",
    "timezone": "America/Asuncion",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "OLL",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "OLL",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "OLM",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "OLM",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "OLN",
    "countryCode": "AR",
    "timezone": "America/Argentina/Catamarca",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "OLO",
    "countryCode": "CZ",
    "timezone": "Europe/Prague",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OLO",
    "countryCode": "CZ",
    "timezone": "Europe/Prague",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OLP",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "OLP",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "OLQ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "OLR",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "OLS",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "OLS",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "OLT",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OLU",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "OLU",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "OLV",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "OLV",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "OLX",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "OLX",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "OLY",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "OLY",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "OLZ",
    "countryCode": "RU",
    "timezone": "Asia/Yakutsk",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "OLZ",
    "countryCode": "RU",
    "timezone": "Asia/Yakutsk",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "OMA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "OMA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "OMB",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "OMC",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "OMC",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "OMD",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "OMD",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "OME",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "OME",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "OMF",
    "countryCode": "JO",
    "timezone": "Asia/Amman",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "OMF",
    "countryCode": "JO",
    "timezone": "Asia/Amman",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "OMG",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "OMH",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "OMH",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "OMI",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "OMI",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "OMJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "OMK",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "OMK",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "OML",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "OMM",
    "countryCode": "OM",
    "timezone": "Asia/Muscat",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "OMM",
    "countryCode": "OM",
    "timezone": "Asia/Muscat",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "OMN",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "OMN",
    "countryCode": "UZ",
    "timezone": "Asia/Samarkand",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "OMN",
    "countryCode": "UZ",
    "timezone": "Asia/Samarkand",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "OMN",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "OMO",
    "countryCode": "BA",
    "timezone": "Europe/Sarajevo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OMO",
    "countryCode": "BA",
    "timezone": "Europe/Sarajevo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OMR",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "OMR",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "OMS",
    "countryCode": "RU",
    "timezone": "Asia/Omsk",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "OMS",
    "countryCode": "RU",
    "timezone": "Asia/Omsk",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "OMY",
    "countryCode": "KH",
    "timezone": "Asia/Phnom_Penh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "ONA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ONA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ONB",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "OND",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "OND",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "ONE",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "ONG",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ONG",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ONH",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ONH",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ONI",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "ONJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "ONJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "ONJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "ONK",
    "countryCode": "RU",
    "timezone": "Asia/Yakutsk",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "ONK",
    "countryCode": "RU",
    "timezone": "Asia/Yakutsk",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "ONL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ONL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ONM",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "ONM",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "ONN",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "ONO",
    "countryCode": "US",
    "timezone": "America/Boise",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "ONO",
    "countryCode": "US",
    "timezone": "America/Boise",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "ONP",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ONP",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ONQ",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ONQ",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ONR",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ONS",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ONS",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ONT",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ONT",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ONU",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "ONU",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "ONX",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ONX",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ONY",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ONY",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "OOA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "OOA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "OOD",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "OOK",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "OOK",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "OOL",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "OOL",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "OOM",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "OOM",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "OOR",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "OOT",
    "countryCode": "KI",
    "timezone": "Pacific/Tarawa",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "OOT",
    "countryCode": "KI",
    "timezone": "Pacific/Tarawa",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "OPA",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "OPA",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "OPB",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "OPF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "OPF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "OPI",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "OPI",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "OPL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "OPL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "OPO",
    "countryCode": "PT",
    "timezone": "Europe/Lisbon",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "OPO",
    "countryCode": "PT",
    "timezone": "Europe/Lisbon",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "OPP",
    "countryCode": "BR",
    "timezone": "America/Belem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "OPP",
    "countryCode": "BR",
    "timezone": "America/Belem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "OPQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "OPQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "OPS",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "OPS",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "OPU",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "OPU",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "OPW",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "OQN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "OQN",
    "countryCode": "UZ",
    "timezone": "Asia/Tashkent",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "OQN",
    "countryCode": "UZ",
    "timezone": "Asia/Tashkent",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "OQN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "OQR",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "ORA",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ORA",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ORB",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ORB",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ORC",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ORC",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ORD",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ORE",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ORE",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ORF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ORF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ORG",
    "countryCode": "SR",
    "timezone": "America/Paramaribo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ORH",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ORH",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ORI",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "ORI",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "ORJ",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "ORJ",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "ORK",
    "countryCode": "IE",
    "timezone": "Europe/Dublin",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "ORK",
    "countryCode": "IE",
    "timezone": "Europe/Dublin",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "ORL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ORL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ORM",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "ORM",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "ORN",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ORN",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ORO",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "ORP",
    "countryCode": "BW",
    "timezone": "Africa/Gaborone",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "ORP",
    "countryCode": "BW",
    "timezone": "Africa/Gaborone",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "ORQ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ORR",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "ORR",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "ORS",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ORT",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "ORT",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "ORU",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "ORU",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "ORV",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "ORV",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "ORW",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "ORW",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "ORX",
    "countryCode": "BR",
    "timezone": "America/Santarem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ORX",
    "countryCode": "BR",
    "timezone": "America/Santarem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ORY",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ORZ",
    "countryCode": "BZ",
    "timezone": "America/Belize",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "ORZ",
    "countryCode": "BZ",
    "timezone": "America/Belize",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "OSA",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "OSB",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "OSC",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "OSC",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "OSD",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OSD",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OSD",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OSE",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "OSF",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "OSF",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "OSF",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "OSG",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "OSH",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "OSH",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "OSI",
    "countryCode": "HR",
    "timezone": "Europe/Zagreb",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OSI",
    "countryCode": "HR",
    "timezone": "Europe/Zagreb",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OSJ",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "OSK",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OSK",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OSL",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OSL",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OSM",
    "countryCode": "IQ",
    "timezone": "Asia/Baghdad",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "OSM",
    "countryCode": "IQ",
    "timezone": "Asia/Baghdad",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "OSN",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "OSN",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "OSO",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "OSO",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "OSQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "OSQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "OSR",
    "countryCode": "CZ",
    "timezone": "Europe/Prague",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OSR",
    "countryCode": "CZ",
    "timezone": "Europe/Prague",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OSS",
    "countryCode": "KG",
    "timezone": "Asia/Bishkek",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "OSS",
    "countryCode": "KG",
    "timezone": "Asia/Bishkek",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "OST",
    "countryCode": "BE",
    "timezone": "Europe/Brussels",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OST",
    "countryCode": "BE",
    "timezone": "Europe/Brussels",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OSU",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "OSW",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "OSW",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "OSX",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "OSX",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "OSY",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OSY",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OSZ",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OSZ",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OTA",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "OTC",
    "countryCode": "TD",
    "timezone": "Africa/Ndjamena",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "OTD",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "OTD",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "OTG",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "OTG",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "OTH",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "OTH",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "OTI",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "OTI",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "OTJ",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "OTJ",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "OTK",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "OTK",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "OTL",
    "countryCode": "MR",
    "timezone": "Africa/Nouakchott",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "OTL",
    "countryCode": "MR",
    "timezone": "Africa/Nouakchott",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "OTM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "OTM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "OTN",
    "countryCode": "US",
    "timezone": "America/Indiana/Vincennes",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "OTN",
    "countryCode": "US",
    "timezone": "America/Indiana/Vincennes",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "OTO",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "OTO",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "OTO",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "OTO",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "OTP",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "OTQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "OTQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "OTR",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "OTS",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "OTS",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "OTT",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "OTU",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "OTU",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "OTV",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "OTV",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "OTY",
    "countryCode": "PG",
    "timezone": "Pacific/Bougainville",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "OTZ",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "OTZ",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "OUA",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "OUA",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "OUB",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OUD",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "OUD",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "OUE",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "OUE",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "OUG",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "OUG",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "OUH",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "OUH",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "OUI",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OUI",
    "countryCode": "LA",
    "timezone": "Asia/Vientiane",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "OUI",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OUK",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "OUK",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "OUL",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "OUL",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "OUM",
    "countryCode": "TD",
    "timezone": "Africa/Ndjamena",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "OUM",
    "countryCode": "TD",
    "timezone": "Africa/Ndjamena",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "OUN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "OUN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "OUQ",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OUQ",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OUR",
    "countryCode": "CM",
    "timezone": "Africa/Douala",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "OUR",
    "countryCode": "CM",
    "timezone": "Africa/Douala",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "OUS",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "OUS",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "OUT",
    "countryCode": "TD",
    "timezone": "Africa/Ndjamena",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "OUT",
    "countryCode": "TD",
    "timezone": "Africa/Ndjamena",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "OUU",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "OUZ",
    "countryCode": "MR",
    "timezone": "Africa/Nouakchott",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "OUZ",
    "countryCode": "MR",
    "timezone": "Africa/Nouakchott",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "OVA",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "OVA",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "OVB",
    "countryCode": "RU",
    "timezone": "Asia/Novosibirsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "OVB",
    "countryCode": "RU",
    "timezone": "Asia/Novosibirsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "OVD",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OVE",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "OVE",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "OVG",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "OVG",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "OVI",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OVI",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OVL",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "OVL",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "OVO",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OVR",
    "countryCode": "AR",
    "timezone": "America/Argentina/Buenos_Aires",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "OVR",
    "countryCode": "AR",
    "timezone": "America/Argentina/Buenos_Aires",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "OVS",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "OVS",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "OWA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "OWA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "OWB",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "OWB",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "OWD",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "OWD",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "OWE",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "OWK",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "OWK",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "OWZ",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "OXB",
    "countryCode": "GW",
    "timezone": "Africa/Bissau",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "OXB",
    "countryCode": "GW",
    "timezone": "Africa/Bissau",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "OXC",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "OXC",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "OXD",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "OXD",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "OXF",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "OXF",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "OXO",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "OXO",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "OXQ",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "OXR",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "OXR",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "OXY",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "OYA",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "OYA",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "OYC",
    "countryCode": "GF",
    "timezone": "America/Cayenne",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "OYC",
    "countryCode": "GF",
    "timezone": "America/Cayenne",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "OYE",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "OYE",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "OYG",
    "countryCode": "UG",
    "timezone": "Africa/Kampala",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "OYG",
    "countryCode": "UG",
    "timezone": "Africa/Kampala",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "OYK",
    "countryCode": "BR",
    "timezone": "America/Belem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "OYK",
    "countryCode": "BR",
    "timezone": "America/Belem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "OYL",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "OYL",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "OYN",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "OYN",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "OYO",
    "countryCode": "AR",
    "timezone": "America/Argentina/Buenos_Aires",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "OYO",
    "countryCode": "AR",
    "timezone": "America/Argentina/Buenos_Aires",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "OYP",
    "countryCode": "GF",
    "timezone": "America/Cayenne",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "OYP",
    "countryCode": "GF",
    "timezone": "America/Cayenne",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "OYS",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "OZA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "OZC",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "OZC",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "OZG",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "OZG",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "OZH",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "OZH",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "OZI",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "OZL",
    "countryCode": "ES",
    "timezone": "Atlantic/Canary",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "OZL",
    "countryCode": "ES",
    "timezone": "Atlantic/Canary",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "OZP",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OZP",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OZR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "OZR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "OZU",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "OZZ",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "OZZ",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "PAA",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "PAB",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "PAC",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PAD",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PAD",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PAE",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "PAE",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "PAF",
    "countryCode": "UG",
    "timezone": "Africa/Kampala",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "PAF",
    "countryCode": "UG",
    "timezone": "Africa/Kampala",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "PAG",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "PAG",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "PAH",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PAH",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PAJ",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "PAJ",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "PAK",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "PAK",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "PAL",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PAL",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PAM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PAN",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PAN",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PAO",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "PAO",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "PAP",
    "countryCode": "HT",
    "timezone": "America/Port-au-Prince",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PAP",
    "countryCode": "HT",
    "timezone": "America/Port-au-Prince",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PAQ",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PAQ",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PAR",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PAS",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "PAS",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "PAS",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "PAT",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "PAT",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "PAU",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "PAU",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "PAV",
    "countryCode": "BR",
    "timezone": "America/Maceio",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PAV",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PAW",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "PAX",
    "countryCode": "HT",
    "timezone": "America/Port-au-Prince",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PAX",
    "countryCode": "HT",
    "timezone": "America/Port-au-Prince",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PAY",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "PAY",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "PAZ",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "PAZ",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "PBA",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PBB",
    "countryCode": "BR",
    "timezone": "America/Campo_Grande",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "PBC",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "PBC",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "PBD",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "PBD",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "PBE",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PBE",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PBF",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PBF",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PBG",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PBG",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PBH",
    "countryCode": "BT",
    "timezone": "Asia/Thimphu",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "PBH",
    "countryCode": "BT",
    "timezone": "Asia/Thimphu",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "PBI",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PBI",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PBJ",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "PBJ",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "PBK",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PBL",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "PBL",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "PBM",
    "countryCode": "SR",
    "timezone": "America/Paramaribo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PBM",
    "countryCode": "SR",
    "timezone": "America/Paramaribo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PBM",
    "countryCode": "SR",
    "timezone": "America/Paramaribo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PBN",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "PBN",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "PBO",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "PBO",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "PBP",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "PBP",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "PBQ",
    "countryCode": "BR",
    "timezone": "America/Porto_Velho",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "PBQ",
    "countryCode": "BR",
    "timezone": "America/Porto_Velho",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "PBR",
    "countryCode": "GT",
    "timezone": "America/Guatemala",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "PBR",
    "countryCode": "GT",
    "timezone": "America/Guatemala",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "PBS",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PBT",
    "countryCode": "PY",
    "timezone": "America/Asuncion",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "PBT",
    "countryCode": "PY",
    "timezone": "America/Asuncion",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "PBU",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "PBU",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "PBV",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "PBW",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "PBX",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "PBY",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "PBZ",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "PBZ",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "PCA",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PCA",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PCB",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PCC",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "PCD",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PCD",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PCE",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PCE",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PCE",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PCF",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "PCG",
    "countryCode": "GT",
    "timezone": "America/Guatemala",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "PCH",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "PCI",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PCI",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PCJ",
    "countryCode": "PY",
    "timezone": "America/Asuncion",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "PCJ",
    "countryCode": "PY",
    "timezone": "America/Asuncion",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "PCK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PCK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PCL",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PCL",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PCM",
    "countryCode": "MX",
    "timezone": "America/Cancun",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PCM",
    "countryCode": "MX",
    "timezone": "America/Cancun",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PCN",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "PCN",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "PCO",
    "countryCode": "MX",
    "timezone": "America/Mazatlan",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "PCO",
    "countryCode": "MX",
    "timezone": "America/Mazatlan",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "PCO",
    "countryCode": "MX",
    "timezone": "America/Mazatlan",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "PCP",
    "countryCode": "ST",
    "timezone": "Africa/Sao_Tome",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "PCP",
    "countryCode": "ST",
    "timezone": "Africa/Sao_Tome",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "PCQ",
    "countryCode": "LA",
    "timezone": "Asia/Vientiane",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PCR",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PCR",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PCS",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PCS",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PCT",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PCT",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PCU",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PCU",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PCU",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PCV",
    "countryCode": "MX",
    "timezone": "America/Mazatlan",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "PCV",
    "countryCode": "MX",
    "timezone": "America/Mazatlan",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "PCW",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "PCW",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "PDA",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PDA",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PDB",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PDB",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PDC",
    "countryCode": "NC",
    "timezone": "Pacific/Noumea",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "PDD",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "PDE",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "PDE",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "PDF",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PDF",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PDG",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PDG",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PDI",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "PDK",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PDL",
    "countryCode": "PT",
    "timezone": "Atlantic/Azores",
    "offset": {
      "gmt": -1,
      "dst": 0
    }
  },
  {
    "code": "PDL",
    "countryCode": "PT",
    "timezone": "Atlantic/Azores",
    "offset": {
      "gmt": -1,
      "dst": 0
    }
  },
  {
    "code": "PDM",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PDM",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PDN",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "PDO",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PDO",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PDP",
    "countryCode": "UY",
    "timezone": "America/Montevideo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PDP",
    "countryCode": "UY",
    "timezone": "America/Montevideo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PDR",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PDS",
    "countryCode": "MX",
    "timezone": "America/Matamoros",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PDS",
    "countryCode": "MX",
    "timezone": "America/Matamoros",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PDT",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "PDT",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "PDU",
    "countryCode": "UY",
    "timezone": "America/Montevideo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PDV",
    "countryCode": "BG",
    "timezone": "Europe/Sofia",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "PDV",
    "countryCode": "BG",
    "timezone": "Europe/Sofia",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "PDX",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "PDX",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "PDZ",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "PDZ",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "PEA",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "PEA",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "PEB",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "PEC",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PEC",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PED",
    "countryCode": "CZ",
    "timezone": "Europe/Prague",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PED",
    "countryCode": "CZ",
    "timezone": "Europe/Prague",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PEE",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "PEE",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "PEF",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PEF",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PEG",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PEG",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PEH",
    "countryCode": "AR",
    "timezone": "America/Argentina/Buenos_Aires",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PEH",
    "countryCode": "AR",
    "timezone": "America/Argentina/Buenos_Aires",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PEI",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PEI",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PEJ",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PEK",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "PEL",
    "countryCode": "LS",
    "timezone": "Africa/Maseru",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "PEM",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PEM",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PEN",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "PEN",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "PEP",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "PEQ",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PER",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "PER",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "PES",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "PES",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "PET",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PET",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PEU",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "PEU",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "PEV",
    "countryCode": "HU",
    "timezone": "Europe/Budapest",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PEV",
    "countryCode": "HU",
    "timezone": "Europe/Budapest",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PEW",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "PEW",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "PEX",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "PEX",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "PEY",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "PEZ",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "PEZ",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "PEZ",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "PFA",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PFA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "PFA",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PFB",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PFB",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PFC",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "PFC",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "PFD",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PFD",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PFE",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PFE",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PFJ",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "PFL",
    "countryCode": "CZ",
    "timezone": "Europe/Prague",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PFM",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "PFM",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "PFN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PFN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PFO",
    "countryCode": "CY",
    "timezone": "Asia/Nicosia",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "PFO",
    "countryCode": "CY",
    "timezone": "Asia/Nicosia",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "PFQ",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "PFQ",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "PFR",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "PFR",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "PFT",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "PGA",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "PGA",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "PGB",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "PGC",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "PGD",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PGD",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PGE",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "PGF",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PGF",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PGG",
    "countryCode": "BR",
    "timezone": "America/Rio_Branco",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PGH",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "PGH",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "PGI",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "PGI",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "PGK",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PGK",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PGL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PGL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PGM",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PGM",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PGN",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "PGO",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "PGP",
    "countryCode": "ST",
    "timezone": "Africa/Sao_Tome",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "PGQ",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "PGQ",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "PGQ",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "PGR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PGR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PGS",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "PGS",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "PGU",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "PGU",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "PGV",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PGV",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PGX",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PGX",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PGZ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PGZ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PHA",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PHA",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PHB",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PHB",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PHC",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "PHC",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "PHD",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PHD",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PHE",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "PHE",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "PHF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PHF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PHF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PHG",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "PHH",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PHI",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PHI",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PHJ",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "PHK",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PHK",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PHL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PHL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PHN",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PHN",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PHO",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PHO",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PHP",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "PHP",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "PHQ",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "PHQ",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "PHR",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "PHR",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "PHS",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PHS",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PHT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PHT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PHU",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PHU",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PHW",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "PHW",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "PHX",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "PHX",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "PHY",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PHY",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PHZ",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PIA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PIA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PIB",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PIC",
    "countryCode": "TC",
    "timezone": "America/Grand_Turk",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PID",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PIE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PIF",
    "countryCode": "TW",
    "timezone": "Asia/Taipei",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "PIF",
    "countryCode": "TW",
    "timezone": "Asia/Taipei",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "PIG",
    "countryCode": "BR",
    "timezone": "America/Boa_Vista",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "PIH",
    "countryCode": "US",
    "timezone": "America/Boise",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "PIH",
    "countryCode": "US",
    "timezone": "America/Boise",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "PII",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PIK",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "PIL",
    "countryCode": "PY",
    "timezone": "America/Asuncion",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "PIL",
    "countryCode": "PY",
    "timezone": "America/Asuncion",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "PIM",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PIM",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PIN",
    "countryCode": "BR",
    "timezone": "America/Manaus",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "PIN",
    "countryCode": "BR",
    "timezone": "America/Manaus",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "PIO",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PIO",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PIP",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PIP",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PIQ",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "PIR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PIR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PIS",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PIS",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PIT",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PIT",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PIU",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PIU",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PIV",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PIW",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PIW",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PIX",
    "countryCode": "PT",
    "timezone": "Atlantic/Azores",
    "offset": {
      "gmt": -1,
      "dst": 0
    }
  },
  {
    "code": "PIX",
    "countryCode": "PT",
    "timezone": "Atlantic/Azores",
    "offset": {
      "gmt": -1,
      "dst": 0
    }
  },
  {
    "code": "PIZ",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PIZ",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PJA",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PJA",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PJB",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "PJB",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "PJC",
    "countryCode": "PY",
    "timezone": "America/Asuncion",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "PJC",
    "countryCode": "PY",
    "timezone": "America/Asuncion",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "PJG",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "PJG",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "PJI",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "PJI",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "PJM",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "PJM",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "PJS",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PJZ",
    "countryCode": "MX",
    "timezone": "America/Cancun",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PKA",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PKA",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PKB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PKB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PKC",
    "countryCode": "RU",
    "timezone": "Asia/Kamchatka",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "PKC",
    "countryCode": "RU",
    "timezone": "Asia/Kamchatka",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "PKC",
    "countryCode": "RU",
    "timezone": "Asia/Kamchatka",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "PKD",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PKE",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "PKE",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "PKF",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PKF",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PKG",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "PKG",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "PKH",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "PKH",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "PKJ",
    "countryCode": "GT",
    "timezone": "America/Guatemala",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "PKJ",
    "countryCode": "GT",
    "timezone": "America/Guatemala",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "PKK",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "PKK",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "PKL",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "PKM",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "PKM",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "PKN",
    "countryCode": "ID",
    "timezone": "Asia/Pontianak",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PKN",
    "countryCode": "ID",
    "timezone": "Asia/Pontianak",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PKO",
    "countryCode": "BJ",
    "timezone": "Africa/Porto-Novo",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "PKO",
    "countryCode": "BJ",
    "timezone": "Africa/Porto-Novo",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "PKP",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "PKP",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "PKR",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "PKR",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "PKS",
    "countryCode": "LA",
    "timezone": "Asia/Vientiane",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PKT",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "PKT",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "PKU",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PKU",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PKV",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "PKV",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "PKW",
    "countryCode": "BW",
    "timezone": "Africa/Gaborone",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "PKW",
    "countryCode": "BW",
    "timezone": "Africa/Gaborone",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "PKX",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "PKY",
    "countryCode": "ID",
    "timezone": "Asia/Pontianak",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PKY",
    "countryCode": "ID",
    "timezone": "Asia/Pontianak",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PKZ",
    "countryCode": "LA",
    "timezone": "Asia/Vientiane",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PKZ",
    "countryCode": "LA",
    "timezone": "Asia/Vientiane",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PLA",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PLA",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PLB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PLB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PLC",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PLD",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "PLD",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "PLD",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "PLE",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "PLE",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "PLF",
    "countryCode": "TD",
    "timezone": "Africa/Ndjamena",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "PLF",
    "countryCode": "TD",
    "timezone": "Africa/Ndjamena",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "PLG",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "PLH",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "PLH",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "PLI",
    "countryCode": "VC",
    "timezone": "America/St_Vincent",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "PLJ",
    "countryCode": "BZ",
    "timezone": "America/Belize",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "PLJ",
    "countryCode": "BZ",
    "timezone": "America/Belize",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "PLK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PLK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PLL",
    "countryCode": "BR",
    "timezone": "America/Manaus",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "PLM",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PLM",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PLN",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PLN",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PLO",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "PLO",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "PLP",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PLP",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PLQ",
    "countryCode": "LT",
    "timezone": "Europe/Vilnius",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "PLQ",
    "countryCode": "LT",
    "timezone": "Europe/Vilnius",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "PLR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PLR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PLS",
    "countryCode": "TC",
    "timezone": "America/Grand_Turk",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PLS",
    "countryCode": "TC",
    "timezone": "America/Grand_Turk",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PLT",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PLT",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PLU",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PLV",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "PLV",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "PLW",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "PLW",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "PLX",
    "countryCode": "KZ",
    "timezone": "Asia/Almaty",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "PLX",
    "countryCode": "KZ",
    "timezone": "Asia/Almaty",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "PLY",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PLZ",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "PLZ",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "PMA",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "PMA",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "PMA",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "PMB",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PMC",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "PMC",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "PMD",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "PMD",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "PME",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "PMF",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PMG",
    "countryCode": "BR",
    "timezone": "America/Campo_Grande",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "PMG",
    "countryCode": "BR",
    "timezone": "America/Campo_Grande",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "PMH",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PMH",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PMI",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PMI",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PMK",
    "countryCode": "AU",
    "timezone": "Australia/Lindeman",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "PMK",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "PML",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PML",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PMM",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PMN",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "PMO",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PMO",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PMP",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "PMP",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "PMQ",
    "countryCode": "AR",
    "timezone": "America/Argentina/Rio_Gallegos",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PMQ",
    "countryCode": "AR",
    "timezone": "America/Argentina/Rio_Gallegos",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PMR",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "PMR",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "PMS",
    "countryCode": "SY",
    "timezone": "Asia/Damascus",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "PMS",
    "countryCode": "SY",
    "timezone": "Asia/Damascus",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "PMT",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "PMU",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PMV",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "PMV",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "PMW",
    "countryCode": "BR",
    "timezone": "America/Araguaina",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PMW",
    "countryCode": "BR",
    "timezone": "America/Araguaina",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PMX",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PMY",
    "countryCode": "AR",
    "timezone": "America/Argentina/Catamarca",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PMY",
    "countryCode": "AR",
    "timezone": "America/Argentina/Catamarca",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PMZ",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "PMZ",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "PNA",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PNA",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PNB",
    "countryCode": "BR",
    "timezone": "America/Araguaina",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PNB",
    "countryCode": "BR",
    "timezone": "America/Araguaina",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PNC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PNC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PND",
    "countryCode": "BZ",
    "timezone": "America/Belize",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "PND",
    "countryCode": "BZ",
    "timezone": "America/Belize",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "PNE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PNF",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PNF",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PNG",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PNG",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PNH",
    "countryCode": "KH",
    "timezone": "Asia/Phnom_Penh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PNH",
    "countryCode": "KH",
    "timezone": "Asia/Phnom_Penh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PNI",
    "countryCode": "FM",
    "timezone": "Pacific/Pohnpei",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "PNI",
    "countryCode": "FM",
    "timezone": "Pacific/Pohnpei",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "PNJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "PNK",
    "countryCode": "ID",
    "timezone": "Asia/Pontianak",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PNK",
    "countryCode": "ID",
    "timezone": "Asia/Pontianak",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PNL",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PNL",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PNN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PNN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PNO",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "PNO",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "PNP",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "PNP",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "PNQ",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "PNQ",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "PNR",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "PNR",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "PNS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PNS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PNT",
    "countryCode": "CL",
    "timezone": "America/Punta_Arenas",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PNT",
    "countryCode": "CL",
    "timezone": "America/Punta_Arenas",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PNU",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "PNU",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "PNV",
    "countryCode": "LT",
    "timezone": "Europe/Vilnius",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "PNV",
    "countryCode": "LT",
    "timezone": "Europe/Vilnius",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "PNX",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PNX",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PNY",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "PNY",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "PNZ",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PNZ",
    "countryCode": "BR",
    "timezone": "America/Recife",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "POA",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "POA",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "POB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "POC",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "POC",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "POD",
    "countryCode": "SN",
    "timezone": "Africa/Dakar",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "POD",
    "countryCode": "SN",
    "timezone": "Africa/Dakar",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "POE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "POF",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "POG",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "POG",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "POH",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "POI",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "POI",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "POJ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "POJ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "POK",
    "countryCode": "AT",
    "timezone": "Europe/Vienna",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "POK",
    "countryCode": "AT",
    "timezone": "Europe/Vienna",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "POL",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "POL",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "POM",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "POM",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "PON",
    "countryCode": "GT",
    "timezone": "America/Guatemala",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "POO",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "POO",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "POP",
    "countryCode": "DO",
    "timezone": "America/Santo_Domingo",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "POP",
    "countryCode": "DO",
    "timezone": "America/Santo_Domingo",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "POQ",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "POQ",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "POR",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "POR",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "POS",
    "countryCode": "TT",
    "timezone": "America/Port_of_Spain",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "POS",
    "countryCode": "TT",
    "timezone": "America/Port_of_Spain",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "POS",
    "countryCode": "TT",
    "timezone": "America/Port_of_Spain",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "POT",
    "countryCode": "JM",
    "timezone": "America/Jamaica",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "POT",
    "countryCode": "JM",
    "timezone": "America/Jamaica",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "POU",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "POU",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "POV",
    "countryCode": "SK",
    "timezone": "Europe/Bratislava",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "POV",
    "countryCode": "SK",
    "timezone": "Europe/Bratislava",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "POW",
    "countryCode": "SI",
    "timezone": "Europe/Ljubljana",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "POW",
    "countryCode": "SI",
    "timezone": "Europe/Ljubljana",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "POX",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "POY",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "POY",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "POY",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "POZ",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "POZ",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PPA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PPA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PPB",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PPB",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PPC",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PPC",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PPD",
    "countryCode": "PR",
    "timezone": "America/Puerto_Rico",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "PPE",
    "countryCode": "MX",
    "timezone": "America/Hermosillo",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "PPE",
    "countryCode": "MX",
    "timezone": "America/Hermosillo",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "PPF",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PPF",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PPG",
    "countryCode": "AS",
    "timezone": "Pacific/Pago_Pago",
    "offset": {
      "gmt": -11,
      "dst": -11
    }
  },
  {
    "code": "PPG",
    "countryCode": "AS",
    "timezone": "Pacific/Pago_Pago",
    "offset": {
      "gmt": -11,
      "dst": -11
    }
  },
  {
    "code": "PPH",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "PPI",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "PPI",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "PPJ",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PPK",
    "countryCode": "KZ",
    "timezone": "Asia/Almaty",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "PPK",
    "countryCode": "KZ",
    "timezone": "Asia/Almaty",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "PPL",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "PPL",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "PPM",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PPM",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PPN",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PPN",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PPO",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PPO",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PPP",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "PPP",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "PPQ",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "PPQ",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "PPR",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PPS",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "PPS",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "PPT",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "PPT",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "PPU",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "PPV",
    "countryCode": "US",
    "timezone": "America/Metlakatla",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PPV",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PPW",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "PPW",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "PPX",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "PPY",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PPY",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PPZ",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "PPZ",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "PQC",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PQC",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PQD",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "PQI",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PQI",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PQM",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "PQM",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "PQQ",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "PQQ",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "PQS",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PQS",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PQT",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "PQT",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "PRA",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PRA",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PRB",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "PRB",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "PRC",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "PRC",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "PRD",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "PRE",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PRE",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PRF",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PRG",
    "countryCode": "CZ",
    "timezone": "Europe/Prague",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PRG",
    "countryCode": "CZ",
    "timezone": "Europe/Prague",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PRH",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PRH",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PRI",
    "countryCode": "SC",
    "timezone": "Indian/Mahe",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "PRI",
    "countryCode": "SC",
    "timezone": "Indian/Mahe",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "PRJ",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PRK",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "PRK",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "PRL",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PRL",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PRM",
    "countryCode": "PT",
    "timezone": "Europe/Lisbon",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "PRM",
    "countryCode": "PT",
    "timezone": "Europe/Lisbon",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "PRN",
    "countryCode": "XK",
    "timezone": "Europe/Belgrade",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PRN",
    "countryCode": "XK",
    "timezone": "Europe/Belgrade",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PRO",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PRO",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PRP",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PRP",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PRQ",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PRQ",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PRR",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "PRS",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "PRS",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "PRT",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PRT",
    "countryCode": "XK",
    "timezone": "Europe/Belgrade",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PRT",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PRU",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "PRU",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "PRV",
    "countryCode": "CZ",
    "timezone": "Europe/Prague",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PRV",
    "countryCode": "CZ",
    "timezone": "Europe/Prague",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PRW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "PRX",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PRX",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PRY",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "PRY",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "PRZ",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "PRZ",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "PSA",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PSA",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PSB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PSB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PSB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PSB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PSC",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "PSC",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "PSD",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "PSD",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "PSE",
    "countryCode": "PR",
    "timezone": "America/Puerto_Rico",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "PSE",
    "countryCode": "PR",
    "timezone": "America/Puerto_Rico",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "PSF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PSF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PSG",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PSG",
    "countryCode": "US",
    "timezone": "America/Sitka",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PSH",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PSI",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "PSI",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "PSJ",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "PSJ",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "PSK",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PSK",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PSL",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "PSL",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "PSM",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PSM",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PSN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PSN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PSO",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PSO",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PSP",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "PSP",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "PSQ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PSR",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PSR",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PSS",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PSS",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PST",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PST",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PSU",
    "countryCode": "ID",
    "timezone": "Asia/Pontianak",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PSU",
    "countryCode": "ID",
    "timezone": "Asia/Pontianak",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PSV",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "PSV",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "PSW",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PSW",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PSX",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PSX",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PSY",
    "countryCode": "FK",
    "timezone": "Atlantic/Stanley",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PSY",
    "countryCode": "FK",
    "timezone": "Atlantic/Stanley",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PSZ",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "PTA",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PTA",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PTB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PTB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PTC",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PTD",
    "countryCode": "US",
    "timezone": "America/Sitka",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PTD",
    "countryCode": "US",
    "timezone": "America/Sitka",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PTE",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "PTE",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PTE",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PTF",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "PTF",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "PTG",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "PTG",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "PTH",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PTH",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PTI",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "PTJ",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "PTJ",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "PTK",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PTK",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PTL",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PTL",
    "countryCode": "US",
    "timezone": "America/Sitka",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PTL",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PTM",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "PTM",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "PTN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PTN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PTO",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PTO",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PTP",
    "countryCode": "GP",
    "timezone": "America/Guadeloupe",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "PTP",
    "countryCode": "GP",
    "timezone": "America/Guadeloupe",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "PTQ",
    "countryCode": "BR",
    "timezone": "America/Santarem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PTQ",
    "countryCode": "BR",
    "timezone": "America/Santarem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PTR",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PTS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PTS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PTT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PTU",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PTU",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PTV",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "PTV",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "PTW",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PTW",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PTX",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PTX",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PTY",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PTY",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PTZ",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PTZ",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PUA",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "PUB",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "PUB",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "PUC",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "PUC",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "PUD",
    "countryCode": "AR",
    "timezone": "America/Argentina/Rio_Gallegos",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PUD",
    "countryCode": "AR",
    "timezone": "America/Argentina/Rio_Gallegos",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PUE",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PUE",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PUF",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PUF",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PUG",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "PUG",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "PUH",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PUH",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PUI",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "PUJ",
    "countryCode": "DO",
    "timezone": "America/Santo_Domingo",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "PUJ",
    "countryCode": "DO",
    "timezone": "America/Santo_Domingo",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "PUK",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "PUK",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "PUL",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "PUM",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "PUM",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "PUN",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "PUN",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "PUO",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PUP",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "PUP",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "PUQ",
    "countryCode": "CL",
    "timezone": "America/Punta_Arenas",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PUQ",
    "countryCode": "CL",
    "timezone": "America/Punta_Arenas",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PUR",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "PUR",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "PUS",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "PUS",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "PUT",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "PUT",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "PUU",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PUU",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PUV",
    "countryCode": "NC",
    "timezone": "Pacific/Noumea",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "PUV",
    "countryCode": "NC",
    "timezone": "Pacific/Noumea",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "PUW",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "PUW",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "PUX",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "PUX",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "PUY",
    "countryCode": "HR",
    "timezone": "Europe/Zagreb",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PUY",
    "countryCode": "HR",
    "timezone": "Europe/Zagreb",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PUZ",
    "countryCode": "NI",
    "timezone": "America/Managua",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "PUZ",
    "countryCode": "NI",
    "timezone": "America/Managua",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "PVA",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PVA",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PVB",
    "countryCode": "UY",
    "timezone": "America/Montevideo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PVC",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PVC",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PVD",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PVD",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PVE",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PVE",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PVF",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "PVF",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "PVG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "PVH",
    "countryCode": "BR",
    "timezone": "America/Porto_Velho",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "PVH",
    "countryCode": "BR",
    "timezone": "America/Porto_Velho",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "PVI",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PVI",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PVK",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "PVK",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "PVL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PVL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PVN",
    "countryCode": "BG",
    "timezone": "Europe/Sofia",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "PVN",
    "countryCode": "BG",
    "timezone": "Europe/Sofia",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "PVO",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PVO",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PVR",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "PVR",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "PVS",
    "countryCode": "RU",
    "timezone": "Asia/Anadyr",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "PVS",
    "countryCode": "RU",
    "timezone": "Asia/Anadyr",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "PVU",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "PVU",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "PVW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PVW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PVY",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PVZ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PVZ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PWA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PWD",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "PWD",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "PWE",
    "countryCode": "RU",
    "timezone": "Asia/Anadyr",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "PWE",
    "countryCode": "RU",
    "timezone": "Asia/Anadyr",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "PWI",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "PWK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "PWL",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PWL",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PWM",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PWM",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PWN",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PWN",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PWO",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "PWO",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "PWQ",
    "countryCode": "KZ",
    "timezone": "Asia/Almaty",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "PWQ",
    "countryCode": "KZ",
    "timezone": "Asia/Almaty",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "PWR",
    "countryCode": "US",
    "timezone": "America/Sitka",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PWR",
    "countryCode": "US",
    "timezone": "America/Sitka",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PWT",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "PWT",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "PWY",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "PWY",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "PXA",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PXA",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PXA",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PXB",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PXC",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PXG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "PXG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "PXH",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "PXH",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "PXL",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "PXL",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "PXM",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "PXM",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "PXO",
    "countryCode": "PT",
    "timezone": "Atlantic/Madeira",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "PXO",
    "countryCode": "PT",
    "timezone": "Atlantic/Madeira",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "PXR",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PXS",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PXS",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PXU",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PXU",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PYA",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PYA",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PYB",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "PYB",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "PYC",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PYC",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PYE",
    "countryCode": "CK",
    "timezone": "Pacific/Rarotonga",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "PYE",
    "countryCode": "CK",
    "timezone": "Pacific/Rarotonga",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "PYG",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "PYG",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "PYG",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "PYH",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "PYH",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "PYJ",
    "countryCode": "RU",
    "timezone": "Asia/Yakutsk",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "PYJ",
    "countryCode": "RU",
    "timezone": "Asia/Yakutsk",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "PYK",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "PYK",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "PYL",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "PYM",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PYM",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "PYN",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PYO",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PYO",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PYR",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "PYS",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "PYT",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PYT",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "PYV",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PYX",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PYX",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PYY",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PYY",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "PZA",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "PZB",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "PZB",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "PZC",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "PZE",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "PZE",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "PZH",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "PZH",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "PZI",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "PZI",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "PZJ",
    "countryCode": "SI",
    "timezone": "Europe/Ljubljana",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PZK",
    "countryCode": "CK",
    "timezone": "Pacific/Rarotonga",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "PZL",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "PZL",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "PZO",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "PZO",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "PZO",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "PZS",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "PZU",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "PZU",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "PZY",
    "countryCode": "SK",
    "timezone": "Europe/Bratislava",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "PZY",
    "countryCode": "SK",
    "timezone": "Europe/Bratislava",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QAC",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QAC",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QAD",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QAE",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QAG",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QAH",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QAI",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QAJ",
    "countryCode": "AE",
    "timezone": "Asia/Dubai",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "QAK",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QAK",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QAL",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QAM",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QAM",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QAN",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QAO",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QAP",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QAQ",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QAR",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QAT",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QAU",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QAV",
    "countryCode": "BR",
    "timezone": "America/Eirunepe",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "QAX",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QAY",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "QAZ",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QBA",
    "countryCode": "ME",
    "timezone": "Europe/Podgorica",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QBB",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QBC",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "QBC",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "QBD",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QBE",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "QBF",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QBG",
    "countryCode": "RS",
    "timezone": "Europe/Belgrade",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QBH",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QBI",
    "countryCode": "MK",
    "timezone": "Europe/Skopje",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QBJ",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QBK",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QBL",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "QBL",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "QBM",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QBN",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QBO",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QBP",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QBQ",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QBR",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "QBS",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QBT",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QBU",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QBV",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QBW",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "QBX",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QBX",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QBY",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "QBZ",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QCA",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QCB",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "QCC",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QCD",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QCE",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "QCF",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QCG",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QCH",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QCH",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QCI",
    "countryCode": "ES",
    "timezone": "Atlantic/Canary",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "QCJ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QCJ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QCK",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -2,
      "dst": -3
    }
  },
  {
    "code": "QCL",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QCM",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QCN",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QCN",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QCN",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QCO",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "QCP",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QCQ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QCR",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QCR",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QCS",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QCT",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QCU",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "QCU",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "QCV",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QCW",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QCX",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QCY",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "QCY",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "QCZ",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QDA",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -2,
      "dst": -3
    }
  },
  {
    "code": "QDA",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QDB",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QDC",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QDC",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QDD",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "QDE",
    "countryCode": "BR",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QDF",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QDF",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QDG",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QDH",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "QDH",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "QDI",
    "countryCode": "AT",
    "timezone": "Europe/Vienna",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QDJ",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QDJ",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QDK",
    "countryCode": "US",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QDL",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QDL",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -2,
      "dst": -3
    }
  },
  {
    "code": "QDM",
    "countryCode": "HK",
    "timezone": "Asia/Hong_Kong",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "QDN",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "QDO",
    "countryCode": "BR",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QDP",
    "countryCode": "BR",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QDQ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QDR",
    "countryCode": "SY",
    "timezone": "Asia/Damascus",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QDS",
    "countryCode": "BR",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QDT",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QDU",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QDV",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QDV",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QDW",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QDX",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "QDY",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QDZ",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QEA",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QEB",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QEC",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QED",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QEE",
    "countryCode": "MH",
    "timezone": "Pacific/Kwajalein",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "QEF",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QEF",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QEG",
    "countryCode": "PT",
    "timezone": "Atlantic/Azores",
    "offset": {
      "gmt": -1,
      "dst": 0
    }
  },
  {
    "code": "QEH",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QEI",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QEJ",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QEK",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QEL",
    "countryCode": "AU",
    "timezone": "Pacific/Rarotonga",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QEM",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QEN",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QEO",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QEP",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QEQ",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QER",
    "countryCode": "YE",
    "timezone": "Asia/Aden",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QES",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QET",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QEU",
    "countryCode": "BR",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QEV",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QEW",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "QEW",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "QEX",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QEX",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QEY",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "QEZ",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QFA",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QFB",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QFB",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QFC",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QFD",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QFD",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QFE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "QFF",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "QFG",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "QFG",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "QFH",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QFI",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "QFI",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "QFJ",
    "countryCode": "GL",
    "timezone": "America/Godthab",
    "offset": {
      "gmt": -3,
      "dst": -2
    }
  },
  {
    "code": "QFK",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QFL",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QFM",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QFN",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "QFN",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "QFO",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "QFO",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "QFP",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QFQ",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QFR",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QFS",
    "countryCode": "BR",
    "timezone": "Indian/Comoro",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QFT",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "QFT",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "QFU",
    "countryCode": "ES",
    "timezone": "Atlantic/Canary",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "QFV",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QFW",
    "countryCode": "US",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QFX",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "QFX",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "QFY",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "QFZ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QGA",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QGA",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -2,
      "dst": -3
    }
  },
  {
    "code": "QGB",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QGB",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QGC",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QGD",
    "countryCode": "BR",
    "timezone": "America/Santarem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QGE",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QGF",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QGG",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "QGH",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "QGI",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QGJ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QGK",
    "countryCode": "BR",
    "timezone": "Indian/Mauritius",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QGL",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QGM",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QGN",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QGN",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QGO",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QGP",
    "countryCode": "BR",
    "timezone": "America/Recife",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QGP",
    "countryCode": "BR",
    "timezone": "America/Recife",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QGQ",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "QGQ",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "QGR",
    "countryCode": "GL",
    "timezone": "America/Godthab",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QGS",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QGS",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QGT",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "QGU",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QGV",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QGW",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QGW",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QGX",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QGY",
    "countryCode": "HU",
    "timezone": "Europe/Budapest",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QGY",
    "countryCode": "HU",
    "timezone": "Europe/Budapest",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QGZ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QHA",
    "countryCode": "BE",
    "timezone": "Europe/Brussels",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QHA",
    "countryCode": "BE",
    "timezone": "Europe/Brussels",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QHB",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QHB",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QHC",
    "countryCode": "BR",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QHD",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QHE",
    "countryCode": "BR",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QHF",
    "countryCode": "BR",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QHG",
    "countryCode": "BR",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QHH",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QHI",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "QHJ",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QHK",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 4
    }
  },
  {
    "code": "QHL",
    "countryCode": "BR",
    "timezone": "Africa/Mogadishu",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QHM",
    "countryCode": "SY",
    "timezone": "Asia/Damascus",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QHN",
    "countryCode": "BR",
    "timezone": "America/Araguaina",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QHN",
    "countryCode": "BR",
    "timezone": "America/Araguaina",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QHO",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "QHP",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QHQ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QHR",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "QHS",
    "countryCode": "SY",
    "timezone": "Asia/Damascus",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "QHT",
    "countryCode": "BR",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QHU",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QHU",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QHV",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QHV",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QHW",
    "countryCode": "US",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QHX",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "QHY",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QHZ",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QIA",
    "countryCode": "BR",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QIB",
    "countryCode": "BR",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QIC",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QID",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QIE",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QIF",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "QIG",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QIG",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QIH",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QII",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QIJ",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QIJ",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QIK",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QIL",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QIM",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QIN",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "QIO",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QIP",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QIQ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QIQ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QIR",
    "countryCode": "JO",
    "timezone": "Asia/Amman",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "QIS",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QIT",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QIT",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QIU",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QIV",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "QIW",
    "countryCode": "AE",
    "timezone": "Asia/Dubai",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QIX",
    "countryCode": "BR",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QIY",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "QIZ",
    "countryCode": "TN",
    "timezone": "Africa/Tunis",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QIZ",
    "countryCode": "TN",
    "timezone": "Africa/Tunis",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QJA",
    "countryCode": "BR",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QJB",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QJC",
    "countryCode": "BT",
    "timezone": "Asia/Thimphu",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QJD",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "QJD",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "QJE",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "QJE",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "QJF",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "QJG",
    "countryCode": "GL",
    "timezone": "America/Godthab",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QJH",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "QJH",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "QJI",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "QJI",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "QJJ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QJK",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QJL",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QJM",
    "countryCode": "BR",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QJN",
    "countryCode": "LB",
    "timezone": "Asia/Beirut",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QJO",
    "countryCode": "BR",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QJP",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QJQ",
    "countryCode": "LB",
    "timezone": "Asia/Beirut",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QJR",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QJS",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QJT",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "QJU",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "QJV",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QJW",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QJX",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "QJY",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QJZ",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QKA",
    "countryCode": "BR",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QKB",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QKC",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 4
    }
  },
  {
    "code": "QKD",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QKE",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "QKE",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "QKF",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QKF",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QKG",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QKH",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QKI",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QKJ",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QKK",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QKL",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QKM",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "QKN",
    "countryCode": "TN",
    "timezone": "Africa/Tunis",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QKN",
    "countryCode": "TN",
    "timezone": "Africa/Tunis",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QKO",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QKP",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QKQ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QKR",
    "countryCode": "GF",
    "timezone": "America/Cayenne",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QKR",
    "countryCode": "GF",
    "timezone": "America/Cayenne",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QKS",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QKT",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "QKU",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QKV",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "QKV",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "QKW",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QKX",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QKY",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "QKZ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QLA",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "QLA",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "QLB",
    "countryCode": "BR",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QLC",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QLD",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QLD",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QLE",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "QLF",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "QLF",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "QLF",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "QLG",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QLH",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QLI",
    "countryCode": "CY",
    "timezone": "Asia/Nicosia",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "QLJ",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QLK",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QLL",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QLM",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QLN",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QLO",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QLP",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QLP",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QLQ",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QLR",
    "countryCode": "PT",
    "timezone": "Europe/Lisbon",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "QLR",
    "countryCode": "PT",
    "timezone": "Europe/Lisbon",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "QLS",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QLS",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QLT",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QLT",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QLU",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QLU",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QLV",
    "countryCode": "AR",
    "timezone": "America/Argentina/Buenos_Aires",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QLW",
    "countryCode": "BR",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QLX",
    "countryCode": "AT",
    "timezone": "Europe/Vienna",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QLX",
    "countryCode": "AT",
    "timezone": "Europe/Vienna",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QLY",
    "countryCode": "ES",
    "timezone": "Atlantic/Canary",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "QLZ",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QMA",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "QMB",
    "countryCode": "BR",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QMC",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QMD",
    "countryCode": "JO",
    "timezone": "Asia/Amman",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QME",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QMF",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QMF",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QMG",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QMH",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QMI",
    "countryCode": "BR",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QMJ",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QMK",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "QMK",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "QML",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "QMM",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QMM",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QMN",
    "countryCode": "SZ",
    "timezone": "Africa/Mbabane",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "QMO",
    "countryCode": "BE",
    "timezone": "Europe/Brussels",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QMP",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QMQ",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "QMR",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QMS",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QMU",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QMV",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "QMW",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QMX",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "QMY",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "QMZ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QNB",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "QNC",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QNC",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QND",
    "countryCode": "RS",
    "timezone": "Europe/Belgrade",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QND",
    "countryCode": "RS",
    "timezone": "Europe/Belgrade",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QNE",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QNF",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "QNG",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QNH",
    "countryCode": "BR",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QNI",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QNJ",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QNJ",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QNK",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QNL",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QNM",
    "countryCode": "BE",
    "timezone": "Europe/Brussels",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QNM",
    "countryCode": "BE",
    "timezone": "Europe/Brussels",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QNN",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QNO",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QNP",
    "countryCode": "CY",
    "timezone": "Asia/Famagusta",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "QNQ",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QNQ",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QNR",
    "countryCode": "BR",
    "timezone": "America/Araguaina",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QNS",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QNT",
    "countryCode": "BR",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QNU",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QNV",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QNW",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "QNX",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QNZ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QOA",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QOA",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QOB",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QOB",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QOC",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QOD",
    "countryCode": "BR",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QOE",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QOE",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QOF",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "QOG",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QOH",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "QOI",
    "countryCode": "BR",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QOJ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QOJ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -2,
      "dst": -3
    }
  },
  {
    "code": "QOK",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QOL",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QOM",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QON",
    "countryCode": "BE",
    "timezone": "Europe/Brussels",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QOO",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QOP",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QOQ",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "QOQ",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "QOR",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "QOT",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "QOU",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QOV",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "QOW",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QOW",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QOX",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QOY",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QOZ",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QPA",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QPA",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QPB",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QPC",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QPD",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "QPD",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QPE",
    "countryCode": "BR",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QPF",
    "countryCode": "BR",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QPG",
    "countryCode": "SG",
    "timezone": "Asia/Singapore",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "QPH",
    "countryCode": "BW",
    "timezone": "Africa/Gaborone",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "QPH",
    "countryCode": "BW",
    "timezone": "Africa/Gaborone",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "QPI",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QPJ",
    "countryCode": "HU",
    "timezone": "Europe/Budapest",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QPK",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QPK",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QPL",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QPM",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QPN",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QPO",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QPP",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QPQ",
    "countryCode": "AR",
    "timezone": "America/Argentina/Buenos_Aires",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QPR",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QPS",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QPS",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QPT",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QPT",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QPU",
    "countryCode": "BR",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QPV",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QPW",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "QPW",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "QPX",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "QPY",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "QPZ",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QQA",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QQB",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QQC",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QQD",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "QQE",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QQF",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QQG",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QQH",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "QQI",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QQJ",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QQK",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "QQL",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QQM",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QQN",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QQO",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QQP",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "QQR",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "QQS",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "QQT",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QQU",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "QQV",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QQW",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "QQX",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "QQX",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "QQY",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "QQZ",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QRA",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "QRB",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QRC",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "QRC",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "QRD",
    "countryCode": "BR",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QRE",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QRE",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QRF",
    "countryCode": "AR",
    "timezone": "America/Argentina/Buenos_Aires",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QRF",
    "countryCode": "AR",
    "timezone": "America/Argentina/Buenos_Aires",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QRG",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QRH",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QRI",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "QRJ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QRK",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QRL",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QRM",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "QRM",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "QRN",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "QRO",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "QRO",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "QRP",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QRQ",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "QRR",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "QRR",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "QRS",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "QRT",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QRU",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QRV",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QRV",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QRW",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QRW",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QRX",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "QRY",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "QRY",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "QRZ",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QRZ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -2,
      "dst": -3
    }
  },
  {
    "code": "QSA",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QSA",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QSB",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QSC",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QSC",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QSD",
    "countryCode": "BR",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QSE",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QSF",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QSF",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QSG",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QSH",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QSI",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "QSI",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "QSJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QSK",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QSL",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QSM",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QSN",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "QSN",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "QSO",
    "countryCode": "TN",
    "timezone": "Africa/Tunis",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QSP",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "QSQ",
    "countryCode": "LB",
    "timezone": "Asia/Beirut",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "QSR",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QSR",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QSS",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QST",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QSU",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QSV",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "QSW",
    "countryCode": "SY",
    "timezone": "Asia/Damascus",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "QSX",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "QSX",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "QSY",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QSZ",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "QSZ",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "QTA",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QTC",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QTD",
    "countryCode": "BR",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QTE",
    "countryCode": "BR",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QTF",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QTG",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QTH",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QTI",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QTJ",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QTJ",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QTK",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QTL",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QTM",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "QTN",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "QTO",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QTP",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QTR",
    "countryCode": "SY",
    "timezone": "Asia/Damascus",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QTS",
    "countryCode": "US",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QTT",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QTU",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QTV",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QTW",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "QTX",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QTY",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QTZ",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "QUA",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QUB",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "QUB",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "QUC",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QUD",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QUE",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "QUF",
    "countryCode": "UA",
    "timezone": "Europe/Uzhgorod",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "QUG",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "QUG",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "QUH",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QUI",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "QUJ",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QUK",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "QUL",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QUL",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QUM",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "QUN",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "QUO",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QUO",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QUP",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "QUP",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "QUQ",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QUQ",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QUR",
    "countryCode": "BR",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QUS",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QUS",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QUT",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QUU",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QUV",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "QUV",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "QUW",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "QUW",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "QUX",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QUY",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "QUZ",
    "countryCode": "ES",
    "timezone": "Atlantic/Canary",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "QVA",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QVA",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QVB",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QVB",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -2,
      "dst": -3
    }
  },
  {
    "code": "QVC",
    "countryCode": "BR",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QVD",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QVE",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "QVE",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "QVF",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "QVG",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QVH",
    "countryCode": "BR",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QVI",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QVJ",
    "countryCode": "BG",
    "timezone": "Europe/Sofia",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "QVK",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "QVL",
    "countryCode": "AT",
    "timezone": "Europe/Vienna",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QVL",
    "countryCode": "AT",
    "timezone": "Europe/Vienna",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QVM",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QVN",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QVO",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QVP",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QVQ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QVR",
    "countryCode": "BR",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QVS",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "QVT",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QVU",
    "countryCode": "LI",
    "timezone": "Europe/Vaduz",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QVV",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "QVW",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QVX",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QVX",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QVX",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QVX",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QVY",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QVZ",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QWA",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "QWB",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QWC",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QWD",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QWF",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QWG",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "QWH",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QWI",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QWJ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QWK",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QWL",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QWM",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QWN",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QWO",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QWP",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "QWP",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "QWQ",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QWR",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QWS",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QWT",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QWU",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QWU",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QWV",
    "countryCode": "RS",
    "timezone": "Europe/Belgrade",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QWW",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QWX",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QWX",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QWY",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QWZ",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QXB",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QXB",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QXC",
    "countryCode": "BR",
    "timezone": "America/Maceio",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QXD",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QXE",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QXF",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QXG",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QXH",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QXI",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QXJ",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "QXK",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QXL",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "QXM",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "QXN",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QXO",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "QXP",
    "countryCode": "MK",
    "timezone": "Europe/Skopje",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QXQ",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QXR",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QXS",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QXT",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QXV",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QXW",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "QXX",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QXY",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QXZ",
    "countryCode": "AT",
    "timezone": "Europe/Vienna",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QXZ",
    "countryCode": "AT",
    "timezone": "Europe/Vienna",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QYA",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QYB",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QYC",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QYD",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QYE",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QYF",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QYG",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QYH",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QYH",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QYI",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QYJ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QYK",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "QYL",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QYM",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QYN",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "QYO",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QYO",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QYP",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QYQ",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QYR",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QYR",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QYS",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QYT",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QYU",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QYV",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QYW",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QYX",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QYX",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QYY",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QYY",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QYZ",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QZA",
    "countryCode": "JO",
    "timezone": "Asia/Amman",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "QZB",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QZC",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QZD",
    "countryCode": "HU",
    "timezone": "Europe/Budapest",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QZE",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QZF",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QZG",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QZH",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QZI",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QZJ",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "QZK",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QZL",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "QZM",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QZN",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "QZO",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QZP",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "QZQ",
    "countryCode": "LB",
    "timezone": "Asia/Beirut",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "QZR",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QZS",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QZT",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "QZU",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "QZV",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QZW",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QZX",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "QZY",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "QZZ",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "RAA",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "RAB",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "RAB",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "RAC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RAC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RAD",
    "countryCode": "VG",
    "timezone": "America/Tortola",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "RAE",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "RAE",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "RAF",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "RAF",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "RAG",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "RAG",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "RAH",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "RAH",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "RAI",
    "countryCode": "CV",
    "timezone": "Atlantic/Cape_Verde",
    "offset": {
      "gmt": -1,
      "dst": -1
    }
  },
  {
    "code": "RAI",
    "countryCode": "CV",
    "timezone": "Atlantic/Cape_Verde",
    "offset": {
      "gmt": -1,
      "dst": -1
    }
  },
  {
    "code": "RAJ",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RAJ",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RAK",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "RAK",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "RAL",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "RAL",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "RAM",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "RAN",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RAN",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RAO",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "RAO",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "RAP",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "RAP",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "RAQ",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "RAQ",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "RAR",
    "countryCode": "CK",
    "timezone": "Pacific/Rarotonga",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "RAR",
    "countryCode": "CK",
    "timezone": "Pacific/Rarotonga",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "RAR",
    "countryCode": "CK",
    "timezone": "Pacific/Rarotonga",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "RAS",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "RAS",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "RAT",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "RAT",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "RAU",
    "countryCode": "BD",
    "timezone": "Asia/Dhaka",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "RAV",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "RAV",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "RAW",
    "countryCode": "PG",
    "timezone": "Pacific/Bougainville",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "RAW",
    "countryCode": "PG",
    "timezone": "Pacific/Bougainville",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "RAX",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "RAZ",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RAZ",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RBA",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "RBA",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "RBB",
    "countryCode": "BR",
    "timezone": "America/Manaus",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "RBB",
    "countryCode": "BR",
    "timezone": "America/Manaus",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "RBC",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "RBC",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "RBD",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RBE",
    "countryCode": "KH",
    "timezone": "Asia/Phnom_Penh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "RBF",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "RBG",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "RBG",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "RBH",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "RBI",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "RBI",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "RBJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "RBJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "RBK",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "RBK",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "RBL",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "RBL",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "RBM",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RBM",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RBN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "RBN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "RBO",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "RBO",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "RBP",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "RBQ",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "RBQ",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "RBR",
    "countryCode": "BR",
    "timezone": "America/Rio_Branco",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "RBR",
    "countryCode": "BR",
    "timezone": "America/Rio_Branco",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "RBS",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "RBS",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "RBT",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "RBT",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "RBU",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "RBU",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "RBV",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "RBV",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "RBW",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "RBW",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "RBX",
    "countryCode": "SS",
    "timezone": "Africa/Juba",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "RBX",
    "countryCode": "SS",
    "timezone": "Africa/Juba",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "RBY",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "RBY",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "RBZ",
    "countryCode": "UZ",
    "timezone": "Asia/Samarkand",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RBZ",
    "countryCode": "UZ",
    "timezone": "Asia/Samarkand",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RCA",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "RCB",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "RCB",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "RCC",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RCE",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "RCE",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "RCH",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "RCH",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "RCK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RCK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RCL",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "RCM",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "RCM",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "RCN",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "RCO",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RCO",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RCP",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "RCQ",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "RCQ",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "RCR",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "RCR",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "RCS",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "RCS",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "RCT",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "RCT",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "RCU",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "RCU",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "RCY",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "RCY",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "RCY",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "RCY",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "RDA",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "RDB",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "RDB",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "RDC",
    "countryCode": "BR",
    "timezone": "America/Santarem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "RDC",
    "countryCode": "BR",
    "timezone": "America/Belem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "RDD",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "RDD",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "RDE",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "RDG",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "RDG",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "RDL",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "RDL",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "RDM",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "RDM",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "RDN",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "RDN",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "RDO",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RDO",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RDP",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RDP",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RDR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RDS",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "RDS",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "RDT",
    "countryCode": "SN",
    "timezone": "Africa/Dakar",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "RDT",
    "countryCode": "SN",
    "timezone": "Africa/Dakar",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "RDU",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "RDU",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "RDU",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "RDV",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "RDV",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "RDZ",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RDZ",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "REA",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "REA",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "REB",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "REB",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "REC",
    "countryCode": "BR",
    "timezone": "America/Recife",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "REC",
    "countryCode": "BR",
    "timezone": "America/Recife",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "RED",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "RED",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "REE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "REG",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "REG",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "REH",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "REI",
    "countryCode": "GF",
    "timezone": "America/Cayenne",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "REI",
    "countryCode": "GF",
    "timezone": "America/Cayenne",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "REK",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "REL",
    "countryCode": "AR",
    "timezone": "America/Argentina/Catamarca",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "REL",
    "countryCode": "AR",
    "timezone": "America/Argentina/Catamarca",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "REN",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "REN",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "REO",
    "countryCode": "US",
    "timezone": "America/Boise",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "REO",
    "countryCode": "US",
    "timezone": "America/Boise",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "REP",
    "countryCode": "KH",
    "timezone": "Asia/Phnom_Penh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "REP",
    "countryCode": "KH",
    "timezone": "Asia/Phnom_Penh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "REQ",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "REQ",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RER",
    "countryCode": "GT",
    "timezone": "America/Guatemala",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "RER",
    "countryCode": "GT",
    "timezone": "America/Guatemala",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "RES",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "RES",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "RET",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RET",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "REU",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "REU",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "REW",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "REW",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "REX",
    "countryCode": "MX",
    "timezone": "America/Matamoros",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "REX",
    "countryCode": "MX",
    "timezone": "America/Matamoros",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "REY",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "REY",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "REZ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "REZ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "RFA",
    "countryCode": "CF",
    "timezone": "Africa/Bangui",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "RFD",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RFG",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RFG",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RFK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RFK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RFN",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "RFN",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "RFP",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "RFP",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "RFP",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "RFR",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "RFS",
    "countryCode": "NI",
    "timezone": "America/Managua",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "RFS",
    "countryCode": "NI",
    "timezone": "America/Managua",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "RGA",
    "countryCode": "AR",
    "timezone": "America/Argentina/Ushuaia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "RGA",
    "countryCode": "AR",
    "timezone": "America/Argentina/Ushuaia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "RGD",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RGE",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "RGH",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RGH",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RGI",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "RGI",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "RGK",
    "countryCode": "RU",
    "timezone": "Asia/Barnaul",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "RGK",
    "countryCode": "RU",
    "timezone": "Asia/Barnaul",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "RGL",
    "countryCode": "AR",
    "timezone": "America/Argentina/Rio_Gallegos",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "RGL",
    "countryCode": "AR",
    "timezone": "America/Argentina/Rio_Gallegos",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "RGN",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "RGN",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "RGO",
    "countryCode": "KP",
    "timezone": "Asia/Pyongyang",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "RGO",
    "countryCode": "KP",
    "timezone": "Asia/Pyongyang",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "RGR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RGR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RGS",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RGS",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RGT",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "RGT",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "RGT",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "RHA",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "RHD",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "RHD",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "RHE",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RHE",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RHG",
    "countryCode": "RW",
    "timezone": "Africa/Kigali",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "RHG",
    "countryCode": "RW",
    "timezone": "Africa/Kigali",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "RHI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RHI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RHL",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "RHL",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "RHN",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "RHN",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "RHO",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "RHO",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "RHP",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RHP",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RHP",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RHR",
    "countryCode": "AE",
    "timezone": "Asia/Dubai",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "RHR",
    "countryCode": "AE",
    "timezone": "Asia/Dubai",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "RHT",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "RHT",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "RHV",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "RIA",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "RIA",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "RIB",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "RIB",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "RIC",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "RIC",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "RID",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "RID",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "RIE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RIE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RIF",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "RIF",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "RIG",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "RIG",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "RIH",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "RIH",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "RII",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RIJ",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "RIJ",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "RIK",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "RIK",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "RIL",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "RIL",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "RIM",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "RIM",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "RIN",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "RIN",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "RIO",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "RIR",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "RIS",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "RIS",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "RIT",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "RIV",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "RIW",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "RIW",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "RIX",
    "countryCode": "LV",
    "timezone": "Europe/Riga",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "RIX",
    "countryCode": "LV",
    "timezone": "Europe/Riga",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "RIY",
    "countryCode": "YE",
    "timezone": "Asia/Aden",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "RIY",
    "countryCode": "YE",
    "timezone": "Asia/Aden",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "RIZ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "RIZ",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "RIZ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "RJA",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RJA",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RJB",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RJH",
    "countryCode": "BD",
    "timezone": "Asia/Dhaka",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "RJH",
    "countryCode": "BD",
    "timezone": "Asia/Dhaka",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "RJI",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RJK",
    "countryCode": "HR",
    "timezone": "Europe/Zagreb",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RJK",
    "countryCode": "HR",
    "timezone": "Europe/Zagreb",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RJK",
    "countryCode": "HR",
    "timezone": "Europe/Zagreb",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RJL",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RJL",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RJM",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "RJM",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "RJN",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "RJN",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "RKA",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "RKA",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "RKC",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "RKC",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "RKD",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "RKD",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "RKE",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RKH",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "RKH",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "RKI",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "RKI",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "RKO",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "RKP",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RKP",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RKR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RKR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RKS",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "RKS",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "RKT",
    "countryCode": "AE",
    "timezone": "Asia/Dubai",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "RKT",
    "countryCode": "AE",
    "timezone": "Asia/Dubai",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "RKU",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "RKV",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "RKW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RKW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RKY",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "RKZ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "RKZ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "RLA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RLA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RLD",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "RLG",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RLG",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RLK",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "RLK",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "RLO",
    "countryCode": "AR",
    "timezone": "America/Argentina/San_Luis",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "RLO",
    "countryCode": "AR",
    "timezone": "America/Argentina/San_Luis",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "RLP",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "RLR",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "RLT",
    "countryCode": "NE",
    "timezone": "Africa/Niamey",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "RLT",
    "countryCode": "NE",
    "timezone": "Africa/Niamey",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "RLU",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "RLZ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "RMA",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "RMA",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "RMB",
    "countryCode": "OM",
    "timezone": "Asia/Muscat",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "RMB",
    "countryCode": "OM",
    "timezone": "Asia/Muscat",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "RMC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RMC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RMC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RMD",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RMD",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RMD",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RME",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "RME",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "RMF",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "RMF",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "RMG",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "RMG",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "RMI",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RMI",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RMK",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "RMK",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "RML",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RMN",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "RMN",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "RMO",
    "countryCode": "MD",
    "timezone": "Europe/Chisinau",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "RMO",
    "countryCode": "MD",
    "timezone": "Europe/Chisinau",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "RMP",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "RMP",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "RMQ",
    "countryCode": "TW",
    "timezone": "Asia/Taipei",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "RMQ",
    "countryCode": "TW",
    "timezone": "Asia/Taipei",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "RMS",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RMS",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RMT",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "RMT",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "RMU",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RMU",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RMY",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "RMY",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "RMZ",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RNA",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "RNA",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "RNB",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RNB",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RNC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RNC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RND",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RNE",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RNE",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RNG",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "RNH",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RNH",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RNI",
    "countryCode": "NI",
    "timezone": "America/Managua",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "RNI",
    "countryCode": "NI",
    "timezone": "America/Managua",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "RNJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "RNJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "RNL",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "RNL",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "RNL",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "RNM",
    "countryCode": "OM",
    "timezone": "Asia/Muscat",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "RNM",
    "countryCode": "OM",
    "timezone": "Asia/Muscat",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "RNN",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RNN",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RNO",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "RNO",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "RNP",
    "countryCode": "MH",
    "timezone": "Pacific/Kwajalein",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "RNR",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "RNS",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RNS",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RNT",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "RNT",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "RNU",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "RNU",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "RNW",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "RNW",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "RNX",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "RNX",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "RNZ",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RNZ",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ROA",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ROA",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ROB",
    "countryCode": "LR",
    "timezone": "Africa/Monrovia",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "ROC",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ROC",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ROD",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "ROD",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "ROF",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ROG",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ROG",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ROH",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ROI",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "ROI",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "ROK",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ROK",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ROL",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "ROL",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "ROM",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RON",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "RON",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ROO",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "ROO",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "ROP",
    "countryCode": "MP",
    "timezone": "Pacific/Saipan",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ROP",
    "countryCode": "MP",
    "timezone": "Pacific/Saipan",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ROR",
    "countryCode": "PW",
    "timezone": "Pacific/Palau",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "ROR",
    "countryCode": "PW",
    "timezone": "Pacific/Palau",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "ROS",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ROS",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ROT",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "ROT",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "ROU",
    "countryCode": "BG",
    "timezone": "Europe/Sofia",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "ROU",
    "countryCode": "BG",
    "timezone": "Europe/Sofia",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "ROV",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ROV",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ROV",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ROW",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "ROW",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "ROX",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ROY",
    "countryCode": "AR",
    "timezone": "America/Argentina/Catamarca",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ROZ",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ROZ",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RPA",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RPB",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "RPB",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "RPM",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "RPM",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "RPN",
    "countryCode": "IL",
    "timezone": "Asia/Jerusalem",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "RPN",
    "countryCode": "IL",
    "timezone": "Asia/Jerusalem",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "RPR",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RPR",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RPU",
    "countryCode": "BR",
    "timezone": "America/Manaus",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "RPV",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "RPV",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "RPX",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "RPX",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "RPZ",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RQA",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "RQA",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "RQW",
    "countryCode": "IQ",
    "timezone": "Asia/Baghdad",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "RQY",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RQY",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RRA",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RRA",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RRB",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "RRE",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "RRE",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "RRG",
    "countryCode": "MU",
    "timezone": "Indian/Mauritius",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "RRG",
    "countryCode": "MU",
    "timezone": "Indian/Mauritius",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "RRG",
    "countryCode": "MU",
    "timezone": "Indian/Mauritius",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "RRI",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "RRJ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "RRJ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "RRJ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "RRK",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RRK",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RRL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RRL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RRM",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "RRN",
    "countryCode": "BR",
    "timezone": "America/Santarem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "RRN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "RRO",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RRR",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "RRR",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "RRS",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RRS",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RRT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RRT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RRV",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "RSA",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "RSA",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "RSB",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "RSC",
    "countryCode": "LV",
    "timezone": "Europe/Riga",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "RSD",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "RSD",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "RSD",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "RSE",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "RSG",
    "countryCode": "BR",
    "timezone": "America/Santarem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "RSH",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "RSH",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "RSI",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "RSI",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "RSI",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "RSJ",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "RSJ",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "RSK",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "RSK",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "RSL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RSL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RSN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RSN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RSP",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "RSS",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "RSS",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "RST",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RST",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RSU",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "RSU",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "RSW",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "RSX",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "RSX",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "RSY",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "RTA",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "RTA",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "RTB",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "RTB",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "RTC",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RTC",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RTD",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "RTD",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "RTD",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "RTE",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "RTE",
    "countryCode": "US",
    "timezone": "America/Sitka",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "RTG",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "RTG",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "RTI",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "RTI",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "RTK",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RTL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RTL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RTM",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RTM",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RTN",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "RTN",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "RTO",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "RTO",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "RTP",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "RTP",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "RTS",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "RTS",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "RTW",
    "countryCode": "RU",
    "timezone": "Europe/Saratov",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "RTW",
    "countryCode": "RU",
    "timezone": "Europe/Saratov",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "RTY",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "RUA",
    "countryCode": "UG",
    "timezone": "Africa/Kampala",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "RUA",
    "countryCode": "UG",
    "timezone": "Africa/Kampala",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "RUD",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "RUD",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "RUE",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "RUE",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "RUF",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "RUG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "RUH",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "RUH",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "RUI",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "RUI",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "RUJ",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "RUK",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RUK",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RUL",
    "countryCode": "MV",
    "timezone": "Indian/Maldives",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RUL",
    "countryCode": "MV",
    "timezone": "Indian/Maldives",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RUL",
    "countryCode": "MV",
    "timezone": "Indian/Maldives",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RUM",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RUM",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RUN",
    "countryCode": "RE",
    "timezone": "Indian/Reunion",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "RUN",
    "countryCode": "RE",
    "timezone": "Indian/Reunion",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "RUP",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RUP",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RUR",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "RUR",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "RUS",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "RUS",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "RUT",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "RUT",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "RUU",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "RUV",
    "countryCode": "GT",
    "timezone": "America/Guatemala",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "RUV",
    "countryCode": "GT",
    "timezone": "America/Guatemala",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "RUY",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "RUY",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "RVA",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "RVA",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "RVC",
    "countryCode": "LR",
    "timezone": "Africa/Monrovia",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "RVD",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "RVD",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "RVE",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "RVE",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "RVH",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "RVI",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "RVK",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RVK",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RVN",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "RVN",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "RVO",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "RVO",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "RVR",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "RVR",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "RVS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RVT",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "RVT",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "RVV",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "RVV",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "RVY",
    "countryCode": "UY",
    "timezone": "America/Montevideo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "RVY",
    "countryCode": "UY",
    "timezone": "America/Montevideo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "RWA",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RWB",
    "countryCode": "US",
    "timezone": "America/Sitka",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "RWF",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "RWI",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "RWI",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "RWL",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "RWL",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "RWN",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "RWN",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "RWP",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RWP",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RWS",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -2,
      "dst": -3
    }
  },
  {
    "code": "RWS",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -2,
      "dst": -3
    }
  },
  {
    "code": "RXA",
    "countryCode": "YE",
    "timezone": "Asia/Aden",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "RXE",
    "countryCode": "US",
    "timezone": "America/Boise",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "RXE",
    "countryCode": "US",
    "timezone": "America/Boise",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "RXS",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "RXS",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "RYB",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "RYB",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "RYG",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RYK",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RYK",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RYL",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "RYL",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "RYN",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RYN",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RYN",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RYO",
    "countryCode": "AR",
    "timezone": "America/Argentina/Rio_Gallegos",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "RYO",
    "countryCode": "AR",
    "timezone": "America/Argentina/Rio_Gallegos",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "RZA",
    "countryCode": "AR",
    "timezone": "America/Argentina/Rio_Gallegos",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "RZA",
    "countryCode": "AR",
    "timezone": "America/Argentina/Rio_Gallegos",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "RZE",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RZE",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RZG",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "RZH",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "RZH",
    "countryCode": "RU",
    "timezone": "Asia/Vladivostok",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "RZH",
    "countryCode": "RU",
    "timezone": "Asia/Vladivostok",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "RZN",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "RZP",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "RZP",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "RZR",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "RZR",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "RZS",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RZS",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "RZV",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "RZV",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "RZZ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "RZZ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SAA",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "SAA",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "SAB",
    "countryCode": "BQ",
    "timezone": "America/Kralendijk",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SAB",
    "countryCode": "BQ",
    "timezone": "America/Kralendijk",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SAC",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SAC",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SAD",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "SAD",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "SAE",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SAE",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "SAE",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "SAF",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "SAF",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "SAG",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SAG",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SAG",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SAG",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SAH",
    "countryCode": "YE",
    "timezone": "Asia/Aden",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SAH",
    "countryCode": "YE",
    "timezone": "Asia/Aden",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SAI",
    "countryCode": "KH",
    "timezone": "Asia/Phnom_Penh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "SAI",
    "countryCode": "SM",
    "timezone": "Europe/San_Marino",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SAJ",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SAJ",
    "countryCode": "BD",
    "timezone": "Asia/Dhaka",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "SAK",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "SAL",
    "countryCode": "SV",
    "timezone": "America/El_Salvador",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "SAL",
    "countryCode": "SV",
    "timezone": "America/El_Salvador",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "SAM",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SAN",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SAN",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SAO",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SAP",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "SAP",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "SAQ",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SAQ",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SAR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SAR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SAS",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SAS",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SAT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SAT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SAU",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SAU",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SAV",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SAV",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SAW",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SAX",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "SAX",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "SAY",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SAY",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SAZ",
    "countryCode": "LR",
    "timezone": "Africa/Monrovia",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "SAZ",
    "countryCode": "LR",
    "timezone": "Africa/Monrovia",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "SBA",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SBA",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SBB",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SBC",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SBD",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SBD",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SBD",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SBE",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SBF",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "SBF",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "SBG",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "SBG",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "SBH",
    "countryCode": "BL",
    "timezone": "America/St_Barthelemy",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SBH",
    "countryCode": "BL",
    "timezone": "America/St_Barthelemy",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SBI",
    "countryCode": "GN",
    "timezone": "Africa/Conakry",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "SBI",
    "countryCode": "GN",
    "timezone": "Africa/Conakry",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "SBJ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SBJ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SBK",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SBK",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SBL",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SBL",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SBM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SBM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SBN",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SBN",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SBO",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "SBP",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SBP",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SBQ",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SBQ",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SBR",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SBR",
    "countryCode": "AU",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SBS",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "SBS",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "SBT",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SBT",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SBT",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SBT",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SBU",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "SBU",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "SBV",
    "countryCode": "PG",
    "timezone": "Pacific/Bougainville",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "SBW",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SBW",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SBX",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "SBX",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "SBY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SBY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SBZ",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SBZ",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SCA",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "SCB",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SCB",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SCC",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SCC",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SCC",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SCD",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "SCD",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "SCE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SCE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SCF",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "SCF",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "SCG",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SCH",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SCH",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SCI",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SCI",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SCJ",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SCK",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SCL",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "SCL",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "SCM",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SCM",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SCN",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SCN",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SCO",
    "countryCode": "KZ",
    "timezone": "Asia/Aqtau",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SCO",
    "countryCode": "KZ",
    "timezone": "Asia/Aqtau",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SCP",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SCP",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SCQ",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SCQ",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SCR",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SCR",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SCR",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SCS",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "SCT",
    "countryCode": "YE",
    "timezone": "Asia/Aden",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SCT",
    "countryCode": "YE",
    "timezone": "Asia/Aden",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SCU",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SCU",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SCV",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SCV",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SCW",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SCW",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SCX",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "SCX",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "SCY",
    "countryCode": "EC",
    "timezone": "Pacific/Galapagos",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "SCY",
    "countryCode": "EC",
    "timezone": "Pacific/Galapagos",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "SCZ",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "SCZ",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "SDA",
    "countryCode": "IQ",
    "timezone": "Asia/Baghdad",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SDA",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SDB",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "SDC",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SDD",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "SDD",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "SDE",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SDE",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SDF",
    "countryCode": "US",
    "timezone": "America/Kentucky/Louisville",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SDF",
    "countryCode": "US",
    "timezone": "America/Kentucky/Louisville",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SDG",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SDG",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SDH",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "SDH",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "SDI",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SDI",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SDJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "SDJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "SDK",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SDK",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SDL",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SDL",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SDL",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SDL",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SDM",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SDN",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SDN",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SDO",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "SDP",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SDP",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SDQ",
    "countryCode": "DO",
    "timezone": "America/Santo_Domingo",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SDQ",
    "countryCode": "DO",
    "timezone": "America/Santo_Domingo",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SDR",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SDR",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SDS",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "SDS",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "SDT",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SDU",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SDV",
    "countryCode": "IL",
    "timezone": "Asia/Jerusalem",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SDW",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SDX",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "SDX",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "SDY",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "SDY",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "SDZ",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "SEA",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SEA",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SEB",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "SEB",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "SEC",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SED",
    "countryCode": "IL",
    "timezone": "Asia/Jerusalem",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SEE",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SEF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SEF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SEG",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SEG",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SEH",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "SEI",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SEI",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SEI",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SEJ",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "SEJ",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SEK",
    "countryCode": "RU",
    "timezone": "Asia/Magadan",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "SEK",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "SEK",
    "countryCode": "RU",
    "timezone": "Asia/Magadan",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "SEL",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "SEM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SEM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SEN",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "SEN",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "SEO",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "SEO",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "SEP",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SEQ",
    "countryCode": "ID",
    "timezone": "Asia/Pontianak",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "SER",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SER",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SES",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SES",
    "countryCode": "RU",
    "timezone": "Asia/Krasnoyarsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "SES",
    "countryCode": "RU",
    "timezone": "Asia/Krasnoyarsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "SES",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SET",
    "countryCode": "BR",
    "timezone": "America/Recife",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SET",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "SET",
    "countryCode": "BR",
    "timezone": "America/Recife",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SEU",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SEU",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SEV",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SEW",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SEW",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SEX",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SEY",
    "countryCode": "MR",
    "timezone": "Africa/Nouakchott",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "SEZ",
    "countryCode": "SC",
    "timezone": "Indian/Mahe",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "SEZ",
    "countryCode": "SC",
    "timezone": "Indian/Mahe",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "SFA",
    "countryCode": "TN",
    "timezone": "Africa/Tunis",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "SFA",
    "countryCode": "TN",
    "timezone": "Africa/Tunis",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "SFB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SFC",
    "countryCode": "GP",
    "timezone": "America/Guadeloupe",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SFC",
    "countryCode": "GP",
    "timezone": "America/Guadeloupe",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SFD",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SFD",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SFE",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SFE",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SFF",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SFG",
    "countryCode": "MF",
    "timezone": "America/Marigot",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SFG",
    "countryCode": "MF",
    "timezone": "America/Marigot",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SFG",
    "countryCode": "MF",
    "timezone": "America/Marigot",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SFH",
    "countryCode": "MX",
    "timezone": "America/Tijuana",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SFH",
    "countryCode": "MX",
    "timezone": "America/Tijuana",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SFI",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "SFI",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "SFJ",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "SFJ",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "SFK",
    "countryCode": "BR",
    "timezone": "America/Belem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SFK",
    "countryCode": "BR",
    "timezone": "America/Belem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SFL",
    "countryCode": "CV",
    "timezone": "Atlantic/Cape_Verde",
    "offset": {
      "gmt": -1,
      "dst": -1
    }
  },
  {
    "code": "SFL",
    "countryCode": "CV",
    "timezone": "Atlantic/Cape_Verde",
    "offset": {
      "gmt": -1,
      "dst": -1
    }
  },
  {
    "code": "SFL",
    "countryCode": "CV",
    "timezone": "Atlantic/Cape_Verde",
    "offset": {
      "gmt": -1,
      "dst": -1
    }
  },
  {
    "code": "SFM",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SFM",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SFN",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SFN",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SFO",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SFO",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SFP",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SFQ",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SFQ",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SFR",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SFS",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SFS",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SFT",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SFT",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SFU",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SFU",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SFV",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SFV",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SFW",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "SFX",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SFY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SFZ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SFZ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SGA",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "SGA",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "SGB",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SGC",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SGC",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SGD",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SGD",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SGE",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SGE",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SGF",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SGF",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SGG",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SGG",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "SGG",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SGG",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "SGH",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SGH",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SGI",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SGI",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SGJ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SGK",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SGK",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SGL",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SGM",
    "countryCode": "MX",
    "timezone": "America/Mazatlan",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "SGM",
    "countryCode": "MX",
    "timezone": "America/Mazatlan",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "SGN",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "SGN",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "SGO",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SGO",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SGP",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SGP",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SGQ",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SGR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SGR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SGS",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SGS",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "SGT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SGT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SGU",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "SGU",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "SGV",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SGV",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SGW",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SGX",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SGX",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SGY",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SGY",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SGZ",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "SGZ",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "SHA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SHA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SHB",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "SHB",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "SHC",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SHC",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SHD",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SHD",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SHE",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SHE",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SHF",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "SHF",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "SHG",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SHG",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SHH",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SHH",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SHI",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "SHI",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "SHJ",
    "countryCode": "AE",
    "timezone": "Asia/Dubai",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "SHJ",
    "countryCode": "AE",
    "timezone": "Asia/Dubai",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "SHK",
    "countryCode": "LS",
    "timezone": "Africa/Maseru",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "SHK",
    "countryCode": "LS",
    "timezone": "Africa/Maseru",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "SHL",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SHL",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SHM",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "SHM",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "SHN",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SHN",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SHO",
    "countryCode": "SZ",
    "timezone": "Africa/Mbabane",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "SHO",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "SHP",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SHP",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SHQ",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SHQ",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SHR",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "SHR",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "SHS",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SHS",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SHS",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SHT",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "SHT",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "SHU",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "SHU",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "SHV",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SHV",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SHW",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SHW",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SHX",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SHX",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SHY",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SHY",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SHZ",
    "countryCode": "LS",
    "timezone": "Africa/Maseru",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "SHZ",
    "countryCode": "LS",
    "timezone": "Africa/Maseru",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "SIA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SIA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SIB",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "SIB",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "SIC",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SIC",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "SIC",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SIC",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "SID",
    "countryCode": "CV",
    "timezone": "Atlantic/Cape_Verde",
    "offset": {
      "gmt": -1,
      "dst": -1
    }
  },
  {
    "code": "SID",
    "countryCode": "CV",
    "timezone": "Atlantic/Cape_Verde",
    "offset": {
      "gmt": -1,
      "dst": -1
    }
  },
  {
    "code": "SIE",
    "countryCode": "PT",
    "timezone": "Europe/Lisbon",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "SIE",
    "countryCode": "PT",
    "timezone": "Europe/Lisbon",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "SIF",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SIF",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SIG",
    "countryCode": "PR",
    "timezone": "America/Puerto_Rico",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SIH",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SII",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "SII",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "SIJ",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "SIK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SIK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SIL",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SIM",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SIM",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SIN",
    "countryCode": "SG",
    "timezone": "Asia/Singapore",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SIN",
    "countryCode": "SG",
    "timezone": "Asia/Singapore",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SIO",
    "countryCode": "AU",
    "timezone": "Australia/Hobart",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "SIO",
    "countryCode": "AU",
    "timezone": "Australia/Hobart",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "SIP",
    "countryCode": "UA",
    "timezone": "Europe/Simferopol",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SIP",
    "countryCode": "UA",
    "timezone": "Europe/Simferopol",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SIQ",
    "countryCode": "ID",
    "timezone": "Asia/Pontianak",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "SIQ",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "SIQ",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "SIR",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SIR",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SIS",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "SIS",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "SIT",
    "countryCode": "US",
    "timezone": "America/Sitka",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SIT",
    "countryCode": "US",
    "timezone": "America/Sitka",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SIU",
    "countryCode": "NI",
    "timezone": "America/Managua",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "SIU",
    "countryCode": "NI",
    "timezone": "America/Managua",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "SIV",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SIV",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SIW",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "SIW",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "SIW",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "SIX",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "SIX",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "SIY",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SIY",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SIY",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SIY",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SIZ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SJA",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "SJA",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "SJB",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SJB",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SJC",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SJC",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SJD",
    "countryCode": "MX",
    "timezone": "America/Mazatlan",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "SJD",
    "countryCode": "MX",
    "timezone": "America/Mazatlan",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "SJE",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "SJE",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "SJF",
    "countryCode": "VI",
    "timezone": "America/St_Thomas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SJF",
    "countryCode": "VI",
    "timezone": "America/St_Thomas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SJG",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "SJH",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "SJH",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "SJI",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SJI",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SJJ",
    "countryCode": "BA",
    "timezone": "Europe/Sarajevo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SJJ",
    "countryCode": "BA",
    "timezone": "Europe/Sarajevo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SJK",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SJK",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SJL",
    "countryCode": "BR",
    "timezone": "America/Boa_Vista",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SJL",
    "countryCode": "BR",
    "timezone": "America/Manaus",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SJM",
    "countryCode": "DO",
    "timezone": "America/Santo_Domingo",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SJM",
    "countryCode": "DO",
    "timezone": "America/Santo_Domingo",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SJN",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "SJO",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "SJO",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "SJP",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SJP",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SJQ",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "SJQ",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "SJR",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "SJS",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SJS",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SJT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SJT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SJU",
    "countryCode": "PR",
    "timezone": "America/Puerto_Rico",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SJU",
    "countryCode": "PR",
    "timezone": "America/Puerto_Rico",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SJV",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SJV",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SJW",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SJW",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SJX",
    "countryCode": "BZ",
    "timezone": "America/Belize",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "SJX",
    "countryCode": "BZ",
    "timezone": "America/Belize",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "SJY",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SJY",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SJY",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SJZ",
    "countryCode": "PT",
    "timezone": "Atlantic/Azores",
    "offset": {
      "gmt": -1,
      "dst": 0
    }
  },
  {
    "code": "SJZ",
    "countryCode": "PT",
    "timezone": "Atlantic/Azores",
    "offset": {
      "gmt": -1,
      "dst": 0
    }
  },
  {
    "code": "SKA",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SKB",
    "countryCode": "KN",
    "timezone": "America/St_Kitts",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SKB",
    "countryCode": "KN",
    "timezone": "America/St_Kitts",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SKB",
    "countryCode": "KN",
    "timezone": "America/St_Kitts",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SKC",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SKC",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SKD",
    "countryCode": "UZ",
    "timezone": "Asia/Samarkand",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SKD",
    "countryCode": "UZ",
    "timezone": "Asia/Samarkand",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SKE",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SKE",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SKF",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SKG",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SKG",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SKH",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SKH",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SKI",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "SKI",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "SKJ",
    "countryCode": "ID",
    "timezone": "Asia/Pontianak",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "SKJ",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SKJ",
    "countryCode": "ID",
    "timezone": "Asia/Pontianak",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "SKK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SKK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SKL",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "SKL",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "SKM",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SKM",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SKN",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SKN",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SKO",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "SKO",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "SKP",
    "countryCode": "MK",
    "timezone": "Europe/Skopje",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SKP",
    "countryCode": "MK",
    "timezone": "Europe/Skopje",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SKQ",
    "countryCode": "LS",
    "timezone": "Africa/Maseru",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "SKQ",
    "countryCode": "LS",
    "timezone": "Africa/Maseru",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "SKR",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SKR",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SKS",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SKS",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SKT",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SKT",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SKU",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SKU",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SKV",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SKV",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SKW",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SKW",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SKX",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SKX",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SKY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SKY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SKZ",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SKZ",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SLA",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SLA",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SLB",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SLB",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SLC",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "SLC",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "SLD",
    "countryCode": "SK",
    "timezone": "Europe/Bratislava",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SLD",
    "countryCode": "SK",
    "timezone": "Europe/Bratislava",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SLE",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SLE",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SLF",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SLG",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SLG",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SLH",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "SLH",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "SLI",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "SLI",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "SLJ",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SLJ",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SLK",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SLK",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SLL",
    "countryCode": "OM",
    "timezone": "Asia/Muscat",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "SLL",
    "countryCode": "OM",
    "timezone": "Asia/Muscat",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "SLM",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SLM",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SLN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SLN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SLO",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SLO",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SLP",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "SLP",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "SLQ",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SLQ",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SLR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SLR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SLS",
    "countryCode": "BG",
    "timezone": "Europe/Sofia",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SLS",
    "countryCode": "BG",
    "timezone": "Europe/Sofia",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SLT",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "SLT",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "SLU",
    "countryCode": "LC",
    "timezone": "America/St_Lucia",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SLU",
    "countryCode": "LC",
    "timezone": "America/St_Lucia",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SLV",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SLV",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SLW",
    "countryCode": "MX",
    "timezone": "America/Monterrey",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "SLW",
    "countryCode": "MX",
    "timezone": "America/Monterrey",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "SLW",
    "countryCode": "MX",
    "timezone": "America/Monterrey",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "SLX",
    "countryCode": "TC",
    "timezone": "America/Grand_Turk",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SLX",
    "countryCode": "TC",
    "timezone": "America/Grand_Turk",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SLY",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SLY",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SLZ",
    "countryCode": "BR",
    "timezone": "America/Belem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SLZ",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SMA",
    "countryCode": "PT",
    "timezone": "Atlantic/Azores",
    "offset": {
      "gmt": -1,
      "dst": 0
    }
  },
  {
    "code": "SMA",
    "countryCode": "PT",
    "timezone": "Atlantic/Azores",
    "offset": {
      "gmt": -1,
      "dst": 0
    }
  },
  {
    "code": "SMB",
    "countryCode": "CL",
    "timezone": "America/Punta_Arenas",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SMB",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "SMC",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "SMD",
    "countryCode": "US",
    "timezone": "America/Indiana/Indianapolis",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SME",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SME",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SMF",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SMG",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "SMG",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "SMH",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SMI",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SMI",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SMJ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SMK",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SMK",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SML",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SML",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SMM",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SMM",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SMN",
    "countryCode": "US",
    "timezone": "America/Boise",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "SMN",
    "countryCode": "US",
    "timezone": "America/Boise",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "SMO",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SMO",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SMP",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SMQ",
    "countryCode": "ID",
    "timezone": "Asia/Pontianak",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "SMQ",
    "countryCode": "ID",
    "timezone": "Asia/Pontianak",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "SMR",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "SMR",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "SMS",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SMS",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SMT",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SMT",
    "countryCode": "TW",
    "timezone": "Asia/Taipei",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SMT",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SMU",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SMU",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SMV",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SMV",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SMW",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "SMW",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "SMX",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SMX",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SMY",
    "countryCode": "SN",
    "timezone": "Africa/Dakar",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "SMY",
    "countryCode": "SN",
    "timezone": "Africa/Dakar",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "SMZ",
    "countryCode": "SR",
    "timezone": "America/Paramaribo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SMZ",
    "countryCode": "SR",
    "timezone": "America/Paramaribo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SNA",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SNA",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SNB",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "SNB",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "SNC",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "SNC",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "SND",
    "countryCode": "LA",
    "timezone": "Asia/Vientiane",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "SNE",
    "countryCode": "CV",
    "timezone": "Atlantic/Cape_Verde",
    "offset": {
      "gmt": -1,
      "dst": -1
    }
  },
  {
    "code": "SNE",
    "countryCode": "CV",
    "timezone": "Atlantic/Cape_Verde",
    "offset": {
      "gmt": -1,
      "dst": -1
    }
  },
  {
    "code": "SNF",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SNF",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SNG",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SNG",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SNH",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SNH",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SNI",
    "countryCode": "LR",
    "timezone": "Africa/Monrovia",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "SNI",
    "countryCode": "LR",
    "timezone": "Africa/Monrovia",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "SNJ",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SNJ",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SNK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SNL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SNL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SNM",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SNM",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SNN",
    "countryCode": "IE",
    "timezone": "Europe/Dublin",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "SNN",
    "countryCode": "IE",
    "timezone": "Europe/Dublin",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "SNO",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "SNO",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "SNP",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SNP",
    "countryCode": "US",
    "timezone": "America/Adak",
    "offset": {
      "gmt": -10,
      "dst": -9
    }
  },
  {
    "code": "SNQ",
    "countryCode": "MX",
    "timezone": "America/Tijuana",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SNQ",
    "countryCode": "MX",
    "timezone": "America/Tijuana",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SNR",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SNR",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SNS",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SNS",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SNT",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "SNU",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SNU",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SNV",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SNV",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SNW",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "SNW",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "SNX",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SNX",
    "countryCode": "DO",
    "timezone": "America/Santo_Domingo",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SNX",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SNX",
    "countryCode": "DO",
    "timezone": "America/Santo_Domingo",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SNY",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "SNY",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "SNZ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SOA",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "SOB",
    "countryCode": "HU",
    "timezone": "Europe/Budapest",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SOB",
    "countryCode": "HU",
    "timezone": "Europe/Budapest",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SOC",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "SOC",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "SOD",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SOD",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SOE",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "SOE",
    "countryCode": "CG",
    "timezone": "Africa/Brazzaville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "SOF",
    "countryCode": "BG",
    "timezone": "Europe/Sofia",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SOF",
    "countryCode": "BG",
    "timezone": "Europe/Sofia",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SOG",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SOG",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SOH",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "SOI",
    "countryCode": "AU",
    "timezone": "Australia/Lindeman",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SOI",
    "countryCode": "AU",
    "timezone": "Australia/Lindeman",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SOJ",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SOJ",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SOK",
    "countryCode": "LS",
    "timezone": "Africa/Maseru",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "SOL",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SOL",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SOM",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SOM",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SON",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "SON",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "SOO",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SOO",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SOP",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SOP",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SOQ",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "SOQ",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "SOR",
    "countryCode": "SY",
    "timezone": "Asia/Damascus",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SOT",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SOT",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SOU",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "SOU",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "SOV",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SOV",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SOW",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "SOW",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "SOX",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "SOX",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "SOY",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "SOY",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "SOZ",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SOZ",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SPA",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SPA",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SPB",
    "countryCode": "VI",
    "timezone": "America/St_Thomas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SPC",
    "countryCode": "ES",
    "timezone": "Atlantic/Canary",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "SPC",
    "countryCode": "ES",
    "timezone": "Atlantic/Canary",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "SPD",
    "countryCode": "BD",
    "timezone": "Asia/Dhaka",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "SPD",
    "countryCode": "BD",
    "timezone": "Asia/Dhaka",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "SPE",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SPF",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "SPF",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "SPG",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SPG",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SPH",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SPI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SPI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SPJ",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SPJ",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SPK",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "SPL",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SPM",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SPM",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SPN",
    "countryCode": "MP",
    "timezone": "Pacific/Saipan",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SPN",
    "countryCode": "MP",
    "timezone": "Pacific/Saipan",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SPO",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "SPP",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "SPP",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "SPQ",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SPQ",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SPR",
    "countryCode": "BZ",
    "timezone": "America/Belize",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "SPR",
    "countryCode": "BZ",
    "timezone": "America/Belize",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "SPS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SPS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SPT",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SPT",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SPU",
    "countryCode": "HR",
    "timezone": "Europe/Zagreb",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SPU",
    "countryCode": "HR",
    "timezone": "Europe/Zagreb",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SPV",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SPW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SPW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SPX",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SPX",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SPX",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SPY",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "SPY",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "SPZ",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SPZ",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SQA",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SQA",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SQB",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "SQC",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SQC",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SQD",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SQD",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SQD",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SQE",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "SQE",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "SQF",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "SQG",
    "countryCode": "ID",
    "timezone": "Asia/Pontianak",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "SQG",
    "countryCode": "ID",
    "timezone": "Asia/Pontianak",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "SQH",
    "countryCode": "VN",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "SQH",
    "countryCode": "VN",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "SQH",
    "countryCode": "VN",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "SQI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SQJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SQJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SQJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SQK",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SQL",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SQL",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SQM",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SQN",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "SQN",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "SQO",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SQO",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SQP",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SQQ",
    "countryCode": "LT",
    "timezone": "Europe/Vilnius",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SQQ",
    "countryCode": "LT",
    "timezone": "Europe/Vilnius",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SQR",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SQR",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SQS",
    "countryCode": "BZ",
    "timezone": "America/Belize",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "SQS",
    "countryCode": "BZ",
    "timezone": "America/Belize",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "SQT",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SQU",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "SQU",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "SQV",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SQV",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SQW",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SQW",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SQX",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SQX",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SQY",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SQZ",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "SQZ",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "SRA",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SRA",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SRB",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SRB",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SRC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SRC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SRD",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SRE",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SRE",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SRF",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SRF",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SRG",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "SRG",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "SRH",
    "countryCode": "TD",
    "timezone": "Africa/Ndjamena",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "SRI",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SRI",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SRJ",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SRJ",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SRK",
    "countryCode": "SL",
    "timezone": "Africa/Freetown",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "SRK",
    "countryCode": "GL",
    "timezone": "America/Thule",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "SRK",
    "countryCode": "GL",
    "timezone": "America/Thule",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "SRL",
    "countryCode": "MX",
    "timezone": "America/Mazatlan",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "SRL",
    "countryCode": "MX",
    "timezone": "America/Mazatlan",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "SRM",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SRM",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SRN",
    "countryCode": "AU",
    "timezone": "Australia/Hobart",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "SRN",
    "countryCode": "AU",
    "timezone": "Australia/Hobart",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "SRO",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "SRP",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SRP",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SRQ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SRQ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SRR",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SRR",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SRR",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SRS",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "SRT",
    "countryCode": "UG",
    "timezone": "Africa/Kampala",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SRT",
    "countryCode": "UG",
    "timezone": "Africa/Kampala",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SRU",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "SRU",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "SRU",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "SRV",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SRV",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SRW",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SRW",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SRX",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "SRX",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "SRY",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SRY",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SRZ",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SRZ",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SSA",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SSA",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SSB",
    "countryCode": "VI",
    "timezone": "America/St_Thomas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SSC",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SSC",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SSD",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "SSD",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "SSE",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SSE",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SSF",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SSG",
    "countryCode": "GQ",
    "timezone": "Africa/Malabo",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "SSG",
    "countryCode": "GQ",
    "timezone": "Africa/Malabo",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "SSH",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SSH",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SSI",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SSI",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SSJ",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SSJ",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SSK",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SSL",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "SSL",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "SSM",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SSM",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SSN",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "SSO",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SSO",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SSP",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SSQ",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SSQ",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SSR",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "SSR",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "SSS",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SST",
    "countryCode": "AR",
    "timezone": "America/Argentina/Buenos_Aires",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SST",
    "countryCode": "AR",
    "timezone": "America/Argentina/Buenos_Aires",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SSV",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SSV",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SSW",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SSW",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SSX",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SSX",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "SSX",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SSX",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "SSY",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "SSY",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "SSZ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "STA",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "STA",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "STB",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "STB",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "STC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "STC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "STD",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "STD",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "STE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "STE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "STF",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "STF",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "STG",
    "countryCode": "US",
    "timezone": "America/Adak",
    "offset": {
      "gmt": -10,
      "dst": -9
    }
  },
  {
    "code": "STG",
    "countryCode": "US",
    "timezone": "America/Adak",
    "offset": {
      "gmt": -10,
      "dst": -9
    }
  },
  {
    "code": "STH",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "STH",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "STI",
    "countryCode": "DO",
    "timezone": "America/Santo_Domingo",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "STI",
    "countryCode": "DO",
    "timezone": "America/Santo_Domingo",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "STJ",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "STJ",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "STK",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "STL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "STL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "STM",
    "countryCode": "BR",
    "timezone": "America/Santarem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "STM",
    "countryCode": "BR",
    "timezone": "America/Santarem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "STN",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "STO",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "STP",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "STQ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "STQ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "STR",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "STR",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "STS",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "STS",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "STT",
    "countryCode": "VI",
    "timezone": "America/St_Thomas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "STT",
    "countryCode": "VI",
    "timezone": "America/St_Thomas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "STU",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "STU",
    "countryCode": "BZ",
    "timezone": "America/Belize",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "STV",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "STV",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "STW",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "STW",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "STX",
    "countryCode": "VI",
    "timezone": "America/St_Thomas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "STX",
    "countryCode": "VI",
    "timezone": "America/St_Thomas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "STX",
    "countryCode": "VI",
    "timezone": "America/St_Thomas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "STY",
    "countryCode": "UY",
    "timezone": "America/Montevideo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "STY",
    "countryCode": "UY",
    "timezone": "America/Montevideo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "STZ",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "STZ",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SUA",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SUA",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SUB",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "SUB",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "SUC",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "SUD",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SUD",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SUE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SUE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SUF",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SUF",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SUG",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SUG",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SUH",
    "countryCode": "OM",
    "timezone": "Asia/Muscat",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "SUI",
    "countryCode": "GE",
    "timezone": "Asia/Tbilisi",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "SUI",
    "countryCode": "GE",
    "timezone": "Asia/Tbilisi",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "SUJ",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SUJ",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SUK",
    "countryCode": "RU",
    "timezone": "Asia/Yakutsk",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "SUK",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "SUK",
    "countryCode": "RU",
    "timezone": "Asia/Yakutsk",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "SUL",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SUL",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SUM",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SUN",
    "countryCode": "US",
    "timezone": "America/Boise",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "SUN",
    "countryCode": "US",
    "timezone": "America/Boise",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "SUO",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SUO",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SUP",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "SUQ",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "SUR",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SUR",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SUS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SUT",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SUT",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SUU",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SUU",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SUV",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "SUV",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "SUW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SUW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SUX",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SUX",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SUY",
    "countryCode": "RU",
    "timezone": "Asia/Yakutsk",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "SUY",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "SUY",
    "countryCode": "RU",
    "timezone": "Asia/Yakutsk",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "SUZ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SVA",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SVA",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SVB",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SVB",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SVC",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "SVC",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "SVD",
    "countryCode": "VC",
    "timezone": "America/St_Vincent",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SVD",
    "countryCode": "VC",
    "timezone": "America/St_Vincent",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SVD",
    "countryCode": "VC",
    "timezone": "America/St_Vincent",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SVD",
    "countryCode": "VC",
    "timezone": "America/St_Vincent",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SVE",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SVE",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SVF",
    "countryCode": "BJ",
    "timezone": "Africa/Porto-Novo",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "SVF",
    "countryCode": "BJ",
    "timezone": "Africa/Porto-Novo",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "SVG",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SVG",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SVH",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SVH",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SVI",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "SVJ",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SVJ",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SVK",
    "countryCode": "BZ",
    "timezone": "America/Belize",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "SVK",
    "countryCode": "BZ",
    "timezone": "America/Belize",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "SVK",
    "countryCode": "BZ",
    "timezone": "America/Belize",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "SVL",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SVL",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SVM",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SVN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SVO",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SVP",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "SVP",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "SVQ",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SVQ",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SVR",
    "countryCode": "KH",
    "timezone": "Asia/Phnom_Penh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "SVR",
    "countryCode": "GL",
    "timezone": "America/Thule",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "SVR",
    "countryCode": "GL",
    "timezone": "America/Thule",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "SVS",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SVS",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SVT",
    "countryCode": "BW",
    "timezone": "Africa/Gaborone",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "SVT",
    "countryCode": "BW",
    "timezone": "Africa/Gaborone",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "SVU",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "SVU",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "SVV",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SVW",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SVX",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SVX",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SVY",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "SVZ",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SVZ",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SWA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SWA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SWA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SWA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SWA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SWB",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SWC",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "SWC",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "SWD",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SWD",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SWE",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SWF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SWF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SWG",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SWH",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "SWH",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "SWI",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "SWJ",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "SWJ",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "SWK",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SWK",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SWL",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SWL",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SWL",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SWL",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SWM",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SWM",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SWM",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SWN",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SWO",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SWO",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SWP",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "SWP",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "SWQ",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SWQ",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SWR",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SWS",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "SWS",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "SWT",
    "countryCode": "RU",
    "timezone": "Asia/Tomsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "SWT",
    "countryCode": "RU",
    "timezone": "Asia/Tomsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "SWU",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "SWV",
    "countryCode": "RU",
    "timezone": "Asia/Magadan",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "SWV",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SWV",
    "countryCode": "RU",
    "timezone": "Asia/Magadan",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "SWW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SWX",
    "countryCode": "BW",
    "timezone": "Africa/Gaborone",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "SWX",
    "countryCode": "BW",
    "timezone": "Africa/Gaborone",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "SWY",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SWY",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SWZ",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "SXA",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SXB",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SXB",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SXC",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SXC",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SXC",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SXD",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SXE",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "SXE",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "SXF",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SXG",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "SXG",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "SXH",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SXI",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SXJ",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "SXJ",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "SXK",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "SXK",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "SXL",
    "countryCode": "IE",
    "timezone": "Europe/Dublin",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "SXL",
    "countryCode": "IE",
    "timezone": "Europe/Dublin",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "SXM",
    "countryCode": "SX",
    "timezone": "America/Lower_Princes",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SXM",
    "countryCode": "SX",
    "timezone": "America/Lower_Princes",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SXN",
    "countryCode": "BW",
    "timezone": "Africa/Gaborone",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "SXN",
    "countryCode": "BW",
    "timezone": "Africa/Gaborone",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "SXO",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SXO",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "SXP",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SXP",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SXQ",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SXQ",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SXR",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SXR",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SXS",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SXT",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SXU",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SXU",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SXV",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SXV",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SXW",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SXX",
    "countryCode": "BR",
    "timezone": "America/Santarem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SXX",
    "countryCode": "BR",
    "timezone": "America/Belem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SXY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SXY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SXZ",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SXZ",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SYA",
    "countryCode": "US",
    "timezone": "America/Adak",
    "offset": {
      "gmt": -10,
      "dst": -9
    }
  },
  {
    "code": "SYA",
    "countryCode": "US",
    "timezone": "America/Adak",
    "offset": {
      "gmt": -10,
      "dst": -9
    }
  },
  {
    "code": "SYB",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SYB",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "SYC",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "SYD",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "SYD",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "SYE",
    "countryCode": "YE",
    "timezone": "Asia/Aden",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SYE",
    "countryCode": "YE",
    "timezone": "Asia/Aden",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SYF",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SYF",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SYF",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SYG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SYG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SYH",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SYH",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SYI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SYI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SYJ",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SYJ",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SYK",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "SYK",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "SYL",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SYL",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SYM",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SYM",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SYN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SYN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SYO",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "SYO",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "SYO",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "SYP",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "SYP",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "SYQ",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "SYR",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SYR",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SYS",
    "countryCode": "RU",
    "timezone": "Asia/Yakutsk",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "SYS",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "SYS",
    "countryCode": "RU",
    "timezone": "Asia/Yakutsk",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "SYT",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SYT",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SYU",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SYU",
    "countryCode": "AU",
    "timezone": "Australia/Lindeman",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SYU",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "SYV",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SYV",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SYW",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SYW",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "SYX",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SYX",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SYY",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "SYY",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "SYZ",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SYZ",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SZA",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "SZA",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "SZB",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SZC",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "SZC",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "SZC",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "SZD",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "SZE",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SZE",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SZF",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SZF",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "SZG",
    "countryCode": "AT",
    "timezone": "Europe/Vienna",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SZG",
    "countryCode": "AT",
    "timezone": "Europe/Vienna",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SZH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SZH",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SZH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SZI",
    "countryCode": "KZ",
    "timezone": "Asia/Almaty",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "SZI",
    "countryCode": "KZ",
    "timezone": "Asia/Almaty",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "SZJ",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SZJ",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "SZK",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "SZK",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "SZL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SZL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "SZM",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "SZN",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SZN",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SZO",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SZP",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SZP",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "SZQ",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SZQ",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "SZR",
    "countryCode": "BG",
    "timezone": "Europe/Sofia",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SZR",
    "countryCode": "BG",
    "timezone": "Europe/Sofia",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "SZS",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "SZT",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "SZT",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "SZU",
    "countryCode": "ML",
    "timezone": "Africa/Bamako",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "SZU",
    "countryCode": "ML",
    "timezone": "Africa/Bamako",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "SZV",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SZV",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SZW",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SZW",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SZW",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SZX",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SZX",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "SZY",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SZY",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SZY",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SZZ",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "SZZ",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TAA",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "TAB",
    "countryCode": "TT",
    "timezone": "America/Port_of_Spain",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "TAB",
    "countryCode": "TT",
    "timezone": "America/Port_of_Spain",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "TAC",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TAC",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TAD",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "TAD",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "TAE",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "TAE",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "TAF",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "TAG",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TAG",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TAG",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TAG",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TAG",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TAH",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "TAH",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "TAI",
    "countryCode": "YE",
    "timezone": "Asia/Aden",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TAI",
    "countryCode": "YE",
    "timezone": "Asia/Aden",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TAJ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TAK",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "TAK",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "TAL",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "TAL",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "TAM",
    "countryCode": "MX",
    "timezone": "America/Monterrey",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "TAM",
    "countryCode": "MX",
    "timezone": "America/Monterrey",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "TAN",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TAO",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TAO",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TAP",
    "countryCode": "MX",
    "timezone": "America/Merida",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "TAP",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "TAQ",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "TAQ",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "TAR",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TAR",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TAS",
    "countryCode": "UZ",
    "timezone": "Asia/Tashkent",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "TAS",
    "countryCode": "UZ",
    "timezone": "Asia/Tashkent",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "TAT",
    "countryCode": "SK",
    "timezone": "Europe/Bratislava",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TAT",
    "countryCode": "SK",
    "timezone": "Europe/Bratislava",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TAU",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TAV",
    "countryCode": "AS",
    "timezone": "Pacific/Pago_Pago",
    "offset": {
      "gmt": -11,
      "dst": -11
    }
  },
  {
    "code": "TAW",
    "countryCode": "UY",
    "timezone": "America/Montevideo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "TAX",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "TAY",
    "countryCode": "EE",
    "timezone": "Europe/Tallinn",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "TAY",
    "countryCode": "EE",
    "timezone": "Europe/Tallinn",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "TAY",
    "countryCode": "EE",
    "timezone": "Europe/Tallinn",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "TAZ",
    "countryCode": "TM",
    "timezone": "Asia/Ashgabat",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "TAZ",
    "countryCode": "TM",
    "timezone": "Asia/Ashgabat",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "TBA",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TBB",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "TBB",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "TBC",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "TBC",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "TBD",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TBE",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TBF",
    "countryCode": "KI",
    "timezone": "Pacific/Tarawa",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "TBF",
    "countryCode": "KI",
    "timezone": "Pacific/Tarawa",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "TBG",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TBG",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TBH",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TBH",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TBI",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "TBI",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "TBJ",
    "countryCode": "TN",
    "timezone": "Africa/Tunis",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "TBJ",
    "countryCode": "TN",
    "timezone": "Africa/Tunis",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "TBK",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "TBL",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TBL",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TBM",
    "countryCode": "ID",
    "timezone": "Asia/Pontianak",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "TBN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "TBN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "TBO",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TBO",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TBP",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TBP",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TBQ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TBR",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "TBR",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "TBS",
    "countryCode": "GE",
    "timezone": "Asia/Tbilisi",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "TBS",
    "countryCode": "GE",
    "timezone": "Asia/Tbilisi",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "TBT",
    "countryCode": "BR",
    "timezone": "America/Porto_Velho",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "TBT",
    "countryCode": "BR",
    "timezone": "America/Manaus",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "TBU",
    "countryCode": "TO",
    "timezone": "Pacific/Tongatapu",
    "offset": {
      "gmt": 13,
      "dst": 13
    }
  },
  {
    "code": "TBU",
    "countryCode": "TO",
    "timezone": "Pacific/Tongatapu",
    "offset": {
      "gmt": 13,
      "dst": 13
    }
  },
  {
    "code": "TBV",
    "countryCode": "MH",
    "timezone": "Pacific/Majuro",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "TBW",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TBW",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TBX",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "TBX",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "TBY",
    "countryCode": "BW",
    "timezone": "Africa/Gaborone",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "TBY",
    "countryCode": "BW",
    "timezone": "Africa/Gaborone",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "TBZ",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TBZ",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TCA",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "TCA",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "TCB",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "TCB",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "TCC",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "TCC",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "TCD",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TCE",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "TCE",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "TCF",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "TCG",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "TCG",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "TCH",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "TCH",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "TCI",
    "countryCode": "ES",
    "timezone": "Atlantic/Canary",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "TCJ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TCK",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TCL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "TCL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "TCM",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "TCN",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "TCN",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "TCO",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TCO",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TCP",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "TCP",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "TCQ",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TCQ",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TCR",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "TCR",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "TCS",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "TCS",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "TCT",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "TCT",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "TCU",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "TCU",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "TCV",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "TCV",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "TCV",
    "countryCode": "UY",
    "timezone": "America/Montevideo",
    "offset": {
      "gmt": -2,
      "dst": -3
    }
  },
  {
    "code": "TCV",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "TCW",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "TCW",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "TCX",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TCX",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TCY",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "TCZ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TCZ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TDA",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TDA",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TDB",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TDD",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "TDD",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "TDG",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TDG",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TDJ",
    "countryCode": "DJ",
    "timezone": "Africa/Djibouti",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TDJ",
    "countryCode": "DJ",
    "timezone": "Africa/Djibouti",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TDK",
    "countryCode": "KZ",
    "timezone": "Asia/Almaty",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "TDK",
    "countryCode": "KZ",
    "timezone": "Asia/Almaty",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "TDL",
    "countryCode": "AR",
    "timezone": "America/Argentina/Buenos_Aires",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "TDL",
    "countryCode": "AR",
    "timezone": "America/Argentina/Buenos_Aires",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "TDN",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TDO",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "TDO",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "TDP",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TDP",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TDR",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TDR",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TDS",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TDT",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "TDV",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TDW",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "TDX",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "TDX",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "TDZ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "TEA",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "TEA",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "TEB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "TEB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "TEC",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "TEC",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "TED",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TED",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TEE",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "TEE",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "TEF",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TEF",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TEG",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "TEG",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "TEH",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "TEI",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "TEI",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "TEJ",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TEJ",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TEK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "TEK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "TEL",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TEL",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TEM",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "TEM",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "TEN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TEN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TEO",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TEP",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TEQ",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TEQ",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TER",
    "countryCode": "PT",
    "timezone": "Atlantic/Azores",
    "offset": {
      "gmt": -1,
      "dst": 0
    }
  },
  {
    "code": "TER",
    "countryCode": "PT",
    "timezone": "Atlantic/Azores",
    "offset": {
      "gmt": -1,
      "dst": 0
    }
  },
  {
    "code": "TER",
    "countryCode": "PT",
    "timezone": "Atlantic/Azores",
    "offset": {
      "gmt": -1,
      "dst": 0
    }
  },
  {
    "code": "TES",
    "countryCode": "ER",
    "timezone": "Africa/Asmara",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TES",
    "countryCode": "ER",
    "timezone": "Africa/Asmara",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TET",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "TET",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "TEU",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "TEU",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "TEU",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "TEV",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TEV",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TEW",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 4
    }
  },
  {
    "code": "TEX",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "TEX",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "TEY",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "TEY",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "TEZ",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "TEZ",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "TFA",
    "countryCode": "AL",
    "timezone": "Europe/Tirane",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TFB",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TFB",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TFC",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TFF",
    "countryCode": "BR",
    "timezone": "America/Manaus",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "TFF",
    "countryCode": "BR",
    "timezone": "America/Manaus",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "TFI",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TFI",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TFL",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "TFM",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TFM",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TFN",
    "countryCode": "ES",
    "timezone": "Atlantic/Canary",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "TFR",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "TFS",
    "countryCode": "ES",
    "timezone": "Atlantic/Canary",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "TFT",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "TFU",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TFY",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "TFY",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "TGA",
    "countryCode": "SG",
    "timezone": "Asia/Singapore",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TGB",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TGB",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TGC",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TGD",
    "countryCode": "ME",
    "timezone": "Europe/Podgorica",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TGD",
    "countryCode": "ME",
    "timezone": "Europe/Podgorica",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TGE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "TGE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "TGF",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TGG",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TGG",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TGH",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "TGH",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "TGI",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TGI",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TGJ",
    "countryCode": "NC",
    "timezone": "Pacific/Noumea",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "TGJ",
    "countryCode": "NC",
    "timezone": "Pacific/Noumea",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "TGK",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TGK",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TGL",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TGL",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TGM",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "TGM",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "TGN",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "TGN",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "TGO",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TGO",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TGP",
    "countryCode": "RU",
    "timezone": "Asia/Krasnoyarsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "TGP",
    "countryCode": "RU",
    "timezone": "Asia/Krasnoyarsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "TGQ",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "TGR",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "TGR",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "TGS",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "TGT",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TGT",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TGU",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "TGU",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "TGV",
    "countryCode": "BG",
    "timezone": "Europe/Sofia",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "TGV",
    "countryCode": "BG",
    "timezone": "Europe/Sofia",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "TGX",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TGX",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TGY",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "TGZ",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "TGZ",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "THA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "THA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "THB",
    "countryCode": "LS",
    "timezone": "Africa/Maseru",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "THB",
    "countryCode": "LS",
    "timezone": "Africa/Maseru",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "THC",
    "countryCode": "LR",
    "timezone": "Africa/Monrovia",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "THC",
    "countryCode": "LR",
    "timezone": "Africa/Monrovia",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "THD",
    "countryCode": "VN",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "THD",
    "countryCode": "VN",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "THE",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "THE",
    "countryCode": "BR",
    "timezone": "America/Fortaleza",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "THF",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "THG",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "THG",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "THH",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "THI",
    "countryCode": "MR",
    "timezone": "Africa/Nouakchott",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "THJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "THK",
    "countryCode": "LA",
    "timezone": "Asia/Vientiane",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "THL",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "THL",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "THM",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "THM",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "THN",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "THN",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "THO",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "THO",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "THP",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "THP",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "THQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "THQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "THR",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "THR",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "THS",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "THS",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "THT",
    "countryCode": "MR",
    "timezone": "Africa/Nouakchott",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "THU",
    "countryCode": "GL",
    "timezone": "America/Thule",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "THU",
    "countryCode": "GL",
    "timezone": "America/Thule",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "THV",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "THV",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "THW",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "THX",
    "countryCode": "RU",
    "timezone": "Asia/Krasnoyarsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "THX",
    "countryCode": "RU",
    "timezone": "Asia/Krasnoyarsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "THY",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "THY",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "THZ",
    "countryCode": "NE",
    "timezone": "Africa/Niamey",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "THZ",
    "countryCode": "NE",
    "timezone": "Africa/Niamey",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "TIA",
    "countryCode": "AL",
    "timezone": "Europe/Tirane",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TIA",
    "countryCode": "AL",
    "timezone": "Europe/Tirane",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TIB",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TIC",
    "countryCode": "MH",
    "timezone": "Pacific/Majuro",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "TIC",
    "countryCode": "MH",
    "timezone": "Pacific/Majuro",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "TID",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "TID",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "TIE",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TIE",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TIF",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TIF",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TIG",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TIG",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TIH",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "TIH",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "TII",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "TII",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "TIJ",
    "countryCode": "MX",
    "timezone": "America/Tijuana",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "TIJ",
    "countryCode": "MX",
    "timezone": "America/Tijuana",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "TIK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "TIL",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "TIL",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "TIM",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "TIM",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "TIN",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "TIN",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "TIO",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "TIO",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "TIP",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "TIP",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "TIQ",
    "countryCode": "MP",
    "timezone": "Pacific/Saipan",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TIQ",
    "countryCode": "MP",
    "timezone": "Pacific/Saipan",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TIR",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "TIR",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "TIS",
    "countryCode": "AU",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "TIU",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "TIU",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "TIV",
    "countryCode": "ME",
    "timezone": "Europe/Podgorica",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TIV",
    "countryCode": "ME",
    "timezone": "Europe/Podgorica",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TIW",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "TIW",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "TIX",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "TIX",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "TIY",
    "countryCode": "MR",
    "timezone": "Africa/Nouakchott",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "TIY",
    "countryCode": "MR",
    "timezone": "Africa/Nouakchott",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "TIZ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TIZ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TJA",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "TJA",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "TJB",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "TJB",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "TJC",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TJG",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "TJH",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "TJH",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "TJI",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "TJI",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "TJJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TJK",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TJK",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TJL",
    "countryCode": "BR",
    "timezone": "America/Campo_Grande",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "TJL",
    "countryCode": "BR",
    "timezone": "America/Campo_Grande",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "TJM",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "TJM",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "TJN",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "TJN",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "TJQ",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "TJQ",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "TJS",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TJS",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TJU",
    "countryCode": "TJ",
    "timezone": "Asia/Dushanbe",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "TJU",
    "countryCode": "TJ",
    "timezone": "Asia/Dushanbe",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "TJV",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "TJX",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "TJX",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "TKA",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "TKA",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "TKB",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TKB",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TKC",
    "countryCode": "CM",
    "timezone": "Africa/Douala",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "TKC",
    "countryCode": "CM",
    "timezone": "Africa/Douala",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "TKD",
    "countryCode": "GH",
    "timezone": "Africa/Accra",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "TKD",
    "countryCode": "GH",
    "timezone": "Africa/Accra",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "TKE",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "TKE",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "TKF",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "TKF",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "TKG",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "TKG",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "TKH",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "TKH",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "TKI",
    "countryCode": "US",
    "timezone": "America/Metlakatla",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "TKI",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "TKJ",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "TKJ",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "TKK",
    "countryCode": "FM",
    "timezone": "Pacific/Chuuk",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TKK",
    "countryCode": "FM",
    "timezone": "Pacific/Chuuk",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TKL",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "TKM",
    "countryCode": "RU",
    "timezone": "Asia/Irkutsk",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TKM",
    "countryCode": "GT",
    "timezone": "America/Guatemala",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "TKM",
    "countryCode": "RU",
    "timezone": "Asia/Irkutsk",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TKN",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "TKN",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "TKO",
    "countryCode": "LS",
    "timezone": "Africa/Maseru",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "TKO",
    "countryCode": "LS",
    "timezone": "Africa/Maseru",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "TKP",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "TKP",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "TKQ",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TKQ",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TKR",
    "countryCode": "BD",
    "timezone": "Asia/Dhaka",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "TKR",
    "countryCode": "BD",
    "timezone": "Asia/Dhaka",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "TKS",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "TKS",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "TKT",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "TKT",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "TKU",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "TKU",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "TKV",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "TKV",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "TKW",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TKX",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "TKX",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "TKY",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TKZ",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "TKZ",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "TLA",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "TLA",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "TLB",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "TLB",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "TLC",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "TLC",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "TLD",
    "countryCode": "BW",
    "timezone": "Africa/Gaborone",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "TLE",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TLE",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TLF",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "TLF",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "TLG",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "TLG",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "TLG",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "TLH",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "TLH",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "TLI",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TLI",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TLJ",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "TLJ",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "TLK",
    "countryCode": "RU",
    "timezone": "Asia/Yakutsk",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "TLK",
    "countryCode": "RU",
    "timezone": "Asia/Yakutsk",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "TLL",
    "countryCode": "EE",
    "timezone": "Europe/Tallinn",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "TLL",
    "countryCode": "EE",
    "timezone": "Europe/Tallinn",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "TLM",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "TLM",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "TLN",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TLN",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TLO",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TLO",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TLP",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TLQ",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "TLQ",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "TLR",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "TLR",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "TLS",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TLS",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TLT",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "TLT",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "TLU",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TLU",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TLV",
    "countryCode": "IL",
    "timezone": "Asia/Jerusalem",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "TLV",
    "countryCode": "IL",
    "timezone": "Asia/Jerusalem",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "TLW",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TLX",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "TLX",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "TLY",
    "countryCode": "RU",
    "timezone": "Asia/Vladivostok",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TLY",
    "countryCode": "RU",
    "timezone": "Asia/Vladivostok",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TLZ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "TMA",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "TMA",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "TMB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "TMC",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TMC",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TMD",
    "countryCode": "MR",
    "timezone": "Africa/Nouakchott",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "TME",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TME",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TMF",
    "countryCode": "MV",
    "timezone": "Indian/Maldives",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "TMF",
    "countryCode": "MV",
    "timezone": "Indian/Maldives",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "TMG",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TMG",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TMH",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "TMH",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "TMI",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "TMJ",
    "countryCode": "UZ",
    "timezone": "Asia/Samarkand",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "TMK",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "TML",
    "countryCode": "GH",
    "timezone": "Africa/Accra",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "TML",
    "countryCode": "GH",
    "timezone": "Africa/Accra",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "TMM",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TMM",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TMN",
    "countryCode": "KI",
    "timezone": "Pacific/Tarawa",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "TMN",
    "countryCode": "KI",
    "timezone": "Pacific/Tarawa",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "TMO",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "TMO",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "TMP",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "TMP",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "TMP",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "TMQ",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "TMR",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "TMR",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "TMS",
    "countryCode": "ST",
    "timezone": "Africa/Sao_Tome",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "TMS",
    "countryCode": "ST",
    "timezone": "Africa/Sao_Tome",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "TMT",
    "countryCode": "BR",
    "timezone": "America/Santarem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "TMT",
    "countryCode": "BR",
    "timezone": "America/Santarem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "TMU",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "TMU",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "TMV",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TMV",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TMW",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "TMW",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "TMX",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "TMX",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "TMY",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "TMY",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "TMZ",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "TMZ",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "TNA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TNA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TNB",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TNC",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "TNC",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "TND",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "TND",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "TNE",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "TNE",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "TNF",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TNF",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TNG",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "TNG",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "TNH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TNH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TNI",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "TNI",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "TNJ",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "TNJ",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "TNK",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "TNK",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "TNL",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "TNL",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "TNM",
    "countryCode": "AQ",
    "timezone": "Antarctica/Palmer",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "TNM",
    "countryCode": "AQ",
    "timezone": "Antarctica/Palmer",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "TNN",
    "countryCode": "TW",
    "timezone": "Asia/Taipei",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TNN",
    "countryCode": "TW",
    "timezone": "Asia/Taipei",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TNO",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "TNO",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "TNP",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "TNP",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "TNQ",
    "countryCode": "KI",
    "timezone": "Pacific/Kiritimati",
    "offset": {
      "gmt": 14,
      "dst": 14
    }
  },
  {
    "code": "TNQ",
    "countryCode": "KI",
    "timezone": "Pacific/Kiritimati",
    "offset": {
      "gmt": 14,
      "dst": 14
    }
  },
  {
    "code": "TNR",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TNR",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TNS",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "TNS",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "TNT",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "TNT",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "TNU",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "TNU",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "TNV",
    "countryCode": "KI",
    "timezone": "Pacific/Kiritimati",
    "offset": {
      "gmt": 14,
      "dst": 14
    }
  },
  {
    "code": "TNW",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TNW",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TNX",
    "countryCode": "KH",
    "timezone": "Asia/Phnom_Penh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "TNX",
    "countryCode": "KH",
    "timezone": "Asia/Phnom_Penh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "TNZ",
    "countryCode": "MN",
    "timezone": "Asia/Hovd",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "TNZ",
    "countryCode": "MN",
    "timezone": "Asia/Hovd",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "TOA",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "TOA",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "TOB",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "TOB",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "TOC",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "TOC",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "TOD",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TOD",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TOE",
    "countryCode": "TN",
    "timezone": "Africa/Tunis",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "TOE",
    "countryCode": "TN",
    "timezone": "Africa/Tunis",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "TOF",
    "countryCode": "RU",
    "timezone": "Asia/Tomsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "TOF",
    "countryCode": "RU",
    "timezone": "Asia/Tomsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "TOG",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "TOG",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "TOH",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "TOH",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "TOH",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "TOI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "TOI",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "TOJ",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TOK",
    "countryCode": "PG",
    "timezone": "Pacific/Bougainville",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "TOK",
    "countryCode": "PG",
    "timezone": "Pacific/Bougainville",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "TOL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "TOL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "TOM",
    "countryCode": "ML",
    "timezone": "Africa/Bamako",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "TOM",
    "countryCode": "ML",
    "timezone": "Africa/Bamako",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "TON",
    "countryCode": "PG",
    "timezone": "Pacific/Bougainville",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "TON",
    "countryCode": "PG",
    "timezone": "Pacific/Bougainville",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "TOO",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "TOO",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "TOP",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "TOP",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "TOQ",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "TOQ",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "TOR",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "TOR",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "TOS",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TOS",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TOT",
    "countryCode": "SR",
    "timezone": "America/Paramaribo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "TOT",
    "countryCode": "SR",
    "timezone": "America/Paramaribo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "TOU",
    "countryCode": "NC",
    "timezone": "Pacific/Noumea",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "TOU",
    "countryCode": "NC",
    "timezone": "Pacific/Noumea",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "TOV",
    "countryCode": "VG",
    "timezone": "America/Tortola",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "TOV",
    "countryCode": "VG",
    "timezone": "America/Tortola",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "TOW",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "TOW",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "TOX",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "TOX",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "TOY",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "TOY",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "TOZ",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "TOZ",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "TPA",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "TPA",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "TPB",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "TPC",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TPC",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TPE",
    "countryCode": "TW",
    "timezone": "Asia/Taipei",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TPE",
    "countryCode": "TW",
    "timezone": "Asia/Taipei",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TPF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "TPG",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TPG",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TPH",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "TPH",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "TPI",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TPI",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TPJ",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "TPJ",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "TPK",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "TPK",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "TPL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "TPL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "TPN",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TPN",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TPO",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "TPP",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TPP",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TPQ",
    "countryCode": "MX",
    "timezone": "America/Mazatlan",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "TPQ",
    "countryCode": "MX",
    "timezone": "America/Mazatlan",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "TPR",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TPR",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TPS",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TPS",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TPT",
    "countryCode": "LR",
    "timezone": "Africa/Monrovia",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "TPT",
    "countryCode": "LR",
    "timezone": "Africa/Monrovia",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "TPU",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "TPU",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "TPX",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "TPY",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TQA",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "TQA",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "TQD",
    "countryCode": "IQ",
    "timezone": "Asia/Baghdad",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TQD",
    "countryCode": "IQ",
    "timezone": "Asia/Baghdad",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TQE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "TQE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "TQI",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "TQI",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "TQL",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "TQL",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "TQN",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "TQN",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "TQO",
    "countryCode": "MX",
    "timezone": "America/Cancun",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TQP",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TQP",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TQQ",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TQQ",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TQR",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TQR",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TQS",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TQS",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TRA",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "TRB",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TRB",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TRC",
    "countryCode": "MX",
    "timezone": "America/Monterrey",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "TRC",
    "countryCode": "MX",
    "timezone": "America/Monterrey",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "TRD",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TRD",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TRE",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "TRE",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "TRF",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TRG",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "TRG",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "TRH",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "TRH",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "TRI",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "TRJ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TRJ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TRK",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TRK",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TRL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "TRL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "TRM",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "TRM",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "TRN",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TRN",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TRO",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "TRO",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "TRP",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "TRQ",
    "countryCode": "BR",
    "timezone": "America/Eirunepe",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TRQ",
    "countryCode": "BR",
    "timezone": "America/Rio_Branco",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TRR",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "TRR",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "TRS",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TRS",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TRT",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TRT",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "TRU",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TRU",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TRV",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "TRV",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "TRW",
    "countryCode": "KI",
    "timezone": "Pacific/Tarawa",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "TRW",
    "countryCode": "KI",
    "timezone": "Pacific/Tarawa",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "TRX",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "TRX",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "TRY",
    "countryCode": "UG",
    "timezone": "Africa/Kampala",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TRY",
    "countryCode": "UG",
    "timezone": "Africa/Kampala",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TRZ",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "TRZ",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "TSA",
    "countryCode": "TW",
    "timezone": "Asia/Taipei",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TSB",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "TSB",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "TSC",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TSC",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TSD",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "TSD",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "TSE",
    "countryCode": "KZ",
    "timezone": "Asia/Almaty",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "TSE",
    "countryCode": "KZ",
    "timezone": "Asia/Almaty",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "TSF",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TSF",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TSG",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "TSG",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "TSH",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "TSH",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "TSI",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TSJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "TSJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "TSK",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TSL",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "TSL",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "TSM",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "TSM",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "TSN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TSN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TSO",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "TSP",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "TSP",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "TSQ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "TSR",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "TSR",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "TSS",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "TST",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "TST",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "TSU",
    "countryCode": "KI",
    "timezone": "Pacific/Tarawa",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "TSU",
    "countryCode": "KI",
    "timezone": "Pacific/Tarawa",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "TSV",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TSV",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TSW",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TSX",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TSY",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "TSY",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "TSZ",
    "countryCode": "MN",
    "timezone": "Asia/Ulaanbaatar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TTA",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "TTA",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "TTB",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TTB",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TTC",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "TTC",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "TTD",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "TTD",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "TTE",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "TTE",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "TTG",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "TTG",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "TTH",
    "countryCode": "OM",
    "timezone": "Asia/Muscat",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "TTI",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "TTJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "TTJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "TTK",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "TTL",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "TTL",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "TTM",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TTN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "TTO",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "TTO",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "TTQ",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "TTQ",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "TTR",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TTR",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TTS",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TTS",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TTT",
    "countryCode": "TW",
    "timezone": "Asia/Taipei",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TTT",
    "countryCode": "TW",
    "timezone": "Asia/Taipei",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TTU",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "TTU",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "TTV",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TTV",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TTW",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "TTW",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "TTX",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TTX",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TTY",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "TTY",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "TTZ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TUA",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TUA",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TUB",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "TUB",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "TUC",
    "countryCode": "AR",
    "timezone": "America/Argentina/Tucuman",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "TUC",
    "countryCode": "AR",
    "timezone": "America/Argentina/Tucuman",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "TUD",
    "countryCode": "SN",
    "timezone": "Africa/Dakar",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "TUD",
    "countryCode": "SN",
    "timezone": "Africa/Dakar",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "TUE",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TUF",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TUF",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TUG",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TUG",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TUH",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "TUI",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TUI",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TUJ",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TUK",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "TUK",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "TUL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "TUL",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "TUM",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "TUM",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "TUN",
    "countryCode": "TN",
    "timezone": "Africa/Tunis",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "TUN",
    "countryCode": "TN",
    "timezone": "Africa/Tunis",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "TUO",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "TUO",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "TUP",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "TUP",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "TUQ",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "TUQ",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "TUR",
    "countryCode": "BR",
    "timezone": "America/Belem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "TUR",
    "countryCode": "BR",
    "timezone": "America/Belem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "TUS",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "TUS",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "TUT",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TUU",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TUU",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TUV",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "TUV",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "TUW",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TUW",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TUX",
    "countryCode": "CA",
    "timezone": "America/Dawson_Creek",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "TUX",
    "countryCode": "CA",
    "timezone": "America/Dawson_Creek",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "TUY",
    "countryCode": "MX",
    "timezone": "America/Cancun",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TUY",
    "countryCode": "MX",
    "timezone": "America/Cancun",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TUZ",
    "countryCode": "BR",
    "timezone": "America/Santarem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "TVA",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TVA",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TVC",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "TVC",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "TVF",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "TVF",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "TVI",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "TVI",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "TVL",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "TVL",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "TVS",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TVS",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TVS",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TVU",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "TVU",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "TVX",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TVX",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TVY",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "TVY",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "TWA",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "TWA",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "TWB",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TWB",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TWC",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "TWC",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "TWD",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "TWD",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "TWE",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "TWE",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "TWF",
    "countryCode": "US",
    "timezone": "America/Boise",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "TWF",
    "countryCode": "US",
    "timezone": "America/Boise",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "TWH",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "TWN",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TWP",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TWT",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TWT",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TWU",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TWU",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TWY",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TWZ",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "TXB",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TXC",
    "countryCode": "BY",
    "timezone": "Europe/Minsk",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TXC",
    "countryCode": "BY",
    "timezone": "Europe/Minsk",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TXE",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "TXE",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "TXF",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "TXF",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "TXG",
    "countryCode": "TW",
    "timezone": "Asia/Taipei",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TXK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "TXK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "TXL",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TXM",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "TXM",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "TXN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TXN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TXQ",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TXR",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TXR",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TXU",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "TXU",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "TYA",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TYA",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TYB",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "TYB",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "TYC",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TYD",
    "countryCode": "RU",
    "timezone": "Asia/Yakutsk",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "TYD",
    "countryCode": "RU",
    "timezone": "Asia/Yakutsk",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "TYE",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "TYE",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "TYF",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TYF",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TYG",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "TYL",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TYL",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "TYM",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "TYM",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "TYN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TYN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "TYO",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "TYP",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "TYQ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "TYR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "TYR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "TYS",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "TYS",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "TYT",
    "countryCode": "UY",
    "timezone": "America/Montevideo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "TYZ",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "TYZ",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "TZA",
    "countryCode": "BZ",
    "timezone": "America/Belize",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "TZC",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "TZC",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "TZC",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "TZL",
    "countryCode": "BA",
    "timezone": "Europe/Sarajevo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TZL",
    "countryCode": "BA",
    "timezone": "Europe/Sarajevo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TZM",
    "countryCode": "MX",
    "timezone": "America/Merida",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "TZM",
    "countryCode": "MX",
    "timezone": "America/Merida",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "TZN",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "TZN",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "TZO",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TZR",
    "countryCode": "HU",
    "timezone": "Europe/Budapest",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TZR",
    "countryCode": "HU",
    "timezone": "Europe/Budapest",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "TZX",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "TZX",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "UAB",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "UAC",
    "countryCode": "MX",
    "timezone": "America/Hermosillo",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "UAC",
    "countryCode": "MX",
    "timezone": "America/Hermosillo",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "UAE",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "UAH",
    "countryCode": "PF",
    "timezone": "Pacific/Marquesas",
    "offset": {
      "gmt": -9,
      "dst": -9
    }
  },
  {
    "code": "UAH",
    "countryCode": "PF",
    "timezone": "Pacific/Marquesas",
    "offset": {
      "gmt": -9,
      "dst": -9
    }
  },
  {
    "code": "UAI",
    "countryCode": "TL",
    "timezone": "Asia/Dili",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "UAI",
    "countryCode": "TL",
    "timezone": "Asia/Dili",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "UAK",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "UAK",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "UAL",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "UAL",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "UAM",
    "countryCode": "GU",
    "timezone": "Pacific/Guam",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "UAP",
    "countryCode": "PF",
    "timezone": "Pacific/Marquesas",
    "offset": {
      "gmt": -9,
      "dst": -9
    }
  },
  {
    "code": "UAP",
    "countryCode": "PF",
    "timezone": "Pacific/Marquesas",
    "offset": {
      "gmt": -9,
      "dst": -9
    }
  },
  {
    "code": "UAQ",
    "countryCode": "AR",
    "timezone": "America/Argentina/San_Juan",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "UAQ",
    "countryCode": "AR",
    "timezone": "America/Argentina/San_Juan",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "UAR",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "UAR",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "UAS",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "UAS",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "UAX",
    "countryCode": "GT",
    "timezone": "America/Guatemala",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "UAX",
    "countryCode": "GT",
    "timezone": "America/Guatemala",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "UBA",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "UBA",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "UBB",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "UBI",
    "countryCode": "PG",
    "timezone": "Pacific/Bougainville",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "UBJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "UBJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "UBN",
    "countryCode": "MN",
    "timezone": "Asia/Ulaanbaatar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "UBP",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "UBP",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "UBR",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "UBR",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "UBS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "UBS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "UBT",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "UBT",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "UBU",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "UBU",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "UBW",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "UBW",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "UCA",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "UCA",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "UCB",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "UCB",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "UCC",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "UCC",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "UCE",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "UCK",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "UCN",
    "countryCode": "LR",
    "timezone": "Africa/Monrovia",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "UCN",
    "countryCode": "LR",
    "timezone": "Africa/Monrovia",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "UCT",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "UCT",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "UCY",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "UCY",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "UCZ",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "UCZ",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "UDA",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "UDA",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "UDD",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "UDE",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "UDE",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "UDI",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "UDI",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "UDJ",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "UDJ",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "UDN",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "UDN",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "UDO",
    "countryCode": "LA",
    "timezone": "Asia/Vientiane",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "UDR",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "UDR",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "UEE",
    "countryCode": "AU",
    "timezone": "Australia/Hobart",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "UEE",
    "countryCode": "AU",
    "timezone": "Australia/Hobart",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "UEL",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "UEL",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "UEN",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "UEN",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "UEO",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "UEO",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "UER",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "UER",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "UES",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "UES",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "UET",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "UET",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "UFA",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "UFA",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "UFM",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "UFM",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "UGA",
    "countryCode": "MN",
    "timezone": "Asia/Ulaanbaatar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "UGA",
    "countryCode": "MN",
    "timezone": "Asia/Ulaanbaatar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "UGB",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "UGC",
    "countryCode": "UZ",
    "timezone": "Asia/Samarkand",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "UGC",
    "countryCode": "UZ",
    "timezone": "Asia/Samarkand",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "UGI",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "UGI",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "UGL",
    "countryCode": "AQ",
    "timezone": "Antarctica/Rothera",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "UGL",
    "countryCode": "AQ",
    "timezone": "Antarctica/Rothera",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "UGL",
    "countryCode": "AQ",
    "timezone": "Antarctica/Rothera",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "UGN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "UGN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "UGO",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "UGO",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "UGR",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "UGS",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "UGS",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "UGT",
    "countryCode": "MN",
    "timezone": "Asia/Ulaanbaatar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "UGU",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "UHE",
    "countryCode": "CZ",
    "timezone": "Europe/Prague",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "UHE",
    "countryCode": "CZ",
    "timezone": "Europe/Prague",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "UHS",
    "countryCode": "RU",
    "timezone": "Asia/Sakhalin",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "UHS",
    "countryCode": "RU",
    "timezone": "Asia/Sakhalin",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "UIB",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "UIB",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "UIG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "UIH",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "UIH",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "UII",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "UII",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "UIK",
    "countryCode": "RU",
    "timezone": "Asia/Irkutsk",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "UIK",
    "countryCode": "RU",
    "timezone": "Asia/Irkutsk",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "UIL",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "UIL",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "UIN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "UIN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "UIO",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "UIO",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "UIP",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "UIP",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "UIQ",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "UIR",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "UIR",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "UIT",
    "countryCode": "MH",
    "timezone": "Pacific/Majuro",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "UIT",
    "countryCode": "MH",
    "timezone": "Pacific/Majuro",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "UIZ",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "UIZ",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "UIZ",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "UJE",
    "countryCode": "MH",
    "timezone": "Pacific/Kwajalein",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "UJN",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "UJN",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "UJU",
    "countryCode": "KP",
    "timezone": "Asia/Pyongyang",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "UJU",
    "countryCode": "KP",
    "timezone": "Asia/Pyongyang",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "UKA",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "UKA",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "UKB",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "UKE",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "UKE",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "UKG",
    "countryCode": "RU",
    "timezone": "Asia/Vladivostok",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "UKG",
    "countryCode": "RU",
    "timezone": "Asia/Vladivostok",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "UKH",
    "countryCode": "OM",
    "timezone": "Asia/Muscat",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "UKH",
    "countryCode": "OM",
    "timezone": "Asia/Muscat",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "UKI",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "UKI",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "UKK",
    "countryCode": "KZ",
    "timezone": "Asia/Almaty",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "UKK",
    "countryCode": "KZ",
    "timezone": "Asia/Almaty",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "UKN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "UKR",
    "countryCode": "YE",
    "timezone": "Asia/Aden",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "UKS",
    "countryCode": "UA",
    "timezone": "Europe/Simferopol",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "UKS",
    "countryCode": "UA",
    "timezone": "Europe/Simferopol",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "UKT",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "UKT",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "UKU",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "UKU",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "UKX",
    "countryCode": "RU",
    "timezone": "Asia/Irkutsk",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "UKX",
    "countryCode": "RU",
    "timezone": "Asia/Irkutsk",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "UKY",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "UKY",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "ULA",
    "countryCode": "AR",
    "timezone": "America/Argentina/Rio_Gallegos",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ULA",
    "countryCode": "AR",
    "timezone": "America/Argentina/Rio_Gallegos",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ULB",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "ULB",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "ULC",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "ULD",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "ULD",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "ULE",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ULE",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ULG",
    "countryCode": "MN",
    "timezone": "Asia/Hovd",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "ULG",
    "countryCode": "MN",
    "timezone": "Asia/Hovd",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "ULH",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ULH",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ULI",
    "countryCode": "FM",
    "timezone": "Pacific/Chuuk",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ULK",
    "countryCode": "RU",
    "timezone": "Asia/Yakutsk",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "ULK",
    "countryCode": "RU",
    "timezone": "Asia/Yakutsk",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "ULL",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "ULM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ULM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ULN",
    "countryCode": "MN",
    "timezone": "Asia/Ulaanbaatar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ULN",
    "countryCode": "MN",
    "timezone": "Asia/Ulaanbaatar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ULO",
    "countryCode": "MN",
    "timezone": "Asia/Hovd",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "ULO",
    "countryCode": "MN",
    "timezone": "Asia/Hovd",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "ULP",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ULP",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ULQ",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ULS",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ULU",
    "countryCode": "UG",
    "timezone": "Africa/Kampala",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ULU",
    "countryCode": "UG",
    "timezone": "Africa/Kampala",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ULV",
    "countryCode": "RU",
    "timezone": "Europe/Ulyanovsk",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "ULX",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "ULX",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "ULY",
    "countryCode": "RU",
    "timezone": "Europe/Ulyanovsk",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "ULY",
    "countryCode": "RU",
    "timezone": "Europe/Ulyanovsk",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "ULZ",
    "countryCode": "MN",
    "timezone": "Asia/Hovd",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "ULZ",
    "countryCode": "MN",
    "timezone": "Asia/Hovd",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "UMA",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "UMA",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "UMB",
    "countryCode": "US",
    "timezone": "America/Adak",
    "offset": {
      "gmt": -10,
      "dst": -9
    }
  },
  {
    "code": "UMB",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "UMC",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "UMD",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "UMD",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "UME",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "UME",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "UMI",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "UMM",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "UMM",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "UMR",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "UMR",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "UMS",
    "countryCode": "RU",
    "timezone": "Asia/Vladivostok",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "UMS",
    "countryCode": "RU",
    "timezone": "Asia/Vladivostok",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "UMT",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "UMT",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "UMU",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "UMU",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "UMY",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "UMY",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "UMZ",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "UMZ",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "UNA",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "UNA",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "UNA",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "UNC",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "UND",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "UND",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "UNE",
    "countryCode": "LS",
    "timezone": "Africa/Maseru",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "UNE",
    "countryCode": "LS",
    "timezone": "Africa/Maseru",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "UNG",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "UNG",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "UNI",
    "countryCode": "VC",
    "timezone": "America/St_Vincent",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "UNI",
    "countryCode": "VC",
    "timezone": "America/St_Vincent",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "UNK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "UNK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "UNN",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "UNN",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "UNR",
    "countryCode": "MN",
    "timezone": "Asia/Ulaanbaatar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "UNS",
    "countryCode": "US",
    "timezone": "America/Adak",
    "offset": {
      "gmt": -10,
      "dst": -9
    }
  },
  {
    "code": "UNT",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "UNT",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "UNU",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "UNU",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "UOA",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "UOA",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "UOL",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "UOL",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "UON",
    "countryCode": "LA",
    "timezone": "Asia/Vientiane",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "UOS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "UOS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "UOX",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "UOX",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "UPA",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "UPA",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "UPB",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "UPB",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "UPC",
    "countryCode": "ES",
    "timezone": "Atlantic/Canary",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "UPF",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "UPG",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "UPG",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "UPK",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "UPK",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "UPL",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "UPL",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "UPN",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "UPN",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "UPP",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "UPP",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "UPR",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "UPV",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "UPV",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "UQE",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "URA",
    "countryCode": "KZ",
    "timezone": "Asia/Oral",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "URA",
    "countryCode": "KZ",
    "timezone": "Asia/Oral",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "URB",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "URC",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "URC",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "URC",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "URD",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "URD",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "URE",
    "countryCode": "EE",
    "timezone": "Europe/Tallinn",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "URE",
    "countryCode": "EE",
    "timezone": "Europe/Tallinn",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "URG",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "URG",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "URI",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "URI",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "URJ",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "URJ",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "URM",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "URN",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "URN",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "URO",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "URO",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "URR",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "URR",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "URS",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "URS",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "URT",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "URT",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "URU",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "URY",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "URY",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "URZ",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "USA",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "USA",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "USC",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "USC",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "USH",
    "countryCode": "AR",
    "timezone": "America/Argentina/Ushuaia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "USH",
    "countryCode": "AR",
    "timezone": "America/Argentina/Ushuaia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "USI",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "USI",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "USJ",
    "countryCode": "KZ",
    "timezone": "Asia/Almaty",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "USJ",
    "countryCode": "KZ",
    "timezone": "Asia/Almaty",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "USK",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "USK",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "USL",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "USM",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "USM",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "USN",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "USN",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "USO",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "USQ",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "USQ",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "USR",
    "countryCode": "RU",
    "timezone": "Asia/Magadan",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "USR",
    "countryCode": "RU",
    "timezone": "Asia/Magadan",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "USS",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "USS",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "UST",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "UST",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "USU",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "USU",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "USX",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "USX",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "UTA",
    "countryCode": "ZW",
    "timezone": "Africa/Harare",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "UTA",
    "countryCode": "ZW",
    "timezone": "Africa/Harare",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "UTB",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "UTB",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "UTC",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "UTC",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "UTD",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "UTE",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "UTE",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "UTG",
    "countryCode": "LS",
    "timezone": "Africa/Maseru",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "UTG",
    "countryCode": "LS",
    "timezone": "Africa/Maseru",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "UTH",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "UTH",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "UTI",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "UTI",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "UTK",
    "countryCode": "MH",
    "timezone": "Pacific/Kwajalein",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "UTK",
    "countryCode": "MH",
    "timezone": "Pacific/Majuro",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "UTL",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "UTM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "UTM",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "UTN",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "UTN",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "UTO",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "UTO",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "UTP",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "UTP",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "UTR",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "UTR",
    "countryCode": "TH",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "UTS",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "UTS",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "UTT",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "UTT",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "UTU",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "UTU",
    "countryCode": "PA",
    "timezone": "America/Panama",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "UTW",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "UTW",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "UUA",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "UUD",
    "countryCode": "RU",
    "timezone": "Asia/Irkutsk",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "UUD",
    "countryCode": "RU",
    "timezone": "Asia/Irkutsk",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "UUK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "UUK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "UUN",
    "countryCode": "MN",
    "timezone": "Asia/Choibalsan",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "UUN",
    "countryCode": "MN",
    "timezone": "Asia/Choibalsan",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "UUS",
    "countryCode": "RU",
    "timezone": "Asia/Sakhalin",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "UUS",
    "countryCode": "RU",
    "timezone": "Asia/Sakhalin",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "UUU",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "UUU",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "UVA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "UVA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "UVE",
    "countryCode": "NC",
    "timezone": "Pacific/Noumea",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "UVE",
    "countryCode": "NC",
    "timezone": "Pacific/Noumea",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "UVF",
    "countryCode": "LC",
    "timezone": "America/St_Lucia",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "UVI",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "UVI",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "UVI",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "UVL",
    "countryCode": "EG",
    "timezone": "Africa/Cairo",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "UVO",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "UWA",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "UWA",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "UWC",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "UWC",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "UWE",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "UWP",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "UWP",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "UWP",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "UYL",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "UYL",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "UYN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "UYN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "UYU",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "UYU",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "UZC",
    "countryCode": "RS",
    "timezone": "Europe/Belgrade",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "UZH",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "UZM",
    "countryCode": "CA",
    "timezone": "America/Cambridge_Bay",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "UZM",
    "countryCode": "CA",
    "timezone": "America/Cambridge_Bay",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "UZR",
    "countryCode": "KZ",
    "timezone": "Asia/Almaty",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "UZR",
    "countryCode": "KZ",
    "timezone": "Asia/Almaty",
    "offset": {
      "gmt": 6,
      "dst": 5
    }
  },
  {
    "code": "UZU",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "UZU",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "VAA",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "VAA",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "VAB",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "VAC",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VAC",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VAD",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "VAF",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VAF",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VAF",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VAG",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "VAG",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "VAH",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "VAH",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "VAI",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "VAI",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "VAK",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "VAK",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "VAL",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "VAL",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "VAM",
    "countryCode": "MV",
    "timezone": "Indian/Maldives",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "VAM",
    "countryCode": "MV",
    "timezone": "Indian/Maldives",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "VAN",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "VAN",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "VAO",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "VAO",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "VAP",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "VAQ",
    "countryCode": "RU",
    "timezone": "Asia/Krasnoyarsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "VAQ",
    "countryCode": "RU",
    "timezone": "Asia/Krasnoyarsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "VAR",
    "countryCode": "BG",
    "timezone": "Europe/Sofia",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "VAR",
    "countryCode": "BG",
    "timezone": "Europe/Sofia",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "VAS",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "VAS",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "VAT",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "VAT",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "VAU",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "VAU",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "VAV",
    "countryCode": "TO",
    "timezone": "Pacific/Tongatapu",
    "offset": {
      "gmt": 13,
      "dst": 13
    }
  },
  {
    "code": "VAV",
    "countryCode": "TO",
    "timezone": "Pacific/Tongatapu",
    "offset": {
      "gmt": 13,
      "dst": 13
    }
  },
  {
    "code": "VAW",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VAW",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VAZ",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VBA",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "VBA",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "VBC",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "VBG",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "VBL",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "VBM",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "VBP",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "VBP",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "VBR",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "VBR",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "VBS",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VBT",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VBV",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "VBV",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "VBY",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VBY",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VCA",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "VCA",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "VCC",
    "countryCode": "CM",
    "timezone": "Africa/Douala",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "VCC",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "VCC",
    "countryCode": "CM",
    "timezone": "Africa/Douala",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "VCC",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "VCD",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "VCD",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "VCE",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VCE",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VCF",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "VCF",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "VCH",
    "countryCode": "UY",
    "timezone": "America/Montevideo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "VCH",
    "countryCode": "UY",
    "timezone": "America/Montevideo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "VCL",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "VCL",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "VCP",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "VCR",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "VCR",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "VCS",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "VCS",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "VCT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "VCT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "VCV",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "VCV",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "VDA",
    "countryCode": "IL",
    "timezone": "Asia/Jerusalem",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "VDA",
    "countryCode": "IL",
    "timezone": "Asia/Jerusalem",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "VDB",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VDB",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VDC",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "VDC",
    "countryCode": "BR",
    "timezone": "America/Bahia",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "VDE",
    "countryCode": "ES",
    "timezone": "Atlantic/Canary",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "VDE",
    "countryCode": "ES",
    "timezone": "Atlantic/Canary",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "VDH",
    "countryCode": "VN",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "VDH",
    "countryCode": "VN",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "VDI",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "VDI",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "VDM",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "VDM",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "VDO",
    "countryCode": "VN",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "VDO",
    "countryCode": "VN",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "VDO",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "VDP",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "VDP",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "VDR",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "VDR",
    "countryCode": "AR",
    "timezone": "America/Argentina/Cordoba",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "VDS",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VDS",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VDT",
    "countryCode": "UY",
    "timezone": "America/Montevideo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "VDU",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "VDY",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "VDZ",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "VDZ",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "VEE",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "VEE",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "VEG",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "VEG",
    "countryCode": "GY",
    "timezone": "America/Guyana",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "VEL",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "VEL",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "VEO",
    "countryCode": "RU",
    "timezone": "Asia/Krasnoyarsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "VEO",
    "countryCode": "RU",
    "timezone": "Asia/Krasnoyarsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "VER",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "VER",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "VEV",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "VEX",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "VEY",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "VEY",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "VFA",
    "countryCode": "ZW",
    "timezone": "Africa/Harare",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "VFA",
    "countryCode": "ZW",
    "timezone": "Africa/Harare",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "VFC",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VFC",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VGA",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "VGA",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "VGA",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "VGD",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "VGD",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "VGG",
    "countryCode": "LA",
    "timezone": "Asia/Vientiane",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "VGN",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "VGN",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "VGN",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "VGO",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VGO",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VGS",
    "countryCode": "AR",
    "timezone": "America/Argentina/Buenos_Aires",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "VGS",
    "countryCode": "AR",
    "timezone": "America/Argentina/Buenos_Aires",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "VGT",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "VGZ",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "VGZ",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "VHC",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "VHC",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "VHM",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VHM",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VHN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "VHN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "VHV",
    "countryCode": "RU",
    "timezone": "Asia/Yakutsk",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "VHV",
    "countryCode": "RU",
    "timezone": "Asia/Yakutsk",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "VHY",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VHY",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VHZ",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "VHZ",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "VIA",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "VIA",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "VIB",
    "countryCode": "MX",
    "timezone": "America/Mazatlan",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "VIC",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VIC",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VIE",
    "countryCode": "AT",
    "timezone": "Europe/Vienna",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VIE",
    "countryCode": "AT",
    "timezone": "Europe/Vienna",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VIF",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VIF",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VIF",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VIG",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "VIG",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "VIH",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "VIH",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "VII",
    "countryCode": "VN",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "VII",
    "countryCode": "VN",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "VIJ",
    "countryCode": "VG",
    "timezone": "America/Tortola",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "VIJ",
    "countryCode": "VG",
    "timezone": "America/Tortola",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "VIK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "VIK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "VIL",
    "countryCode": "MA",
    "timezone": "Africa/Casablanca",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "VIL",
    "countryCode": "EH",
    "timezone": "Africa/El_Aaiun",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "VIN",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "VIN",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "VIO",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VIP",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VIP",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VIQ",
    "countryCode": "TL",
    "timezone": "Asia/Dili",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "VIQ",
    "countryCode": "TL",
    "timezone": "Asia/Dili",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "VIR",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "VIS",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "VIS",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "VIT",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VIT",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VIU",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "VIV",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "VIV",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "VIX",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "VIX",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "VIY",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VJB",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "VJI",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "VJI",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "VJQ",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "VKB",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VKG",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "VKG",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "VKO",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "VKR",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VKS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "VKS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "VKT",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "VKT",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "VKV",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "VKW",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "VKW",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "VLA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "VLA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "VLC",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VLC",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VLD",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "VLD",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "VLE",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "VLE",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "VLG",
    "countryCode": "AR",
    "timezone": "America/Argentina/Buenos_Aires",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "VLG",
    "countryCode": "AR",
    "timezone": "America/Argentina/Buenos_Aires",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "VLI",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "VLI",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "VLK",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "VLK",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "VLL",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VLL",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VLM",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "VLM",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "VLN",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "VLN",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "VLO",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "VLP",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "VLP",
    "countryCode": "BR",
    "timezone": "America/Cuiaba",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "VLR",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "VLR",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "VLS",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "VLS",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "VLU",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "VLU",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "VLV",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "VLV",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "VLY",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "VLY",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "VME",
    "countryCode": "AR",
    "timezone": "America/Argentina/San_Luis",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "VME",
    "countryCode": "AR",
    "timezone": "America/Argentina/San_Luis",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "VMI",
    "countryCode": "PY",
    "timezone": "America/Asuncion",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "VMU",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "VMU",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "VNA",
    "countryCode": "LA",
    "timezone": "Asia/Vientiane",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "VNA",
    "countryCode": "LA",
    "timezone": "Asia/Vientiane",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "VNC",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "VNC",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "VND",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "VND",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "VNE",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VNE",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VNG",
    "countryCode": "LA",
    "timezone": "Asia/Vientiane",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "VNG",
    "countryCode": "LA",
    "timezone": "Asia/Vientiane",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "VNL",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "VNL",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "VNO",
    "countryCode": "LT",
    "timezone": "Europe/Vilnius",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "VNO",
    "countryCode": "LT",
    "timezone": "Europe/Vilnius",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "VNR",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "VNR",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "VNS",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "VNS",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "VNT",
    "countryCode": "LV",
    "timezone": "Europe/Riga",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "VNT",
    "countryCode": "LV",
    "timezone": "Europe/Riga",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "VNX",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "VNX",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "VNY",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "VNZ",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VOD",
    "countryCode": "CZ",
    "timezone": "Europe/Prague",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VOG",
    "countryCode": "RU",
    "timezone": "Europe/Volgograd",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "VOG",
    "countryCode": "RU",
    "timezone": "Europe/Volgograd",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "VOH",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "VOI",
    "countryCode": "LR",
    "timezone": "Africa/Monrovia",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "VOI",
    "countryCode": "LR",
    "timezone": "Africa/Monrovia",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "VOK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "VOK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "VOL",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "VOL",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "VOT",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "VOT",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "VOZ",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "VOZ",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "VPE",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "VPE",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "VPG",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "VPG",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "VPN",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "VPN",
    "countryCode": "IS",
    "timezone": "Atlantic/Reykjavik",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "VPS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "VPS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "VPS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "VPS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "VPY",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "VPY",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "VPZ",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "VPZ",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "VQQ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "VQS",
    "countryCode": "PR",
    "timezone": "America/Puerto_Rico",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "VQS",
    "countryCode": "PR",
    "timezone": "America/Puerto_Rico",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "VQS",
    "countryCode": "PR",
    "timezone": "America/Puerto_Rico",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "VRA",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "VRA",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "VRB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "VRB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "VRC",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "VRC",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "VRD",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VRE",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "VRE",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "VRI",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "VRI",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "VRK",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "VRK",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "VRL",
    "countryCode": "PT",
    "timezone": "Europe/Lisbon",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "VRL",
    "countryCode": "PT",
    "timezone": "Europe/Lisbon",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "VRN",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VRN",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VRO",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "VRO",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "VRR",
    "countryCode": "AL",
    "timezone": "Europe/Tirane",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VRR",
    "countryCode": "AL",
    "timezone": "Europe/Tirane",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VRS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "VRS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "VRU",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "VRU",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "VRY",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VRZ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "VRZ",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "VSA",
    "countryCode": "MX",
    "timezone": "America/Merida",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "VSA",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "VSE",
    "countryCode": "PT",
    "timezone": "Europe/Lisbon",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "VSE",
    "countryCode": "PT",
    "timezone": "Europe/Lisbon",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "VSF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "VSF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "VSG",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "VSG",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "VSK",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "VSK",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "VSN",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VSN",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VSO",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "VST",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VSV",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "VSV",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "VTA",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "VTB",
    "countryCode": "BY",
    "timezone": "Europe/Minsk",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "VTB",
    "countryCode": "BY",
    "timezone": "Europe/Minsk",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "VTE",
    "countryCode": "LA",
    "timezone": "Asia/Vientiane",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "VTE",
    "countryCode": "LA",
    "timezone": "Asia/Vientiane",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "VTF",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "VTF",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "VTG",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "VTG",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "VTI",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VTL",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VTL",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VTM",
    "countryCode": "IL",
    "timezone": "Asia/Jerusalem",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "VTN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "VTN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "VTU",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "VTU",
    "countryCode": "CU",
    "timezone": "America/Havana",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "VTZ",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "VTZ",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "VUP",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "VUP",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "VUS",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "VUS",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "VUU",
    "countryCode": "MW",
    "timezone": "Africa/Blantyre",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "VUU",
    "countryCode": "MW",
    "timezone": "Africa/Blantyre",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "VVB",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "VVC",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "VVC",
    "countryCode": "CO",
    "timezone": "America/Bogota",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "VVE",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "VVI",
    "countryCode": "BO",
    "timezone": "America/La_Paz",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "VVK",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VVN",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "VVN",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "VVO",
    "countryCode": "RU",
    "timezone": "Asia/Vladivostok",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "VVO",
    "countryCode": "RU",
    "timezone": "Asia/Vladivostok",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "VVZ",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "VVZ",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "VWA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "VWB",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "VWY",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "VXC",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "VXC",
    "countryCode": "MZ",
    "timezone": "Africa/Maputo",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "VXE",
    "countryCode": "CV",
    "timezone": "Atlantic/Cape_Verde",
    "offset": {
      "gmt": -1,
      "dst": -1
    }
  },
  {
    "code": "VXE",
    "countryCode": "CV",
    "timezone": "Atlantic/Cape_Verde",
    "offset": {
      "gmt": -1,
      "dst": -1
    }
  },
  {
    "code": "VXO",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VXO",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VYD",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "VYD",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "VYI",
    "countryCode": "RU",
    "timezone": "Asia/Yakutsk",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "VYI",
    "countryCode": "RU",
    "timezone": "Asia/Yakutsk",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "VYN",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VYN",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "VYS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "VYS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "WAA",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WAA",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WAB",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WAC",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WAD",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WAD",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WAE",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WAE",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WAF",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "WAG",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "WAG",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "WAH",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "WAH",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "WAI",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WAI",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WAJ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WAK",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WAK",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WAL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "WAL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "WAM",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WAM",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WAN",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WAO",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WAP",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "WAP",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "WAQ",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WAQ",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WAR",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "WAR",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "WAS",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "WAT",
    "countryCode": "IE",
    "timezone": "Europe/Dublin",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "WAT",
    "countryCode": "IE",
    "timezone": "Europe/Dublin",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "WAU",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "WAU",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "WAV",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "WAW",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "WAW",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "WAX",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "WAX",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "WAY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "WAY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "WAZ",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WAZ",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WBA",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "WBA",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "WBB",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WBB",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WBC",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WBD",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WBD",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WBE",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WBE",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WBG",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "WBG",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "WBI",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "WBK",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "WBM",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WBM",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WBN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "WBO",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WBO",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WBQ",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WBQ",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WBR",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "WBR",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "WBU",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "WBU",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "WBW",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "WCA",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "WCA",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "WCC",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "WCD",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WCD",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WCH",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "WCH",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "WCR",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WCR",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WDA",
    "countryCode": "YE",
    "timezone": "Asia/Aden",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WDB",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "WDB",
    "countryCode": "US",
    "timezone": "America/Sitka",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WDB",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "WDG",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "WDG",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "WDH",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "WDH",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "WDI",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WDI",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WDN",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "WDN",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "WDR",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "WDR",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "WDS",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WDS",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WDY",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WEA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "WEA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "WEC",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WEC",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WED",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WEF",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WEF",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WEH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WEH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WEI",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WEI",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WEL",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "WEL",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "WEM",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "WEP",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WES",
    "countryCode": "LR",
    "timezone": "Africa/Monrovia",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "WET",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "WEW",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "WEW",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "WFB",
    "countryCode": "US",
    "timezone": "America/Sitka",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WFD",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "WFI",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WFI",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WFK",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "WFK",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "WGA",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "WGA",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "WGB",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "WGC",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "WGC",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "WGE",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "WGE",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "WGF",
    "countryCode": "BH",
    "timezone": "Asia/Bahrain",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WGL",
    "countryCode": "EC",
    "timezone": "Pacific/Galapagos",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "WGN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WGN",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "WGN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WGN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WGO",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "WGO",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "WGP",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WGP",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WGT",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "WGT",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "WGU",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WGY",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "WGZ",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "WHA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WHB",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WHD",
    "countryCode": "US",
    "timezone": "America/Metlakatla",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WHF",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "WHF",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "WHH",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "WHK",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "WHK",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "WHL",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "WHN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WHO",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "WHO",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "WHP",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "WHS",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "WHT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "WHT",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "WHU",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WHU",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WHZ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WIB",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "WIB",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "WIB",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "WIC",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "WIC",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "WIE",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "WIE",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "WIK",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "WIK",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "WIL",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WIN",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WIN",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WIO",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "WIO",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "WIR",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "WIR",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "WIT",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WIU",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WJA",
    "countryCode": "MH",
    "timezone": "Pacific/Kwajalein",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "WJA",
    "countryCode": "MH",
    "timezone": "Pacific/Majuro",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "WJF",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "WJF",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "WJR",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WJR",
    "countryCode": "KE",
    "timezone": "Africa/Nairobi",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WJU",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "WJU",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "WKA",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "WKA",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "WKB",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "WKB",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "WKF",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "WKI",
    "countryCode": "ZW",
    "timezone": "Africa/Harare",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "WKI",
    "countryCode": "ZW",
    "timezone": "Africa/Harare",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "WKJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "WKJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "WKK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WKK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WKL",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "WKL",
    "countryCode": "US",
    "timezone": "Pacific/Honolulu",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "WKN",
    "countryCode": "PG",
    "timezone": "Pacific/Bougainville",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "WKN",
    "countryCode": "PG",
    "timezone": "Pacific/Bougainville",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "WKR",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "WKR",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "WLA",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WLB",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WLC",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "WLC",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "WLD",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "WLD",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "WLE",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WLE",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WLG",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "WLG",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "WLH",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "WLH",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "WLI",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WLI",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WLK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WLK",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WLL",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "WLM",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "WLN",
    "countryCode": "US",
    "timezone": "America/Metlakatla",
    "offset": {
      "gmt": -8,
      "dst": -8
    }
  },
  {
    "code": "WLO",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "WLP",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WLP",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WLR",
    "countryCode": "US",
    "timezone": "America/Sitka",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WLR",
    "countryCode": "US",
    "timezone": "America/Sitka",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WLS",
    "countryCode": "WF",
    "timezone": "Pacific/Wallis",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "WLS",
    "countryCode": "WF",
    "timezone": "Pacific/Wallis",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "WLW",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "WLW",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "WMA",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WMA",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WMB",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "WMB",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "WMC",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "WMC",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "WMD",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WMD",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WME",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WME",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WMH",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "WMH",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "WMI",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "WMI",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "WMI",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "WMK",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WMK",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WML",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WML",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WMN",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WMN",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WMO",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WMO",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WMP",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WMR",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WMR",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WMT",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WMT",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WMT",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WMV",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WMX",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "WMX",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "WNA",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WNA",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WNC",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "WNC",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "WND",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WNE",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "WNH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WNH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WNI",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WNI",
    "countryCode": "ID",
    "timezone": "Asia/Makassar",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WNN",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "WNN",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "WNP",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WNP",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WNR",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WNR",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WNS",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "WNS",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "WNU",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WNZ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WNZ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WOA",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WOA",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WOB",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "WOD",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WOE",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "WOE",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "WOG",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "WOG",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "WOI",
    "countryCode": "LR",
    "timezone": "Africa/Monrovia",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "WOK",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "WOK",
    "countryCode": "VE",
    "timezone": "America/Caracas",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "WOL",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "WOL",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "WON",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WON",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WOO",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WOR",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WOS",
    "countryCode": "KP",
    "timezone": "Asia/Pyongyang",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "WOS",
    "countryCode": "KP",
    "timezone": "Asia/Pyongyang",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "WOT",
    "countryCode": "TW",
    "timezone": "Asia/Taipei",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WOW",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WOW",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WPA",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "WPA",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "WPB",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WPB",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WPC",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "WPC",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "WPK",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WPL",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "WPM",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WPM",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WPO",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "WPR",
    "countryCode": "CL",
    "timezone": "America/Punta_Arenas",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "WPR",
    "countryCode": "CL",
    "timezone": "America/Punta_Arenas",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "WPS",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "WPT",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "WPU",
    "countryCode": "CL",
    "timezone": "America/Punta_Arenas",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "WPU",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "WRA",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WRB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "WRB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "WRE",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "WRE",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "WRG",
    "countryCode": "US",
    "timezone": "America/Sitka",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WRG",
    "countryCode": "US",
    "timezone": "America/Sitka",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WRH",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "WRH",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WRH",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "WRI",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "WRI",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "WRL",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "WRL",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "WRN",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WRN",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WRO",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "WRO",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "WRT",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "WRT",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "WRW",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WRY",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "WRY",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "WRZ",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "WRZ",
    "countryCode": "LK",
    "timezone": "Asia/Colombo",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "WSA",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WSB",
    "countryCode": "US",
    "timezone": "America/Metlakatla",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WSD",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "WSD",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "WSE",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "WSF",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WSF",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WSG",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "WSH",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "WSH",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "WSH",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "WSI",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "WSJ",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WSK",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WSK",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WSM",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WSM",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WSN",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WSN",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WSO",
    "countryCode": "SR",
    "timezone": "America/Paramaribo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "WSO",
    "countryCode": "SR",
    "timezone": "America/Paramaribo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "WSP",
    "countryCode": "NI",
    "timezone": "America/Managua",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "WSP",
    "countryCode": "NI",
    "timezone": "America/Managua",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "WSQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WSQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WSR",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "WSR",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "WSS",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "WST",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "WST",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "WSU",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WSX",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "WSX",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "WSY",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WSY",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WSY",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WSZ",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "WSZ",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "WTA",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WTB",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WTD",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "WTD",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "WTE",
    "countryCode": "MH",
    "timezone": "Pacific/Majuro",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "WTK",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WTK",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WTL",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WTL",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WTN",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "WTN",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "WTO",
    "countryCode": "MH",
    "timezone": "Pacific/Kwajalein",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "WTO",
    "countryCode": "MH",
    "timezone": "Pacific/Kwajalein",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "WTP",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WTP",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WTR",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "WTR",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "WTS",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WTS",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WTT",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WTT",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WTZ",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "WTZ",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "WUA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WUA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WUD",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "WUD",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "WUG",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WUG",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WUH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WUH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WUI",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WUI",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WUK",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "WUM",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WUN",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WUN",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WUS",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WUS",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WUT",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WUT",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WUT",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WUU",
    "countryCode": "SS",
    "timezone": "Africa/Juba",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "WUU",
    "countryCode": "SS",
    "timezone": "Africa/Juba",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "WUV",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WUV",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WUX",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WUX",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WUZ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WUZ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WUZ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WVB",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "WVB",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "WVI",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "WVI",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "WVK",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WVK",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "WVL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "WVL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "WVN",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "WVN",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "WWA",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WWA",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WWD",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "WWD",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "WWG",
    "countryCode": "OM",
    "timezone": "Asia/Muscat",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "WWI",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WWK",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WWK",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "WWP",
    "countryCode": "US",
    "timezone": "America/Metlakatla",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WWP",
    "countryCode": "US",
    "timezone": "America/Juneau",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WWR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "WWR",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "WWS",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WWT",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WWT",
    "countryCode": "US",
    "timezone": "America/Nome",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WWY",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "WWY",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "WXB",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WXF",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "WXF",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "WXF",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "WXF",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "WXF",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "WXJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WXJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WXN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WXN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WYA",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "WYA",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "WYB",
    "countryCode": "US",
    "timezone": "America/Sitka",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "WYE",
    "countryCode": "SL",
    "timezone": "Africa/Freetown",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "WYE",
    "countryCode": "SL",
    "timezone": "Africa/Freetown",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "WYN",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WYN",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WYS",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "WYS",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "WZA",
    "countryCode": "GH",
    "timezone": "Africa/Accra",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "WZA",
    "countryCode": "GH",
    "timezone": "Africa/Accra",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "WZE",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WZQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WZQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "WZY",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XAB",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XAB",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XAC",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XAC",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XAD",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "XAG",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XAH",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XAI",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "XAI",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XAI",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "XAJ",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XAK",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XAL",
    "countryCode": "MX",
    "timezone": "America/Hermosillo",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "XAM",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XAN",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XAN",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XAP",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "XAP",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "XAQ",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XAQ",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XAR",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "XAR",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "XAS",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XAT",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XAT",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XAU",
    "countryCode": "GF",
    "timezone": "America/Cayenne",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "XAV",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XAV",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XAW",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XAW",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XAX",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XAY",
    "countryCode": "LA",
    "timezone": "Asia/Vientiane",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "XAZ",
    "countryCode": "CA",
    "timezone": "America/Moncton",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "XBA",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XBB",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "XBB",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "XBC",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XBD",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XBE",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "XBE",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "XBF",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XBG",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "XBG",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "XBH",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XBI",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XBJ",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "XBJ",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "XBK",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XBL",
    "countryCode": "ET",
    "timezone": "Africa/Addis_Ababa",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "XBM",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XBN",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "XBO",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "XBO",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "XBP",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XBQ",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XBQ",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XBR",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XBR",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XBS",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XBT",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XBU",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XBV",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XBW",
    "countryCode": "AE",
    "timezone": "Asia/Dubai",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "XBW",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XBX",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XBY",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XBZ",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XCA",
    "countryCode": "CA",
    "timezone": "America/Moncton",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "XCB",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XCB",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XCC",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XCD",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XCE",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XCF",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XCG",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XCG",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XCH",
    "countryCode": "CX",
    "timezone": "Indian/Christmas",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "XCH",
    "countryCode": "CX",
    "timezone": "Indian/Christmas",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "XCI",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XCJ",
    "countryCode": "MF",
    "timezone": "America/Marigot",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "XCK",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XCL",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "XCL",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "XCM",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XCM",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XCM",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XCN",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "XCO",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "XCO",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "XCP",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XCQ",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XCR",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XCS",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XCT",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XCU",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XCV",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XCW",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XCX",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XCY",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XCZ",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XDA",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XDA",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XDB",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XDC",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XDD",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XDE",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "XDE",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "XDG",
    "countryCode": "CA",
    "timezone": "America/Halifax",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "XDH",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "XDI",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XDJ",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "XDJ",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "XDK",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "XDL",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XDM",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XDN",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XDO",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XDP",
    "countryCode": "CA",
    "timezone": "America/Moncton",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "XDQ",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XDR",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XDS",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XDT",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XDU",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XDU",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XDV",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "XDW",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "XDX",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XDY",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XDZ",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "XEA",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "XEB",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XEC",
    "countryCode": "CA",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XED",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XED",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XEE",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XEE",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XEF",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "XEG",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XEH",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "XEH",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "XEI",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "XEJ",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "XEJ",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "XEK",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XEL",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XEM",
    "countryCode": "CA",
    "timezone": "America/Moncton",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XEN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "XEO",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "XEO",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "XEP",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XEQ",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "XEQ",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "XER",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XES",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "XES",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "XET",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XEU",
    "countryCode": "AE",
    "timezone": "Asia/Dubai",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XEV",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XEW",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XEX",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XEY",
    "countryCode": "CA",
    "timezone": "America/Moncton",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "XEZ",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XEZ",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XFA",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XFB",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XFC",
    "countryCode": "CA",
    "timezone": "America/Moncton",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "XFD",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XFE",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XFF",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XFG",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XFH",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XFI",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XFJ",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XFK",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XFK",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XFL",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XFM",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "XFM",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "XFN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "XFN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "XFO",
    "countryCode": "CA",
    "timezone": "America/Montreal",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XFO",
    "countryCode": "CA",
    "timezone": "America/Montreal",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XFP",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XFQ",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XFR",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XFS",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XFS",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XFT",
    "countryCode": "HK",
    "timezone": "Asia/Hong_Kong",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XFU",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XFV",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XFW",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XFX",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XFY",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XFZ",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XGA",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "XGA",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "XGB",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XGC",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XGD",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XGD",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XGE",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XGF",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XGG",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "XGG",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "XGH",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XGI",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XGJ",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XGJ",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XGK",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XGL",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "XGL",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XGM",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XGN",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "XGN",
    "countryCode": "AO",
    "timezone": "Africa/Luanda",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "XGO",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XGP",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XGP",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XGQ",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "XGQ",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "XGR",
    "countryCode": "CA",
    "timezone": "America/Iqaluit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XGR",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XGS",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XGT",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XGU",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XGU",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XGV",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XGW",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XGX",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "XGY",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XGZ",
    "countryCode": "AT",
    "timezone": "Europe/Vienna",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XGZ",
    "countryCode": "AT",
    "timezone": "Europe/Vienna",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XHA",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "XHA",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "XHB",
    "countryCode": "AE",
    "timezone": "Asia/Dubai",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "XHC",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XHD",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "XHE",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XHF",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XHG",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XHH",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XHI",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XHJ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XHK",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XHL",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XHM",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XHN",
    "countryCode": "BE",
    "timezone": "Europe/Brussels",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XHO",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "XHO",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "XHP",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XHQ",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "XHQ",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "XHQ",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "XHR",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XHS",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XHT",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XHU",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XHV",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "XHV",
    "countryCode": "RO",
    "timezone": "Europe/Bucharest",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "XHW",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "XHX",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XHY",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XHZ",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XIA",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XIA",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XIB",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XIB",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XIC",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "XIC",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "XID",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XIE",
    "countryCode": "LA",
    "timezone": "Asia/Vientiane",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "XIE",
    "countryCode": "LA",
    "timezone": "Asia/Vientiane",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "XIF",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XIF",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XIG",
    "countryCode": "BR",
    "timezone": "America/Belem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "XIG",
    "countryCode": "BR",
    "timezone": "America/Belem",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "XIH",
    "countryCode": "IQ",
    "timezone": "Asia/Baghdad",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XII",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XIJ",
    "countryCode": "KW",
    "timezone": "Asia/Kuwait",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "XIJ",
    "countryCode": "KW",
    "timezone": "Asia/Kuwait",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "XIK",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XIL",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "XIL",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "XIM",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XIM",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XIN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "XIO",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XIP",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XIQ",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "XIQ",
    "countryCode": "GL",
    "timezone": "America/Nuuk",
    "offset": {
      "gmt": -2,
      "dst": -1
    }
  },
  {
    "code": "XIR",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XIS",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XIT",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XIU",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XIV",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XIX",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XIY",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "XIZ",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XJA",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XJA",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XJB",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XJC",
    "countryCode": "CZ",
    "timezone": "Europe/Prague",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XJD",
    "countryCode": "QA",
    "timezone": "Asia/Qatar",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "XJE",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XJF",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XJG",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XJH",
    "countryCode": "HK",
    "timezone": "Asia/Hong_Kong",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "XJI",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XJJ",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XJK",
    "countryCode": "LT",
    "timezone": "Europe/Vilnius",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "XJL",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XJM",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "XJM",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "XJN",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XJO",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XJO",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XJP",
    "countryCode": "CZ",
    "timezone": "Europe/Prague",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XJQ",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XJQ",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XJR",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XJR",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XJS",
    "countryCode": "AT",
    "timezone": "Europe/Vienna",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XJT",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XJU",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XJV",
    "countryCode": "CZ",
    "timezone": "Europe/Prague",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XJW",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "XJW",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "XJX",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "XJY",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XJZ",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XJZ",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XKA",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "XKA",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "XKB",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XKC",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XKD",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XKE",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XKF",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XKG",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XKH",
    "countryCode": "LA",
    "timezone": "Asia/Vientiane",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "XKH",
    "countryCode": "LA",
    "timezone": "Asia/Vientiane",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "XKI",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XKJ",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XKK",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XKL",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "XKM",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XKM",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XKN",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XKO",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "XKO",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "XKP",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XKQ",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XKR",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XKS",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "XKS",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "XKT",
    "countryCode": "HK",
    "timezone": "Asia/Hong_Kong",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "XKU",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XKV",
    "countryCode": "CA",
    "timezone": "America/Moncton",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "XKW",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XKW",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XKX",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "XKX",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "XKY",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "XKY",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "XKZ",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XLA",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XLB",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "XLB",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "XLC",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "XLD",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XLE",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XLF",
    "countryCode": "CA",
    "timezone": "America/Iqaluit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XLF",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "XLG",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XLH",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XLI",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XLJ",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XLK",
    "countryCode": "CA",
    "timezone": "America/Montreal",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XLL",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XLM",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XLM",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XLN",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XLO",
    "countryCode": "VN",
    "timezone": "Asia/Bangkok",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "XLP",
    "countryCode": "CA",
    "timezone": "America/Moncton",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XLQ",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XLQ",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XLR",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XLS",
    "countryCode": "SN",
    "timezone": "Africa/Dakar",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "XLS",
    "countryCode": "SN",
    "timezone": "Africa/Dakar",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "XLT",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XLU",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "XLV",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XLV",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XLW",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XLW",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XLX",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XLY",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XLZ",
    "countryCode": "CA",
    "timezone": "America/Halifax",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "XMA",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "XMB",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "XMC",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "XMC",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "XMD",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "XMD",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "XME",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XME",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XMF",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XMG",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "XMG",
    "countryCode": "NP",
    "timezone": "Asia/Kathmandu",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "XMH",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "XMH",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "XMI",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "XMI",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "XMJ",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XMJ",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XMK",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XML",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "XML",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": 10,
      "dst": 9
    }
  },
  {
    "code": "XMM",
    "countryCode": "MC",
    "timezone": "Europe/Monaco",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XMN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "XMN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "XMO",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XMP",
    "countryCode": "CA",
    "timezone": "America/Whitehorse",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "XMP",
    "countryCode": "CA",
    "timezone": "America/Whitehorse",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "XMQ",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XMR",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XMS",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "XMS",
    "countryCode": "EC",
    "timezone": "America/Guayaquil",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "XMT",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XMU",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XMV",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "XMW",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XMW",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XMX",
    "countryCode": "AR",
    "timezone": "America/Argentina/Jujuy",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "XMY",
    "countryCode": "AU",
    "timezone": "Australia/Lindeman",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "XMY",
    "countryCode": "AU",
    "timezone": "Australia/Lindeman",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "XMZ",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XNA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "XNB",
    "countryCode": "AE",
    "timezone": "Asia/Dubai",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "XNC",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XND",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XNE",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XNE",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XNG",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "XNH",
    "countryCode": "IQ",
    "timezone": "Asia/Baghdad",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "XNH",
    "countryCode": "IQ",
    "timezone": "Asia/Baghdad",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "XNJ",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "XNK",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XNL",
    "countryCode": "BE",
    "timezone": "Europe/Brussels",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XNM",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XNN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "XNN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "XNO",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XNR",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XNS",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XNT",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "XNT",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "XNU",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "XNU",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "XNV",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XNY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XNZ",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XOA",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XOB",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XOC",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XOD",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XOE",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "XOF",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XOG",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XOH",
    "countryCode": "AT",
    "timezone": "Europe/Vienna",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XOI",
    "countryCode": "AT",
    "timezone": "Europe/Vienna",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XOI",
    "countryCode": "AT",
    "timezone": "Europe/Vienna",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XOJ",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XOK",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XOL",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XOM",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XON",
    "countryCode": "CA",
    "timezone": "America/Moncton",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XOO",
    "countryCode": "AT",
    "timezone": "Europe/Vienna",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XOP",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XOQ",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XOQ",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XOR",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XOS",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "XOT",
    "countryCode": "GP",
    "timezone": "America/Guadeloupe",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XOU",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XOU",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XOV",
    "countryCode": "AT",
    "timezone": "Europe/Vienna",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XOW",
    "countryCode": "AT",
    "timezone": "Europe/Vienna",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XOW",
    "countryCode": "AT",
    "timezone": "Europe/Vienna",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XOX",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XOY",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XOZ",
    "countryCode": "QA",
    "timezone": "Asia/Qatar",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "XPA",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "XPA",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "XPB",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "XPB",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "XPB",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "XPC",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XPD",
    "countryCode": "AR",
    "timezone": "America/Argentina/Jujuy",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "XPD",
    "countryCode": "AR",
    "timezone": "America/Argentina/Jujuy",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "XPF",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XPG",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XPH",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XPH",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XPI",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XPJ",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XPK",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "XPK",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "XPL",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "XPL",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "XPL",
    "countryCode": "HN",
    "timezone": "America/Tegucigalpa",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "XPM",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XPN",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XPO",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XPP",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "XPP",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "XPQ",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XPR",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "XPR",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "XPS",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XPT",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XPT",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XPU",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "XPU",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XPV",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XPW",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XPX",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XPY",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XPZ",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XQA",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XQB",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XQC",
    "countryCode": "IQ",
    "timezone": "Asia/Baghdad",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "XQD",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XQE",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XQG",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XQH",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XQI",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XQL",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XQL",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XQM",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XQN",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XQO",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XQP",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "XQP",
    "countryCode": "CR",
    "timezone": "America/Costa_Rica",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "XQS",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "XQT",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XQU",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "XQU",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "XQV",
    "countryCode": "IQ",
    "timezone": "Asia/Baghdad",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "XQW",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XQY",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XRA",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XRB",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XRC",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XRD",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XRE",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XRE",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XRF",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XRG",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XRH",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "XRH",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "XRI",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XRJ",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XRK",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XRL",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XRM",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XRN",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XRP",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XRQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "XRQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "XRR",
    "countryCode": "CA",
    "timezone": "America/Whitehorse",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "XRR",
    "countryCode": "CA",
    "timezone": "America/Whitehorse",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "XRS",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XRT",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XRU",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XRV",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XRX",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XRX",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XRY",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XRY",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XRZ",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XSA",
    "countryCode": "AL",
    "timezone": "Europe/Tirane",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XSA",
    "countryCode": "LR",
    "timezone": "Africa/Monrovia",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "XSA",
    "countryCode": "AL",
    "timezone": "Europe/Tirane",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XSB",
    "countryCode": "AE",
    "timezone": "Asia/Dubai",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "XSB",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XSB",
    "countryCode": "AE",
    "timezone": "Asia/Dubai",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "XSC",
    "countryCode": "TC",
    "timezone": "America/Grand_Turk",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XSC",
    "countryCode": "TC",
    "timezone": "America/Grand_Turk",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XSD",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "XSE",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "XSE",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "XSF",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XSG",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XSH",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XSI",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "XSI",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "XSJ",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XSK",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XSL",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XSM",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XSM",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "XSM",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XSN",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XSN",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XSO",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "XSP",
    "countryCode": "SG",
    "timezone": "Asia/Singapore",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "XSQ",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XSR",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XSS",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XST",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XSU",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XSV",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XSW",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XSX",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XSY",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XSZ",
    "countryCode": "PT",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XTA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XTB",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XTC",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XTD",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XTE",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XTF",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XTG",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "XTG",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "XTH",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XTI",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XTJ",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XTJ",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XTK",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XTL",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "XTL",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "XTM",
    "countryCode": "IQ",
    "timezone": "Asia/Baghdad",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "XTN",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XTN",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XTO",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "XTO",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "XTP",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "XTQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "XTQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "XTR",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "XTR",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "XTS",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XTT",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XTU",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XTV",
    "countryCode": "IQ",
    "timezone": "Asia/Baghdad",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XTX",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XTY",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XTZ",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "XUA",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XUB",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XUC",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XUE",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XUF",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XUG",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XUH",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XUI",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XUJ",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XUK",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "XUL",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XUM",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XUM",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XUN",
    "countryCode": "US",
    "timezone": "America/Boise",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XUO",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XUQ",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XUQ",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XUR",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XUS",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XUT",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XUU",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XUV",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XUW",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XUX",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XUY",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XUZ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "XUZ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "XVA",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XVB",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XVC",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XVC",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XVD",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XVE",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XVF",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XVG",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XVG",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XVH",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XVI",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XVJ",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XVK",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XVL",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "XVL",
    "countryCode": "VN",
    "timezone": "Asia/Ho_Chi_Minh",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "XVM",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "XVN",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XVO",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XVO",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XVP",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XVQ",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XVR",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XVS",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XVS",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XVT",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XVU",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XVV",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XVV",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XVW",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XVX",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XVY",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XVZ",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XWA",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "XWA",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XWA",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XWB",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XWC",
    "countryCode": "AT",
    "timezone": "Europe/Vienna",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XWD",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XWE",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XWF",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XWG",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XWH",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XWH",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XWI",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XWI",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XWK",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XWL",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XWM",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XWM",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XWN",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XWO",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XWP",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XWP",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XWQ",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XWR",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XWS",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XWS",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "XWT",
    "countryCode": "AT",
    "timezone": "Europe/Vienna",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XWU",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "XWV",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XWW",
    "countryCode": "AT",
    "timezone": "Europe/Vienna",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XWX",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XWY",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XWZ",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XXA",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XXB",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "XXC",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XXD",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XXE",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XXF",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XXG",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XXH",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XXI",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XXJ",
    "countryCode": "HU",
    "timezone": "Europe/Budapest",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XXK",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XXL",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XXM",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XXN",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XXN",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XXO",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XXO",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XXP",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XXQ",
    "countryCode": "HU",
    "timezone": "Europe/Budapest",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XXR",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XXS",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XXT",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XXU",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XXU",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XXV",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XXW",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XXX",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XXY",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XXZ",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XYA",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "XYA",
    "countryCode": "SB",
    "timezone": "Pacific/Guadalcanal",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "XYB",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XYC",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XYD",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XYE",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "XYE",
    "countryCode": "MM",
    "timezone": "Asia/Yangon",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "XYF",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XYF",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XYG",
    "countryCode": "CZ",
    "timezone": "Europe/Prague",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XYH",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XYI",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XYI",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XYJ",
    "countryCode": "CZ",
    "timezone": "Europe/Prague",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XYK",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XYL",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XYM",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XYN",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XYO",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XYP",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XYP",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XYQ",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XYR",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "XYS",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XYT",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "XYU",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XYW",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "XYW",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "XYX",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XYX",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XYY",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XYZ",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XZA",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "XZA",
    "countryCode": "BF",
    "timezone": "Africa/Ouagadougou",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "XZB",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XZC",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XZD",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XZE",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XZF",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "XZG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "XZH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "XZI",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XZJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "XZK",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XZL",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "XZM",
    "countryCode": "MO",
    "timezone": "Asia/Macau",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "XZN",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XZO",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XZP",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XZQ",
    "countryCode": "SE",
    "timezone": "Europe/Stockholm",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XZR",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XZT",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XZU",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "XZV",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XZX",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "XZY",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "XZZ",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "YAA",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YAA",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YAB",
    "countryCode": "CA",
    "timezone": "America/Rankin_Inlet",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YAB",
    "countryCode": "CA",
    "timezone": "America/Rankin_Inlet",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YAC",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YAC",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YAD",
    "countryCode": "CA",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "YAE",
    "countryCode": "RU",
    "timezone": "Asia/Krasnoyarsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "YAE",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YAE",
    "countryCode": "RU",
    "timezone": "Asia/Krasnoyarsk",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "YAF",
    "countryCode": "CA",
    "timezone": "America/Montreal",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YAG",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YAG",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YAH",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YAH",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YAI",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "YAJ",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YAJ",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YAK",
    "countryCode": "US",
    "timezone": "America/Yakutat",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "YAK",
    "countryCode": "US",
    "timezone": "America/Yakutat",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "YAL",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YAL",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YAM",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YAM",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YAN",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "YAN",
    "countryCode": "CD",
    "timezone": "Africa/Lubumbashi",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "YAO",
    "countryCode": "CM",
    "timezone": "Africa/Douala",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "YAO",
    "countryCode": "CM",
    "timezone": "Africa/Douala",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "YAP",
    "countryCode": "FM",
    "timezone": "Pacific/Chuuk",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "YAP",
    "countryCode": "FM",
    "timezone": "Pacific/Chuuk",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "YAQ",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YAQ",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YAR",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YAR",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YAS",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "YAS",
    "countryCode": "FJ",
    "timezone": "Pacific/Fiji",
    "offset": {
      "gmt": 12,
      "dst": 12
    }
  },
  {
    "code": "YAT",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YAT",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YAU",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YAU",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YAU",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YAV",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YAV",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YAV",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YAW",
    "countryCode": "CA",
    "timezone": "America/Halifax",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YAX",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YAX",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YAY",
    "countryCode": "CA",
    "timezone": "America/St_Johns",
    "offset": {
      "gmt": -3,
      "dst": -2
    }
  },
  {
    "code": "YAY",
    "countryCode": "CA",
    "timezone": "America/St_Johns",
    "offset": {
      "gmt": -3,
      "dst": -2
    }
  },
  {
    "code": "YAZ",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YAZ",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YBA",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YBA",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YBB",
    "countryCode": "CA",
    "timezone": "America/Cambridge_Bay",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YBB",
    "countryCode": "CA",
    "timezone": "America/Cambridge_Bay",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YBC",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YBC",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YBD",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YBD",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YBE",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "YBE",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "YBF",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YBF",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YBG",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YBG",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YBH",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YBH",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YBI",
    "countryCode": "CA",
    "timezone": "America/St_Johns",
    "offset": {
      "gmt": -3,
      "dst": -2
    }
  },
  {
    "code": "YBI",
    "countryCode": "CA",
    "timezone": "America/St_Johns",
    "offset": {
      "gmt": -3,
      "dst": -2
    }
  },
  {
    "code": "YBJ",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YBJ",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YBK",
    "countryCode": "CA",
    "timezone": "America/Rankin_Inlet",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YBK",
    "countryCode": "CA",
    "timezone": "America/Rankin_Inlet",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YBL",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YBL",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YBM",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YBN",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YBN",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YBO",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YBO",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YBP",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YBP",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YBP",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YBP",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YBQ",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YBQ",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YBR",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YBR",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YBS",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YBS",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YBT",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YBT",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YBV",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YBV",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YBW",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YBW",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YBX",
    "countryCode": "CA",
    "timezone": "America/Blanc-Sablon",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "YBX",
    "countryCode": "CA",
    "timezone": "America/Blanc-Sablon",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "YBX",
    "countryCode": "CA",
    "timezone": "America/Blanc-Sablon",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "YBY",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YBY",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YBZ",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YCA",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YCA",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YCB",
    "countryCode": "CA",
    "timezone": "America/Cambridge_Bay",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YCB",
    "countryCode": "CA",
    "timezone": "America/Cambridge_Bay",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YCC",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YCC",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YCD",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YCD",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YCE",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YCE",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YCF",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YCF",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YCG",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YCG",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YCH",
    "countryCode": "CA",
    "timezone": "America/Moncton",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YCH",
    "countryCode": "CA",
    "timezone": "America/Moncton",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YCI",
    "countryCode": "CA",
    "timezone": "America/Nipigon",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YCJ",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YCK",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YCK",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YCL",
    "countryCode": "CA",
    "timezone": "America/Moncton",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YCL",
    "countryCode": "CA",
    "timezone": "America/Moncton",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YCM",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YCM",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YCN",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YCN",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YCO",
    "countryCode": "CA",
    "timezone": "America/Cambridge_Bay",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YCO",
    "countryCode": "CA",
    "timezone": "America/Cambridge_Bay",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YCP",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "YCQ",
    "countryCode": "CA",
    "timezone": "America/Dawson_Creek",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "YCQ",
    "countryCode": "CA",
    "timezone": "America/Dawson_Creek",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "YCR",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YCR",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YCS",
    "countryCode": "CA",
    "timezone": "America/Rankin_Inlet",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YCS",
    "countryCode": "CA",
    "timezone": "America/Rankin_Inlet",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YCT",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YCT",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YCU",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YCU",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YCV",
    "countryCode": "CA",
    "timezone": "America/Montreal",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YCV",
    "countryCode": "CA",
    "timezone": "America/Montreal",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YCW",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YCW",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YCX",
    "countryCode": "CA",
    "timezone": "America/Moncton",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YCX",
    "countryCode": "CA",
    "timezone": "America/Moncton",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YCY",
    "countryCode": "CA",
    "timezone": "America/Iqaluit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YCY",
    "countryCode": "CA",
    "timezone": "America/Iqaluit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YCZ",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YCZ",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YDA",
    "countryCode": "CA",
    "timezone": "America/Whitehorse",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "YDA",
    "countryCode": "CA",
    "timezone": "America/Dawson",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "YDB",
    "countryCode": "CA",
    "timezone": "America/Whitehorse",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "YDB",
    "countryCode": "CA",
    "timezone": "America/Whitehorse",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "YDC",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YDD",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YDE",
    "countryCode": "CA",
    "timezone": "America/St_Johns",
    "offset": {
      "gmt": -3,
      "dst": -2
    }
  },
  {
    "code": "YDF",
    "countryCode": "CA",
    "timezone": "America/St_Johns",
    "offset": {
      "gmt": -3,
      "dst": -2
    }
  },
  {
    "code": "YDF",
    "countryCode": "CA",
    "timezone": "America/St_Johns",
    "offset": {
      "gmt": -3,
      "dst": -2
    }
  },
  {
    "code": "YDG",
    "countryCode": "CA",
    "timezone": "America/Halifax",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YDG",
    "countryCode": "CA",
    "timezone": "America/Halifax",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YDH",
    "countryCode": "CA",
    "timezone": "America/St_Johns",
    "offset": {
      "gmt": -3,
      "dst": -2
    }
  },
  {
    "code": "YDI",
    "countryCode": "CA",
    "timezone": "America/Goose_Bay",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YDJ",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "YDJ",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "YDK",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YDL",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YDL",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YDN",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YDN",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YDO",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YDO",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YDP",
    "countryCode": "CA",
    "timezone": "America/Goose_Bay",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YDP",
    "countryCode": "CA",
    "timezone": "America/Goose_Bay",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YDQ",
    "countryCode": "CA",
    "timezone": "America/Dawson_Creek",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "YDQ",
    "countryCode": "CA",
    "timezone": "America/Dawson_Creek",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "YDR",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "YDS",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YDS",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YDT",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YDU",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YDU",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YDV",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YDV",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YDW",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YDW",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YDX",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YEA",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YEB",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YEB",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YEC",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "YED",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YEG",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YEH",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 4
    }
  },
  {
    "code": "YEH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YEI",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "YEI",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "YEI",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "YEK",
    "countryCode": "CA",
    "timezone": "America/Rankin_Inlet",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YEK",
    "countryCode": "CA",
    "timezone": "America/Rankin_Inlet",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YEL",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YEL",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YEM",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YEM",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YEN",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "YEN",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "YEO",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "YEO",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "YEP",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YEP",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YEQ",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "YER",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YER",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YES",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "YES",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "YET",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YET",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YEU",
    "countryCode": "CA",
    "timezone": "America/Rankin_Inlet",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YEU",
    "countryCode": "CA",
    "timezone": "America/Rankin_Inlet",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YEV",
    "countryCode": "CA",
    "timezone": "America/Inuvik",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YEV",
    "countryCode": "CA",
    "timezone": "America/Inuvik",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YEY",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YEY",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YFA",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YFA",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YFB",
    "countryCode": "CA",
    "timezone": "America/Iqaluit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YFB",
    "countryCode": "CA",
    "timezone": "America/Iqaluit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YFC",
    "countryCode": "CA",
    "timezone": "America/Moncton",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YFC",
    "countryCode": "CA",
    "timezone": "America/Moncton",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YFE",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YFE",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YFG",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YFG",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YFH",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YFH",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YFI",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YFJ",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YFJ",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YFJ",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YFL",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YFL",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YFO",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YFO",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YFR",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YFR",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YFS",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YFS",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YFT",
    "countryCode": "MO",
    "timezone": "Asia/Macau",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YFX",
    "countryCode": "CA",
    "timezone": "America/St_Johns",
    "offset": {
      "gmt": -3,
      "dst": -2
    }
  },
  {
    "code": "YFX",
    "countryCode": "CA",
    "timezone": "America/St_Johns",
    "offset": {
      "gmt": -3,
      "dst": -2
    }
  },
  {
    "code": "YGA",
    "countryCode": "CA",
    "timezone": "America/Montreal",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YGA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YGA",
    "countryCode": "CA",
    "timezone": "America/Montreal",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YGB",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YGB",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YGC",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YGC",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YGE",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YGE",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YGG",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YGG",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YGH",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YGH",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YGI",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YGJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "YGJ",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "YGK",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YGK",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YGL",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YGL",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YGM",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YGM",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YGN",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YGN",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YGO",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YGO",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YGP",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YGP",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YGQ",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YGQ",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YGR",
    "countryCode": "CA",
    "timezone": "America/Halifax",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YGR",
    "countryCode": "CA",
    "timezone": "America/Halifax",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YGS",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YGS",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YGT",
    "countryCode": "CA",
    "timezone": "America/Iqaluit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YGT",
    "countryCode": "CA",
    "timezone": "America/Iqaluit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YGV",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YGV",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YGW",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YGW",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YGX",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YGX",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YGY",
    "countryCode": "CA",
    "timezone": "America/Montreal",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YGY",
    "countryCode": "CA",
    "timezone": "America/Montreal",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YGZ",
    "countryCode": "CA",
    "timezone": "America/Iqaluit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YGZ",
    "countryCode": "CA",
    "timezone": "America/Iqaluit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YHA",
    "countryCode": "CA",
    "timezone": "America/St_Johns",
    "offset": {
      "gmt": -3,
      "dst": -2
    }
  },
  {
    "code": "YHA",
    "countryCode": "CA",
    "timezone": "America/St_Johns",
    "offset": {
      "gmt": -3,
      "dst": -2
    }
  },
  {
    "code": "YHB",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "YHB",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "YHC",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YHC",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YHD",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YHD",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YHE",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YHE",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YHF",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YHF",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YHG",
    "countryCode": "CA",
    "timezone": "America/St_Johns",
    "offset": {
      "gmt": -3,
      "dst": -2
    }
  },
  {
    "code": "YHG",
    "countryCode": "CA",
    "timezone": "America/St_Johns",
    "offset": {
      "gmt": -3,
      "dst": -2
    }
  },
  {
    "code": "YHH",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YHI",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YHI",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YHK",
    "countryCode": "CA",
    "timezone": "America/Cambridge_Bay",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YHK",
    "countryCode": "CA",
    "timezone": "America/Cambridge_Bay",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YHM",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YHN",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YHN",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YHO",
    "countryCode": "CA",
    "timezone": "America/Goose_Bay",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YHO",
    "countryCode": "CA",
    "timezone": "America/Goose_Bay",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YHP",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YHP",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YHR",
    "countryCode": "CA",
    "timezone": "America/Blanc-Sablon",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "YHR",
    "countryCode": "CA",
    "timezone": "America/Blanc-Sablon",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "YHS",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YHS",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YHT",
    "countryCode": "CA",
    "timezone": "America/Whitehorse",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "YHT",
    "countryCode": "CA",
    "timezone": "America/Whitehorse",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "YHU",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YHY",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YHY",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YHZ",
    "countryCode": "CA",
    "timezone": "America/Halifax",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YHZ",
    "countryCode": "CA",
    "timezone": "America/Halifax",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YIA",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "YIB",
    "countryCode": "CA",
    "timezone": "America/Atikokan",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "YIB",
    "countryCode": "CA",
    "timezone": "America/Atikokan",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "YIC",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YIC",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YIE",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YIE",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YIF",
    "countryCode": "CA",
    "timezone": "America/Blanc-Sablon",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "YIF",
    "countryCode": "CA",
    "timezone": "America/Blanc-Sablon",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "YIG",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YIG",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YIH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YIH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YIK",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YIK",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YIN",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "YIN",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "YIO",
    "countryCode": "CA",
    "timezone": "America/Iqaluit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YIO",
    "countryCode": "CA",
    "timezone": "America/Iqaluit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YIP",
    "countryCode": "US",
    "timezone": "America/Detroit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YIU",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YIV",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YIV",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YIV",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YIW",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YIW",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YJA",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YJA",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YJB",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "YJC",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "YJD",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "YJE",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "YJF",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YJF",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YJG",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "YJH",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "YJI",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "YJL",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "YJM",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "YJN",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YJN",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YJO",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YJP",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YJP",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YJR",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "YJS",
    "countryCode": "KP",
    "timezone": "Asia/Pyongyang",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "YJT",
    "countryCode": "CA",
    "timezone": "America/St_Johns",
    "offset": {
      "gmt": -3,
      "dst": -2
    }
  },
  {
    "code": "YJT",
    "countryCode": "CA",
    "timezone": "America/St_Johns",
    "offset": {
      "gmt": -3,
      "dst": -2
    }
  },
  {
    "code": "YJV",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "YJW",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "YKA",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YKA",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YKC",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "YKC",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "YKD",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YKD",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YKE",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YKE",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YKF",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YKG",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YKG",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YKH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YKH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YKI",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YKJ",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "YKJ",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "YKK",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YKK",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YKL",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YKL",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YKM",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YKM",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YKN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YKN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YKO",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "YKO",
    "countryCode": "TR",
    "timezone": "Europe/Istanbul",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "YKQ",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YKQ",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YKS",
    "countryCode": "RU",
    "timezone": "Asia/Yakutsk",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "YKS",
    "countryCode": "RU",
    "timezone": "Asia/Yakutsk",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "YKT",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YKT",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YKU",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YKU",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YKX",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YKX",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YKY",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "YKY",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "YKZ",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YLA",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YLA",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YLB",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YLB",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YLC",
    "countryCode": "CA",
    "timezone": "America/Iqaluit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YLC",
    "countryCode": "CA",
    "timezone": "America/Iqaluit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YLD",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YLD",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YLE",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YLE",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YLF",
    "countryCode": "CA",
    "timezone": "America/Montreal",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YLF",
    "countryCode": "CA",
    "timezone": "America/Montreal",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YLG",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YLG",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YLH",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YLI",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "YLI",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "YLJ",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "YLJ",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "YLK",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YLK",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YLK",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YLK",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YLL",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YLL",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YLM",
    "countryCode": "CA",
    "timezone": "America/Whitehorse",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "YLN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YLN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YLO",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YLO",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YLP",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YLQ",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YLQ",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YLR",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YLR",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YLS",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YLS",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YLT",
    "countryCode": "CA",
    "timezone": "America/Iqaluit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YLT",
    "countryCode": "CA",
    "timezone": "America/Iqaluit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YLV",
    "countryCode": "AZ",
    "timezone": "Asia/Baku",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "YLV",
    "countryCode": "AZ",
    "timezone": "Asia/Baku",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "YLW",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YLW",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YLX",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YLX",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YLX",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YLX",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YLY",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YLY",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YMA",
    "countryCode": "CA",
    "timezone": "America/Whitehorse",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "YMA",
    "countryCode": "CA",
    "timezone": "America/Whitehorse",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "YMB",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YMB",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YMC",
    "countryCode": "CA",
    "timezone": "America/Montreal",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YMD",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YMD",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YME",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YME",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YMF",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YMF",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YMG",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YMG",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YMH",
    "countryCode": "CA",
    "timezone": "America/St_Johns",
    "offset": {
      "gmt": -3,
      "dst": -2
    }
  },
  {
    "code": "YMH",
    "countryCode": "CA",
    "timezone": "America/St_Johns",
    "offset": {
      "gmt": -3,
      "dst": -2
    }
  },
  {
    "code": "YMI",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YMI",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YMJ",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "YMJ",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "YMK",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "YMK",
    "countryCode": "RU",
    "timezone": "Asia/Yekaterinburg",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "YML",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YML",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YMM",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YMM",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YMN",
    "countryCode": "CA",
    "timezone": "America/Goose_Bay",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YMN",
    "countryCode": "CA",
    "timezone": "America/Goose_Bay",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YMO",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YMO",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YMP",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YMP",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YMQ",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YMR",
    "countryCode": "CA",
    "timezone": "America/Pangnirtung",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YMS",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "YMS",
    "countryCode": "PE",
    "timezone": "America/Lima",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "YMT",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YMT",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YMU",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YMU",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YMV",
    "countryCode": "CA",
    "timezone": "America/Iqaluit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YMW",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YMW",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YMX",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YMY",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YNA",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YNA",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YNB",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "YNB",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "YNC",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YNC",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YND",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YNE",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YNE",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YNF",
    "countryCode": "CA",
    "timezone": "America/St_Johns",
    "offset": {
      "gmt": -3,
      "dst": -2
    }
  },
  {
    "code": "YNG",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YNG",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YNH",
    "countryCode": "CA",
    "timezone": "America/Dawson_Creek",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "YNH",
    "countryCode": "CA",
    "timezone": "America/Dawson_Creek",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "YNI",
    "countryCode": "CA",
    "timezone": "America/Montreal",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YNI",
    "countryCode": "CA",
    "timezone": "America/Montreal",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YNJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YNJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YNK",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YNK",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YNL",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "YNM",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YNM",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YNN",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YNN",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YNN",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YNO",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YNO",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YNP",
    "countryCode": "CA",
    "timezone": "America/Goose_Bay",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YNP",
    "countryCode": "CA",
    "timezone": "America/Goose_Bay",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YNR",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YNR",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YNS",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YNS",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YNT",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YNT",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YNX",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YNX",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YNY",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "YNY",
    "countryCode": "KR",
    "timezone": "Asia/Seoul",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "YNZ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YNZ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YOA",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YOA",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YOC",
    "countryCode": "CA",
    "timezone": "America/Whitehorse",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "YOC",
    "countryCode": "CA",
    "timezone": "America/Whitehorse",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "YOD",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YOD",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YOE",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YOE",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YOG",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YOG",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YOH",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YOH",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YOI",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YOJ",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YOJ",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YOK",
    "countryCode": "JP",
    "timezone": "Asia/Tokyo",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "YOL",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "YOL",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "YON",
    "countryCode": "BT",
    "timezone": "Asia/Thimphu",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "YON",
    "countryCode": "BT",
    "timezone": "Asia/Thimphu",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "YOO",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YOO",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YOP",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YOP",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YOS",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YOS",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YOT",
    "countryCode": "IL",
    "timezone": "Asia/Jerusalem",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "YOT",
    "countryCode": "IL",
    "timezone": "Asia/Jerusalem",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "YOW",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YOW",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YOY",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YOY",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YOY",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YPA",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "YPA",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "YPB",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YPB",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YPC",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YPC",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YPD",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YPD",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YPE",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YPE",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YPF",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YPG",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YPG",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YPH",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YPH",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YPI",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YPI",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YPJ",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YPJ",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YPL",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YPL",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YPM",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YPM",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YPN",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YPN",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YPO",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YPO",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YPP",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YPQ",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YPQ",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YPR",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YPR",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YPS",
    "countryCode": "CA",
    "timezone": "America/Halifax",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YPS",
    "countryCode": "CA",
    "timezone": "America/Halifax",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YPT",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YPT",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YPW",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YPW",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YPX",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YPX",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YPY",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YPY",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YPZ",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YPZ",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YQA",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YQA",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YQB",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YQB",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YQC",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YQC",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YQD",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YQD",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YQE",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YQF",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YQF",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YQG",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YQG",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YQH",
    "countryCode": "CA",
    "timezone": "America/Whitehorse",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "YQH",
    "countryCode": "CA",
    "timezone": "America/Whitehorse",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "YQI",
    "countryCode": "CA",
    "timezone": "America/Halifax",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YQI",
    "countryCode": "CA",
    "timezone": "America/Halifax",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YQJ",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YQK",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YQK",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YQL",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YQL",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YQM",
    "countryCode": "CA",
    "timezone": "America/Moncton",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YQM",
    "countryCode": "CA",
    "timezone": "America/Moncton",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YQN",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YQN",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YQQ",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YQQ",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YQR",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "YQR",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "YQS",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YQS",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YQT",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YQT",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YQU",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YQU",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YQV",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "YQV",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "YQW",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "YQW",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "YQX",
    "countryCode": "CA",
    "timezone": "America/St_Johns",
    "offset": {
      "gmt": -3,
      "dst": -2
    }
  },
  {
    "code": "YQX",
    "countryCode": "CA",
    "timezone": "America/St_Johns",
    "offset": {
      "gmt": -3,
      "dst": -2
    }
  },
  {
    "code": "YQY",
    "countryCode": "CA",
    "timezone": "America/Glace_Bay",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YQY",
    "countryCode": "CA",
    "timezone": "America/Glace_Bay",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YQZ",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YQZ",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YRA",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YRA",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YRB",
    "countryCode": "CA",
    "timezone": "America/Resolute",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YRB",
    "countryCode": "CA",
    "timezone": "America/Resolute",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YRC",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YRC",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YRD",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YRD",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YRE",
    "countryCode": "CA",
    "timezone": "America/Pangnirtung",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YRE",
    "countryCode": "CA",
    "timezone": "America/Pangnirtung",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YRF",
    "countryCode": "CA",
    "timezone": "America/Goose_Bay",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YRF",
    "countryCode": "CA",
    "timezone": "America/Goose_Bay",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YRG",
    "countryCode": "CA",
    "timezone": "America/Goose_Bay",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YRG",
    "countryCode": "CA",
    "timezone": "America/Goose_Bay",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YRI",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YRI",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YRJ",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YRJ",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YRL",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YRL",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YRM",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YRM",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YRN",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YRN",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YRO",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YRQ",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YRQ",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YRR",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YRR",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YRS",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YRS",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YRT",
    "countryCode": "CA",
    "timezone": "America/Rankin_Inlet",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YRT",
    "countryCode": "CA",
    "timezone": "America/Rankin_Inlet",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YRV",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YRV",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YSA",
    "countryCode": "CA",
    "timezone": "America/Halifax",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YSA",
    "countryCode": "CA",
    "timezone": "America/Halifax",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YSB",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YSB",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YSC",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YSC",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YSD",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YSD",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YSE",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YSE",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YSF",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "YSF",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "YSG",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YSG",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YSH",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YSH",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YSI",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YSI",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YSJ",
    "countryCode": "CA",
    "timezone": "America/Moncton",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YSJ",
    "countryCode": "CA",
    "timezone": "America/Moncton",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YSK",
    "countryCode": "CA",
    "timezone": "America/Iqaluit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YSK",
    "countryCode": "CA",
    "timezone": "America/Iqaluit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YSL",
    "countryCode": "CA",
    "timezone": "America/Moncton",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YSL",
    "countryCode": "CA",
    "timezone": "America/Moncton",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YSM",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YSM",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YSN",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YSN",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YSO",
    "countryCode": "CA",
    "timezone": "America/Goose_Bay",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YSO",
    "countryCode": "CA",
    "timezone": "America/Goose_Bay",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YSP",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YSP",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YSQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YSQ",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YSQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YSR",
    "countryCode": "CA",
    "timezone": "America/Iqaluit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YSR",
    "countryCode": "CA",
    "timezone": "America/Iqaluit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YSS",
    "countryCode": "CA",
    "timezone": "America/Nipigon",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YST",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YST",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YSU",
    "countryCode": "CA",
    "timezone": "America/Halifax",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YSU",
    "countryCode": "CA",
    "timezone": "America/Halifax",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YSV",
    "countryCode": "CA",
    "timezone": "America/Goose_Bay",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YSX",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YSX",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YSY",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YSY",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YSZ",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YSZ",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YTA",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YTA",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YTB",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YTB",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YTC",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YTC",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YTD",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YTD",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YTE",
    "countryCode": "CA",
    "timezone": "America/Iqaluit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YTE",
    "countryCode": "CA",
    "timezone": "America/Iqaluit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YTF",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YTF",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YTG",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YTG",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YTH",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YTH",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YTI",
    "countryCode": "CA",
    "timezone": "America/Whitehorse",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YTI",
    "countryCode": "CA",
    "timezone": "America/Whitehorse",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YTJ",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YTJ",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YTK",
    "countryCode": "CA",
    "timezone": "America/Montreal",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YTL",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YTL",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YTM",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YTM",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YTM",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YTN",
    "countryCode": "CA",
    "timezone": "America/Montreal",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YTN",
    "countryCode": "CA",
    "timezone": "America/Montreal",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YTO",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YTP",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YTQ",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YTQ",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YTR",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YTR",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YTS",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YTS",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YTT",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "YTT",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "YTU",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YTU",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YTW",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "YTX",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YTX",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YTY",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YTY",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YTY",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YTZ",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YUA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YUA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YUB",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YUB",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YUD",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YUD",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YUE",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "YUE",
    "countryCode": "AU",
    "timezone": "Australia/Darwin",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "YUG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YUK",
    "countryCode": "AM",
    "timezone": "Asia/Yerevan",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "YUK",
    "countryCode": "AM",
    "timezone": "Asia/Yerevan",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "YUL",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YUM",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "YUM",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "YUQ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YUS",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YUS",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YUT",
    "countryCode": "CA",
    "timezone": "America/Rankin_Inlet",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YUT",
    "countryCode": "CA",
    "timezone": "America/Rankin_Inlet",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YUX",
    "countryCode": "CA",
    "timezone": "America/Iqaluit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YUX",
    "countryCode": "CA",
    "timezone": "America/Iqaluit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YUY",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YUY",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YVA",
    "countryCode": "KM",
    "timezone": "Indian/Comoro",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "YVA",
    "countryCode": "KM",
    "timezone": "Indian/Comoro",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "YVB",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YVB",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YVC",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "YVC",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "YVD",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "YVE",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YVE",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YVG",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YVG",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YVH",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "YVH",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "YVK",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "YVM",
    "countryCode": "CA",
    "timezone": "America/Iqaluit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YVM",
    "countryCode": "CA",
    "timezone": "America/Iqaluit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YVN",
    "countryCode": "CA",
    "timezone": "America/Iqaluit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YVN",
    "countryCode": "CA",
    "timezone": "America/Iqaluit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YVO",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YVO",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YVP",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YVP",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YVQ",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YVQ",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YVR",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YVR",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YVS",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "YVS",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "YVT",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "YVT",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "YVV",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YVZ",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YVZ",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YWA",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YWA",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YWB",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YWB",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YWF",
    "countryCode": "CA",
    "timezone": "America/Halifax",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YWG",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YWG",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YWH",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YWJ",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YWJ",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YWK",
    "countryCode": "CA",
    "timezone": "America/Goose_Bay",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YWK",
    "countryCode": "CA",
    "timezone": "America/Goose_Bay",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YWL",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YWL",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YWM",
    "countryCode": "CA",
    "timezone": "America/St_Johns",
    "offset": {
      "gmt": -3,
      "dst": -2
    }
  },
  {
    "code": "YWM",
    "countryCode": "CA",
    "timezone": "America/St_Johns",
    "offset": {
      "gmt": -3,
      "dst": -2
    }
  },
  {
    "code": "YWN",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YWN",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YWO",
    "countryCode": "CA",
    "timezone": "America/Cambridge_Bay",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YWO",
    "countryCode": "CA",
    "timezone": "America/Cambridge_Bay",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YWP",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YWP",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YWQ",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YWQ",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YWR",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YWR",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YWS",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YWS",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YWY",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YWY",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YXC",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YXC",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YXD",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YXE",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "YXE",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "YXF",
    "countryCode": "CA",
    "timezone": "America/Whitehorse",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YXH",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YXH",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YXI",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YXJ",
    "countryCode": "CA",
    "timezone": "America/Dawson_Creek",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "YXJ",
    "countryCode": "CA",
    "timezone": "America/Dawson_Creek",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "YXK",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YXK",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YXL",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YXL",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YXN",
    "countryCode": "CA",
    "timezone": "America/Rankin_Inlet",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YXN",
    "countryCode": "CA",
    "timezone": "America/Rankin_Inlet",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YXP",
    "countryCode": "CA",
    "timezone": "America/Iqaluit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YXP",
    "countryCode": "CA",
    "timezone": "America/Iqaluit",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YXQ",
    "countryCode": "CA",
    "timezone": "America/Whitehorse",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "YXQ",
    "countryCode": "CA",
    "timezone": "America/Whitehorse",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "YXR",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YXR",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YXS",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YXS",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YXT",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YXT",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YXU",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YXU",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YXX",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YXX",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YXY",
    "countryCode": "CA",
    "timezone": "America/Whitehorse",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "YXY",
    "countryCode": "CA",
    "timezone": "America/Whitehorse",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "YXZ",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YXZ",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YYA",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YYA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YYA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YYB",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YYB",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YYC",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YYC",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YYD",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YYD",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YYE",
    "countryCode": "CA",
    "timezone": "America/Fort_Nelson",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "YYE",
    "countryCode": "CA",
    "timezone": "America/Fort_Nelson",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "YYF",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YYF",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YYG",
    "countryCode": "CA",
    "timezone": "America/Halifax",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YYG",
    "countryCode": "CA",
    "timezone": "America/Halifax",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YYH",
    "countryCode": "CA",
    "timezone": "America/Cambridge_Bay",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YYH",
    "countryCode": "CA",
    "timezone": "America/Cambridge_Bay",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YYI",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YYJ",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YYJ",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YYL",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YYL",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YYM",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YYM",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YYN",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "YYN",
    "countryCode": "CA",
    "timezone": "America/Swift_Current",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "YYP",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YYP",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YYQ",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YYQ",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "YYR",
    "countryCode": "CA",
    "timezone": "America/Goose_Bay",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YYR",
    "countryCode": "CA",
    "timezone": "America/Goose_Bay",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YYT",
    "countryCode": "CA",
    "timezone": "America/St_Johns",
    "offset": {
      "gmt": -3,
      "dst": -2
    }
  },
  {
    "code": "YYT",
    "countryCode": "CA",
    "timezone": "America/St_Johns",
    "offset": {
      "gmt": -3,
      "dst": -2
    }
  },
  {
    "code": "YYU",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YYU",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YYW",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YYW",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YYY",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YYY",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YYZ",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YZA",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YZA",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YZC",
    "countryCode": "CA",
    "timezone": "America/Dawson_Creek",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "YZC",
    "countryCode": "CA",
    "timezone": "America/Dawson_Creek",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "YZE",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YZE",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YZF",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YZF",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YZG",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YZG",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YZH",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YZH",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YZJ",
    "countryCode": "MK",
    "timezone": "Europe/Skopje",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "YZL",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YZL",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YZM",
    "countryCode": "CA",
    "timezone": "America/St_Johns",
    "offset": {
      "gmt": -3,
      "dst": -2
    }
  },
  {
    "code": "YZP",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YZP",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YZR",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YZR",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YZS",
    "countryCode": "CA",
    "timezone": "America/Atikokan",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "YZS",
    "countryCode": "CA",
    "timezone": "America/Atikokan",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "YZT",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YZT",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YZU",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YZU",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "YZV",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YZV",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "YZW",
    "countryCode": "CA",
    "timezone": "America/Whitehorse",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "YZW",
    "countryCode": "CA",
    "timezone": "America/Whitehorse",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "YZX",
    "countryCode": "CA",
    "timezone": "America/Halifax",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YZX",
    "countryCode": "CA",
    "timezone": "America/Halifax",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "YZY",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YZY",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "YZZ",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "YZZ",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ZAA",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ZAA",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ZAB",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ZAC",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ZAC",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ZAD",
    "countryCode": "HR",
    "timezone": "Europe/Zagreb",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZAD",
    "countryCode": "HR",
    "timezone": "Europe/Zagreb",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZAE",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ZAF",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZAG",
    "countryCode": "HR",
    "timezone": "Europe/Zagreb",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZAG",
    "countryCode": "HR",
    "timezone": "Europe/Zagreb",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZAH",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ZAH",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ZAI",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ZAI",
    "countryCode": "AR",
    "timezone": "America/Argentina/Salta",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ZAJ",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "ZAJ",
    "countryCode": "AF",
    "timezone": "Asia/Kabul",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "ZAK",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZAK",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZAL",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "ZAL",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "ZAM",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZAM",
    "countryCode": "PH",
    "timezone": "Asia/Manila",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZAN",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "ZAO",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZAO",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZAP",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZAQ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZAR",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ZAR",
    "countryCode": "NG",
    "timezone": "Africa/Lagos",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ZAS",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZAS",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZAT",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZAT",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZAU",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZAV",
    "countryCode": "PT",
    "timezone": "Europe/Lisbon",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "ZAW",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZAX",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZAY",
    "countryCode": "BE",
    "timezone": "Europe/Brussels",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZAZ",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZAZ",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZBA",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZBB",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZBC",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZBD",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZBD",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZBE",
    "countryCode": "CZ",
    "timezone": "Europe/Prague",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZBF",
    "countryCode": "CA",
    "timezone": "America/Moncton",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "ZBF",
    "countryCode": "CA",
    "timezone": "America/Moncton",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "ZBG",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZBH",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZBI",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ZBJ",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZBK",
    "countryCode": "ME",
    "timezone": "Europe/Podgorica",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZBL",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ZBM",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ZBM",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ZBN",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZBO",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ZBO",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ZBP",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ZBQ",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZBR",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ZBR",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ZBS",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "ZBT",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZBU",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZBV",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZBW",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ZBX",
    "countryCode": "HU",
    "timezone": "Europe/Budapest",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZBY",
    "countryCode": "LA",
    "timezone": "Asia/Vientiane",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "ZBY",
    "countryCode": "LA",
    "timezone": "Asia/Vientiane",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "ZBZ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZCA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZCA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZCB",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZCC",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZCD",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZCD",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZCE",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZCF",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZCG",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZCH",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZCI",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZCJ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZCK",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZCL",
    "countryCode": "MX",
    "timezone": "America/Monterrey",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "ZCL",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "ZCM",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZCN",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZCN",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZCO",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "ZCO",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "ZCP",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZCQ",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "ZCR",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZCS",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZCT",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZCU",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZCV",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZCV",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZCW",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZCW",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZCX",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZCY",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZCZ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZDA",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZDB",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZDC",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZDD",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZDE",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZDF",
    "countryCode": "PS",
    "timezone": "Asia/Hebron",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "ZDG",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZDH",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZDI",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZDI",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZDJ",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZDK",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZDK",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZDL",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZDM",
    "countryCode": "PS",
    "timezone": "Asia/Hebron",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "ZDN",
    "countryCode": "CZ",
    "timezone": "Europe/Prague",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZDO",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZDP",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZDQ",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZDR",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZDS",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZDT",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZDU",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZDV",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZDV",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZDV",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZDW",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZDX",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZDY",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZDY",
    "countryCode": "AE",
    "timezone": "Asia/Dubai",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "ZDY",
    "countryCode": "AE",
    "timezone": "Asia/Dubai",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "ZDY",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZDZ",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZEA",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZEB",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZEC",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "ZEC",
    "countryCode": "ZA",
    "timezone": "Africa/Johannesburg",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "ZED",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZEE",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZEE",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZEF",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZEF",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZEG",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "ZEH",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZEI",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZEJ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZEK",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZEL",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ZEL",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ZEM",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ZEM",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ZEN",
    "countryCode": "PG",
    "timezone": "Pacific/Port_Moresby",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ZEO",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZEP",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "ZEQ",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "ZER",
    "countryCode": "IN",
    "timezone": "Asia/Kolkata",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "ZES",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZET",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZEU",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZEU",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZEV",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZEV",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZEW",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZEX",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZEY",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZEY",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZEZ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZFA",
    "countryCode": "CA",
    "timezone": "America/Whitehorse",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "ZFA",
    "countryCode": "CA",
    "timezone": "America/Whitehorse",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "ZFB",
    "countryCode": "CA",
    "timezone": "America/Blanc-Sablon",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "ZFC",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "ZFD",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "ZFD",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "ZFE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ZFF",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZFG",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "ZFH",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZFI",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "ZFJ",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZFK",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZFL",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "ZFL",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ZFL",
    "countryCode": "CN",
    "timezone": "Asia/Urumqi",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "ZFM",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "ZFM",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "ZFN",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "ZFN",
    "countryCode": "CA",
    "timezone": "America/Yellowknife",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "ZFO",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ZFP",
    "countryCode": "HU",
    "timezone": "Europe/Budapest",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZFQ",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZFR",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZFR",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZFS",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ZFT",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ZFU",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ZFV",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ZFW",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "ZFW",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "ZFX",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZFY",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZFZ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZGA",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZGB",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "ZGC",
    "countryCode": "CN",
    "timezone": "Asia/Chongqing",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZGC",
    "countryCode": "HR",
    "timezone": "Europe/Zagreb",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZGD",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ZGE",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZGF",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ZGF",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ZGG",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "ZGH",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZGI",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ZGI",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ZGJ",
    "countryCode": "BE",
    "timezone": "Europe/Brussels",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZGK",
    "countryCode": "BE",
    "timezone": "Europe/Brussels",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZGL",
    "countryCode": "AU",
    "timezone": "Australia/Brisbane",
    "offset": {
      "gmt": 10,
      "dst": 10
    }
  },
  {
    "code": "ZGM",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "ZGM",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "ZGN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZGN",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZGO",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZGP",
    "countryCode": "BE",
    "timezone": "Europe/Brussels",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZGQ",
    "countryCode": "BE",
    "timezone": "Europe/Brussels",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZGR",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ZGR",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ZGS",
    "countryCode": "CA",
    "timezone": "America/Blanc-Sablon",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "ZGS",
    "countryCode": "CA",
    "timezone": "America/Blanc-Sablon",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "ZGU",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "ZGU",
    "countryCode": "VU",
    "timezone": "Pacific/Efate",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "ZGV",
    "countryCode": "BE",
    "timezone": "Europe/Brussels",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZGW",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZGX",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZGY",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZGZ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZHA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZHA",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZHB",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZHC",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZHD",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZHE",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZHF",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZHG",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZHH",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZHI",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZHI",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZHJ",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZHK",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZHL",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZHM",
    "countryCode": "BD",
    "timezone": "Asia/Dhaka",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "ZHM",
    "countryCode": "BD",
    "timezone": "Asia/Dhaka",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "ZHN",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZHO",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ZHO",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ZHP",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "ZHP",
    "countryCode": "CA",
    "timezone": "America/Edmonton",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "ZHR",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZHS",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZHT",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZHU",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZHV",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZHV",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZHW",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZHX",
    "countryCode": "BR",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZHY",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZHY",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZHZ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZIA",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZIA",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ZIA",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ZIA",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZIB",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZIC",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "ZIC",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "ZID",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZIE",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZIF",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZIG",
    "countryCode": "SN",
    "timezone": "Africa/Dakar",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "ZIG",
    "countryCode": "SN",
    "timezone": "Africa/Dakar",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "ZIH",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "ZIH",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "ZII",
    "countryCode": "AU",
    "timezone": "Australia/Adelaide",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZIJ",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZIK",
    "countryCode": "CZ",
    "timezone": "Europe/Prague",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZIL",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZIM",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZIN",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZIN",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZIO",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZIO",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZIP",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZIQ",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZIR",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZIS",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "ZIS",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZIS",
    "countryCode": "LY",
    "timezone": "Africa/Tripoli",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "ZIT",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZIT",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZIU",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZIV",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZIW",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZIX",
    "countryCode": "RU",
    "timezone": "Asia/Yakutsk",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "ZIX",
    "countryCode": "RU",
    "timezone": "Asia/Yakutsk",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "ZIY",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZIY",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZIZ",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "ZIZ",
    "countryCode": "PK",
    "timezone": "Asia/Karachi",
    "offset": {
      "gmt": 5,
      "dst": 5
    }
  },
  {
    "code": "ZJA",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZJB",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZJC",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZJD",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZJE",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZJF",
    "countryCode": "AE",
    "timezone": "Asia/Dubai",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "ZJF",
    "countryCode": "AE",
    "timezone": "Asia/Dubai",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "ZJG",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ZJG",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ZJH",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZJI",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZJI",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZJJ",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZJK",
    "countryCode": "HK",
    "timezone": "Asia/Hong_Kong",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZJL",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZJM",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZJN",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ZJN",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ZJO",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZJP",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZJQ",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZJR",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZJS",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZJT",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZJU",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZJV",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZJW",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZJX",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZJY",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZJZ",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZKA",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZKB",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "ZKC",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZKD",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZKE",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ZKE",
    "countryCode": "CA",
    "timezone": "America/Toronto",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ZKF",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZKG",
    "countryCode": "CA",
    "timezone": "America/Blanc-Sablon",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "ZKG",
    "countryCode": "CA",
    "timezone": "America/Blanc-Sablon",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "ZKH",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZKI",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZKJ",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZKK",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZKL",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZKL",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "ZKL",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZKM",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ZKM",
    "countryCode": "GA",
    "timezone": "Africa/Libreville",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ZKN",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZKO",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZKP",
    "countryCode": "ZM",
    "timezone": "Africa/Lusaka",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "ZKP",
    "countryCode": "RU",
    "timezone": "Asia/Magadan",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "ZKP",
    "countryCode": "RU",
    "timezone": "Asia/Magadan",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "ZKQ",
    "countryCode": "ME",
    "timezone": "Europe/Podgorica",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZKR",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZKS",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZKT",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZKU",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZKV",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZKW",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZKX",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZKY",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZKZ",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZLA",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZLB",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZLC",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZLD",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZLE",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZLE",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZLF",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZLG",
    "countryCode": "HU",
    "timezone": "Europe/Budapest",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZLH",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZLI",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZLJ",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZLK",
    "countryCode": "RU",
    "timezone": "Europe/Moscow",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZLL",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZLM",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZLN",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZLO",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "ZLO",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "ZLP",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZLQ",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZLR",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "ZLR",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "ZLS",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "ZLT",
    "countryCode": "CA",
    "timezone": "America/Blanc-Sablon",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "ZLT",
    "countryCode": "CA",
    "timezone": "America/Blanc-Sablon",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "ZLU",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZLV",
    "countryCode": "FI",
    "timezone": "Europe/Helsinki",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZLW",
    "countryCode": "MY",
    "timezone": "Asia/Kuala_Lumpur",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZLX",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "ZLX",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "ZLX",
    "countryCode": "SD",
    "timezone": "Africa/Khartoum",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "ZLY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ZLZ",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "ZMA",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "ZMB",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZMC",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZMD",
    "countryCode": "BR",
    "timezone": "America/Rio_Branco",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ZMD",
    "countryCode": "BR",
    "timezone": "America/Eirunepe",
    "offset": {
      "gmt": -5,
      "dst": -5
    }
  },
  {
    "code": "ZME",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ZME",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ZMF",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZMG",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZMH",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ZMH",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ZMI",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZMJ",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZMK",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZML",
    "countryCode": "US",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZMM",
    "countryCode": "MX",
    "timezone": "America/Mexico_City",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "ZMN",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZMO",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZMP",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "ZMQ",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZMR",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZMS",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZMT",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ZMT",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ZMU",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZMV",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZMW",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZMX",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZMY",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZMY",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZMZ",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZMZ",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZNA",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ZNB",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZNC",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "ZNC",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": -9,
      "dst": -8
    }
  },
  {
    "code": "ZND",
    "countryCode": "NE",
    "timezone": "Africa/Niamey",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ZND",
    "countryCode": "NE",
    "timezone": "Africa/Niamey",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ZNE",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZNE",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZNF",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZNG",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ZNG",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZNH",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZNI",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZNJ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZNK",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZNL",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZNM",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZNN",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZNN",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZNO",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZNP",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZNQ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZNR",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZNS",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZNS",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZNT",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZNU",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ZNU",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ZNV",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZNV",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZNW",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZNX",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZNX",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZNY",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZNZ",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ZNZ",
    "countryCode": "TZ",
    "timezone": "Africa/Dar_es_Salaam",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ZOA",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZOB",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZOB",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZOB",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZOC",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZOD",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZOE",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZOF",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ZOF",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ZOG",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZOH",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZOI",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZOJ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZOK",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZOK",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZOL",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZOM",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZON",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZON",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZOO",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZOO",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZOP",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZOQ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZOR",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZOS",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "ZOS",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "ZOT",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZOU",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZOV",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZOW",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZOX",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZOY",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZOY",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZOZ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZPA",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZPB",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ZPB",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ZPC",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "ZPC",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "ZPD",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZPE",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZPF",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZPF",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZPG",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZPH",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ZPH",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ZPI",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZPJ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZPJ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZPK",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZPL",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZPM",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZPM",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZPN",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZPN",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZPO",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "ZPO",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "ZPP",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZPQ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZPQ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZPR",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZPR",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZPS",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZPT",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZPU",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZPV",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZPW",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZPX",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZPY",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZPZ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZQA",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZQB",
    "countryCode": "US",
    "timezone": "America/Anchorage",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZQC",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZQD",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZQE",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZQF",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZQG",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZQH",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZQI",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZQJ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZQK",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZQL",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZQM",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZQN",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "ZQN",
    "countryCode": "NZ",
    "timezone": "Pacific/Auckland",
    "offset": {
      "gmt": 13,
      "dst": 12
    }
  },
  {
    "code": "ZQO",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZQP",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZQQ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZQR",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZQS",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ZQS",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ZQT",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZQU",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZQV",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZQV",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZQW",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZQX",
    "countryCode": "ID",
    "timezone": "Asia/Jakarta",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZQY",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZQZ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZQZ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZRA",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ZRB",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZRC",
    "countryCode": "ES",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZRD",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ZRE",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "ZRF",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ZRG",
    "countryCode": "SK",
    "timezone": "Europe/Bratislava",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZRH",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZRH",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZRI",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "ZRI",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "ZRJ",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ZRJ",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ZRK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ZRK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ZRL",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZRM",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "ZRM",
    "countryCode": "ID",
    "timezone": "Asia/Jayapura",
    "offset": {
      "gmt": 9,
      "dst": 9
    }
  },
  {
    "code": "ZRN",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZRO",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZRP",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ZRQ",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZRR",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZRS",
    "countryCode": "AT",
    "timezone": "Europe/Vienna",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZRT",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ZRU",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZRV",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZRW",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZRX",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZRY",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZRY",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZRZ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ZSA",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ZSA",
    "countryCode": "BS",
    "timezone": "America/Nassau",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ZSB",
    "countryCode": "AT",
    "timezone": "Europe/Vienna",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZSC",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZSD",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZSD",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZSE",
    "countryCode": "RE",
    "timezone": "Indian/Reunion",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "ZSE",
    "countryCode": "RE",
    "timezone": "Indian/Reunion",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "ZSF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ZSG",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZSH",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": -7,
      "dst": -6
    }
  },
  {
    "code": "ZSI",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZSJ",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ZSJ",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ZSK",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZSL",
    "countryCode": "IE",
    "timezone": "Europe/Dublin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZSM",
    "countryCode": "US",
    "timezone": "America/Los_Angeles",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZSN",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZSO",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZSP",
    "countryCode": "CA",
    "timezone": "America/Montreal",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ZSQ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZSR",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZSS",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "ZSS",
    "countryCode": "CI",
    "timezone": "Africa/Abidjan",
    "offset": {
      "gmt": 0,
      "dst": 0
    }
  },
  {
    "code": "ZST",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ZST",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ZSU",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZSV",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ZSW",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ZSX",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZSY",
    "countryCode": "US",
    "timezone": "America/Phoenix",
    "offset": {
      "gmt": -7,
      "dst": -7
    }
  },
  {
    "code": "ZSZ",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZTA",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "ZTA",
    "countryCode": "PF",
    "timezone": "Pacific/Tahiti",
    "offset": {
      "gmt": -10,
      "dst": -10
    }
  },
  {
    "code": "ZTB",
    "countryCode": "CA",
    "timezone": "America/Blanc-Sablon",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "ZTB",
    "countryCode": "CA",
    "timezone": "America/Blanc-Sablon",
    "offset": {
      "gmt": -4,
      "dst": -4
    }
  },
  {
    "code": "ZTC",
    "countryCode": "IT",
    "timezone": "Europe/Rome",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZTD",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZTE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZTF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ZTF",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ZTG",
    "countryCode": "DK",
    "timezone": "Europe/Copenhagen",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZTH",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "ZTH",
    "countryCode": "GR",
    "timezone": "Europe/Athens",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "ZTI",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZTI",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZTJ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ZTJ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ZTK",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZTL",
    "countryCode": "US",
    "timezone": "America/Denver",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZTM",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ZTM",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ZTN",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZTO",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZTP",
    "countryCode": "BR",
    "timezone": "America/Sao_Paulo",
    "offset": {
      "gmt": -3,
      "dst": -3
    }
  },
  {
    "code": "ZTQ",
    "countryCode": "CH",
    "timezone": "Europe/Zurich",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZTR",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "ZTR",
    "countryCode": "UA",
    "timezone": "Europe/Kyiv",
    "offset": {
      "gmt": 2,
      "dst": 3
    }
  },
  {
    "code": "ZTS",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ZTS",
    "countryCode": "CA",
    "timezone": "America/Vancouver",
    "offset": {
      "gmt": -8,
      "dst": -7
    }
  },
  {
    "code": "ZTT",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZTU",
    "countryCode": "AZ",
    "timezone": "Asia/Baku",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "ZTU",
    "countryCode": "AZ",
    "timezone": "Asia/Baku",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "ZTV",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZTW",
    "countryCode": "HK",
    "timezone": "Asia/Hong_Kong",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZTX",
    "countryCode": "HK",
    "timezone": "Asia/Hong_Kong",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZTY",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZTZ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZTZ",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZUA",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ZUA",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ZUB",
    "countryCode": "GE",
    "timezone": "Asia/Tbilisi",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZUC",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ZUC",
    "countryCode": "CA",
    "timezone": "America/Winnipeg",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ZUD",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "ZUD",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "ZUG",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZUH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZUH",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZUI",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZUJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZUJ",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZUK",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZUL",
    "countryCode": "SA",
    "timezone": "Asia/Riyadh",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ZUM",
    "countryCode": "CA",
    "timezone": "America/Goose_Bay",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "ZUM",
    "countryCode": "CA",
    "timezone": "America/Goose_Bay",
    "offset": {
      "gmt": -4,
      "dst": -3
    }
  },
  {
    "code": "ZUN",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ZUO",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZUP",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZUQ",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZUR",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZUS",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZUT",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZUU",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZUV",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZUW",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZUX",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZUY",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZUZ",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZVA",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ZVA",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ZVB",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZVC",
    "countryCode": "CL",
    "timezone": "America/Santiago",
    "offset": {
      "gmt": -3,
      "dst": -4
    }
  },
  {
    "code": "ZVD",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZVD",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZVE",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ZVG",
    "countryCode": "AU",
    "timezone": "Australia/Perth",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZVH",
    "countryCode": "AE",
    "timezone": "Asia/Dubai",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "ZVI",
    "countryCode": "IR",
    "timezone": "Asia/Tehran",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZVJ",
    "countryCode": "AE",
    "timezone": "Asia/Dubai",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "ZVK",
    "countryCode": "LA",
    "timezone": "Asia/Vientiane",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "ZVK",
    "countryCode": "LA",
    "timezone": "Asia/Vientiane",
    "offset": {
      "gmt": 7,
      "dst": 7
    }
  },
  {
    "code": "ZVL",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZVM",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZVN",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZVO",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZVP",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZVQ",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZVR",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZVS",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZVT",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZVU",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZVV",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZVW",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZVX",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZVY",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZVZ",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZWA",
    "countryCode": "MG",
    "timezone": "Indian/Antananarivo",
    "offset": {
      "gmt": 3,
      "dst": 3
    }
  },
  {
    "code": "ZWB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ZWB",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ZWC",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZWD",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZWE",
    "countryCode": "BE",
    "timezone": "Europe/Brussels",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZWG",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZWH",
    "countryCode": "NA",
    "timezone": "Africa/Windhoek",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "ZWI",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ZWJ",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZWK",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZWK",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZWL",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "ZWL",
    "countryCode": "CA",
    "timezone": "America/Regina",
    "offset": {
      "gmt": -6,
      "dst": -6
    }
  },
  {
    "code": "ZWM",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZWN",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZWO",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZWP",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ZWQ",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZWR",
    "countryCode": "MY",
    "timezone": "Asia/Kuching",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZWS",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZWT",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZWU",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ZWV",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ZWV",
    "countryCode": "US",
    "timezone": "America/Chicago",
    "offset": {
      "gmt": -6,
      "dst": -5
    }
  },
  {
    "code": "ZWW",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ZWW",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ZWX",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZWY",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZWZ",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZXA",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZXB",
    "countryCode": "SJ",
    "timezone": "Arctic/Longyearbyen",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZXC",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZXD",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZXE",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  },
  {
    "code": "ZXG",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZXH",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZXI",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZXJ",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZXK",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZXL",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZXM",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZXN",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZXO",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZXP",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZXQ",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZXR",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZXS",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZXT",
    "countryCode": "AZ",
    "timezone": "Asia/Baku",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "ZXU",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZXX",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZXY",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZXZ",
    "countryCode": "AE",
    "timezone": "Asia/Dubai",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZYA",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZYB",
    "countryCode": "BE",
    "timezone": "Europe/Brussels",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZYC",
    "countryCode": "PL",
    "timezone": "Europe/Warsaw",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZYD",
    "countryCode": "PT",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZYE",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZYF",
    "countryCode": "PT",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZYG",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZYH",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZYI",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZYI",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZYI",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZYJ",
    "countryCode": "PT",
    "timezone": "Europe/Madrid",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZYK",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZYK",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZYL",
    "countryCode": "BD",
    "timezone": "Asia/Dhaka",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "ZYL",
    "countryCode": "BD",
    "timezone": "Asia/Dhaka",
    "offset": {
      "gmt": 6,
      "dst": 6
    }
  },
  {
    "code": "ZYM",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZYM",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZYN",
    "countryCode": "FR",
    "timezone": "Europe/Paris",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZYO",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZYP",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ZYQ",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZYR",
    "countryCode": "BE",
    "timezone": "Europe/Brussels",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZYS",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZYS",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZYT",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZYU",
    "countryCode": "NL",
    "timezone": "Europe/Amsterdam",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZYV",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZYW",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZYX",
    "countryCode": "CN",
    "timezone": "Asia/Shanghai",
    "offset": {
      "gmt": 8,
      "dst": 8
    }
  },
  {
    "code": "ZYY",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZYZ",
    "countryCode": "BE",
    "timezone": "Europe/Brussels",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZZA",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ZZB",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ZZC",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ZZD",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ZZE",
    "countryCode": "AZ",
    "timezone": "Asia/Baku",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "ZZE",
    "countryCode": "AZ",
    "timezone": "Asia/Baku",
    "offset": {
      "gmt": 4,
      "dst": 4
    }
  },
  {
    "code": "ZZF",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": 11,
      "dst": 10
    }
  },
  {
    "code": "ZZG",
    "countryCode": "DE",
    "timezone": "Europe/Berlin",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZZI",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZZJ",
    "countryCode": "AU",
    "timezone": "Australia/Melbourne",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZZK",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ZZL",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ZZM",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ZZN",
    "countryCode": "NO",
    "timezone": "Europe/Oslo",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZZO",
    "countryCode": "RU",
    "timezone": "Asia/Sakhalin",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "ZZO",
    "countryCode": "RU",
    "timezone": "Asia/Sakhalin",
    "offset": {
      "gmt": 11,
      "dst": 11
    }
  },
  {
    "code": "ZZP",
    "countryCode": "RS",
    "timezone": "Europe/Belgrade",
    "offset": {
      "gmt": 1,
      "dst": 2
    }
  },
  {
    "code": "ZZR",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ZZS",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ZZT",
    "countryCode": "DZ",
    "timezone": "Africa/Algiers",
    "offset": {
      "gmt": 1,
      "dst": 1
    }
  },
  {
    "code": "ZZU",
    "countryCode": "MW",
    "timezone": "Africa/Blantyre",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "ZZU",
    "countryCode": "MW",
    "timezone": "Africa/Blantyre",
    "offset": {
      "gmt": 2,
      "dst": 2
    }
  },
  {
    "code": "ZZV",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ZZV",
    "countryCode": "US",
    "timezone": "America/New_York",
    "offset": {
      "gmt": -5,
      "dst": -4
    }
  },
  {
    "code": "ZZW",
    "countryCode": "AU",
    "timezone": "Australia/Sydney",
    "offset": {
      "gmt": null,
      "dst": null
    }
  },
  {
    "code": "ZZZ",
    "countryCode": "GB",
    "timezone": "Europe/London",
    "offset": {
      "gmt": 0,
      "dst": 1
    }
  }
]
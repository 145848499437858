import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TicketTableRow } from '../pages/tickets/tickets.component';
import { HttpClient } from '@angular/common/http';
import { FLIGHT_API } from '@modules/flight/constants';
import jsPDF from 'jspdf';
import { FlightHistory } from '@modules/flight-history/flight-history.component';

@Injectable({
  providedIn: 'root',
})
export class MyTicketsService {
  private _viewTicket: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  viewTicket$ = this._viewTicket.asObservable();

  private _selectedTicket: BehaviorSubject<TicketTableRow | undefined> =
    new BehaviorSubject<TicketTableRow | undefined>(undefined);
  selectedTicket$ = this._selectedTicket.asObservable();

  private _downloadableTicket: BehaviorSubject<jsPDF | undefined> =
    new BehaviorSubject<jsPDF | undefined>(undefined);
  downloadableTicket$ = this._downloadableTicket.asObservable();

  constructor(private _http: HttpClient) {}

  setViewTicket(value: boolean) {
    this._viewTicket.next(value);
  }

  setSelectedTicket(value: TicketTableRow | undefined) {
    this._selectedTicket.next(value);
  }

  setDownloadableTicket(value: jsPDF | undefined) {
    this._downloadableTicket.next(value);
  }

  getUpcomingFlights(): Observable<TicketTableRow[]> {
    return this._http.get<TicketTableRow[]>(
      `${FLIGHT_API.GET_ALL_UPCOMING_FLIGHT}`,
    );
  }

  getFlightHistory(): Observable<FlightHistory[]> {
    return this._http.get<FlightHistory[]>(
      `${FLIGHT_API.GET_ALL_FLIGHT_HISTORY}`,
    );
  }
}

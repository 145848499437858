import { Pipe, PipeTransform } from '@angular/core';
import airportTimezone from 'airport-timezone';
import moment from 'moment-timezone';
import { getTimeZones, TimeZone } from '@vvo/tzdb';

@Pipe({
  name: 'dateAirportLocale',
  standalone: true,
})
export class DateAirportLocalePipe implements PipeTransform {
  transform(
    value: string,
    iataCode: string,
    format: string,
    tzAbbr: boolean = false,
  ): any {
    const airportTz = airportTimezone.filter((airport: any) => {
      return airport.code === iataCode;
    })[0];

    let airportLocalTime = moment
      .utc(value)
      .tz(airportTz.timezone)
      .format(format);

    if (tzAbbr) {
      const timezones = getTimeZones();
      const usTz = timezones.find(
        (tz: TimeZone) => tz.name === airportTz.timezone,
      );
      airportLocalTime = `${airportLocalTime} (${usTz?.abbreviation})`;
    }

    return airportLocalTime;
  }
}
